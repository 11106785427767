import React, {useCallback} from 'react';
import { useDropzone } from 'react-dropzone';

import {LoadPhotoWrapperCloud, PCloud, WithoutPhoto} from "../StyledCourse";

import cloud from "../img/Download_1_.png";

function DropzoneComponent({saveAvatar, avatar, sours, addImgSrc}) {

	const onDrop = useCallback(acceptedFiles => {
		addImgSrc(URL.createObjectURL(acceptedFiles[0]));
		saveAvatar(acceptedFiles);
	}, []);

	const {
		getRootProps,
		getInputProps
	} = useDropzone({
		onDrop
	});

		return (
		<div {...getRootProps()}>
			<input {...getInputProps()} id="fileData" />
			{avatar.length ? (
				<LoadPhotoWrapperCloud>
					<img src={sours} className="im-sours"/>
				</LoadPhotoWrapperCloud>
			) : (
				<WithoutPhoto>
					<img src={cloud} />
					<PCloud>Drag files here or browse</PCloud>
				</WithoutPhoto>
			)}
		</div>
	)
}

export default DropzoneComponent;
