import React from 'react';

import {
	CloseBtnNot,
	NotificationContentWrapper,
	NotificationWrapper
} from "./StyleNotification";

import close from "./VectorClose.png";
import ErrImg from "./VectorErr.png"

const NotificationContentErr = (props) => {

	const setClose = props.setClose;
	return (
		<NotificationWrapper>
		<NotificationContentWrapper>
			<CloseBtnNot onClick={setClose}>
				<img src={close} alt={close}/>
			</CloseBtnNot>
			<div>
				<img src={ErrImg}/>
			</div>
			<div>
				<h3>{props.h3}</h3>
				<p>{props.p}</p>
			</div>
		</NotificationContentWrapper>
		</NotificationWrapper>
	);
};

export default NotificationContentErr;