import ReportTable from "./ReportTable";
import {ReportWrapper, ReportName} from "./ReportStyled";

const Report = () => {
    return(
        <ReportWrapper>
            <ReportName>Report</ReportName>
            <ReportTable />
        </ReportWrapper>
    )
}

export default Report;