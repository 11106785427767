import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import OneChildName from "./OneChildName";
import LabelChildMore from "./LabelChildMore";
import MoreChildName from "./MoreChildName";
import ChildNameBlock from "./ChildNameBlock";
import {setSelectChild} from "../../../../store/slicers/slice-modal";
import {childLanguageSave} from "../../../../store/slicers/name-slice";

import {WrapperForImgAnaRequired} from "../../styled-create-meeting/CreateMeetingStyled";

import kinder from "../../img/kinder.png";

const SelectChildren = ({setShowChildren, showChildren, meetingShow, child, setNewParent, newParent}) => {

	const [childName, setChildName] = useState(null);
	const [loading, setLoading] = useState(true);
	const dependents = !meetingShow && !localStorage.getItem(`${meetingShow?.parent_id}dependents`) || meetingShow === undefined ?
		[] : JSON.parse(localStorage.getItem(`${meetingShow?.parent_id}dependents`));
	const childInCall = dependents.filter(item => item.dependent_id === meetingShow.dependent_id);
	const dispatch = useDispatch();

	const handleClickChildId = (id, name, lan) => {

		setChildName(name);
		dispatch(setSelectChild(id));
		dispatch(childLanguageSave(lan));

	};

	const handleClickChildIdOne = () => {
		setChildName(null);
		setShowChildren(false);
	};

	useEffect(() => {
		if(child){
			setLoading(false)
		}
	},[child]);


	useEffect(() => {
		if (!newParent && childInCall) {
			setChildName(childInCall[0]?.first_name);
			dispatch(childLanguageSave(childInCall[0]?.preferred_language));
		} else {
			setChildName(null);
		}
	}, [newParent]);


	return (
		<div>
			{ loading ? (
				<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
			) : (
				<>
					{child ? (
						<div>
							<WrapperForImgAnaRequired>
								<div className="div-img">
									<img src={kinder} alt={kinder}/>
								</div>
								<div className="required-div">
									<span>Kid</span>
									* required
								</div>
							</WrapperForImgAnaRequired>
							{child && child.length !== 0  ? (
								<>
									{child.length === 1 ? (
										<OneChildName child={child}/>
									) : (
										<div>
											{!childName ? (
												<div style={{position: 'relative'}}>
													<LabelChildMore setShowChildren={setShowChildren}/>
													{showChildren &&
														<MoreChildName kid={child} handleClickChildId={handleClickChildId}/>
													}
												</div>
											) : (
												<ChildNameBlock handleClickChildIdOne={handleClickChildIdOne} childName={childName}/>
											)}

										</div>
									)}
								</>
							) : (<></>)}
						</div>
					) : (<></>)}

				</>
			)

			}

		</div>
	);
};

export default SelectChildren;