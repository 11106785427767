import React, {useEffect, useState} from 'react';
import {CourseEntertainerInfoWr, EntertainerBodyWr, GetAllBtn} from "../StyledCourse";
import SearchEntertainerInCourse from "./SearchEntertainerInCourse";
import rem from "../img/remove.png";
import {CLEAR_LINE} from "../../../../helpers/consts";

const EntertainerBody = ({setAddEntertainers, selectedEntertainer, setSelectedEntertainer}) => {

	const [value, setValue]=useState(CLEAR_LINE);
	const selectEntertainerHandler = (item) => {
		setSelectedEntertainer((prevState) => {
			const isItemSelected = prevState.some((ent) => ent.id === item.id);
			if (isItemSelected) {
				// Элемент уже выбран, удаляем его
				return prevState.filter((ent) => ent.id !== item.id);
			} else {
				// Элемент не выбран, добавляем его
				return [...prevState, item];
			}
		});
		setValue(CLEAR_LINE);
	};

	useEffect(()=>{
		if (selectedEntertainer){
		const entertainerIds = selectedEntertainer.map((entertainer) => entertainer.id);
		setAddEntertainers([...entertainerIds]);
		}
	},[selectedEntertainer])

	return (
		<EntertainerBodyWr>
			<SearchEntertainerInCourse selectEntertainerHandler={selectEntertainerHandler}
																 selectedEntertainer={selectedEntertainer}
																 value={value}
																 setValue={setValue}/>
			{/*<GetAllBtn>*/}
			{/*	<img src={plus}/>Add All*/}
			{/*</GetAllBtn>*/}
			{selectedEntertainer &&
				selectedEntertainer.map(entertainer => (
					<CourseEntertainerInfoWr key={entertainer.phone}>
						{entertainer.first_name} {entertainer.last_name}
						<img src={rem} onClick={()=>selectEntertainerHandler(entertainer)}/>
					</CourseEntertainerInfoWr>
				))
			}
		</EntertainerBodyWr>
	);
};

export default EntertainerBody;