import React from 'react';
import {Button, FieldContainer, Label, Select} from "../StyledConfig";

const AllowJoinPassedMeetings = (props) => {

const {
	isEditing,
	handlePublish,
	handleDiscard,
	allowJoinPassedMeetings,
	setAllowJoinPassedMeeting,
	setIsEditing
} = props;
	const editHandler = () => {
		handleDiscard('allowJoinPassedMeetings')
		setIsEditing((prevState) => ({...prevState, allowJoinPassedMeetings: true}))
	}

	return (
		<FieldContainer>
			<Label>Allow Join Passed Meetings:</Label>
			{isEditing.allowJoinPassedMeetings ? (
				<Select
					value={allowJoinPassedMeetings}
					onChange={(e) => setAllowJoinPassedMeeting(e.target.value)}>
					<option value="yes">yes</option>
					<option value="no">no (default)</option>
				</Select>
			) : (
				<div style={{width: "150px"}}>
					<span>{allowJoinPassedMeetings}</span>
				</div>

			)}
			{isEditing.allowJoinPassedMeetings ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('allowJoinPassedMeetings')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('allowJoinPassedMeetings')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>
	);
};

export default AllowJoinPassedMeetings;