import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	loadingCourse: true,
}
const loadingSlice = createSlice({
	name: "loading",
	initialState,
	reducers: {

		loadingTrue: (state, action) => {
			state.loadingCourse = true;
		},
		loadingFalse: (state, action) => {
			state.loadingCourse = false;
		},

	},

})

export const {
	loadingFalse, loadingTrue
} = loadingSlice.actions;

export default loadingSlice.reducer;
