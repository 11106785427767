import React from 'react';
import SearchItemLessons from "../search-item/SearchItemLessons";

import {
	SearchWrapperLessons
} from "../../styled-create-meeting/search-styled/SearchCollectionStyled";

const SearchCollectionLessons = ({items, closeLessons}) => {
	return (
		<SearchWrapperLessons>
			{items &&
				items.map( ( item ,index) =>
					<SearchItemLessons item={item} key={item.lesson_id} closeLessons={closeLessons} index={index}/>

				)
			}
		</SearchWrapperLessons>
	);
};

export default SearchCollectionLessons;