import React from 'react';
import SearchItemParent from "../search-item/SearchItemParent";

import {SearchWrapper} from '../../styled-create-meeting/search-styled/SearchCollectionStyled';

function SearchCollection(props) {

	const items = props.items;
	const us = props.onPersonSelect;

	return (
		<>
			<SearchWrapper>
				{items &&
					items.map( item =>
						<SearchItemParent
							key={item.id}
							item={item}
							us={us}/>
					)
				}
			</SearchWrapper>
		</>
	);
}

export default SearchCollection;