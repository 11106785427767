import React from 'react';

import {useDispatch} from "react-redux";
import {
	addEntertainerNameClear, addKidNameClear, closeIsOpenMeetingFilter,
	closeModal,
	closeOpenMeetingOptions,
	setSelectChildClear
} from "../../store/slicers/slice-modal";
import {
	childClear,
	entertainerNameClear,
	entertainerNameForClear,
	parentNameClear,
	parentNameForClear
} from "../../store/slicers/name-slice";
import {clearEventSearchListEntertainer} from "../../store/slicers/search-meetings-entertainer-slice";
import {clearEventSearchListKid} from "../../store/slicers/search-meetings-kid-slice";
import {showMeetingByIdFalse} from "../../store/slicers/calls-slice";

const useClose = () => {

	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(closeModal());
		dispatch(parentNameForClear());
		dispatch(entertainerNameForClear());
	}
	const handleCloseOptions = () => {
		dispatch(closeOpenMeetingOptions());
		dispatch(parentNameForClear());
		dispatch(entertainerNameForClear());
	}
	const handleClearGetEntertainer = () => {
		dispatch(clearEventSearchListEntertainer());
		dispatch(entertainerNameClear());
		dispatch(addEntertainerNameClear())
		dispatch(entertainerNameForClear());
	}
	const handleClearParentKid = () => {
		dispatch(clearEventSearchListKid());
		dispatch(setSelectChildClear());
		dispatch(parentNameClear());
		dispatch(addKidNameClear());
		dispatch(childClear());
		dispatch(parentNameForClear());
	}
	const handleCloseContentEdit = () => {
		dispatch(closeOpenMeetingOptions());
		dispatch(parentNameForClear());
		dispatch(entertainerNameForClear());
		dispatch(showMeetingByIdFalse());
	}
	const handlerCloseUpdateFilter = () => {
		dispatch(closeIsOpenMeetingFilter());
		dispatch(parentNameForClear());
		dispatch(entertainerNameForClear());
	}
	return {
		handleClose,
		handleCloseOptions,
		handleClearGetEntertainer,
		handleClearParentKid,
		handleCloseContentEdit,
		handlerCloseUpdateFilter
	}

};

export default useClose;