import React from 'react';
import {Alert, Button} from "react-bootstrap";

import './showModal.sass';

export default function ShowModal({show, setShow,text}) {

   return (
     <>
        <Alert show={show}
               variant="success"
               className="alert-success-wrapper">
           <Alert.Heading>{text}</Alert.Heading>
           <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant="outline-success">
                 OK
              </Button>
           </div>
        </Alert>
     </>
   );
}