import React from 'react';
import styled from "styled-components";
export const NotificationWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
	width: 400px;
	top: 10px;
	right: 0;
  z-index: 30000;
`;
export const NotificationContentWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: -6px 7px 32px 9px rgba(34, 60, 80, 0.1);
  background-color: #fff;
  height: 84px;
  width: 368px;
  border-radius: 8px;
  padding: 10px 20px 10px 10px;
	
	& h3{
    font-size: 16px;
    font-weight: 700;
    color: #554D66;
		padding: 0;
    margin: 0 0 5px 0;
	}
	& p {
    color: #333333;
    padding: 0;
		margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const CloseBtnNot = styled.div`
  position: absolute;
  top: 10px;
  right: 35px;
  z-index: 1000;
  
`;
