import moment from "moment";
import {
	DAY, HOURS,
	MINUTES,
	ONE_NUMBER,
	QUANTITY_OF_MINUTES_ELEMENTS_INTERVAL,
	QUANTITY_OF_TIMELINE_ELEMENTS, STARTING_BALANCE, TIME_ZONE,
	TOTAL_DAYS,
} from "./consts";

export const isCurrentDay = (dayItem) => moment().isSame(dayItem, 'day');
export const isSelectedMonth = (dayItem, today) => today.isSame(dayItem, 'week');

export const operation = (eventSearchListEntertainer, eventSearchListKid, isUnion = false) =>
	eventSearchListEntertainer.filter(
		(set => a => isUnion === set.has(a.meeting_id))(new Set(eventSearchListKid.map(b => b.meeting_id)))
	);

export const inBoth = (eventSearchListEntertainer, eventSearchListKid) => operation(eventSearchListEntertainer, eventSearchListKid, true);

export const isDayContainCurrentEvent = (event,dayItem) => {
	const result = +moment(event.slot_time.start_time).format('x') >= +dayItem.clone().startOf('day').format('x')
		&& +moment(event.slot_time.end_time).format('x') <= +dayItem.clone().endOf('day').format('x') + 1;

	return result;
}

export const getMilliseconds = (timeAdd) => {
	let res;
	if(timeAdd){
		// console.log("timeAdd",timeAdd)
		const stamp = timeAdd?.split(":");
		let timeHourStamp = parseInt(stamp[0]);
		let timeMinStamp = parseInt(stamp[1]);
		res = (timeMinStamp * 60 * 1000) + (timeHourStamp * 60 * 60 * 1000)
		return res;
	}
};

export const timeStampListCount = (hour, setTimeStamp) => {

	let stamp = hour.clone();
	const timelineElements = new Array(QUANTITY_OF_TIMELINE_ELEMENTS)
		.fill().map((acc, index) => stamp.clone()
			.add(QUANTITY_OF_MINUTES_ELEMENTS_INTERVAL * (index), MINUTES));
	// console.log("timelineElements",timelineElements)
	setTimeStamp(timelineElements);

}

export const dayArray = (day) => {
	return [...Array(TOTAL_DAYS)].map((_,i) => day.clone().add(i, DAY));
}

export const startDaySubtract = (startDay) => {
	return startDay.clone().subtract(ONE_NUMBER, DAY);
}

export const startOfTime = (today) => {
	return today.clone().startOf(DAY).startOf(HOURS);
}

export const callsEntertainer = (dashboard, entertainer) =>{
	return  Array.isArray(dashboard) ? dashboard?.filter(item =>  item.entertainer_id === entertainer?.id ) : [];
}
export const allCalls = (dashboard) => {
	const allMeetings = [];
	const data = dashboard?.map( x => x.meetings.map( h => allMeetings.push({...h, id:x.entertainer_id})));
	return allMeetings;
}


export const entertainerSlots = (eventsNew,stamp) => {
	const res =   eventsNew.filter(event =>
		+moment(event.slot_time.start_time).format('x') +TIME_ZONE <= stamp.format('x') &&
		stamp.format('x') < +moment(event.slot_time.end_time).format('x') +TIME_ZONE);
	return res;

}

export 	const changeState = (isActive, setIsActive) => {
setIsActive(!isActive)
}

export const handleStopPropagation = (e) => {
	e.stopPropagation();
}

export const childAge = (dob, etAge) => {

	let today = new Date();
	let birthDate = new Date(dob);
	let age = today.getFullYear() - birthDate.getFullYear();

	let m = today.getMonth() - birthDate.getMonth();
	let d = today.getDay() - birthDate.getDay();

	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	if ( age === 0 ) {
		m = 12 + m;
		if (d < 0 || (d === 0 && today.getDate() < birthDate.getDate())) {
			m--;
		}
	}
	etAge(age ? age + ' ' + 'Years' : m + 'Month')
}

export const getToISOString = (timeAdd, dayAdd) => {
	if(timeAdd && dayAdd){
		return moment(parseInt(dayAdd)).startOf("day").add(getMilliseconds(timeAdd)).toISOString('Europe/Kyiv');
	}

}

export const clientUse = (configs, str) => {
const res =	configs.find(x => x.config_id === str).client_use !== undefined ?
		configs.find(x => x.config_id === str).client_use : null;
return res;
}