import React from 'react';
import {Button, Form} from "react-bootstrap";
import usePhone from "../../hooks/usePhone";
import ClipLoader from "react-spinners/ClipLoader";

const LoginByPhone = ({apiBeginPhoneAuth, byNumber, setByNumber, loading}) => {

	const phone = usePhone();
	const telDirty = phone.telDirty;
	const errorTel = phone.errorTel;
	const blurHandler = phone.blurHandler;

	const onChange = function (el) {
		setByNumber(el.target.value.replace(/\D/g, ''))
	}
	return (
		<>
			<div>
				<h3 className="h3-auth">Authorization</h3>
			</div>
			{(telDirty && errorTel) && <div style={{color: "red"}}>{errorTel}</div>}
			<Form.Control onChange={onChange} type="tel"
										name="tel"
										value={byNumber}
										onBlur={e => blurHandler(e)}
										placeholder="Please enter your Phone number"
										className="input-phone-number"
										maxLength='15'/>
			{loading ? (
				<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
			) : (
			<Button onClick={() => apiBeginPhoneAuth()} className="button btn-auth">Sign in</Button>
				)}
		</>
	);

}

export default LoginByPhone;