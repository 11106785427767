import moment from "moment";
import {useSelector} from "react-redux";

export default function useTime() {

	const {modalProps: x} = useSelector(state => state.modal);
	const modalProps = moment.unix(x / 1000);
	const timeAdd = modalProps.format("HH:mm");
	const year = modalProps.format("YYYY");
	const day = modalProps.format("DD");
	const today = modalProps.format("MMM");
	const ti = new Date(modalProps);
	const m = ti.getMonth()
	const stamp = timeAdd.split(":");
	const timeHourStamp = parseInt(stamp[0]);
	const timeMinStamp = parseInt(stamp[1]);
	const y = parseInt(year);
	const d = parseInt(day);
	const utcDate = Date.UTC(y, m, d, timeHourStamp, timeMinStamp, 0);

	let moment_tz = moment.tz(utcDate, "Europe/Kyiv");
	const finalDate = (utcDate - moment_tz.utcOffset()*60*1000);

	return [day, today, finalDate, timeAdd, year]

}