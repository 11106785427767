import React from 'react';

import {ChildrenUl} from '../../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled';

const MoreChildName = ({kid, handleClickChildId}) => {

	return (
		<ChildrenUl>
			{
				kid?.map(item =>
					<li key={item.dependent_id}
							onClick={() => handleClickChildId(item.dependent_id, item.first_name, item.preferred_language)}>{item.first_name}</li>
				)
			}
		</ChildrenUl>
	);
}

export default MoreChildName;