export const logger = ({data, mode = "info"}) => {
  if (process.env.NODE_ENV ) {
		switch (mode) {
			case "info":
				// console.log(data);
				break;
			case "error":
				// console.error(data);
				break;
		}
	}
}