import React, {useState} from 'react';
import styled from "styled-components";
import moment from "moment";
import 'moment-timezone';
import {TimeLi} from "../StyledcreateSlotsModal";

const TimeWrapper = styled.ul`
  overflow-y: auto;
	height: 140px;
	width: 65px;
	position: absolute;
  z-index: 260;
	background-color: #ffffff;
	top: 42px;
	left: 2px;
	border-radius: 6px;
  
`;

function TimeInterval({onTimeSelect}) {

	const [today, setToday] = useState(moment())
	const startTime = today.clone().startOf('day').startOf('hours');
	const hours = startTime.clone().add(20, 'minutes');
	const times = []
	const timeArr = () => {

		let currentDate = hours.clone();
		new Array(71).fill().map((acc, index) => {
			times.push(currentDate.format('HH:mm'))
		 		currentDate.add(20, 'minutes');
		})
		return times
	}
	timeArr();
	return (
	<>

		<TimeWrapper>
			{
				times.map((time, index) => (
					<TimeLi data-time="time-set" time={time} key={'asd'+index} onClick={onTimeSelect}>
						{time}
					</TimeLi>
				))
			}
		</TimeWrapper>
	</>
	);
}

export default TimeInterval;