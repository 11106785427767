import React, {useState} from "react";
import tz from "moment-timezone";
import moment from "moment";
import {useDispatch} from "react-redux";
import {setTodaySave} from "../../store/slicers/time-slice";
moment.tz.setDefault('Europe/Kyiv');
export default function useMoment(){
	const d = new Date();
	let diff = d.getTimezoneOffset();
	moment.updateLocale("en", { week: {
			dow: 1, // First day of week is Monday
			doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
		}});

	const [today, setTodayState] = useState(moment());
	const dispatch  = useDispatch();
	const startDay = today.clone().startOf('isoWeek');
	const Start = startDay.clone().format('x');
	const End = today.clone().endOf('isoWeek').format('x');

	const timeStampStart = moment(+Start ).toISOString()
	const timeStampEnd = moment(+End ).toISOString()

	const setToday = (x) => {
		setTodayState(x);
		dispatch(setTodaySave(moment(x).format("x")));
	}

	const prevSetDayHandler = () => {
		setTodayState(prev => prev.clone().subtract(1, 'week'));
		dispatch(setTodaySave(moment(today).format("x")));
	}

	const nextSetDayHandler = () => {
		setTodayState(prev => prev.clone().add(1, 'week'));
		dispatch(setTodaySave(moment(today).format("x")));
	}

	return {
		timeStampEnd, timeStampStart, setToday, today, startDay, prevSetDayHandler, nextSetDayHandler
	}
}