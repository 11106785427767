import React, { useEffect, useState } from 'react';
import { ADMIN_USERS_SEARCH, route } from '../../../../utils/constants_urls';
import { fetchApiGet } from '../../../../utils/axios';
import useToken from '../../../hooks/useToken';

import {InfoWrAddEntertainer, RemoveWr, SearchEntWrapper, UlSearchItemEnter} from '../StyledCourse';
import rem from "../img/remove.png";
const SearchEntertainerInCourse = ({
																		 selectEntertainerHandler,
																		 selectedEntertainer,
																		 value,
																		 setValue,
																	 }) => {
	const { token } = useToken();
	const [entertainerItems, setEntertainerItems] = useState([]);

	const getEntertainers = async (el) => {
		let search = el.target.value;
		setValue(search);
		const url = route(ADMIN_USERS_SEARCH, [':searchBody', search]);
		const result = await fetchApiGet({ url, token });
		const entertainers = result.entities.filter((item) => item.role === 2);
		setEntertainerItems(entertainers);
	};

	return (
		<div style={{ position: 'relative' }}>
			<label>
				<div>
					 <span>Entertainers</span>
					<InfoWrAddEntertainer>Leave this field empty to assign all entertainers</InfoWrAddEntertainer>
				</div>
				<input
					type="text"
					placeholder="You can add more than one entertainer"
					value={value}
					maxLength="18"
					onChange={(el) => getEntertainers(el)}
				/>
			</label>
			{entertainerItems && value.length ? (
				<SearchEntWrapper>
					{entertainerItems.map((item) => (
						<UlSearchItemEnter
							onClick={() => selectEntertainerHandler(item)}
							className="ul-search-item"
							key={item.phone}
						>
							<li>
								{item.first_name} {item.last_name}
							</li>
							<li>
								{item.phone}
							</li>
							<RemoveWr>{selectedEntertainer && selectedEntertainer.some((ent) => ent.id === item.id) ? <img src={rem}/> : null}</RemoveWr>
						</UlSearchItemEnter>
					))}
				</SearchEntWrapper>
			) : null}
		</div>
	);
};

export default SearchEntertainerInCourse;
