import React, {useEffect, useState} from "react";

const NewDate = () => {

    const days = [
        'SUN',
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI',
        'SAT'
    ]

    const [clock, setClock] = useState(new Date());

    let update;
    const time = [clock.getHours(), clock.getMinutes()].map(function (x) {
        return x < 10 ? "0" + x : x
    }).join(":");

    const ampm = clock.getHours() >= 12 ? 'PM' : 'AM';
    const dayName = days[clock.getDay()]
    const dd = String(clock.getDate()).padStart(2, '0');
    const mm = String(clock.getMonth() + 1).padStart(2, '0');
    const yyyy = clock.getFullYear();
    const today = `${dd}.${mm}.${yyyy}`

    useEffect(() => {
        update = setInterval(() => {
            setClock(new Date());
        }, 1 * 1000);
    }, []);

    useEffect(() => {
     if(time === "00:00" || time === "05:00")   {
        setTimeout(() => window.location.reload(), 59000)
     }
    },[time]);

    return(
        <>
            <div className="new-date">{time} {ampm} - {dayName} {today}</div>
        </>
    );
}

export default NewDate;