import React from 'react';
import {Button, FieldContainer, Label, Select} from "../StyledConfig";

const SurnameCreation = ({isEditing, setIsEditing, surnameCreation, handleDiscard, handlePublish, setSurnameCreation}) => {

	const editHandler = () => {
		handleDiscard('surnameCreation');
		setIsEditing((prevState) => ({...prevState, surnameCreation: true}))
	}

	return (
		<FieldContainer>
			<Label>Surname Creation:</Label>
			{isEditing.surnameCreation ? (
				<Select
					value={surnameCreation}
					onChange={(e) => setSurnameCreation(e.target.value)}
				>
					<option value="yes">yes (default)</option>
					<option value="no">no</option>
				</Select>
			) : (
				<div style={{width: "150px"}}>
					<span>{surnameCreation} </span>
				</div>
			)}
			{isEditing.surnameCreation ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('surnameCreation')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('surnameCreation')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>
	);
};

export default SurnameCreation;