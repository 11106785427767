import React from 'react';
import {useSelector} from "react-redux";

import {
	IconPhoto,
	LoadPhotoWrapper,
	LoadPhotoWrapperSmall, RequiredImg
} from "../StyledCourse";

import cloud from "../img/Download_1_.png";
import iconPhoto from "../img/Frame 316.png";

const LoadPhoto = ({openAddPhoto, sours}) => {

	const {imageUrl} = useSelector(state => state.course);
	const {courseItem} = useSelector(state => state.course);

	return (
		<>
			{imageUrl ? (
					<div style={{position: "relative", width: "169px", height: "172px"}}>
						<LoadPhotoWrapperSmall>
								<img src={sours} className="im-sours"/>
						</LoadPhotoWrapperSmall>
						<IconPhoto onClick={openAddPhoto}><img src={iconPhoto}/></IconPhoto>
					</div>
			) : (
				<>
					{
						courseItem.cover_image_url ?
							(
								<div style={{position: "relative", width: "169px", height: "172px"}}>
								<LoadPhotoWrapperSmall>
										<img src={courseItem.cover_image_url} className="im-sours"/>
								</LoadPhotoWrapperSmall>
								<IconPhoto onClick={openAddPhoto}><img src={iconPhoto}/></IconPhoto>
								</div>
							) :
							(
								<LoadPhotoWrapper>
									<img src={cloud}/>
									<p>Drag files here or browse</p>
									<IconPhoto onClick={openAddPhoto}><img src={iconPhoto}/></IconPhoto>
							</LoadPhotoWrapper>
							)
					}
				</>

			)}
			<RequiredImg>
				* required
			</RequiredImg>
		</>

	);
};

export default LoadPhoto;