import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Monitor} from "../monitor/Monitor";
import CreateMeeting from "../../create-meeting/CreateMeeting";
import GetEntertainerMeetings from "../../search-meetings/meetings-entertainer/GetEntertainerMeetings";
import GetKidsMeetings from "../../search-meetings/meetings-kids/GetKidsMeetings";
import CreateModal from "../../showModal/CreateModal/CreateModal";
import MeetingOptions from "../../meetings-options/MeetingOptions";
import UpdateMeeting from "../../update-meeting/UpdateMeeting";
import useShowAlert from "../../hooks/useShowAlert";

import DeleteMeetingQuestion from "../../meetings-options/DeleteMeetingQuestion";

import {
	CalendarHeaderStyle,
	WrapperForButtons,
	WrapperForSearches,
	AbsoluteCreateDiv, UpdateDashboardBtn
} from './header-styled/HeaderStyled';
import {getData} from "../../../store/actions/dashboard-action";
import useMoment from "../../hooks/useMoment";
import useToken from "../../hooks/useToken";

const CalendarHeader = (props) => {
	const {
		today,
		timeStampEnd,
		timeStampStart,
		setToday,
		nextSetDayHandler,
		prevSetDayHandler, setLoading
	} = props

	const {isOpen} = useSelector(state => state.modal);
	const {isOpenMeetingOptions} = useSelector(state => state.modal);
	const {callDeleteIsOpen} = useSelector(state => state.calls);
	const {modalPropsMeetingId} = useSelector(state => state.modal);
	const dispatch = useDispatch();

	const {token} = useToken();

	const {showAlert, setShowAlert, hideAlert} = useShowAlert();
	const getDashboard = () => {
		dispatch(getData({timeStampStart, timeStampEnd, token}))
		setLoading(true);
	}

	return (
		<CalendarHeaderStyle>
			<WrapperForSearches>
				<GetEntertainerMeetings today={today}/>
				<GetKidsMeetings today={today}/>
			</WrapperForSearches>
			<AbsoluteCreateDiv>
				{showAlert &&
					<CreateModal text={showAlert} hideAlert={hideAlert}/>}
			</AbsoluteCreateDiv>
			<WrapperForButtons>
				{isOpenMeetingOptions &&
					<MeetingOptions timeStampStart={timeStampStart}
													timeStampEnd={timeStampEnd}
													setShowAlert={setShowAlert}/>}
				{callDeleteIsOpen &&
					<DeleteMeetingQuestion meetingId={modalPropsMeetingId}
																 timeStampStart={timeStampStart}
																 timeStampEnd={timeStampEnd}
																 setShowAlert={setShowAlert}/>}
				<UpdateMeeting timeStampStart={timeStampStart}
											 timeStampEnd={timeStampEnd}
											 setShowAlert={setShowAlert}/>
				{isOpen &&
					<CreateMeeting setShowAlert={setShowAlert}
												 timeStampStart={timeStampStart}
												 timeStampEnd={timeStampEnd}/>}
				<UpdateDashboardBtn onClick={getDashboard}>Get Dashboard</UpdateDashboardBtn>
				<Monitor today={today}
								 setToday={setToday}
								 prevSetDayHandler={prevSetDayHandler}
								 nextSetDayHandler={nextSetDayHandler}/>
			</WrapperForButtons>
		</CalendarHeaderStyle>
	);
}

export {CalendarHeader};