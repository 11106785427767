import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {ADMIN_COURSES} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import {coursesSave, lessonItemClear} from "../../../store/slicers/course-slice";
import useToken from "../../hooks/useToken";
import SelectedCourse from "./SelectedCourse";
import SelectedLesson from "./SelectedLesson";

import {ThemeWrapper} from "../styled-create-meeting/CreateMeetingStyled";

const Theme = ({kid}) => {

	const {courseItemCreateCall} = useSelector(state => state.course);
	const {childLanguage} = useSelector(state => state.name);

	const [courses, setCourses] = useState([]);
	const [items, setItems] = useState([]);
	const [value, setValue] = useState('');

	const dispatch = useDispatch();
	const {token} = useToken();

	const getCourses = async () => {
		const url = ADMIN_COURSES;
		const response = await fetchApiGet({url, token});
		dispatch(coursesSave(response));
		setCourses(response)
	};
	useEffect(()=>{
		getCourses();
	},[])
	const onChange =  (el) => {

		const search = el.target.value.toLowerCase();
		setValue(search);

		const x = courses.filter(i => {

			if (`${i.name[childLanguage]}`.toLowerCase().includes(search)) {
				return i;
			}
		});
		setItems(x);

	}

	const clearValue = () => {
	  setValue('');
		dispatch(lessonItemClear())
	}

	return (
		<ThemeWrapper>
			<SelectedCourse items={items}
											kid={kid}
											value={value}
											onChange={onChange}
											clearValue={clearValue}/>
			{
				courseItemCreateCall.course_id ? (
					<SelectedLesson/>
				) : (
					<div></div>
				)
			}
		</ThemeWrapper>
	);
};

export default Theme;