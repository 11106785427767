import {createSlice} from '@reduxjs/toolkit';
import {getProfileChildUpdate} from "../actions/get-profile-by-phone-for-update-child-action";

const getChildProfileUpdate = createSlice({
	name: "get-child",
	initialState: {
		childProfile: {},
		statusCode: null,
		status: null,
		error: null,
	},
	reducers: {

	},
	extraReducers:
		{
			//? get meeting by id
			[getProfileChildUpdate.pending]: (state) => {
				state.status = 'loading';
				state.error = null;
				state.statusCode = null;
			},
			[getProfileChildUpdate.fulfilled]: (state, action) => {
				state.status = 'resolved';
				state.childProfile = action.payload.profile;
				state.error = null;
			},
			[getProfileChildUpdate.rejected]: (state, action) => {
				state.status = 'rejected';
				state.error = action.payload;
				state.statusCode = null;
			},
		}
})

export default getChildProfileUpdate.reducer;