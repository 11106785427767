import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

const LiRole = ({ classLi, myRole, getProfileByUserId, item }) => {

	const { editRoleItem } = useSelector(state => state.editRole);
	useEffect(()=> {
		if(editRoleItem && editRoleItem.id === item.id){
			getProfileByUserId();
		}

	},[editRoleItem]);


	return (
		<li className={classLi}>{ myRole }</li>
	);
};

export default LiRole;