import React,{useState} from 'react';
import {useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {Link} from "react-router-dom";
import useToken from "../../../hooks/useToken";
import {fetchApiDelete} from "../../../../utils/axios";
import {ADMIN_COURSES_BY_ID, route} from "../../../../utils/constants_urls";

import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {H3PopUp} from "../../monetization/styled-monetization/StyledMonetization";

import {
	CanselGroup,
	DeleteGroup,
	DeleteGroupName,
	WrapperButtonGroupDelete
} from "../StyledCourse";

import close from "../../../create-meeting/img/close.png";

const DeleteCourse = ({isDeleteOpen}) => {

	const {courseItem} = useSelector(state => state.course);
	const {token} = useToken();
	const [loading, setLoading] = useState(false);

	const deleteCourse = async () => {
		setLoading(true);
		const url = route(ADMIN_COURSES_BY_ID, [':courseId', courseItem.course_id]);
		const result = await fetchApiDelete({url, token});
		isDeleteOpen();
		setLoading(false);
	}

	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn onClick={isDeleteOpen}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Course delete</H3PopUp>
					<DeleteGroupName>Delete course {courseItem.name.uk}?</DeleteGroupName>
					<WrapperButtonGroupDelete>
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<>
								<CanselGroup onClick={isDeleteOpen}>Cancel</CanselGroup>
								<Link to="/course">
									<DeleteGroup onClick={deleteCourse}>Delete</DeleteGroup>
								</Link>
							</>
						)}
					</WrapperButtonGroupDelete>
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>

	);
};

export default DeleteCourse;