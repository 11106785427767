import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	monetizationItem: null,
	edit: false,
	cancelEditing: false,
	openDelete: false,
}
const MonetizationSlice = createSlice({
	name: "monetization",
	initialState,
	reducers: {
		monetizationItemSave(state, action){
			state.monetizationItem = action.payload;
		},
		monetizationItemRemove:(state, action)=>{
			state.monetizationItem = null;
		},
		monetizationItemEditTrue:(state, action)=>{
			state.edit = true;
		},
		monetizationItemEditFalse:(state, action)=>{
			state.edit = false;
		},
		cancelEditingFalse:(state, action)=>{
			state.cancelEditing = false;
		},
		cancelEditingTrue:(state, action)=>{
			state.cancelEditing = true;
		},
		openDeleteFalse:(state, action)=>{
			state.openDelete = false;
		},
		openDeleteTrue:(state, action)=>{
			state.openDelete = true;
		},


	},

})

export const {
	monetizationItemSave,
	monetizationItemRemove,
	monetizationItemEditTrue,
	monetizationItemEditFalse,
	cancelEditingFalse,
	cancelEditingTrue,
	openDeleteFalse,
	openDeleteTrue
} = MonetizationSlice.actions;

export default MonetizationSlice.reducer;
