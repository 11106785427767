import moment from "moment";

export function converterMS(x) {
    const modalProps = moment.unix(x / 1000);
    const year = modalProps.format("YYYY");
    const day = modalProps.format("DD");
    const month = modalProps.format("MM")

    return `${day}.${month}.${year}`

}

export function converterToTime (ms) {
    const modalProps = moment.unix(ms / 1000);
    const timeAdd = modalProps.format("HH:mm");

    return timeAdd;
}