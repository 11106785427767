import {createAsyncThunk} from '@reduxjs/toolkit';

export const getProfileForUserList = createAsyncThunk(
	"get-for-user-list/getProfileForUserList",
	async function (userPhone, {rejectWithValue}) {
		try {

			const res = await 	fetch(`/api/admin/get-profile-by-user-phone?phone=${userPhone}`)
			const data = await  res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}

	}
);
