import React from "react";
import styled from "styled-components";

export const CalendarHeaderStyle = styled.header`
  position: absolute;
  right: 10px;
  top: 10px;
	display: flex;
  justify-content: space-between;
  width: 100%;
	padding-left: 20px;
`;
export const WrapperForSearches = styled.div`
  display: flex;
	justify-content: space-between;
	width: 480px;
`;
export const WrapperForButtons = styled.div`
  display: flex;
`;
export const AbsoluteCreateDiv = styled.div`
	position: absolute;
	top: 0;
	z-index: 100;
`;
export const UpdateDashboardBtn = styled.button`
	border: #0b5ed7;
	color: white;
	background-color: #0b5ed7;
	border-radius: 6px;
	padding: 0 15px;
`;