import React, {useState} from 'react';
import {useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import useToken from "../../../hooks/useToken";
import {ADMIN_GROUPS_ID, route} from "../../../../utils/constants_urls";
import {fetchApiDelete} from "../../../../utils/axios";

import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {H3PopUp} from "../../monetization/styled-monetization/StyledMonetization";
import {
	CanselGroup,
	DeleteGroup,
	DeleteGroupName,
	WrapperButtonGroupDelete,

} from "../StyledCourse";

import close from "../../../create-meeting/img/close.png";

const GroupDelete = ({deleteGroupIsOpen, getGroup}) => {

	const {groupItem} = useSelector(state => state.course);
	const {token} = useToken();
	const [loading, setLoading] = useState(false);
	const deleteGroup = async () => {
		setLoading(true);
		const url = route(ADMIN_GROUPS_ID, [':group_id', groupItem.group_id]);
		const result = await fetchApiDelete({url, token});
		getGroup();
		deleteGroupIsOpen();
		setLoading(false);
	}
	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn onClick={deleteGroupIsOpen}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Group delete</H3PopUp>
					<DeleteGroupName>Delete group "{groupItem.group_name}"?</DeleteGroupName>
					<WrapperButtonGroupDelete>
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<>
								<CanselGroup onClick={deleteGroupIsOpen}>Cancel</CanselGroup>
								<DeleteGroup onClick={deleteGroup}>Delete group</DeleteGroup>
							</>
						)}
					</WrapperButtonGroupDelete>
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>
	);
};

export default GroupDelete;