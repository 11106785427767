import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	slotId: [],
	saveSlots: [],
	isOpenCreateSlots: false,
	isOpenDeleteSlots: false,
	isOpenInfoSlot: false,
	isOpenCreateTimeOffSlot: false,
	slotInfo: null
}
const slotIdSlice = createSlice({
	name: "create",
	initialState,
	reducers: {
		slotIdSave(state, action){
			state.slotId = action.payload;
		},
		slotIdClear: (state, action) =>{
			state.slotId = [];
		},
		slotInfoSave(state, action){
			state.slotInfo = action.payload;
		},
		slotInfoClear: (state, action) =>{
			state.slotInfo = null;
		},
		openCreateSlots: (state, action) =>{
				state.isOpenCreateSlots = true;
		},
		closeCreateSlots: (state, action) =>{
			state.isOpenCreateSlots = false;
		},
		openDeleteSlots: (state, action) =>{
				state.isOpenDeleteSlots = true;
		},
		closeDeleteSlots: (state, action) =>{
			state.isOpenDeleteSlots = false;
		},
		openInfoSlots: (state, action) =>{
				state.isOpenInfoSlot = true;
		},
		closeInfoSlots: (state, action) =>{
			state.isOpenInfoSlot = false;
		},
		openCreateTimeOffSlot: (state, action) =>{
				state.isOpenCreateTimeOffSlot = true;
		},
		closeCreateTimeOffSlot: (state, action) =>{
			state.isOpenCreateTimeOffSlot = false;
		},
	},

})

export const {
	slotIdSave,
	slotIdClear,
	closeCreateSlots,
	openCreateSlots,
	openDeleteSlots,
	closeDeleteSlots,
	slotInfoClear,
	openInfoSlots,
	closeInfoSlots,
	slotInfoSave,
	closeCreateTimeOffSlot,
	openCreateTimeOffSlot
} = slotIdSlice.actions;

export default slotIdSlice.reducer;