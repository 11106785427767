import {
	ROLE_ADMIN,
	ROLE_ADMIN_STRING,
	ROLE_ENTERTAINER,
	ROLE_ENTERTAINER_STRING,
	ROLE_OBSERVER,
	ROLE_OBSERVER_STRING, ROLE_USER, ROLE_USER_STRING
} from "./consts";

export const LANGUAGES = [
	{name: 'All', key: null, selected: true},
	{name: 'Українська', key: 'uk', selected: false},
	{name: 'Русский', key: 'ru', selected: false},
	{name: 'English', key: 'en', selected: false},
];
export const SORT_COURSES = [
	{name: 'Name A-Z', key: 'ASC', selected: true},
	{name: 'Name Z-A', key: 'DESC', selected: false},
];
export const ACTIVE_STATE = [
	{name: 'All', key: null, selected: true},
	{name: 'Active', key: 'active', selected: false},
	{name: 'Inactive', key: 'inactive', selected: false},
];
export const AGES = [
	{name: 'All', key: null, selected: true},
	{name: '4-5', key: '4-5', selected: false},
	{name: '6-8', key: '6-8', selected: false},
];

export const options = [
	{value: ROLE_ADMIN, label: ROLE_ADMIN_STRING},
	{value: ROLE_OBSERVER, label: ROLE_OBSERVER_STRING},
	{value: ROLE_ENTERTAINER, label: ROLE_ENTERTAINER_STRING},
	{value: ROLE_USER, label: ROLE_USER_STRING}
]

