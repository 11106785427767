import React from "react";
import { useSelector } from "react-redux";

import HideContent from "./HideContent";

const ContentWithHideFalse = ({ handleClick, shownContent }) => {
  //const {modalPropsMeetings} = useSelector(state => state.modal);
  const { modalPropsMeetingsByPages, modalCurrentPage } = useSelector(
    (state) => state.modal
  );

  // console.log("modalPropsMeetings",modalPropsMeetings)
  return (
    <>
      {modalPropsMeetingsByPages &&
        modalPropsMeetingsByPages[modalCurrentPage].map((item) => (
          <HideContent
            key={item.meeting_id}
            item={item}
            handleClick={handleClick}
            shownContent={shownContent}
          />
        ))}
    </>
  );
};

export default ContentWithHideFalse;
