import {createSlice} from '@reduxjs/toolkit';


const initialState = {
	parentName: null,
	entertainerName: null,
	entertainerNameSlotsCreate: null,
	childN: null,
	newRole: null,
	parentNameFor: null,
	entertainerNameFor: null,
	itemSelected: null,
	userName: null,
	selectChildAdd: null,
	childLanguage: ''
}
const nameSlice = createSlice({
	name: "name",
	initialState,
	reducers: {

		parentNameSave(state, action){
			state.parentName = action.payload;
		},
		childSave(state, action){
			state.childN = action.payload;
		},
		selectChildAddSave(state, action){
			state.selectChildAdd = action.payload;
		},
		entertainerNameSave(state, action){
			state.entertainerName = action.payload;
		},
		parentNameClear: (state, action) =>{
			state.parentName = null;
		},
		childClear: (state, action) =>{
			state.childN = null;
		},
		entertainerNameClear: (state, action) =>{
			state.entertainerName = null;
		},
		parentNameForSave(state, action){
			state.parentNameFor = action.payload;
		},
		parentNameForClear: (state, action) =>{
			state.parentNameFor = null;
		},
		entertainerNameForSave(state, action){
			state.entertainerNameFor = action.payload;
		},
		entertainerNameForClear: (state, action) =>{
			state.entertainerNameFor = null;
		},
		userNameSave(state, action){
			state.userName = action.payload;
		},
		userNameClear: (state, action) =>{
			state.userName = null;
		},
		entertainerNameSlotsCreateSave(state, action){
					state.entertainerNameSlotsCreate = action.payload;
				},
		childLanguageSave(state, action){
					state.childLanguage = action.payload;
				},
		entertainerNameSlotsCreateClear: (state, action) =>{
					state.entertainerNameSlotsCreate = null;
				},
	},

})

export const {
	entertainerNameSave,
	parentNameSave,
	parentNameClear,
	entertainerNameClear,
	childSave,
	childClear,
	entertainerNameForSave,
	parentNameForSave,
	entertainerNameForClear,
	userNameClear,
	userNameSave,
	parentNameForClear, selectChildAddSave,
	entertainerNameSlotsCreateClear,
	entertainerNameSlotsCreateSave, childLanguageSave
} = nameSlice.actions;

export default nameSlice.reducer;
