import React from 'react';
import styled from "styled-components";


export const DesWrapper = styled.div`
  display: flex;
`;
export const ModalWrapperEdit = styled.div`
 display: flex;
	justify-content: center;
	align-items: center;
`;

export const FilterModalWrapper = styled.div`
  height: calc(100vh - 150px);
  background-color: rgba(0, 0, 0, 0.0001);
	width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
  transition: .5s;
`;

export const FilterModalContent =styled.div`
  position: relative;
  width: 620px;
  height: 450px;
`;