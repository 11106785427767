import React, {useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {lessonItemSave} from "../../../store/slicers/course-slice";

import edit from "./img/edit.png";

const LessonInfoItem = ({lesson, id}) => {

	const dispatch = useDispatch();
	const [lessonName, setLessonName] = useState('');
	useEffect(() => {
		if(lesson){
			if (id === 'English') {
				setLessonName(lesson.name.en);
			}
			if (id === 'Ukrainian') {
				setLessonName(lesson.name.uk);
			}
			if (id === 'Russian') {
				setLessonName(lesson.name.ru);
			}
		}
	},[lesson]);

	return (
		<li onClick={() => dispatch(lessonItemSave(lesson))}>
			<Link to={`/lesson-info/${lesson.lesson_id}`}>
				{lessonName}
			</Link>
			<Link to="/create-lesson">
				<div onClick={() => dispatch(lessonItemSave(lesson))}>
					<img src={edit}/>
				</div>
			</Link>
		</li>
	);
};

export default LessonInfoItem;