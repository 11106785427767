import React from 'react';
import moment from "moment";

import {
	ButtonWrapperCenter,
	DivWrapper,
	ButtonWrapper
} from './monitor-styled/MonitorStyled';

const Monitor = ({setToday, today, nextSetDayHandler, prevSetDayHandler}) => {

	const yearStartWeek = today.clone().startOf('week').startOf('days').format("YY");
	const monthStartWeek = today.clone().startOf('week').startOf('days').format("MM");
	const dayStartWeek = today.clone().startOf('week').startOf('days').format("DD");

	const yearEndWeek = today.clone().endOf('week').endOf('days').format("YY");
	const monthEndWeek = today.clone().endOf('week').endOf('days').format("MM");
	const dayEndWeek = today.clone().endOf('week').endOf('days').format("DD");

	const startWeek = `${dayStartWeek}.${monthStartWeek}.${yearStartWeek}`;
	const endWeek = `${dayEndWeek}.${monthEndWeek}.${yearEndWeek}`;

	const todayHandler = () => setToday(moment());

	return (
		<DivWrapper>
				<ButtonWrapper onClick={prevSetDayHandler} >&lt;</ButtonWrapper>
				<ButtonWrapperCenter onClick={todayHandler}>
					{startWeek}
					<span>-</span>
					{endWeek}
				</ButtonWrapperCenter>
				<ButtonWrapper onClick={nextSetDayHandler}>&gt;</ButtonWrapper>
		</DivWrapper>
	);
}

export {Monitor};
