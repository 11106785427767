import React from 'react';
import {handleStopPropagation} from "../../../../helpers";

import {
	DropDownLi,
	PWrapper,
	RepeatableFieldView, RepeatableUlWrapper,
} from "./StyledcreateSlotsModal";

import Arr from "../img/arrDown.png";

const RepeatableField = ({setShowDropDownRep, showDropDownRep, repeatableSelect, setRepeatableSelect, todayDay}) => {

	const repeatableSelectHandler = (ev) => {
		setRepeatableSelect(ev.target.innerText);
		setShowDropDownRep(!showDropDownRep);
	}
	const repeatableHandler = (ev) => {
		handleStopPropagation(ev);
		setShowDropDownRep(true);
		}

	return (
		<div style={{paddingBottom: "70px", position: "relative"}}>
			<PWrapper>
				<p style={{padding: "10px 0"}} >Repeatable</p>
			</PWrapper>
			<RepeatableFieldView onClick={(ev)=> repeatableHandler(ev)}>
				{repeatableSelect}
				<img src={Arr}/>
			</RepeatableFieldView>
			{showDropDownRep &&
				<RepeatableUlWrapper>
					<DropDownLi data-time="time-set" onClick={(ev)=> repeatableSelectHandler(ev)}>Does not repeat</DropDownLi>
					<DropDownLi data-time="time-set" onClick={(ev)=> repeatableSelectHandler(ev)}>Daily</DropDownLi>
					<DropDownLi data-time="time-set" onClick={(ev)=> repeatableSelectHandler(ev)}>{`Weekly on ${todayDay}`}</DropDownLi>
					<DropDownLi data-time="time-set" onClick={(ev)=> repeatableSelectHandler(ev)}>Every week day</DropDownLi>
				</RepeatableUlWrapper>
			}
		</div>
	);
};

export default RepeatableField;