import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import UserModalInfoChildren from "./UserModalInfoChildren";
import {ROLE_ADMIN} from "../../../helpers/consts";
import ClipLoader from "react-spinners/ClipLoader";

import {
	ButtonEditUserGo, ButtonEditUserWrapper,
	DivInfoDescription,
	ImgHWrapper,
	UserInfoGridItem,
	UserInfoGridWrapper,
	UserRoleInfo,
	UserRoleInfoWrapper
} from "./UserModalStyled";

import VectorAcc from "./img/VectorAcc.png";
import phoneImg from "./img/phone.png";
import emailImg from "./img/email.png"
import desImg from "./img/dess.png";
import Vector from "./img/Vector.png";

const UserModalInfoContent = ({handlerEditUserGo, roleInfo, checked, handlerDetailsOpen}) => {

	const {editRoleItem} = useSelector(state => state.editRole);
	const {admin} = useSelector(state => state.admin);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		if (editRoleItem && editRoleItem.phone){
			setLoading(false);
		}
	},[editRoleItem])
	return (
		< >
			<UserRoleInfoWrapper>
				<div>
					<img src={Vector}/>
				</div>
				<UserRoleInfo>{roleInfo}</UserRoleInfo>
			</UserRoleInfoWrapper>
			<div style={{overflow: "auto", maxHeight: "600px"}}>
				{editRoleItem && editRoleItem.phone ? ( <UserInfoGridWrapper>
						<UserInfoGridItem>
							<ImgHWrapper>
								<div>
									<img src={VectorAcc}/>
								</div>
								<span>First Name</span>
							</ImgHWrapper>
							<span className='span-info-user'>{editRoleItem.first_name}</span>
						</UserInfoGridItem>
						<UserInfoGridItem>
							<ImgHWrapper>
								<div>
									<img src={VectorAcc}/>
								</div>
								<span>Second Name</span>
							</ImgHWrapper>
							<span className='span-info-user'>{editRoleItem.last_name}</span>
						</UserInfoGridItem>
						<UserInfoGridItem>
							<ImgHWrapper>
								<div>
									<img src={phoneImg}/>
								</div>
								<span>Phone</span>
							</ImgHWrapper>
							<span className='span-info-user'>{editRoleItem.phone}</span>
						</UserInfoGridItem>
						<UserInfoGridItem>
							<ImgHWrapper>
								<div>
									<img src={emailImg}/>
								</div>
								<span>Email</span>
							</ImgHWrapper>
							<span className='span-info-user'>{editRoleItem.email}</span>
						</UserInfoGridItem>
						<UserInfoGridItem>
							<ImgHWrapper>
								<div>
									<img src={desImg}/>
								</div>
								<span>Description</span>
							</ImgHWrapper>
							<DivInfoDescription>{editRoleItem.comments}</DivInfoDescription>
						</UserInfoGridItem>
					</UserInfoGridWrapper>
					) : (<ClipLoader color={"#C014E3"} loading={loading} size={300}/>) }
			<UserModalInfoChildren checked={checked} handlerDetailsOpen={handlerDetailsOpen}/>
			</div>
			<ButtonEditUserWrapper>
				{admin && admin.role === ROLE_ADMIN &&
					<ButtonEditUserGo onClick={handlerEditUserGo}>Edit</ButtonEditUserGo>
				}
			</ButtonEditUserWrapper>
		</>
	);
};

export default UserModalInfoContent;