import React from 'react';

import SearchUser from "../../search/SearchUser";
import EntertainerNameBlock from "./EntertainerNameBlock";

import {WrapperForImgAnaRequired} from "../../styled-create-meeting/CreateMeetingStyled";

import accounts from "../../img/accounts.png";

const Entertainer = ({user, setUser}) => {

	return (
		<div className="div-img-accounts-search-user">
			<WrapperForImgAnaRequired>
				<div className="div-img">
					<img src={accounts} alt={accounts}/>
				</div>
				<div className="required-div" style={{paddingTop: "8px"}}>
					<span>Entertainer</span>
					* required
				</div>
			</WrapperForImgAnaRequired>
			{!user ? (
				<SearchUser setUser={setUser}/>
			) : (
				<EntertainerNameBlock user={user}
															setUser={setUser}/>
			)}
		</div>
	);
};

export default Entertainer;