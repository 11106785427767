import React from "react";
import styled from "styled-components";

export const SearchUserWrapper = styled.div`
`;

export const LabelSearchUser = styled.label`
`;
export const SearchDiv = styled.div`
	position: relative;
`;

export const LabelInputWrapper = styled.div`
	margin: 5px 30px 2px 30px;
`;

export const LabelSearchKid = styled.label`
	
`;
export const LabelSearchCourse = styled.label`
  margin: 5px 30px 2px 30px;
	
`;
export const LabelSearchLessons = styled.label`
	margin-top: 5px;
	
`;
export const UlSearchItem = styled.ul`
	font-size: 13px;
	color:#554D66;
	padding: 2px 0px 2px 16px;
	margin-top: 10px;
	border-radius: 5px;
	&:hover{
		background-color: #E0E6EC;
	}
	& li:last-child {
		font-size: 10px;
		color: #B0ABBC;
	}
  & li.show-message{
    color: red;
  }
`;
export const UlSearchCourseItem = styled.ul`
  padding: 3px 0px 3px 16px;
	display: flex;
	gap: 10px;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: #554D66;
	margin: 5px 0px;
    background-color: #E0E6EC;
  }

`;
export const CourseItemImgWrapper = styled.div`
  width: 30px;
	height: 30px;
	border-radius: 50%;
	overflow: hidden;
	& img{
		width: 100%;
	}
`;

export const CourseNameLi = styled.li`
  display: flex;
	justify-content: space-between;
	width: 100%;
	padding-right: 20px;
	& span{
    font-size: 14px;
    font-weight: 600;
  }
`;

export const LessonOrderWrapper = styled.div`
  padding-right: 15px;
  font-size: 14px;
  font-weight: 500;
`;