import React from 'react';
import {Link} from "react-router-dom";

import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {
	BackButton,
	DeleteButtonPop,
	H3PopUp,
	PPopUp,
	WrapperButtonPopUp
} from "../styled-monetization/StyledMonetization";

import close from "../../../create-meeting/img/close.png";

const CancelCreate = ({handlerCancel}) => {
	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn  onClick={handlerCancel}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Object of Purchase Creation</H3PopUp>
					<PPopUp>Are you sure to discard unsaved changes?</PPopUp>
					<WrapperButtonPopUp>
						<BackButton onClick={handlerCancel}>Cancel</BackButton>
						<Link to="/monetization"><DeleteButtonPop onClick={handlerCancel}>Discard</DeleteButtonPop></Link>
					</WrapperButtonPopUp>
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>
	);
};

export default CancelCreate;