import {useState} from "react";

export default function usePhone() {
	const [telDirty, setTelDirty] = useState(false);
	const [errorTel, setErrorTel] = useState('');

	const blurHandler = (e) => {
		if (e.target.value.length < 7) {
			setErrorTel("Некорректный номер")
		}
		switch (e.target.name) {
			case "tel":
				setTelDirty(true)
				break
		}
	}

	return {
		blurHandler, telDirty, errorTel
	}

}