import React from "react";
import styled from "styled-components";

export const GridWrappersHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  width: 96%;
  position: absolute;
  right: 0;
  top: 70px;
`;

export const CellWrappers = styled.div`
`;
export const RowInCells = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
export const WrapperWeek = styled.div`
  font-size: 14px;
  color: #554D66;
`;
export const DayWrapper = styled.div`
  font-size: 14px;
  color: #554D66;
  height: 20px;
  width: 40px;
  margin: 9px 2px;
  display: inline-block;
`;
export const CurrentDay = styled.div`
  height: 100%;
  width: 100%;
  background: #1485ED;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff
`;