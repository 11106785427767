import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import {ADMIN_COURSES, ADMIN_GROUPS} from "../../../utils/constants_urls";
import {Link} from "react-router-dom";
import {changeState} from "../../../helpers";
import {ACTIVE_STATE, AGES, LANGUAGES, SORT_COURSES} from "../../../helpers/objects";
import GroupCollection from "./GroupCollection";
import SearchCollectionCourse from "./SearchCollectionCourse";
import CourseFilters from "./CourseFilters";
import {courseItemClear, coursesSave, groupsArrSave} from "../../../store/slicers/course-slice";
import GroupEdit from "./pop-ups/GroupEdit";
import GroupDelete from "./pop-ups/GroupDelete";
import GroupCreate from "./pop-ups/GroupCreate";

import {
	CourseSearch,
	CourseWrapper,
	DropDown,
	DropDownWrapper,
	SearchWrapper
} from "./StyledCourse";
import {
	ButtonAddWIthArr,
	ContentWrapperMonetization,
	HeaderButtonsWrapper
} from "../monetization/styled-monetization/StyledMonetization";
import {SearchDiv} from "../../create-meeting/styled-create-meeting/search-styled/SearchStyled";

import plus from "../monetization/img/Add.png";
import arr from "./img/downArr.png";

function Course({searchString}) {

	const {allCourses} = useSelector(state => state.course);

	const {token} = useToken();

	const [dropDown, setDropDown] = useState(false);
	const [courses, setCourses] = useState([]);
	const [groups, setGroups] = useState([]);
	const [value, setValue] = useState('');
	const [items, setItems] = useState([]);
	const [courseSelect, setCourseSelect] = useState([]);
	const [deleteGroup, setDeleteGroup] = useState(false);
	const [languages, setLanguages] = useState(LANGUAGES);
	const [sort, setSort] = useState(SORT_COURSES);
	const [actives, setActives] = useState(ACTIVE_STATE);
	const [ages, setAges] = useState(AGES);
	const [loading, setLoading] = useState(true);

	const [groupEdit, setGroupEdit] = useState(false);
	const [groupCreate, setGroupCreate] = useState(false);

	const dispatch = useDispatch();
		useEffect(() =>{
			if(allCourses.length){
				setCourses(allCourses)
			}

		},[allCourses]);

	const getCourses = async () => {
		const url = ADMIN_COURSES;
		const response = await fetchApiGet({url, token});
		dispatch(coursesSave(response));
		setCourses([...response]);
		setLoading(false);
	};

	const getGroup = async () => {
		const url = ADMIN_GROUPS;
		const response = await fetchApiGet({url, token});
		setGroups(JSON.parse(JSON.stringify(response)).sort((a, b) => {
			return sort.key === 'ASC' ? b.group_name.localeCompare(a.group_name) : a.group_name.localeCompare(b.group_name)
		}));
		dispatch(groupsArrSave(JSON.parse(JSON.stringify(response))));
	};

	useEffect(() => {
		getCourses();
		getGroup();

	}, []);

	const onChange = (el) => {

		const searchKids = el.target.value.toLowerCase();
		setValue(searchKids);

		const x = courses.filter(i => {

			if (`${i.name.uk}${i.name.en}${i.name.ru}`.toLowerCase().includes(searchKids)) {

				return i;
			}
		});
		setItems(x);

	}

	useEffect(() => {

		if (courseSelect.length) {
			setCourses(courseSelect);
		}

	}, [courseSelect]);

	const onCourseSelected = (course) => {

		setCourseSelect(prevState => [...prevState, {...course}])

	};

	const setLanguagesHandler = (language) => {

		setLanguages(prevState => prevState.map(lang => ({
			...lang,
			selected: language === lang.name
		})));
	};

	const setActiveHandler = (active) => {

		setActives(prevState => prevState.map(lang => ({
			...lang,
			selected: active === lang.name
		})));
	};

	const setAgeHandler = (ages) => {

		setAges(prevState => prevState.map(lang => ({
			...lang,
			selected: ages === lang.name
		})));
	};

	const selectedLanguage = languages.filter(({selected}) => selected)[0];
	const selectedSort = sort.filter(({selected}) => selected)[0];
	const selectedActive = actives.filter(({selected}) => selected)[0];
	const selectedAge = ages.filter(({selected}) => selected)[0];


	useEffect(() =>{
		const x = allCourses
			.filter(course => {
				if (selectedActive.key === null) return true;
				return course.status===selectedActive.key;
			})
			.filter(course => {
				if (selectedLanguage.key === null) return true;
				return Object.keys(course.name).includes(selectedLanguage.key);
			})
			.filter(course => {
			if (selectedAge.key === null) return true;
			const age = `${course.age_threshold_from}-${course.age_threshold_to}`;
			return age.includes(selectedAge.key);
			})
		setCourses(x);
	},[selectedActive, selectedLanguage, selectedAge]);


	const setSortHandler = (sortObj) => {
		setSort(prevState => {
			const x = prevState.map(el => ({
				...el,
				selected: el.name === sortObj.name
			}));

			setGroups(prevState1 => prevState1.sort((a, b) => {
				return sortObj.key === 'ASC' ? a.group_name.localeCompare(b.group_name) : b.group_name.localeCompare(a.group_name)
			}));

			return x;
		})
	};

	const groupEditIsOpen = () => {
		setGroupEdit(!groupEdit);
	};

	const deleteGroupIsOpen = () => {
		setDeleteGroup(!deleteGroup);
		setGroupEdit(false);
	};

	const createGroupIsOpen = () => {
		setGroupCreate(!groupCreate);
		setDropDown(false);
	};

	const createCourseOpen = () => {
		dispatch(courseItemClear());
		setDropDown(false);
	};

	const clearSearch = () => {
		setCourseSelect([]);
		setValue('');
		getCourses();
	}
	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Course</h2>
			<ContentWrapperMonetization>
				<HeaderButtonsWrapper>
					{groupEdit && <GroupEdit groupEditIsOpen={groupEditIsOpen}
																	 getGroup={getGroup}
																	 courses={courses}
																	 deleteGroupIsOpen={deleteGroupIsOpen}/>}
					{deleteGroup && <GroupDelete deleteGroupIsOpen={deleteGroupIsOpen}
																			 getGroup={getGroup}/>}
					{groupCreate && <GroupCreate createGroupIsOpen={createGroupIsOpen}
																			 getGroup={getGroup}/>}
					<SearchWrapper>
						{courseSelect.length ? (
							<CourseSearch  onClick={clearSearch}>
								{courseSelect[0].name.uk ? courseSelect[0].name.uk : courseSelect[0].name.en || courseSelect[0].name.ru}
							</CourseSearch>
						) : (
							<label>
								<input type="text"
											 placeholder={searchString ? searchString : "Search"}
											 onChange={onChange}/>
							</label>
						)
						}

						{
							courseSelect.length === 0
							&&
							<>
								{value.length === 0 ? (
									<></>
								) : (
									<SearchDiv>
										<SearchCollectionCourse items={items}
																						onCourseSelected={onCourseSelected}/>
									</SearchDiv>
								)}
							</>
						}
					</SearchWrapper>
					<DropDownWrapper>
						<Link>
							<ButtonAddWIthArr onClick={() => changeState(dropDown, setDropDown)}>
								<img src={plus}/>Add
								<img src={arr}/>
							</ButtonAddWIthArr>
						</Link>
						{dropDown &&
							<DropDown>
								<li onClick={createGroupIsOpen}>Group</li>
								<li onClick={createCourseOpen}><Link to="/create-course">Course</Link></li>
							</DropDown>
						}
					</DropDownWrapper>
				</HeaderButtonsWrapper>
				<CourseFilters 	selectedActive={selectedActive}
												selectedAge={selectedAge}
												selectedLanguage={selectedLanguage}
												selectedSort={selectedSort}
												languages={languages}
												setLanguages={setLanguagesHandler}
												actives={actives}
												setActives={setActiveHandler}
												setAges={setAgeHandler}
												ages={ages}
												sort={sort}
												setSort={setSortHandler}/>
				<CourseWrapper>
					{loading ? (
						<ClipLoader color={"#C014E3"} loading={loading} size={300}/>
					) : (
						<GroupCollection groups={groups} courses={courses} groupEditIsOpen={groupEditIsOpen}/>
					)}
				</CourseWrapper>
			</ContentWrapperMonetization>
		</Row>
	);
}

export default Course;