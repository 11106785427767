import React from 'react';

import account from "../../img/account.png";

import {RenderDivChildName} from '../../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled';

import edit from "../../img/Vector (Stroke).png";

const ChildNameBlock = ({childName, handleClickChildIdOne}) => {

	return (
		<RenderDivChildName>
			<div className="div-img-render-child-name">
				<img src={account} alt={account}/>
			</div>
			<ul style={{display: 'flex', justifyContent: 'space-between', width: "100%"}}>
				<li onClick={handleClickChildIdOne}>{childName}</li>
				<li><img src={edit}/></li>
			</ul>
		</RenderDivChildName>
	);
}

export default ChildNameBlock;