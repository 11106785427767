import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	token: null
}
const tokenSlice = createSlice({

	name: "token",
	initialState,
	reducers: {
		tokenSave(state, action){
			state.token = action.payload;
		},
		tokenClear(state, action){
			state.token = null;
		},

	},

})

export const {tokenSave, tokenClear} = tokenSlice.actions;

export default tokenSlice.reducer;
