import React from 'react';
import {useState} from 'react';
import {ReportCallItem} from "./ReportCallItem";

import {
    TableWrapper,
    TableName
} from "./ReportStyled";
import './report.sass';

const ReportTable = () => {
    const [reports, setReports] = useState([]);
    const [allReports, setAllReports] = useState([]);
    const [equalChildren, setEqualChildren] = useState([]);
    const [startDateMS, setStartDateMS] = useState("");
    const [endDateMS, setEndDateMS] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const getReports = () => {
        fetch(`/api/admin/get-reports?start=${currentStartDate}&end=${currentEndDate}`)
            .then(res => res.json())
            .then((res) => {
                    setReports(findEquals(res.meetings));
                    setAllReports(res.meetings);
                    setEqualChildren(findEqualChild(res.meetings));
                }
            )
            .catch(err =>
                console.log(err))
    }

    function findEquals(arr) {
        const resultArrays = {}
        arr.forEach(item => {
            if (!resultArrays[item.entertainer.userId]) {
                resultArrays[item.entertainer.userId] = [item]
            } else {
                resultArrays[item.entertainer.userId].push(item)
            }
        })
        return Object.values(resultArrays)
    }

    function findEqualChild(arr) {
        const resultArrays = {}
        arr.forEach(item => {
            if (item.child.id) {
                if (!resultArrays[item.child.id]) {
                    resultArrays[item.child.id] = [item]
                } else {
                    resultArrays[item.child.id].push(item)
                }
            } else if (item.parent.userId) {
                if (!resultArrays[item.parent.userId]) {
                    resultArrays[item.parent.userId] = [item]
                } else {
                    resultArrays[item.parent.userId].push(item)
                }
            }
        })
        const equal = Object.values(resultArrays);
        return equal;
    }

    const onChangeStartDate = function (el) {
        setStartDateMS(Date.parse(el.target.value));
        setStartDate(el.target.value);
    }
    let currentStartDate = startDateMS - 7200000;
    let currentEndDate = endDateMS + 79201000;

    const onChangeEndDate = function (el) {
        setEndDateMS(Date.parse(el.target.value));
        setEndDate(el.target.value);
    }

    let key = 0;

    return (
        <TableWrapper>
            <div className='input-report-wrapper'>
                <input onChange={onChangeStartDate} className='start-report-date' type="date"/>
                <input onChange={onChangeEndDate} className='end-report-date' type="date"/>
                <button type='button' onClick={getReports} className='btn-report'>Send</button>
            </div>
            <div className="table-container">
                {allReports.length
                    ? <TableName>Calls report for period <br/> from {startDate} to {endDate}</TableName>
                    : null
                }
                {reports.map((report, index) =>
                    <div key={report[0].entertainer.name}>
                        <div>
                            <div className='report-ent-name'>
                                {
                                    (report[0]?.entertainer?.name
                                        && report[0]?.entertainer?.userId
                                        && report[0].entertainer.name !== "undefined undefined")
                                        ?
                                        report[0].entertainer.name
                                        :
                                        'Without entertainer'
                                }
                            </div>
                            {report.map((item) =>
                                <ReportCallItem
                                    key={'call' + key++}
                                    item={item}
                                />
                            )}
                            <ol className='table-down'>
                                <li>
                                    {
                                        (report[0]?.entertainer?.name
                                            && report[0]?.entertainer?.userId
                                            && report[0].entertainer.name !== "undefined undefined"
                                            )
                                            ?
                                            report[0].entertainer.name + ' total'
                                            :
                                            'Without entertainer total'
                                    }
                                </li>
                                <li>Calls</li>
                                <li>Children</li>
                                <li></li>
                                <li>{report.length}</li>
                                <li>{findEqualChild(report).length}</li>
                                <li></li>
                            </ol>
                        </div>
                    < /div>
                )}
                {allReports.length
                    ? <ol className='table-total'>
                        <li>Report total</li>
                        <li>Entertainers</li>
                        <li>Children</li>
                        <li>Calls</li>
                        <li>{reports.length}</li>
                        <li>{equalChildren.length}</li>
                        <li>{allReports.length}</li>
                    </ol>
                    : null
                }
            </div>
        </TableWrapper>
    )
}

export default ReportTable;