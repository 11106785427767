import React from 'react';

import {ShowAlertContent} from './create-text-styled/CreateTextStyled';

function CreateText() {

	return (

			<ShowAlertContent>
				<div>
					<h3>Meeting created!</h3>
				</div>
			</ShowAlertContent>

	);
}

export default CreateText;

