import {createAsyncThunk} from '@reduxjs/toolkit';

export const getProfileChildUpdate = createAsyncThunk(
	"get-child/getMeetingById",
	async function (childNumber, {rejectWithValue}) {
		try {

			const res = await 	fetch(`/api/admin/get-profile-by-user-phone?phone=${childNumber}`)
			const data = await  res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}

	}
);
