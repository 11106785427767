import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

import TimeInterval from "./TimeInterval/TimeInterval";
import DayInterval from "./dayInterval/DayInterval";
import useMoment from "../../../hooks/useMoment";
import SearchEntertainer from "./search-entertainer/SearchEntertainer";
import {getMilliseconds, handleStopPropagation} from "../../../../helpers";
import {
	closeCreateSlots,
	closeCreateTimeOffSlot,
	closeInfoSlots,
	slotInfoClear
} from "../../../../store/slicers/create-meeting-slice";
import {fetchApiGet, fetchApiPost, fetchApiPut} from "../../../../utils/axios";
import {
	ADMIN_ENTERTAINERS_SLOTS,
	ADMIN_ENTERTAINERS_SLOTS_DELETE, ADMIN_ENTERTAINERS_SLOTS_DELETE_TIME_OFF, ADMIN_ENTERTAINERS_SLOTS_TIME_OFF,
	ADMIN_USER_BY_ID,
	route
} from "../../../../utils/constants_urls";
import useToken from "../../../hooks/useToken";
import RepeatableField from "./ RepeatableField";
import SlotEditingConfirmation from "./SlotEditingConfirmation";

import {
	CloseBtn,
} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {SlotInfoWrapper} from "../StyledSlots";
import {getDataSchedule} from "../../../../store/actions/schedule-action";
import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {
	ButtonCancelSlot,
	ButtonCreateSlot, DateWrapper,
	H4CreateSlots,
	ModalContentSlots,
	PWrapper, SelectedTime, SelectedTimeWrapper, TimeWrapper,
} from "./StyledcreateSlotsModal";

import close from "../../../create-meeting/img/close.png";
import avatarImg from "../img/Image.png";

function CreateSlotsModal({timeStampEnd, timeStampStart, isOpenOkCreate, isOpenErrCreate,  isOpenOkUpdate, isOpenErrUpdate, }) {

	const {slotInfo} = useSelector(state => state.create);
	const {isOpenCreateTimeOffSlot} = useSelector(state => state.create);

	const {setToday, today} = useMoment();
	const dispatch = useDispatch();
	const {token} = useToken();
	const {entertainerNameSlotsCreate} = useSelector(state => state.name);
	const [user, setUser] = useState('');
	const [loading, setLoading] = useState(true);
	const [loadingSave, setLoadingSave] = useState(false);
	const [timeAdd, setTimeAdd] = useState("Start");
	const [timeAddEnd, setTimeAddEnd] = useState("End");
	const [dayAdd, setDayAdd] = useState(today.format("x"));
	const [Day, setDay] = useState("");
	const [entertainer, setEntertainer] = useState(entertainerNameSlotsCreate);
	const [showDropDown, setShowDropDown] = useState(false);
	const [showDropDownEnd, setShowDropDownEnd] = useState(false);
	const [showCalendar, setShowCalendar] = useState(false);
	const [slot, setSlot] = useState(slotInfo);
	const [disabled, setDisabled] = useState(true);
	const [showDropDownRep, setShowDropDownRep] = useState(false);
	const [repeatableSelect, setRepeatableSelect] = useState("Does not repeat");
	const [repeatable, setRepeatable] = useState(null);
	const [todayDay, setTodayDay] = useState("");
	const [isOpenConfirm, setIsOpenConfirm] = useState(false);


	useEffect(()=> {
		if(repeatableSelect === "Daily"){
			setRepeatable(3);
		}
		if(repeatableSelect === `Weekly on Monday ${todayDay}`){
			setRepeatable(2);
		}
		if(repeatableSelect === "Every week day"){
			setRepeatable(7);
		}
	},[repeatableSelect]);

	useEffect(() => {
		if(timeAdd !== "Start" && timeAddEnd !== "End" && entertainer){
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	},[timeAdd, timeAddEnd, entertainer]);

	useEffect(() => {
		const getProfileByUserId = async () => {
			const url =  route(ADMIN_USER_BY_ID, [':userId', slot.id]);
			const result = await fetchApiGet({url, token});
			setUser(result);
			setLoading(false);
		}


		if(slot){
			getProfileByUserId();
			setTimeAdd(moment(slot.slot_time.start_time).format("HH:mm"));
			setTimeAddEnd(moment(slot.slot_time.end_time).format("HH:mm"));
		}
	},[]);


	useEffect(() => {
		let date;
		if(dayAdd){
			date = `${moment(+dayAdd).format("D")} ${ moment(+dayAdd).format("MMM")}`;
			setTodayDay(moment(+dayAdd).format('dddd'))
		}
			setDay(date);

	},[dayAdd]);
	useEffect(() => {
			let date;
			if(slot){
				setDayAdd(moment(slot.slot_time.start_time).format("x"));
				date = `${moment(slot.slot_time.end_time).format("D")} ${moment(slot.slot_time.end_time).format("MMM")}`;
				setTodayDay(moment(slot.slot_time.end_time).format('dddd'))
				setDay(date);
			}

		},[]);

	const onTimeSelect = (ev) => {
		setTimeAdd(ev.target.innerText);
		setShowDropDown(false);
	}
	const onTimeSelectEnd = (ev) => {
		setTimeAddEnd(ev.target.innerText);
		setShowDropDownEnd(false)
	}

	const onDaySelect = (ev) => {
		setDayAdd(ev);
		setShowCalendar(false)
	}


	const getToISOString = (timeAdd, dayAdd) => {
		if(timeAdd && dayAdd){
			return moment(parseInt(dayAdd)).startOf("day").add(getMilliseconds(timeAdd)).toISOString('Europe/Kyiv');
		}

	}

	const createSlots = async () => {
		setLoadingSave(true);
		const url = isOpenCreateTimeOffSlot ? (
			route(ADMIN_ENTERTAINERS_SLOTS_TIME_OFF, [':entertainer_id', entertainer.id])
		) : (
			route(ADMIN_ENTERTAINERS_SLOTS, [':entertainerId', entertainer.id]));

		const data = {
			"time_from": getToISOString(timeAdd, dayAdd),
			"time_to": getToISOString(timeAddEnd, dayAdd),
			"repeat": repeatable,
		};
		const result = await fetchApiPost({url, token, data});
		if(result.status === 'ok'|| result.slot_id){
			isOpenOkCreate()
			dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
			dispatch(closeCreateSlots());
			dispatch(closeCreateTimeOffSlot());
			setSlot(null);
			setLoadingSave(false);
		} else {
			isOpenErrCreate();
			setLoadingSave(false);
		}
	}
	const updateSlots = async (checkedAll) => {
		setLoadingSave(true);
		const url = isOpenCreateTimeOffSlot ? (
			route(ADMIN_ENTERTAINERS_SLOTS_DELETE_TIME_OFF, [':entertainer_id', slotInfo.id], [':slot_id', slotInfo.slot_id])
		) : (
			route(ADMIN_ENTERTAINERS_SLOTS_DELETE, [':entertainer_id', slot.id], [':slot_id', slotInfo.slot_id])
		)


		const data = {
			"time_from": getToISOString(timeAdd, dayAdd),
			"time_to": getToISOString(timeAddEnd, dayAdd),
			"edit_all": checkedAll ? checkedAll : false
		};
		const result = await  fetchApiPut({url, token, data});
		if(result.status === "ok"){
			isOpenOkUpdate()
			dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
			dispatch(closeCreateSlots());
			dispatch(slotInfoClear());
			dispatch(closeInfoSlots());
			dispatch(closeCreateTimeOffSlot());
			handlerConfirmClose();
			setSlot(null);
			setLoadingSave(false);
		} else {
			isOpenErrUpdate();
			setLoadingSave(false);
			if (checkedAll){
				handlerConfirmClose()
			}
		}
	}



	const closeBtn = () => {
		setSlot(null);
		dispatch(closeCreateSlots());
		dispatch(closeCreateTimeOffSlot());
	}

	const hideDate = () => {
		setShowCalendar(false);
		setShowDropDown(false);
		setShowDropDownEnd(false);
		setShowDropDownRep(false);
	}
	const handlerShowCalendar = (e) => {
		setShowCalendar(true);
		handleStopPropagation(e);
	}
	const handlerShowDropDown = (e) => {
		setShowDropDown(true);
		handleStopPropagation(e);
	}
	const handlerShowDropDownEnd = (e) => {
		setShowDropDownEnd(true);
		handleStopPropagation(e);
	}

	const cancelButton = () => {
		dispatch(closeCreateTimeOffSlot());
		dispatch(closeCreateSlots())
	}

	const handlerConfirmClose = () => {
		setIsOpenConfirm(!isOpenConfirm);
	}

	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentSlots onClick={hideDate}>
					<CloseBtn onClick={closeBtn}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<form className="modal-content-meeting-div" onSubmit={e => e.preventDefault()} >
						 {isOpenConfirm && <SlotEditingConfirmation updateSlots={updateSlots}
																												handlerConfirmClose={handlerConfirmClose}
																												// setOpenConfirm={handlerConfirmClose}
						 />}

						<H4CreateSlots>{isOpenCreateTimeOffSlot ? "Plan a Time Off" : "Plan a Slot"}</H4CreateSlots>
						{slot ? (
							<SlotInfoWrapper>
								{ loading ? <ClipLoader color={"#C014E3"} loading={loading} size={30}/> :
									<li>
										<img className="avatar" src={avatarImg}/>
										<span>{user.first_name} {user.last_name}</span>
									</li>
								}
							</SlotInfoWrapper>
						) : (
							<div>
								<SearchEntertainer entertainer={entertainer} setEntertainer={setEntertainer}/>
							</div>
						)
						}

						<SelectedTimeWrapper>
							<PWrapper>
								<p>Date</p>
								<p>Slot Duration</p>
							</PWrapper>
							<DateWrapper>
								<div>Date:</div>
								{slotInfo && !slotInfo.is_repeatable || !slotInfo? (
									<div style={{position: "relative"}} >
										{showCalendar && <DayInterval setToday={setToday} today={today} onDaySelect={onDaySelect}/>}
										<div  onClick={(e) => handlerShowCalendar(e)}>{Day}</div>
									</div>
								):(<div>{Day}</div>)}

							</DateWrapper>
							<TimeWrapper>
								<div style={{position: "relative"}}>
									{showDropDown && <TimeInterval onTimeSelect={onTimeSelect}/>}
									<SelectedTime className="no-time" onClick={(e) => handlerShowDropDown(e)}>{timeAdd}</SelectedTime>
								</div>
								<p style={{marginTop: "5px"}}>-</p>
								<div style={{position: "relative"}}>
									{showDropDownEnd && <TimeInterval onTimeSelect={onTimeSelectEnd}/>}
									<SelectedTime className="no-time " onClick={(e) => handlerShowDropDownEnd(e)}>{timeAddEnd}</SelectedTime>
								</div>

							</TimeWrapper>
						</SelectedTimeWrapper>
						{!isOpenCreateTimeOffSlot && !slotInfo && <RepeatableField showDropDownRep={showDropDownRep}
																													repeatableSelect={repeatableSelect}
																													todayDay={todayDay}
																													setRepeatableSelect={setRepeatableSelect}
																												 	setShowDropDownRep={setShowDropDownRep}/>
						}
						<ButtonCancelSlot onClick={cancelButton}>Cancel</ButtonCancelSlot>
						{slot ? (
								<>
									{loadingSave ? <ClipLoader color={"#C014E3"} loading={loadingSave} size={30}/> : (
										<>{
											slotInfo.is_repeatable ? (
												<ButtonCreateSlot onClick={handlerConfirmClose} disabled={disabled}>Update</ButtonCreateSlot>

											):(
												<ButtonCreateSlot onClick={() => updateSlots()} disabled={disabled}>Update</ButtonCreateSlot>
											)
										}</>
									)}
								</>
						) : (
							<>
								{loadingSave ? <ClipLoader color={"#C014E3"} loading={loadingSave} size={30}/> : (
									<ButtonCreateSlot onClick={createSlots} disabled={disabled}>Create</ButtonCreateSlot>
								)}
							</>
						)}
					</form>
				</ModalContentSlots>
			</div>
		</WrapperForModalMeeting>

	);
}

export default CreateSlotsModal;
