import React from 'react';
import styled from "styled-components";

export const ShowAlertWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
	width: 400px;
	top: 150px;
	left: calc(50vw - 400px);
  z-index: 30000;
`;

export const ShowAlertContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -6px 7px 32px 9px rgba(34, 60, 80, 0.1);
  background-color: #fff;
  width: 100%;
  height: 200px;
	color: #2C2738;
	
`;