import React, {useState, useEffect} from 'react';
import {getPadTime} from "../../helpers/getPadTime";

export default function useTimer() {
	const [timerLeft, setTimerLeft] = useState(7 * 60);
	const [isCounting, setIsCounting] = useState(false);
	const minutes = getPadTime(Math.floor(timerLeft / 60));
	const seconds = getPadTime(timerLeft - minutes * 60);
	const timerStart = () => {
		setTimerLeft(7 * 60);
		setIsCounting(true);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			isCounting &&
			setTimerLeft((timerLeft) => (timerLeft >= 1 ? timerLeft - 1 : 0));
		}, 1000);
		if (timerLeft === 0) setIsCounting(false);
		return () => {
			clearInterval(interval);
		};
	}, [isCounting])

	return {
		timerStart, minutes, seconds, setIsCounting
	}

}

