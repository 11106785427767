import React from "react";
import styled from "styled-components";
import plus from "../plus.png";

export const InputKid = styled.input`
  border: 1px solid #DBE2EA;
  width: 230px;
  padding: 5px 5px 5px 10px;
  border-radius: 6px;
  background-image: url(${plus});
  background-repeat: no-repeat;
  background-position: 205px 10px;
	&::placeholder {
    color: #262323;
    font-size: 12px;
  }
  &:focus {
    outline: none;
  }
`;

export const UlSelectedKid = styled.ul`
	width: 230px;
	border: 1px solid #DBE2EA;
	background-color: #ffffff;
	border-radius: 6px;
	padding: 5px 10px 5px 10px;
	& li{
		display: flex;
		justify-content: space-between;
		& div.img-clear{
      width: 12px;
			& img{
				width: 100%;
				margin-bottom: 2px;
			}
    }
	}
`;


export const KidWrapper = styled.div`
	
`;
export const LabelSearchFilterWrapper = styled.div`
	position: relative;
	margin-left: -230px;
`;
export const LabelSearchFilter = styled.label`
	position: absolute;
	z-index: 255;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
`;
export const SearchDiv = styled.div`
	position: relative;
`;

export const DivAb = styled.div`
  position: absolute;
  left: 500px;
  top: 0;
  z-index: 301;
`;
export const DivAbOne = styled.div`
  position: absolute;
  left: 520px;
  top: 10px;

`;

export const ChildrenUlHeader = styled.ul`
  position: absolute;
  top: 25px;
  left: 520px;
  background-color: #ffffff;
  width: 230px;
  z-index: 300;

  & li {
    padding: 10px;
    border-bottom: 1px solid #DBE2EA;
    width: 100%;
    height: 40px;
    cursor: pointer;
  }

  & li:first-child {
    padding-top: 18px;
    height: 50px;
    border-top: 1px solid #DBE2EA;
    border-right: 1px solid #DBE2EA;
    border-left: 1px solid #DBE2EA;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  & li:last-child {
    border-bottom: 1px solid #DBE2EA;
    border-right: 1px solid #DBE2EA;
    border-left: 1px solid #DBE2EA;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;
