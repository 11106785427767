import React from "react";
import styled from "styled-components";

export const DivWrapper = styled.div`
	display: flex;
  justify-content: space-between;
	width: 220px;
	margin-left: 10px;
`;
export const ButtonWrapperCenter = styled.button`
   border: unset;
   outline: unset;
	 font-size: 12px;
   color: #C6C2CF;
   border: 1px solid #DBE2EA;
	 background-color: #F1F3F5;
	 width: 128px;
   height: 38px;
	 border-radius: 5px;
 `;

export const ButtonWrapper = styled.button`
	 border: unset;
	 background-color: #DCE3EB;
   border-radius: 6px;
	 color: #9BA8B6;
	 outline: unset;
	 width: 38px;
	 height: 38px;
	 text-align: center;
 `;