import {createSlice} from '@reduxjs/toolkit';

import {searchUsersMeetings} from "../actions/search-meetings-users-action";


const meetingSearchSliceUser = createSlice({
	name: "slotsUser",
	initialState: {
		eventSearchListUsers: [],
		statusCode: null,
		status: null,
		error: null,
		updateEvent: null,
	},
	reducers: {
		clearEventSearchListUser: (state, action) => {
			state.eventSearchListUsers = [];
		},
	},
	extraReducers: {
		//? get meetings entertainer
		[searchUsersMeetings.pending]: (state) => {
			state.status = 'loading';
			state.error = null;
			state.statusCode = null;
		},
		[searchUsersMeetings.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.eventSearchListEntertainer = action.payload.slots;
			state.error = null;
		},
		[searchUsersMeetings.rejected]: (state, action) => {
			state.status = 'rejected';
			state.error = action.payload;
			state.statusCode = null;
		},

	}

})
export const {clearEventSearchListUser} = meetingSearchSliceUser.actions;
export default meetingSearchSliceUser.reducer;