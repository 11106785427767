import React from "react";
import styled from "styled-components";
export const UlUserCollectionNamesOfUserList = styled.div`
  list-style-type: none;
  display: flex;
  margin-left: 0px;
  padding-left: 0px;
  & li:nth-child(1){
    width: 15%;
    padding-left: 25px;
  }
  & li:nth-child(2){
    width: 13%
  }
  & li:nth-child(3){
    width: 11%
  }
  & li:nth-child(4){
    width: 7.2%
  }
  & li:nth-child(5){
    width: 5.2%
  }
	& li:nth-child(6){
    width: 11%
  }
	& li:nth-child(7){
			width: 5.8%
		}
  & li{
    padding-left: 10px
  }
`;