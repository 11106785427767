import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Row} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

import LoadPhoto from "./LoadPhoto";
import DownloadFile from "../pop-ups/DownloadFile";
import Checkbox from "../../../searchBar/usersCollection/checkbox/Checkbox";
import {changeState} from "../../../../helpers";
import {fetchApiGet, fetchApiPath, fetchApiPost} from "../../../../utils/axios";
import {
	ADMIN_COURSES,
	ADMIN_COURSES_BY_ID, ADMIN_COURSES_LINKING_CREATE,
	ADMIN_COURSES_SLASH,
	route
} from "../../../../utils/constants_urls";
import useToken from "../../../hooks/useToken";
import DeleteCourse from "../pop-ups/DeleteCourse";
import CourseCreateBody from "./CourseCreateBody";
import {
	courseItemClear,
	courseItemSave,
	coursesSave,
	groupItemSave,
	lessonItemSave
} from "../../../../store/slicers/course-slice";
import CourseSelectLanguage from "../CourseSelectLanguage";
import ManageTranslation from "../pop-ups/ManageTranslation";
import NotificationContentErr from "../../../notification/NotificationContentErr";
import NotificationContentOk from "../../../notification/NotificationContentOk";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {
	CancelButton,
	ContentWrapperMonetization, CreateButton, DeleteButton,
	IsActiveCheckbox
} from "../../monetization/styled-monetization/StyledMonetization";

import {
	CourseInfoBody, CourseLessonsWrapper,
	CreateCourseButtonWrapper,
	HeaderButtonsWrapperCreateCourse,
	ManageTranslations, PCourseLessons,
} from "../StyledCourse";

import arrToDown from "../img/Icon after.png";
import edit from "../img/edit.png";
import EntertainerBody from "./EntertainerBody";

const CreateCourse = () => {

	const {courseItem} = useSelector(state => state.course);
	const {imageUrl} = useSelector(state => state.course);
	const {token} = useToken();

	const [isActive, setIsActive] = useState(true);
	const [active, setActive] = useState(courseItem.course_id ? courseItem.status : "active");
	const [addPhoto, setAddPhoto] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [show, setShow] = useState(false);
	const [selectedAgeGroupFrom, setSelectedAgeGroupFrom] = useState(courseItem.course_id ? courseItem.age_threshold_from : "");
	const [selectedAgeGroupTo, setSelectedAgeGroupTo] = useState(courseItem.course_id ? courseItem.age_threshold_to : "");
	const [showManage, setShowManage] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [disabledUp, setDisabledUp] = useState(true);
	const [loading, setLoading] = useState(false);
	const [addEntertainers, setAddEntertainers] = useState([]);
	const [selectedEntertainer, setSelectedEntertainer] = useState([]);
	const arrLang = [

		{name: 'English', key: 'English', selected: false, langId: "en", isDefault: false},
		{name: 'Ukrainian', key: 'Ukrainian', selected: false, langId: "uk", isDefault: false},
		{name: 'Russian', key: 'Russian', selected: false, langId: "ru", isDefault: false},

	]
	const dataObg = {
		"name": {
			"uk": '',
		},
		"group_id": selectedGroup.group_id,
		"cover_image_url": imageUrl,
		"description": {
			"uk": '',
		},
		"age_threshold_from": +selectedAgeGroupFrom,
		"age_threshold_to": +selectedAgeGroupFrom,
		"status": active,
		"entertainers": addEntertainers,
		"tags": [
			"test",
			"test2"
		],

		"lessons": []
	};


	const [arrLanguage, setArrLanguage] = useState(arrLang.filter(el => el.isDefault));
	let selectedLanguage = arrLanguage.filter(({selected}) => selected)[0];
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [data, setData] = useState(courseItem.course_id ? JSON.parse(JSON.stringify(courseItem)) : dataObg);
	const [nameInput, setNameInput] = useState(courseItem.course_id ? courseItem.name.uk : '');
	const [descriptionInput, setDescriptionInput] = useState(courseItem.course_id ? courseItem.description.uk : "");
	const [sours, setSours] = useState('');
	const [showErr, setShowErr] = useState(false);
	const [createNotification, setCreateNotification] = useState(false);
	const [itemList, setItemList] = useState(courseItem.course_id && courseItem.lessons ? [...courseItem.lessons] : []);
	const navigate = useNavigate();
	const addImgSrc = (src) => {
		setSours(src);
	};
	const arrLoc = arrLanguage.filter(name => !data.name[name.langId]);
	const arrLocDes = arrLanguage.filter(name => !data.description[name.langId]);

	useEffect(() => {

		if (selectedGroup.group_id ||
			imageUrl ||
			selectedAgeGroupFrom ||
			selectedAgeGroupTo ||
			!arrLoc.length ||
			!arrLocDes.length
		) {
			setDisabled(false)
		} else {
			setDisabled(true);
		}

	}, [selectedGroup, imageUrl, selectedAgeGroupFrom, selectedAgeGroupTo, arrLanguage, data, arrLoc, disabled]);
	useEffect(() => {
		arrLanguage.forEach(lan => {
			if (lan !== undefined) {
				if (!data.name[lan.langId] || !data.description[lan.langId] || !descriptionInput || !nameInput) {
					setDisabledUp(true);
				} else {
					setDisabledUp(false)
				}
			}
		});
	}, [data, descriptionInput, nameInput]);

	useEffect(() => {
		data.age_threshold_from = +selectedAgeGroupFrom;
		data.age_threshold_to = +selectedAgeGroupTo;
		setData(data);
	}, [selectedAgeGroupFrom, selectedAgeGroupTo]);

	useEffect(() => {

		if (courseItem.course_id) {
			setData(JSON.parse(JSON.stringify(courseItem)));
			arrLang.forEach(lang => {
				const infoLoc = getLocale(lang.langId);
				if (infoLoc.name || infoLoc.description) {
					addLocale(lang.langId, infoLoc.name, infoLoc.description);
				}
				if (lang.isDefault) {
					setNameInput(infoLoc.name);
					setDescriptionInput(infoLoc.description)
				}
			})

		}
		setSelectedEntertainer(courseItem && courseItem.entertainers ? courseItem.entertainers : []);
	}, [courseItem]);

	const dispatch = useDispatch();

	const [showMan, setShowMan] = useState(false);

	const setLangHandler = (arrLanguage) => {
		setArrLanguage(prevState => prevState.map(lang => ({
			...lang,
			selected: arrLanguage === lang.name
		})));
	};

	useEffect(() => {
		if (isActive) {
			setActive("active");
		} else {
			setActive("inactive");
		}
	}, [isActive]);

	useEffect(() => {
		if (courseItem.course_id) {
			setActive(courseItem.status);
		}

	}, [courseItem]);

	useEffect(() => {
		if (active === "active") {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [active]);

	const isDeleteOpen = () => {
		setIsOpenDelete(!isOpenDelete);
	};

	const selectGroupHandler = (group) => {
		dispatch(groupItemSave(group));
		setSelectedGroup(group);
		setShow(false);
	};

	const openAddPhoto = () => {
		setAddPhoto(!addPhoto);
	};

	const handlerShowManage = () => {
		setShowManage(!showManage);
	}
	const addLocale = (locale, name, description) => {
		const prototype = arrLang.find(element => element.langId === locale);
		if (prototype === undefined) return;
		data.name[locale] = name;
		data.description[locale] = description;
		data.age_threshold_from = +selectedAgeGroupFrom;
		data.age_threshold_to = +selectedAgeGroupTo;
		data.status = active;
		data.group_id = selectedGroup.group_id;
		data.cover_image_url = imageUrl;
		data.lessons = [];
		setData(data)
		const persist = arrLanguage.find(element => element.langId === locale);
		if (persist !== undefined) return;
		arrLanguage.push(prototype);
		const arr = [...arrLanguage]
		setArrLanguage(arr);
	};

	const removeLocale = (locale) => {
		const prototype = arrLang.find(element => element.langId === locale);
		if (prototype === undefined || prototype.default) return;
		delete data.name[locale];
		delete data.description[locale];
		setData(data)
		const persist = arrLanguage.find(element => element.langId === locale);
		if (persist === undefined) return;
		if (persist.selected) {
			const def = arrLanguage.find(element => element.isDefault);
			if(def){

			def.selected = true;
			}
		}
		const index = arrLanguage.indexOf(persist);
		arrLanguage.splice(index, 1);
		const arr = [...arrLanguage]
		setArrLanguage(arr);
	};


	const getLocale = (locale) => {
		const result = {name: '', description: ''}
		if (data.name[locale]) {
			result.name = data.name[locale]
		}
		if (data.description[locale]) {
			result.description = data.description[locale]
		}
		return result;
	};


	const chooseLocales = (localeArr) => {
		localeArr.forEach(el => {
			const loc = getLocale(el.langId);
			if (el.isChecked) {
				setShowMan(true);
				setLangHandler(el.name);
				addLocale(el.langId, loc.name, loc.description)
			} else {
				removeLocale(el.langId);
			}
		})
		handlerShowManage();
	};

	useEffect(() => {
		const sel = arrLanguage.find(element => element.selected);
		if (sel && sel.langId) {
			addLocale(sel.langId, nameInput, descriptionInput);
		}
	}, [nameInput, descriptionInput]);

	useEffect(() => {
		const sel = arrLanguage.find(element => element.selected);
		if (sel && sel.langId) {
			const values = getLocale(sel.langId);
			setNameInput(values.name);
			setDescriptionInput(values.description);
		}
	}, [arrLanguage]);

	const getCourses = async () => {
		const url = ADMIN_COURSES;
		const response = await fetchApiGet({url, token});
		dispatch(coursesSave(response));
	};

	const updateCourse = async () => {
		data.status = active;
		setLoading(true);
		const newLessons = JSON.parse(JSON.stringify(itemList));
		newLessons.forEach((lesson, i) => {
			lesson.order = i + 1
		});

		data.lessons = newLessons;
		data.group_id = selectedGroup.group_id;
		data.cover_image_url = imageUrl ? imageUrl : courseItem.cover_image_url;
		data.entertainers = addEntertainers;
		const url = route(ADMIN_COURSES_BY_ID, [':courseId', courseItem.course_id]);
		const result = await fetchApiPath({url, token, data});
		if (result.course_id) {
			isOpenOk();
			getCourses();
			setLoading(false);
		} else {
			isOpenErr();
			setLoading(false);
		}
	};

	const createCourse = async () => {
		data.status = active;
		setLoading(true);
		data.cover_image_url = imageUrl ? imageUrl : courseItem.cover_image_url;
		data.entertainers = addEntertainers;
		data.group_id = selectedGroup.group_id;
		const response = await fetchApiPost({url: ADMIN_COURSES_SLASH, token, data});
		dispatch(courseItemSave(response));
		if (response.course_id) {
			isOpenOk();
			getCourses();
			navigate(`/course-info/${response.course_id}`);
			setLoading(false);
		} else {
			isOpenErr();
			setLoading(false);
		}

	};


	const isOpenErr = () => {
		setShowErr(!showErr);
	}
	const isOpenOk = () => {
			setCreateNotification(!createNotification);
		}

	;

	const handleDrop = (droppedItem) => {

		if (!droppedItem.destination) return;
		let updatedList = [...itemList];

		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

		setItemList(updatedList);
	};


	useEffect(()=>{
		if (arrLanguage && arrLanguage.length){
			setShowMan(true)
		} else {
			setShowMan(false);
		}
	},[arrLanguage])
	useEffect(()=>{
		if (!showMan){
			setShowManage(true);
		}
	},[showMan]);

	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Course Creation Page</h2>
					{isOpenDelete && <DeleteCourse isDeleteOpen={isDeleteOpen}/>}
					{showManage && <ManageTranslation handlerShowManage={handlerShowManage}
																						arrLang={arrLang}
																						showMan={showMan}
																						chooseLocales={chooseLocales}
																						arrLanguage={arrLanguage}/>}
			{showMan  &&
				<>
					{showErr && <NotificationContentErr h3="The course has not been created"
																							setClose={isOpenErr}
																							p="Something went wrong. Try again"/>}
					{createNotification && <NotificationContentOk h3="Course created"
																												setClose={isOpenOk}
																												p="Changes were successfully saved!"/>}
					<ContentWrapperMonetization>
						<HeaderButtonsWrapperCreateCourse>
							{addPhoto && <DownloadFile openAddPhoto={openAddPhoto} addImgSrc={addImgSrc} sours={sours}/>}
							<IsActiveCheckbox>
								<span>Active</span>
								<Checkbox handleChangeState={() => changeState(isActive, setIsActive)}
													myState={isActive}/>
							</IsActiveCheckbox>
							<CourseSelectLanguage selectedLanguage={selectedLanguage}
																		arrLanguage={arrLanguage}
																		setLangHandler={setLangHandler}/>
							<ManageTranslations onClick={handlerShowManage}>Manage translations <img
								src={arrToDown}/></ManageTranslations>
						</HeaderButtonsWrapperCreateCourse>
						<CourseInfoBody>
							<LoadPhoto openAddPhoto={openAddPhoto} sours={sours}/>

							<CourseCreateBody selectGroupHandler={selectGroupHandler}
																setShow={setShow}
																show={show}
																setSelectedAgeGroupFrom={setSelectedAgeGroupFrom}
																setSelectedAgeGroupTo={setSelectedAgeGroupTo}
																descriptionInput={descriptionInput}
																nameInput={nameInput}
																setDescriptionInput={setDescriptionInput}
																setNameInput={setNameInput}
																selectedAgeGroupFrom={selectedAgeGroupFrom}
																selectedAgeGroupTo={selectedAgeGroupTo}
																selectedGroup={selectedGroup}/>
							<EntertainerBody setAddEntertainers={setAddEntertainers}
															 selectedEntertainer={selectedEntertainer}
															 setSelectedEntertainer={setSelectedEntertainer}/>
						</CourseInfoBody>
						<CourseLessonsWrapper>
							<PCourseLessons>Course lessons</PCourseLessons>
							<DragDropContext onDragEnd={handleDrop}>
								<Droppable droppableId="list-container">
									{(provided) => (
										<ul
											className="list-container"
											style={{
												display: "flex",
												fontSize: "18px",
												backgroundColor: "#eee",
												flexDirection: "column"
											}}
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											{itemList.map((item, index) => (
												<Draggable key={item.lesson_id} draggableId={item.lesson_id} index={index}>
													{(provided) => (
														<li
															className="item-container"
															style={{
																backgroundColor: '  #F8F9FA',
																border: "1px solid black",
																padding: "25px 70px",
																margin: "15px 50px"
															}}
															ref={provided.innerRef}
															{...provided.dragHandleProps}
															{...provided.draggableProps}
															onClick={() => dispatch(lessonItemSave(item))}
														>

															<Link to={`/lesson-info/${item.lesson_id}`}>
																{index + 1}{item.name.uk ? item.name.uk : item.name.en || item.name.ru}
															</Link>
															<Link to="/create-lesson">
																<div onClick={() => dispatch(lessonItemSave(item))}>
																	<img src={edit}/>
																</div>
															</Link>

														</li>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</ul>
									)}
								</Droppable>
							</DragDropContext>
						</CourseLessonsWrapper>
					</ContentWrapperMonetization>
					<CreateCourseButtonWrapper>
						{courseItem.course_id ? (
							<>
								{loading ? (
									<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
								) : (
									<>
										<DeleteButton onClick={isDeleteOpen}>Delete</DeleteButton>
										<Link to="/course">
											<CancelButton onClick={() => dispatch(courseItemClear())}>Cancel</CancelButton>
										</Link>
										<Link to="/course">
											<CreateButton onClick={updateCourse} disabled={disabledUp}>Save</CreateButton>
										</Link>
									</>
								)}
							</>
						) : (
							<>
								{loading ? (
									<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
								) : (
									<>
										<Link to="/course">
											<CancelButton>Cancel</CancelButton>
										</Link>
										<CreateButton onClick={createCourse} disabled={disabled}>Save</CreateButton>
									</>
								)}
							</>
						)}

					</CreateCourseButtonWrapper>
				</>}
		</Row>
	);
};

export default CreateCourse;