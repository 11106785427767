import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	goTime: null,
}
const goStartTime = createSlice({
	name: "go",
	initialState,
	reducers: {

	},

})

export default goStartTime.reducer;
