import React from 'react';
import {Button, FieldContainer, Input, Label} from "../StyledConfig";

const SupportLink = ({handleDiscard, handlePublish, supportLink, isEditing, setIsEditing, setSupportLink}) => {

	const editHandler = () => {
		handleDiscard('supportLink')
		setIsEditing((prevState) => ({...prevState, supportLink: true}))
	}


	return (
		<FieldContainer>
			<Label>Support Link:</Label>
			{isEditing.supportLink ? (
				<Input style={{width: "200px"}}
					type="text"
					value={supportLink}
					onChange={(e) => setSupportLink(e.target.value)}
				/>
			) : (
				<div style={{width: "200px", marginLeft: "50px"}}>
					<span>{supportLink}</span>
				</div>
			)}
			{isEditing.supportLink ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('supportLink')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('supportLink')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>
	);
};

export default SupportLink;