import React from 'react';
import {useSelector} from "react-redux";

import ContentWithOutHIde from "./meeting-content/ContentWithOutHIde";
import ContentWithHide from "./meeting-content/ContentWithHide";

const WithoutEntertainerTrue = ({withoutEntertainer, handleClick, shownContent}) => {

	const {userName} = useSelector(state => state.name);

	return (
		<>
			{userName && withoutEntertainer? (
				<ContentWithOutHIde handleClick={handleClick}
														shownContent={shownContent}/>
			) : (
				<ContentWithHide handleClick={handleClick}
												 shownContent={shownContent}/>
			)}
		</>
	);
};

export default WithoutEntertainerTrue;