import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {courseItemClearCreateCall} from "../../../store/slicers/course-slice";

import {
	CourseNameDiv,
	CourseNameWrapper
} from "../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled";

import {
	CourseItemImgWrapper,
} from "../styled-create-meeting/search-styled/SearchStyled";


const CourseName = () => {

	const {courseItemCreateCall} = useSelector(state => state.course);
	const {courseProgress} = useSelector(state => state.course);
	const dispatch = useDispatch();


	return (
		<CourseNameWrapper onClick={() => dispatch(courseItemClearCreateCall())}>
			<CourseItemImgWrapper>
				<img src={courseItemCreateCall.cover_image_url}/>
			</CourseItemImgWrapper>
			<CourseNameDiv>
				{courseItemCreateCall.name.uk ? courseItemCreateCall.name.uk : courseItemCreateCall.name.en || courseItemCreateCall.name.ru}
				{courseProgress.length && courseProgress[0].finished_lessons ?
					(
						<span>
							{courseProgress[0].finished_lessons.length}
							/{courseItemCreateCall.lessons.length}
						</span>
					) : (
						<span>0/{courseItemCreateCall.lessons.length}</span>
					)
				}
			</CourseNameDiv>
		</CourseNameWrapper>
	);
};

export default CourseName;