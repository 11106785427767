import {createSlice} from '@reduxjs/toolkit';
import {getAllAccounts} from "../actions/user-list-action";


const setLoading = (state, action) => {
	state.status = 'loading';
	state.error = null;
}
const setError = (state, action) => {
	state.status = 'rejected';
	state.error = action.payload;
	state.statusCode = null;
}


const userListSlice = createSlice({
	name: "accounts",
	initialState: {
		accountsList: [],
		statusCode: null,
		statusCodeLogout: null,
		status: null,
		error: null,
	},
	reducers: {

	},
	extraReducers: {

		//? get accounts
		[getAllAccounts.pending]: setLoading,
		[getAllAccounts.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.accountsList = action.payload;
		},
		[getAllAccounts.rejected]: setError,
	}
})

export default userListSlice.reducer;