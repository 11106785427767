import {createSlice} from '@reduxjs/toolkit';
import {getProfileEntertainerUpdate} from "../actions/get-profile-by-phone-for-update-entertainer-action";

const getEntertainerProfileUpdate = createSlice({
	name: "get-entertainer",
	initialState: {
		entertainerProfile: {},
		statusCode: null,
		status: null,
		error: null,
	},
	reducers: {

	},
	extraReducers:
		{
			//? get meeting by id
			[getProfileEntertainerUpdate.pending]: (state) => {
				state.status = 'loading';
				state.error = null;
				state.statusCode = null;
			},
			[getProfileEntertainerUpdate.fulfilled]: (state, action) => {
				state.status = 'resolved';
				state.entertainerProfile = action.payload.profile;
				state.error = null;
			},
			[getProfileEntertainerUpdate.rejected]: (state, action) => {
				state.status = 'rejected';
				state.error = action.payload;
				state.statusCode = null;
			},
		}
})

export default getEntertainerProfileUpdate.reducer;