import React from 'react';

import clock from "../../img/clock.png";

import {DivImgClockTime} from '../../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled';


const TimeBlock = ({day, timeAdd, today}) => {

	return (
		<DivImgClockTime>
			<div className="div-img">
				<img src={clock} alt={clock}/>
			</div>
			<div style={{paddingTop: "4px"}}>
				<h6>{day} {today} {timeAdd} </h6>
			</div>
		</DivImgClockTime>
	);
}

export default TimeBlock;