import React from 'react';
import {Link} from "react-router-dom";

import {AgeWrapperLi, CourseBody} from "./StyledCourse";

function CourseItem({course}) {

	return (
		<CourseBody>
			<li>
				<ul>
					<li>
						<Link to={`/course-info/${course.course_id}`}>
							<span className="course-name">{course.name.uk ? course.name.uk : course.name.en || course.name.ru}</span>
						</Link>
					</li>
					<AgeWrapperLi>
						<span className="course-age-lang">{course.age_threshold_from} - {course.age_threshold_to} </span>
					</AgeWrapperLi>
					<AgeWrapperLi>
						{
							Object.keys(course.name).map((item,i )=> (
								<span className="course-age-lang" key={i}>{item}</span>
							))
						}
					</AgeWrapperLi>
				</ul>
			</li>
			<li></li>
			<li></li>
		</CourseBody>
	);

}

export default CourseItem;