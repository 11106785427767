import React from "react";
import styled from "styled-components";

export const SearchWrapper = styled.ul`
  overflow-y: auto;
	max-height: 250px;
	position: absolute;
  z-index: 255;
	background-color: #ffffff;
	width: 272px;
	border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
	border: 1px solid #DBE2EA;
	top: -7px;
`;

export const SearchWrapperEntertainer = styled.ul`
  overflow-y: auto;
	max-height: 250px;
	position: absolute;
  z-index: 245;
	background-color: #ffffff;
	width: 272px;
	border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
	border: 1px solid #DBE2EA;
	top: -15px;
	right: 0;
	padding-bottom: 10px;
`;
export const SearchWrapperCourse = styled.ul`
  overflow-y: auto;
	max-height: 250px;
	position: absolute;
  z-index: 245;
	background-color: #ffffff;
	width: 272px;
	border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
	border: 1px solid #DBE2EA;
	top: -7px;
	padding: 5px 0px;
`;

export const SearchWrapperLessons = styled(SearchWrapperCourse)`
  width: 210px;
`;
