import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import useTime from "../../hooks/useTime";
import useClose from "../../hooks/useClose";
import {setSelectChild, setSelectChildClear} from "../../../store/slicers/slice-modal";
import {
	childLanguageSave,
	entertainerNameForSave,
	parentNameForSave
} from "../../../store/slicers/name-slice";
import {slotIdClear} from "../../../store/slicers/create-meeting-slice";
import Theme from "../theme/Theme";
import { courseItemClearCreateCall} from "../../../store/slicers/course-slice";
import TimeBlock from "./create-modal-pieces/TimeBlock";
import Parent from "./create-modal-pieces/Parent";
import Entertainer from "./create-modal-pieces/Entertainer";
import ShowModal from "../../showModal/showModal";
import CreateText from "../../showModal/CreateModal/CreateText";
import Wishes from "../wishes/Wishes";
import CharacterSelect from "./create-modal-pieces/CharacterSelect";
import {TWENTY_MINUTES} from "../../../helpers/consts";
import {getData} from "../../../store/actions/dashboard-action";
import {fetchApiGet, fetchApiPost} from "../../../utils/axios";
import { ADMIN_MEETING, ADMIN_USERS_DEPENDENTS, route} from "../../../utils/constants_urls";
import useToken from "../../hooks/useToken";

import {
	CloseBtn,
	DesWrapper,
	ModalWrapper
} from "../styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import './modal.sass';

import close from "../img/close.png";

const ModalMeeting = (props) => {

	const {
		timeStampEnd,
		timeStampStart,
		setShowAlert
	} = props;

	const {parentName} = useSelector(state => state.name);
	const {selectChild} = useSelector(state => state.modal);
	const {entertainerName} = useSelector(state => state.name);
	const {lessonItem} = useSelector(state => state.course);
	const {courseItemCreateCall} = useSelector(state => state.course);

	const [show, setShow] = useState(false);
	const [user, setUser] = useState(entertainerName);
	const [kid, setKid] = useState(parentName);
	const [wishes, setWishes] = useState("");
	const [showChildren, setShowChildren] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [child, setChild] = useState(null);
	const [selectCharacter, setSelectCharacter] = useState(null);
	const [IdSlot, setIdSlot] = useState([]);
	const [loading, setLoading] = useState(false);
	const [day, today, finalDate, timeAdd] = useTime();
	const {handleClose} = useClose();
	const dispatch = useDispatch();
	const {slotId} = useSelector(state => state.create);
	const {token} = useToken();

	useEffect(() => {
		if(parentName){
			dispatch(parentNameForSave(kid));
		}
	},[parentName]);

	useEffect(() => {
		if(entertainerName){
			dispatch(entertainerNameForSave(user));
		}
	},[entertainerName]);

	useEffect(() => {
		kid && user && selectCharacter && lessonItem.lesson_id ?
			setDisabledBtn(false) :
			setDisabledBtn(true)
	}, [kid, user, selectCharacter, lessonItem]);


	useEffect(() => {
		const x = async () => {
			if (kid && kid.id){
				const url = route(ADMIN_USERS_DEPENDENTS, [':kidId', kid?.id]);
					const response = await fetchApiGet({url, token});
					if(response && response.entities.length){
						localStorage.setItem(`${kid.id}dependents`, JSON.stringify(response.entities));
						setChild(response.entities);
						if(response.entities.length === 1){
							dispatch(setSelectChild(response.entities[0].dependent_id));
							dispatch(childLanguageSave(response.entities[0].preferred_language));

						}
					}
			}
		}
		x();
	}, [kid]);

	const handleCloseBtn = () => {
		clearAll();
		handleClose();
		dispatch(slotIdClear());
	};

	const clearAll = () => {
		setUser(null);
		setKid(null);
	};
	useEffect(()=>{
		if(slotId && user){
			const slotIdEntertainer = slotId.filter(i => {
				if(i.id === user.id)	{
					return i
				}
			})
			setIdSlot(slotIdEntertainer[0]?.slot_id);
		}
	},[slotId, user]);

	const handlerModal = async () => {
		setLoading(true);

		const url = ADMIN_MEETING;

		const data = {
			"slot_id": IdSlot,
			"parent_id": kid.id,
			"dependent_id": selectChild,
			"time_from": moment(finalDate).toISOString(),
			"time_to": moment(finalDate + TWENTY_MINUTES).toISOString(),
			"meeting_theme": '',
			"meeting_description": wishes,
			"character": selectCharacter,
			"course_id": courseItemCreateCall.course_id,
			"lesson_id": lessonItem.lesson_id
		};
		const result = await fetchApiPost({url, token, data});

		if (result.meeting_id) {
			handleCloseBtn();
			dispatch(setSelectChildClear());
			setShowAlert(<CreateText/>);
			// dispatch(getData({timeStampStart, timeStampEnd, token}));
			dispatch(courseItemClearCreateCall());
			setLoading(false);
		} else {
			setShow(true);
			setLoading(false);
		}

	}

	return (
		<>
			{ show ?  (<ModalWrapper>
				<ShowModal show={show}
									 setShow={setShow}
									 text={'The meeting didn\'t create'}/>
			</ModalWrapper>) : null}
			<div className="modal-meeting active">

				<div className="modal-content-meeting">
					<CloseBtn onClick={handleCloseBtn}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<form className="modal-content-meeting-div">
						<div onClick={() => setShowChildren(false)}>
							<TimeBlock day={day}
												 today={today}
												 timeAdd={timeAdd}/>
							<Parent kid={kid}
											setKid={setKid}
											setShowChildren={setShowChildren}
											showChildren={showChildren}
											child={child}
											setChild={setChild}
											dependentInCall={selectChild}/>
							{selectChild && kid ? <Theme kid={kid}/> : null}
							<DesWrapper>
								<Wishes setWishes={setWishes} wishes={wishes} />
							</DesWrapper>
							<div style={{display: 'flex', justifyContent: 'space-between'}}>
								<Entertainer user={user}
														 setUser={setUser}/>
								<CharacterSelect selectCharacter={selectCharacter}
																 setSelectCharacter={setSelectCharacter}/>
							</div>
						</div>
						<div className="div-button-send">
							{ loading ? (
								<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
							) : (
								<button type='button'
												onClick={handlerModal}
												disabled={disabledBtn}>Save
								</button>
							)}
						</div>
					</form>
				</div>
			</div>
		</>
	);

}

export default ModalMeeting;