import React from 'react';
import {LabelTextArea, TextArea} from "../styled-create-meeting/DescriptionStyled";
import {WithesWrapper, WrapperForImgAnaRequired} from "../styled-create-meeting/CreateMeetingStyled";
import then from "../img/wishes.png";

const Wishes = ({wishes, setWishes}) => {

	return (
		<WithesWrapper>
			<WrapperForImgAnaRequired>
				<div className="div-img">
					<img src={then} alt={then}/>
				</div>
				<div className="required-div" style={{paddingTop: "5px"}}>
					<span>Wishes</span>
				</div>
			</WrapperForImgAnaRequired>
			<LabelTextArea htmlFor="descriptionTextarea">
				<TextArea id="descriptionTextarea"
									placeholder="Add wishes"
									value={wishes}
									maxLength='120'
									onChange={(el) => setWishes(el.target.value)}>
				</TextArea>
			</LabelTextArea>
		</WithesWrapper>
	);
};

export default Wishes;