import React, {useEffect, useState} from 'react';
import useInput from "../../hooks/useInput";
import SearchCollectionParent from "./search-collection/SearchCollectionParent";

import {
	SearchDiv,
	LabelSearchKid,
	LabelInputWrapper
} from '../styled-create-meeting/search-styled/SearchStyled';

function SearchKids({setKid}) {

	const {value, onChange, items} = useInput();
	const [parents, setParents] = useState([]);

	useEffect(()=>{
		if(items){
			setParents(items.filter(item => item.role === 1))
		}
	},[items])
	const onKidSelect = (kidIn) => {
		setKid(kidIn);
	}

	return(
		<div>
			<LabelInputWrapper>
				<LabelSearchKid>
					<input onChange={onChange}
								 type="text"
								 className="search-user"
								 placeholder="Select parent"
								 maxLength='16'/>
				</LabelSearchKid>
				<>
					{value.length === 0 ? (
						<></>
					) : (
						<SearchDiv>
							<SearchCollectionParent
								onPersonSelect={onKidSelect}
								items={parents}/>
						</SearchDiv>
					)}
				</>
			</LabelInputWrapper>
		</div>
	)

}

export default SearchKids;