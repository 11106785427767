import React from 'react';

import {ShowAlertContent} from './create-text-styled/CreateTextStyled';

const  CreateTextDelete = () => {
	return (
		<ShowAlertContent>
			<div>
				<h3>Meeting deleted!</h3>
			</div>
		</ShowAlertContent>
	);
}

export default CreateTextDelete;