import React from 'react';
import {useDispatch} from "react-redux";

import {
	addProps, addPropsMeetingId,
	addPropsMeetings,
	closeOpenMeetingOptions, OpenIsOpenMeetingFilter,
	openModal,
	openOpenMeetingOptions
} from "../../store/slicers/slice-modal";

import {slotIdSave} from "../../store/slicers/create-meeting-slice";


const useOpen = () => {
	const dispatch = useDispatch();

	const handleClickOpenModal = (time, slot) => {
		dispatch(addProps(time));
		dispatch(openModal());
		dispatch(closeOpenMeetingOptions());
		dispatch(slotIdSave(slot));
	};
	const handleClickOpenUpdateModal = (time, meet) => {
		dispatch(addProps(time));
		dispatch(addPropsMeetings(meet));
		dispatch(openOpenMeetingOptions());


	};
	const handleClickOpenUpdateFilter = (time, id) => {
		dispatch(addProps(time));
		dispatch(OpenIsOpenMeetingFilter());
		dispatch(addPropsMeetingId(id));
	}
	return {

		handleClickOpenModal, handleClickOpenUpdateModal, handleClickOpenUpdateFilter

	}
};

export default useOpen;