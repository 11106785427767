import styled from "styled-components";

export const ReportWrapper = styled.div`
  display: block;  
  padding-top: 15px; 
`;
export const ReportName = styled.h2`
  margin-top: 10px;  
  font-family: "IBM bold";
  color: #554D66;
`;
export const TableWrapper = styled.div`
  border-radius: 8px;
  background-color: #F8F9FA;
  padding: 40px 10px 0 0;  
`;
export const TableName = styled.h3`
  text-align: center; 
  font-family: "IBM bold";
  margin-bottom: 20px;
`;
