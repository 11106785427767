import React from 'react';
import moment from "moment";

import {ButtonWrapper, ButtonWrapperCenter} from "../../../../calendar/monitor/monitor-styled/MonitorStyled";

function MonitorSmall({setToday, toDays}) {

	const monthStartWeek = toDays.clone().startOf('month').format("MMMM");

	const prevHandler = (e) => {
		e.preventDefault();
		setToday(prev => prev.clone().subtract(1, 'month'));
	}

	const todayHandler = (e) => {
		e.preventDefault();
		setToday(moment());
	};

	const nextHandler = (e) => {
		e.preventDefault()
		setToday(prev => prev.clone().add(1, 'month'));
	}

	return (
		<div>
			<ButtonWrapper onClick={prevHandler} >&lt;</ButtonWrapper>
			<ButtonWrapperCenter onClick={todayHandler}>
				{monthStartWeek}
			</ButtonWrapperCenter>
			<ButtonWrapper onClick={nextHandler}>&gt;</ButtonWrapper>
		</div>
	);
}

export default MonitorSmall;