import {useState} from "react";
import {fetchApiGet} from "../../utils/axios";
import {ADMIN_USERS} from "../../utils/constants_urls";
import useToken from "./useToken";

export default function useInput(){
	const {token} = useToken();
	const [items, setItems] = useState([]);

	const [value, setValue] = useState('');

	const onChange =  async (el) => {

		let searchKids = el.target.value;
		setValue(searchKids);

		const url = `${ADMIN_USERS}?search=${searchKids}`
		const response = await fetchApiGet({url, token});
		setItems(response.entities)
	}

	return {

		value, onChange, items, setValue

	}
};