import {createSlice} from '@reduxjs/toolkit';
import {getProfileForUserList} from "../actions/get-profile-for-user-list-action";

const getForUserListProfile = createSlice({
	name: "get-for-user-list",
	initialState: {
		userListProfile: {},
		statusCode: null,
		status: null,
		error: null,
	},
	reducers: {

	},
	extraReducers:
		{
			//? get meeting by id
			[getProfileForUserList.pending]: (state) => {
				state.status = 'loading';
				state.error = null;
				state.statusCode = null;
			},
			[getProfileForUserList.fulfilled]: (state, action) => {
				state.status = 'resolved';
				state.entertainerProfile = action.payload.profile;
				state.error = null;
			},
			[getProfileForUserList.rejected]: (state, action) => {
				state.status = 'rejected';
				state.error = action.payload;
				state.statusCode = null;
			},
		}
})
export default getForUserListProfile.reducer;