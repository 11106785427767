import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import useTimeShow from "../../hooks/useTimeShow";
import {
	entertainerNameForClear,
	entertainerNameForSave,
	parentNameForClear,
	parentNameForSave
} from "../../../store/slicers/name-slice";
import Wishes from "../../create-meeting/wishes/Wishes";
import {ADMIN_MEETING_BY_ID, route} from "../../../utils/constants_urls";
import { fetchApiPut} from "../../../utils/axios";
import CharacterSelect from "../../create-meeting/modal-meeting/create-modal-pieces/CharacterSelect";
import useToken from "../../hooks/useToken";
import Theme from "../../create-meeting/theme/Theme";
import Entertainer from "../../create-meeting/modal-meeting/create-modal-pieces/Entertainer";
import Parent from "../../create-meeting/modal-meeting/create-modal-pieces/Parent";
import ShowModal from "../../showModal/showModal";
import {closeIsOpenMeetingFilter, closeOpenMeetingOptions} from "../../../store/slicers/slice-modal";
import {getData} from "../../../store/actions/dashboard-action";

import {
	DesWrapper,
	ModalWrapperEdit,
} from "../../update-meeting/update-meeting-styled/UpdateMeetingStyled";

import {
	Content, EditCallButtonWrapper,
	IconEntertainer
} from "../styled-meeting-options/StyledShowMeeting";

import clock from "../img/time.png";

const ContentEdit = (props) => {
	const {
		entertainerName,
		parentName,
		meetingId,
		setEditMeeting,
		setShowAlert,
		timeStampEnd,
		timeStampStart,
		wishes,
		meetingShow,
		selectCharacter,
	} = props;

	const [showChildren, setShowChildren] = useState(false);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const {selectChild} = useSelector(state => state.modal);

	const [show, setShow] = useState(false);
	const [user, setUser] = useState(entertainerName);
	const [kid, setKid] = useState(parentName);
	const [child, setChild] = useState(null);
	const [wishesEdit, setWishesEdit] = useState(wishes);
	const [selectCharacterEdit, setSelectCharacterEdit] = useState(selectCharacter);
	const [loading, setLoading] = useState(false);
	const {admin} = useSelector(state => state.admin);
	const dispatch = useDispatch();
	const {timeToShow} = useTimeShow();
	const {token} = useToken();
	const {courseItemCreateCall} = useSelector(state => state.course);
	const {lessonItem} = useSelector(state => state.course);
	const dependents = JSON.parse(localStorage.getItem(`${meetingShow.parent_id}dependents`));

	useEffect(() => {
		if(dependents.length){
			setChild(dependents);
		}
	}, []);

	useEffect(() => {
		kid && user ?
			setDisabledBtn(false) :
			setDisabledBtn(true)
	}, [kid, user]);

	const handlerModalUpdate = async () => {
		const url = route(ADMIN_MEETING_BY_ID, [':meetingId', meetingId]);
		const data = {
			"slot_id": meetingShow.slot_id,
			"parent_id": kid.id,
			"dependent_id": selectChild ? selectChild : meetingShow.dependent_id,
			"time_from":meetingShow.slot_time.start_time,
			"time_to": meetingShow.slot_time.end_time,
			"meeting_theme": wishesEdit,
			"meeting_description": wishesEdit,
			"character": selectCharacterEdit,
			"course_id": courseItemCreateCall.course_id,
			"lesson_id": lessonItem.lesson_id
		};
		const result = await fetchApiPut({url, token, data});
		if(result.meeting_id){
			dispatch(closeIsOpenMeetingFilter());
			dispatch(closeOpenMeetingOptions());
			dispatch(getData({timeStampStart, timeStampEnd, token}));
			handleClickCansel();
			setShowAlert(true);
			setLoading(false);
		} else {
			setLoading(false);
		}
	}
	const updateCall = () => {
		handlerModalUpdate();
		setLoading(true);
	}
	useEffect(() => {
		if(parentName){
			dispatch(parentNameForSave(kid));
		}
	},[parentName]);

	useEffect(() => {
		if(entertainerName){
			dispatch(entertainerNameForSave(user));
		}
	},[entertainerName]);

	const handleClickCansel = () => {
		dispatch(parentNameForClear());
		dispatch(entertainerNameForClear());
		setEditMeeting(false);
	}


	return (
		<>
			<ModalWrapperEdit>
				<ShowModal show={show} setShow={setShow} text={'The meeting didn\'t create'}/>
			</ModalWrapperEdit>

				<Content>
				<div className="modal-content-meeting">
					<div className="modal-content-meeting-div">
						<div onClick={() => setShowChildren(false)}>
									<IconEntertainer>
										<img src={clock} alt={clock}/>
										<h6 className="time">{timeToShow}</h6>
									</IconEntertainer>
							<Parent kid={kid}
											setKid={setKid}
											setShowChildren={setShowChildren}
											showChildren={showChildren}
											child={child}
											setChild={setChild}
											meetingShow={meetingShow}
							/>
							<Theme kid={kid}/>
							<DesWrapper>
								<Wishes setWishes={setWishesEdit} wishes={wishesEdit} />
							</DesWrapper>
							<div style={{display: 'flex', justifyContent: 'space-between'}}>
								<Entertainer user={user}
														 setUser={setUser}/>
								<CharacterSelect selectCharacter={selectCharacterEdit}
																 setSelectCharacter={setSelectCharacterEdit}/>
							</div>
						</div>
							{admin && admin.role === 3 &&
								<div style={{marginTop: "40px"}}>
									{loading ? (
											<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
									) : (
										<EditCallButtonWrapper className="line position">
											<button className="button cancel-edit" onClick={handleClickCansel}>Cancel</button>
											<button className="button save" onClick={updateCall} disabled={disabledBtn}>Save
											</button>
										</EditCallButtonWrapper>
									)}
								</div>

							}
					</div>
				</div>
				</Content>
			{/*</div>*/}
		</>
	);
};

export default ContentEdit;