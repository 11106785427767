import React, {useEffect, useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import DropzoneComponent from "../create-course/DropzoneComponent";
import {useDispatch} from "react-redux";
import {imageUrlSave} from "../../../../store/slicers/course-slice";
import {TOKEN} from "../../../../helpers/consts";


import {
	ButtonSave,
	DownloadFileBody,
	DownloadFileContent,
	DownloadFileP,
	DownloadFileWrapper,
	FileNameWrapper,
	PopUpHeader
} from "../StyledCourse";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";

import close from "../../../create-meeting/img/close.png";

const DownloadFile = ({openAddPhoto, addImgSrc, sours}) => {

	const [avatar, setAvatar] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [fileSize, setFileSize] = useState(null);
	const [loadingImg, setLoadingImg] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if(avatar.length && sours){
			setDisabled(false);
			setFileSize(avatar[0].size / 1024 / 1024);
		}
	}, [avatar, sours]);

	const saveAvatar = (acceptedFiles) => {
		setAvatar(acceptedFiles);
	}

	const sendFileData = async () => {
		setLoadingImg(true);
		const apiUrl = process.env.REACT_APP_API_URL;
		let myHeaders = new Headers();
		myHeaders.append("Authorization", TOKEN);

		let formData = new FormData();
		formData.append('',avatar[0], avatar[0].name);
		let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formData,
				redirect: 'follow'
			};

		const resp = await fetch(`${apiUrl}/admin/upload-image`, requestOptions)
		const body = await resp.json();
		dispatch(imageUrlSave(body[0].filename));
		openAddPhoto();
		setLoadingImg(false);

	}

	return (
		<DownloadFileWrapper>
			<div className="modal-meeting active">
				<DownloadFileBody>
					<CloseBtn onClick={openAddPhoto}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<PopUpHeader>
						<p className="h-header">Load photo</p>
						<p className="h-header-png">png, jpeg</p>
					</PopUpHeader>
					<DownloadFileContent>
						<DownloadFileP>Only .jpg or opaque .png files up to 10MB can be chosen as course images.</DownloadFileP>
						<DropzoneComponent saveAvatar={saveAvatar} avatar={avatar} sours={sours} addImgSrc={addImgSrc}/>
						<FileNameWrapper>
							{avatar.length ? (
								<>
									<p>{avatar[0].name}</p>
									<span>{fileSize && fileSize.toFixed(2)}</span>
								</>
							) : (null)}
						</FileNameWrapper>
						{ loadingImg ? (
							<ClipLoader color={"#C014E3"} loading={loadingImg} size={30}/>
						) : (
							<ButtonSave onClick={sendFileData} disabled={disabled}>Save</ButtonSave>
						)}
					</DownloadFileContent>

				</DownloadFileBody>
			</div>
		</DownloadFileWrapper>
	);
};

export default DownloadFile;