import {createSlice} from '@reduxjs/toolkit';
import {getMeetings} from "../actions/meeting-action";

const meetingSlice = createSlice({
	name: "meetings",
	initialState: {
		eventList: [],
		statusCode: null,
		status: null,
		error: null,
		updateEvent: null,
	},
	reducers: {

	},
	extraReducers: {
		//? get meetings
		[getMeetings.pending]: (state) => {
			state.status = 'loading';
			state.error = null;
			state.statusCode = null;
		},
		[getMeetings.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.eventList = action.payload.meetings;
			state.error = null;
		},
		[getMeetings.rejected]: (state, action) => {
			state.status = 'rejected';
			state.error = action.payload;
			state.statusCode = null;
		},
	}

})
export default meetingSlice.reducer;