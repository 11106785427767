import React from 'react';
import {useDispatch} from "react-redux";
import {courseItemClearCreateCall, lessonItemClear} from "../../../../store/slicers/course-slice";

import edit from '../../img/plus.png'
const ParentNameBlock = ({kid, setKid, setShowChildren, setChild, setNewParent}) => {
	const dispatch = useDispatch()
	const handleClickGoToSearch = () => {
		setKid(null);
		setShowChildren(false);
		dispatch(lessonItemClear());
		dispatch(courseItemClearCreateCall());
		setChild(null);
		setNewParent(true);
	}
	return (
		<ul onClick={handleClickGoToSearch} className="div-kid-send">
					<li>{kid.first_name} {kid.last_name},</li>
					<li>{kid.phone}</li>
					<li><img src={edit}/></li>
		</ul>
	);
}

export default ParentNameBlock;