import React from "react";
import styled from "styled-components";

export const TextArea = styled.textarea`
	display: block;
	border: 1px solid #DBE2EA;
	resize: none;
	width: 94.5%;
	font-size: 12px;
	border-radius: 6px;
	padding: 15px 0px 0px 10px;
	margin: 6px 0px 0px 30px;
	&:focus{
		outline: none;
	}
`;

export const LabelTextArea = styled.label`
	width: 100%;

`;

