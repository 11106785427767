import React, {useEffect, useState} from 'react';
import {childAge} from "../../../helpers";

import {DetailBtn, ImgHWrapper, UsersChildrenWrapperItem} from "./UserModalStyled";

import avatarImg from "./img/fox_1.png";
import childImg from "./img/Children.png";
import ageImg from "./img/Vectorage.png";
const UsersChildrenItem = ({item, handlerDetailsOpen}) => {

	const [gender, setGender] = useState('');
	const [age, setAge] = useState(null);

	useEffect(()=>{
		if (item.gender === "F"){
			setGender("Girl");
		} else if (item.gender === "M"){
			setGender("Boy");
		} else if (item.gender === "U"){
			setGender("Unknown");
		}
	},[item]);

	useEffect(()=>{
		childAge(item.dob, setAge);
	},[item]);

	return (
		<UsersChildrenWrapperItem>
			<div style={{width: "150px"}}>
				<ImgHWrapper>
					<div>
						<img style={{borderRadius: "50%"}}
								 src={avatarImg}/>
					</div>
					<span>{item.first_name}</span>
				</ImgHWrapper>
			</div>
			<div style={{width: "100px"}}>
				<ImgHWrapper>
					<div>
						<img src={childImg}/>
					</div>
					<span>{gender}</span>
				</ImgHWrapper>
			</div>
			<ImgHWrapper>
				<div>
					<img src={ageImg}/>
				</div>
				<span>{age}</span>
			</ImgHWrapper>
			<DetailBtn onClick={()=>handlerDetailsOpen(item)}>Details</DetailBtn>
		</UsersChildrenWrapperItem>
	);
};

export default UsersChildrenItem;