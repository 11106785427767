import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import Checkbox from "../../searchBar/usersCollection/checkbox/Checkbox";
import {changeState} from "../../../helpers";
import {monetizationItemEditTrue, monetizationItemSave} from "../../../store/slicers/monitization-slice";
import {ADMIN_PACKAGES_ACTIVITY_STATUS, ADMIN_PACKAGES_BY_ID, route} from "../../../utils/constants_urls";
import {fetchApiGet, fetchApiPost} from "../../../utils/axios";
import useToken from "../../hooks/useToken";

import {WrapperLi, WrapperUlBody} from "./styled-monetization/StyledMonetization";

import editImg from './img/Vector.png'


function MonetizationItem({item}) {

	const {admin} = useSelector(state => state.admin);
	const {token} = useToken();

	const [isActive, setIsActive] = useState(item.is_active);
	const [priceUah, setPriceUah] = useState('');
	const [priceUsd, setPriceUsd] = useState('');
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const goToWivePackage = () => {
		dispatch(monetizationItemSave(item));
	}
	const goToEditPackage = () => {
			dispatch(monetizationItemSave(item));
			dispatch(monetizationItemEditTrue())
		}

	const getPackage = async () => {
		const url = route(ADMIN_PACKAGES_BY_ID, [':Id', item.package_id]);
		const response = await fetchApiGet({url, token});
	}

	const statusUpdate = async () => {
		setLoading(true);
		changeState(isActive, setIsActive)
		const data = {
 		"is_active": !isActive
		};
		const url = route(ADMIN_PACKAGES_ACTIVITY_STATUS, [':Id', item.package_id]);
		const response = await fetchApiPost({url, token, data});
		getPackage(item.package_id);
		setLoading(false);
	}
	useEffect(()=>{
		setPriceUah(item.price.uah ? (item.price.uah - ((item.price.uah / 100) * item.discount).toFixed(2)).toFixed(2) : "0");
		setPriceUsd(item.price.usd ? (item.price.usd - ((item.price.usd / 100) * item.discount).toFixed(2)).toFixed(2) : "0");
	},[]);

	return (
		<WrapperLi>
			<WrapperUlBody isActive={isActive}>
				<li onClick={goToWivePackage}><Link to="/monetization-create-obg">{item.name.en}</Link></li>
				<li className="is-active">
					{admin && admin.role === 3 ? (
						<>
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (<Checkbox handleChangeState={() => statusUpdate(setIsActive, isActive, item.package_id)}
													 myState={isActive}/>)}
						</>

					) : (
						<>
							{isActive ? <p>Active</p> : <p>Not active</p>}
						</>
					)

					}

				</li>
				<li>{item.balance}</li>
				<li>{priceUah}</li>
				<li>{priceUsd}</li>
				{
					admin && admin.role === 3 &&
					<li style={{width: '50px'}}
							onClick={goToEditPackage}>
						<Link to="/monetization-create-obg">
							<img src={editImg}/>
						</Link>
					</li>
				}

			</WrapperUlBody>
		</WrapperLi>
	);
}

export default MonetizationItem;