import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {courseItemSaveCreateCall, courseProgressSave} from "../../../../store/slicers/course-slice";
import useToken from "../../../hooks/useToken";
import {ADMIN_COURSES_PROGRESS, route} from "../../../../utils/constants_urls";
import {fetchApiGet} from "../../../../utils/axios";

import {
	CourseItemImgWrapper, CourseNameLi,
	UlSearchCourseItem
} from "../../styled-create-meeting/search-styled/SearchStyled";

const SearchItemCourse = ({item, clearValue, kid}) => {

	const dispatch = useDispatch();
	const {token} = useToken();

	const {selectChild} = useSelector(state => state.modal);

	const [progress, setProgress] = useState([]);
	const [progressTo, setProgressTo] = useState([]);

	const selectCourseHandler = (item, progressTo) => {
	  dispatch(courseItemSaveCreateCall(item));
	  dispatch(courseProgressSave(progressTo));
		clearValue();
	}

	const getUserProgress = async () => {
		const url = route(ADMIN_COURSES_PROGRESS, [':userId', kid.id]);
		const response = await fetchApiGet({url, token});
		const res = response.filter(item => item.dependent_id === selectChild);
		setProgress([...res]);
	};

	useEffect(() => {

		getUserProgress();

	},[kid]);

	useEffect(() => {
		if(progress.length){
				const x = progress.filter( courseItem => courseItem.course_id === item.course_id);
				setProgressTo([...x])
		}
	},[progress,selectChild]);

	return (
		<UlSearchCourseItem onClick={()=> selectCourseHandler(item, progressTo)} className="ul-search-item">
			<li>
				<CourseItemImgWrapper>
					<img src={item.cover_image_url}/>
				</CourseItemImgWrapper>
			</li>
			<CourseNameLi>	{item.name.uk ? item.name.uk : item.name.en || item.name.ru}
				{progressTo.length && progressTo[0].finished_lessons ?
					(
						<span>
							{progressTo[0].finished_lessons.length}
							/{item.lessons.length}
						</span>
					) : (
						<span>0/{item.lessons.length}</span>
					)
				}
			</CourseNameLi>
		</UlSearchCourseItem>
	);
};

export default SearchItemCourse;