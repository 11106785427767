import React from 'react';
import ReactPlayer from 'react-player';

import {
  CloseBtnVideo,
  ModalVideo
} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";

import close from "../../create-meeting/img/close.png";

const MeetingVideo = ({hideVideo, videoUrl}) => {

    return (
        <>
            <ModalVideo>
                    <CloseBtnVideo onClick={hideVideo}>
                        <img src={close} alt={close}/>
                    </CloseBtnVideo>
                    <ReactPlayer url={videoUrl}
                                controls={true}
                                width='100%'
                                height='auto'
                    />
            </ModalVideo>

        </>
    );
};

export default MeetingVideo;