import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	editRoleItem: null,

}
const editRoleItemSlice = createSlice({
	name: "editRole",
	initialState,
	reducers: {
		editRoleItemSave(state, action){
			state.editRoleItem = action.payload;
		},
		editRoleItemClear: (state, action) =>{
			state.editRoleItem = null;
		},

	},

})

export const {editRoleItemClear, editRoleItemSave} = editRoleItemSlice.actions;

export default editRoleItemSlice.reducer;
