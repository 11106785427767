import React, {useEffect, useState} from 'react';

import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {BackButton, H3PopUp, PopUpP, PPopUpColor} from "../../monetization/styled-monetization/StyledMonetization";
import {
	InputCheckbox, LabelToCheckbox,
	ManageTranslationHeaderWrapper,
	ModalContentManageTranslation,
	SaveButtonGroup,
	WrapperButtonManageTranslation
} from "../StyledCourse";

import close from "../../../create-meeting/img/close.png";


const ManageTranslation = ({handlerShowManage, arrLang, arrLanguage, chooseLocales, showMan}) => {

	const array = [];
	arrLang.forEach(item => {
		array.push({name: item.name, key: item.key, isChecked: arrLanguage.find(elem => elem.key === item.key) !== undefined, isDefault: item.isDefault, langId: item.langId})
	})
	const [localeArr, setLocaleArr] = useState(array);
	const [disabled, setDisabled] = useState(true)
	const clearAll = () => {
		localeArr.forEach(el => {
			if (!el.isDefault){
				el.isChecked = false;
			}
		});
		const arr = [...localeArr]
		setLocaleArr(arr);

	}

	useEffect(()=> {
		localeArr.forEach(el => {
			if (el.isDefault && !el.isChecked){
				el.isChecked = true;
				const arr = [...localeArr]
				setLocaleArr(arr);
			}
		});

	},[localeArr]);

	const  onChangeInput = (key) => {
		localeArr.forEach(el => {
			if (!el.isDefault && el.key===key){
				el.isChecked = !el.isChecked;
				const arr = [...localeArr]
				setLocaleArr(arr);
			}
		});
	}


	useEffect(()=>{
		let hasCheckedLang = localeArr.filter(o => o.isChecked === true).length > 0;
		if (hasCheckedLang){
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	},[localeArr]);



	return (

		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentManageTranslation>
					{showMan &&
					<CloseBtn onClick={handlerShowManage}>
						<img src={close} alt={close}/>
					</CloseBtn>
					}
					<H3PopUp>Add or remove languages</H3PopUp>
						<ManageTranslationHeaderWrapper>
							<PPopUpColor>6 language</PPopUpColor>
							<PopUpP onClick={clearAll}>Clear all</PopUpP>
						</ManageTranslationHeaderWrapper>
					{
						localeArr.map(({name, key, isChecked}) => (
							<div key={key}>
										<LabelToCheckbox>
										<InputCheckbox type="checkbox" checked={isChecked} onChange={() => onChangeInput(key)}/>
											{name}
									</LabelToCheckbox>
							<br/>
							</div>
						))
					}
					<WrapperButtonManageTranslation>
						{showMan &&
						<BackButton onClick={handlerShowManage}>Cancel</BackButton>
						}
						<SaveButtonGroup onClick={() => chooseLocales(localeArr)} disabled={disabled} >Save</SaveButtonGroup>
					</WrapperButtonManageTranslation>
				</ModalContentManageTranslation>
			</div>
		</WrapperForModalMeeting>

	);
};

export default ManageTranslation;