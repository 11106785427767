import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {closeOpenUserModal} from "../../../store/slicers/slice-modal";
import UserModalInfoContent from "./UserModalInfoContent";
import UserModalEditContent from "./UserModalEditContent";
import ChildInfoModal from "./ChildInfoModal";
import {handleStopPropagation} from "../../../helpers";
import {
	ROLE_ADMIN,
	ROLE_ADMIN_STRING,
	ROLE_ENTERTAINER,
	ROLE_ENTERTAINER_STRING, ROLE_OBSERVER, ROLE_OBSERVER_STRING,
	ROLE_USER, ROLE_USER_STRING
} from "../../../helpers/consts";

import {CloseBtn} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {UserModalWrapper, UserWrapperContent} from "./UserModalStyled";

import close from "../../create-meeting/img/close.png";

const UserModal = ({checked}) => {

	const {editRoleItem} = useSelector(state => state.editRole);

	const dispatch = useDispatch();
	const [editUser, setEditUser] = useState(false);
	const [roleInfo, setRoleInfo] = useState('');
	const [childInfo, setChildInfo] = useState({});
	const [childrenDetails, setChildrenDetails] = useState(false);
	const handlerDetailsOpen = (item) => {
		setChildrenDetails(!childrenDetails);
		setChildInfo(item)
	}

	useEffect(()=>{
		if(editRoleItem){
			if(editRoleItem.role === ROLE_ADMIN){
				setRoleInfo(ROLE_ADMIN_STRING);
			} else if(editRoleItem.role === ROLE_ENTERTAINER){
				setRoleInfo(ROLE_ENTERTAINER_STRING);
			} else if(editRoleItem.role === ROLE_USER){
				setRoleInfo(ROLE_USER_STRING);
			} else if(editRoleItem.role === ROLE_OBSERVER){
				setRoleInfo(ROLE_OBSERVER_STRING);
			}
		}

	},[editRoleItem]);
	const handleCloseBtn = () => {
	  dispatch(closeOpenUserModal());
		setEditUser(false);
	}
	const handlerEditUserGo = () => {
		setEditUser(!editUser)
	}
	return (
		<>
			{childrenDetails && <ChildInfoModal childInfo={childInfo} handlerDetailsOpen={handlerDetailsOpen}/>}
			<UserModalWrapper onClick={()=>dispatch(closeOpenUserModal())}>
				<UserWrapperContent onClick={(e)=>handleStopPropagation(e)}>
					<CloseBtn onClick={handleCloseBtn}>
						<img src={close} alt={close}/>
					</CloseBtn>
					{editUser ? (
						<UserModalEditContent handlerEditUserGo={handlerEditUserGo}
																	roleInfo={roleInfo}
																	checked={checked}
																	handlerDetailsOpen={handlerDetailsOpen}
																	setRoleInfo={setRoleInfo}/>
					):(
						<UserModalInfoContent handlerEditUserGo={handlerEditUserGo}
																	checked={checked}
																	handlerDetailsOpen={handlerDetailsOpen}
																	roleInfo={roleInfo}/>
					)}

				</UserWrapperContent>
			</UserModalWrapper>
		</>
	);
};

export default UserModal;