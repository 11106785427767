import React from 'react';


import {CalendarHeader} from "./CalendarHeader";

import GridHeader from "./GridHeader";

function CalendarSecondHeader(props){

	const {
		today,
		timeStampStart,
		timeStampEnd,
		setToday,
		daysArray,
		nextSetDayHandler,
		prevSetDayHandler, setLoading
	} = props;



	return (
		<div>
			<CalendarHeader setToday={setToday}
											today={today}
											setLoading={setLoading}
											prevSetDayHandler={prevSetDayHandler}
											nextSetDayHandler={nextSetDayHandler}
											timeStampStart={timeStampStart}
											timeStampEnd={timeStampEnd}/>
			<GridHeader today={today} daysArray={daysArray}/>
		</div>
	);
}

export default CalendarSecondHeader;