import React from "react";
import styled from "styled-components";
export const ContentInfo = styled.div`
  background: #F8F9FA;
  border: 1px solid #C6C2CF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 17px;
  padding: 16px;
	width: 620px;
  min-height: 458px;
	position: relative;
  & .line-div-arrow{
    position: absolute;
    top: 12px;
    right: 18px;
    width: 12px;
		z-index: 200;
    & img {
      width: 100%;
    }
  }
	& .line {
    display: flex;
		align-items: center;
		position: relative;
		& .icon-kid {
      display: flex;
			gap: 10px;
      margin-right: 40px;
      margin-top: 10px;
      & img {
        width: 20px;
        height: 20px;
      }
		}
    & .icon-entertainer{
      display: flex;
      gap: 10px;
      & img {
        width: 20px;
        height: 20px;
      }
		}
		& .kid-name {
      font-weight: 600;
      font-size: 14px;
      color: #554D66;
		}
		& .kid-kid{
      position: absolute;
			top: 50px;
      font-weight: 600;
      font-size: 14px;
      color: #554D66;
		}
    & .description-content {
      width: 432px;
      min-height: 16px;
			margin-bottom: 16px;
			margin-left: 30px;
			color: #554D66;
			font-size: 12px;
    }
		& .icon-search {
      display: flex;
      gap: 10px;
      margin-top: 50px;
      & img {
        width: 20px;
        height: 20px;
      }
			& h6 {
        font-weight: 600;
        font-size: 16px;
        color: #554D66;
			}
		}& .icon-search-wishes {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      & img {
        width: 20px;
        height: 20px;
      }
			& h6 {
        font-weight: 600;
        font-size: 16px;
        color: #554D66;
			}
		}
		& .title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #554D66;
      width: calc(100% - 42px);
		
		}
		& .title p, .title span {
      font-weight: normal;
		}
		& .title p {
      margin-top: 8px;
      margin-bottom: 15px;
       font-weight: 400;
       font-size: 12px;
       line-height: 16px;
 		}
 		& .title span {
       color: #6a6379;
       font-weight: 300;
 		}
 		& input{
 		}
 	}
 	& .div-img-accounts-search-user{
 		display: flex;
 		gap: 10px;
 		& .user-name {
 			margin-top: 4px;
       font-weight: 600;
       font-size: 14px;
       color: #554D66;
 		}
 	}
 	& .position{
 		position: absolute;
 		display: flex;
     justify-content: flex-end;
 		gap: 10px;
     right: 16px;
     bottom: 16px;
 		& .cancel-edit {
 			background-color: #14A38B;
 			color: #FFFFFF;
 			width: 100px;
 		}
 		& .save {
       background-color: #1386f0;
 			color: #FFFFFF;
       width: 100px;
 		}
 		& button {
 			border: none;
       border-radius: 7px;
       justify-content: flex-end;
       height: 40px;
 		}
     & button:disabled {
       background-color: lightgray;
     }
     & .cancel {
 			color: #EB5757;
 			width: 156px;
       box-sizing: border-box;
       background-color: #F8F9FA;
       border-bottom: 2px solid #EB5757;
       border-radius: inherit;
     }
 		& .edit {
 			background-color: #1386f0;
       color: #ffffff;
       height: 40px;
       width: 208px;
       border-radius: 6px;

     }
 	}
 `;
export const Content = styled.div`
  background: #F8F9FA;
  border: 1px solid #C6C2CF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 17px;
  padding: 16px;
	width: 620px;
  min-height: 458px;
	position: relative;
 `;
export const EditCallButtonWrapper = styled.div`
 display: flex;
 align-items: center;
 //position: relative;
 position: absolute;
 justify-content: flex-end;
 gap: 10px;
 right: 16px;
 bottom: 16px;
 & .cancel-edit {
   background-color: #14A38B;
   color: #FFFFFF;
   width: 100px;
 }
 & .save {
   background-color: #1386f0;
   color: #FFFFFF;
   width: 100px;
 }
 & button {
   border: none;
   border-radius: 7px;
   justify-content: flex-end;
   height: 40px;
 }
 & button:disabled {
   background-color: lightgray;
 }
 & .cancel {
   color: #EB5757;
   width: 156px;
   box-sizing: border-box;
   background-color: #F8F9FA;
   border-bottom: 2px solid #EB5757;
   border-radius: inherit;
 }
 & .edit {
   background-color: #1386f0;
   color: #ffffff;
   height: 40px;
   width: 208px;
   border-radius: 6px;

 }
  	
`;
export const ContentHide = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px 10px;
  font-size: 14px;
	width: 540px;
	& .line {
    display: flex;
    justify-content: space-between;
		align-items: center;
		& .without-content {
      width: 175px;
		}
		& .flex-content{
			display: flex;
			gap: 10px;
      & .user-name, .kid-name {
        font-weight: bold;
        color: #554D66;
        width: 130px;
				margin-top: 7px;
      }
		}
		& img {
      width: 35px;
      height: 35px;
		}
		& .time {
      margin-top: 10px;
		}
		& .arrow {
      width: 20px;
      height: 20px;
      & img {
        width: 12px;
        height: 7px;
			}
		}
	}
`;

export const IconEntertainer = styled.div`
  display: flex;
	& img {
		display: block;
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
`;

export const DivImgAccountsSearchUserEdit = styled.div`
`;

export const GrayWrapper = styled.div`
  height: 40px;
	display: flex;
	align-items: center;
	margin-left: 20px;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  background-color: #F2F4F5;
`;

export const NameWrapperW = styled(GrayWrapper)`
	width: 275px;
	margin-left: 30px;
	margin-top: 10px;
`;
export const NameWrapperShort = styled(GrayWrapper)`
	width: 205px;
	margin-left: 0px;
	margin-top: 10px;
`;

export const FamilyWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-right: 25px;
`;

export const ChildrenWrapper = styled.div`
	margin-top: 5px;
`;

export const WishesNameWrapper = styled(GrayWrapper)`
  min-height: 40px;
	width: 100%;
	margin-left: 30px;
`;