import {createSlice} from '@reduxjs/toolkit';
import {getData} from "../actions/dashboard-action";
export const setLoading = (state, action) => {
	state.status = 'loading';
	state.error = null;
	state.statusCode = null;
}
export const setError = (state, action) => {
	state.status = 'rejected';
	state.error = action.payload;
	state.statusCode = null;
}

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: {
		dashboard: [],
		dashboardMeetings: [],
		statusCode: null,
		statusCodeLogout: null,
		status: null,
		error: null,
	},

	reducers: {

	},
	extraReducers: {

		[getData.pending]: setLoading,
		[getData.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.dashboard = action.payload; // todo CHECK IT!!!
			state.statusCode = action.payload.statusCode;

			const allMeetings = [];
			state.dashboard?.map( x => x.meetings.map( h => allMeetings.push({...h, id:x.entertainer_id})));
			state.dashboardMeetings = allMeetings;
		},
		[getData.rejected]: setError,

	}

});

export default dashboardSlice.reducer;