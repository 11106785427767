import React from 'react';
import {TimeFoSlots} from "../styled-components/StyledComponents";

function TimeSlots({timeStamp}) {
	return (
		<ul>
			{
				timeStamp.map( time => (
					<TimeFoSlots data-time="time-set" key={time.format("x")}>
						<div>{time.format('HH:mm')}</div>
					</TimeFoSlots>
				))
			}
		</ul>
	);
}

export default TimeSlots;