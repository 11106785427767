import {logger} from "./logger";

const fetchApi = async ({method = "GET", url = "", token, data}) => {
	const apiUrl = process.env.REACT_APP_API_URL;
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	if(token) headers.append("Authorization", token);

	const requestOptions = {
		method,
		headers,
		redirect: 'follow'
	};

	if(data) requestOptions.body = data;

	const fullUrl = `${apiUrl}${url}`;
	try {
		const response = await fetch(fullUrl, requestOptions);
		if(!response.ok) {
			logger({data: {title: "Fetch data error", url: fullUrl ,response},  mode: "error"});
			throw "Not ok response status))))";
		}
		const json = await response.json();
		logger({data: {title: "Fetch data", url: fullUrl ,json},  mode: "info"});
		return json;
	} catch (error) {
		logger({data: {title: "Fetch data error", url: fullUrl ,error},  mode: "error"});
		return {
			error: "Fetch error",
			text: error.toString()
		}
	}
}

export const fetchApiGet = ({url, token}) => {
	return fetchApi({method: "GET", url, token});
}

export const fetchApiPost = ({url, token, data}) => {
	const strData = JSON.stringify(data);
	return fetchApi({method: "POST", url, token, data: strData});
}

export const fetchApiPut = ({url, token, data}) => {
	const strData = JSON.stringify(data);
	return fetchApi({method: "PUT", url, token, data: strData});
}

export const fetchApiDelete = ({url, token, data}) => {
	const strData = data ? JSON.stringify(data) : null;
	return fetchApi({method: "DELETE", url, token, data: strData});
}
export const fetchApiPath = ({url, token, data}) => {
	const strData = data ? JSON.stringify(data) : null;
	return fetchApi({method: "PATCH", url, token, data: strData});
}