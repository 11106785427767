import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import useTimeShow from "../../hooks/useTimeShow";

import { ContentHide } from "../styled-meeting-options/StyledShowMeeting";

import account from "../img/icon-prev.png";
import arrow from "../img/arrow-symbol.png";

const HideContent = ({ item, handleClick, shownContent }) => {
  const { showMeetingById } = useSelector((state) => state.calls);

  const { timeToShow } = useTimeShow();

  const [entertainer, setEntertainer] = useState({});
  const [parent, setParent] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingEn, setLoadingEn] = useState(true);

  useEffect(() => {
    window.addEventListener("local-storage", () => {
      const e = JSON.parse(localStorage.getItem(`${item.id}`));
      const p = JSON.parse(localStorage.getItem(`${item.parent_id}`));

      setEntertainer(e);
      setParent(p);
    });
  }, [item]);

  useEffect(() => {
    if (parent && parent.id) {
      setLoading(false);
    }
    if (entertainer && entertainer.id) {
      setLoadingEn(false);
    }
  }, [parent, entertainer]);

  return (
    <>
      {shownContent[item.meeting_id] ? (
        <></>
      ) : (
        <ContentHide
          style={
            showMeetingById
              ? { pointerEvents: "none" }
              : { pointerEvents: "auto" }
          }
        >
          <div className="line">
            {!item.id ? (
              <div className="without-content"></div>
            ) : (
              <div className="flex-content">
                <img src={account} alt={account} />
                {loadingEn ? (
                  <ClipLoader color={"#C014E3"} loading={loadingEn} size={30} />
                ) : (
                  <div className="user-name">
                    {entertainer.first_name} {entertainer.last_name}
                  </div>
                )}
              </div>
            )}
            <div className="flex-content">
              <img src={account} alt={account} />
              {loading ? (
                <ClipLoader color={"#C014E3"} loading={loading} size={30} />
              ) : (
                <div className="kid-name">
                  {parent?.first_name} {parent?.last_name}
                </div>
              )}
            </div>
            <h6 className="time">{timeToShow}</h6>
            <div
              className="arrow"
              onClick={() => handleClick(item, item.meeting_id)}
            >
              <img src={arrow} alt={arrow} />
            </div>
          </div>
        </ContentHide>
      )}
    </>
  );
};

export default HideContent;
