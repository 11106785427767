import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import { loadingTrue} from "../../../../store/slicers/loading-slice";
import {
	ADMIN_COURSE_ID_LESSONS, ADMIN_COURSES_BY_ID,
	ADMIN_COURSES_ID_LESSONS_ID,
	route
} from "../../../../utils/constants_urls";
import {fetchApiDelete, fetchApiGet, fetchApiPath, fetchApiPost} from "../../../../utils/axios";
import useToken from "../../../hooks/useToken";
import {courseItemClear, courseItemSave} from "../../../../store/slicers/course-slice";
import {TOKEN} from "../../../../helpers/consts";
import OneLesson from "./OneLesson";
import DeleteLesson from "../pop-ups/DeleteLesson";

import {
	ContentWrapperCreateLessons,
	CreateCourseButtonWrapper,
} from "../StyledCourse";
import {
	CancelButton,
	CreateButton, DeleteButton
} from "../../monetization/styled-monetization/StyledMonetization";


const CreateLesson = () => {

	const {lessonItem} = useSelector(state => state.course);
	const {courseItem} = useSelector(state => state.course);
	const {token} = useToken();
	const dispatch = useDispatch();

	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [nameEn, setNameEn] = useState('');
	const [nameUa, setNameUa] = useState('');
	const [nameRu, setNameRu] = useState('');
	const [descriptionEn, setDescriptionEn] = useState('');
	const [descriptionUa, setDescriptionUa] = useState('');
	const [descriptionRu, setDescriptionRu] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingDel, setLoadingDel] = useState(false);
	const [file, setFile] = useState('');
	const [fileSel, setFileSel] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [order, setOrder] = useState(null);

	useEffect(()=>{
		if(courseItem){
			setOrder(courseItem.lessons.length + 1)
		}
	},[courseItem, order]);

	const dataObj = {
		"name": {

		},
		"order": order,
		"description": {

		},
		"script_url": fileSel,
		"lesson_duration": 20
	};
	const [data, setData] = useState(dataObj)

	const [course, setCourse] = useState(null);
	useEffect(()=>{
		setCourse(JSON.parse(JSON.stringify(courseItem)))
	},[courseItem]);
	const isDeleteOpen = () => {
		setIsOpenDelete(!isOpenDelete);
	};
	useEffect(() => {
		if (lessonItem.lesson_id) {
			setNameEn(lessonItem.name.en);
			setNameUa(lessonItem.name.uk);
			setNameRu(lessonItem.name.ru);
			setDescriptionEn(lessonItem.description.en);
			setDescriptionUa(lessonItem.description.uk);
			setDescriptionRu(lessonItem.description.ru);
		}
	}, []);

	useEffect(() => {
			if(!nameEn && courseItem.name.en||
				!nameUa && courseItem.name.uk||
				!nameRu && courseItem.name.ru||
				!descriptionEn && courseItem.name.en||
				!descriptionUa && courseItem.name.uk||
				!descriptionRu && courseItem.name.ru
			){
				setDisabled(true);
			} else {
				setDisabled(false)
			}
	}, [disabled,nameEn, nameUa, nameRu, descriptionEn, descriptionUa, descriptionRu]);

	useEffect(()=>{
		if (courseItem.name.uk) {
			data.name.uk = nameUa;
			data.description.uk = descriptionUa;
		}
		if (courseItem.name.en) {
			data.name.en = nameEn;
			data.description.en = descriptionEn;
		}
		if (courseItem.name.ru) {
			data.name.ru = nameRu;
			data.description.ru = descriptionRu;
		}
		data.order = order;
		data.script_url = fileSel;
		setData(data)
	},[courseItem,nameEn, nameUa, nameRu, descriptionEn, descriptionUa, descriptionRu]);


	const getCoursesById = async () => {
		const url = route(ADMIN_COURSES_BY_ID, [':courseId', courseItem.course_id]);
		const response = await fetchApiGet({url, token});
		setCourse(response);
			dispatch(courseItemSave(response));
		setLoading(false);
	}


	const updateLesson = async () => {
		setLoading(true);
		data.order = lessonItem.order;
		const url = route(ADMIN_COURSES_ID_LESSONS_ID, [':course_id', courseItem.course_id], [':lesson_id', lessonItem.lesson_id]);
		const result = await fetchApiPath({url, token, data});
		if(result.lesson_id){
			await getCoursesById();
		}
		setLoading(false);
	}

	const createLesson = async () => {
		setLoading(true);
		const url = route(ADMIN_COURSE_ID_LESSONS, [':course_id', courseItem.course_id]);
		const response = await fetchApiPost({url, token, data});
		setLoading(false);
		await getCoursesById();

	}
	const updateCourse = async () => {
		const newLessons = JSON.parse(JSON.stringify(course.lessons));
		newLessons.forEach((lesson, i) =>{
			lesson.order = 	i + 1
		});
		const newCourse = JSON.parse(JSON.stringify(course));
		newCourse.lessons = newLessons;
		const data = {...newCourse}
		const url = route(ADMIN_COURSES_BY_ID, [':courseId', course.course_id]);
		const result = await fetchApiPath({url, token, data});
		if(result.status === "ok"){
			await getCoursesById();
		}
	};
	const deleteLesson = async () => {
		setLoadingDel(true);
		dispatch(loadingTrue());
		const url = route(ADMIN_COURSES_ID_LESSONS_ID, [':course_id', courseItem.course_id], [':lesson_id', lessonItem.lesson_id]);
		const result = await fetchApiDelete({url, token, data});
		if(result.status === "ok"){
			course.lessons.forEach(function(el, i) {
				if (el.lesson_id === lessonItem.lesson_id) course.lessons.splice(i, 1);

			})
			await updateCourse();
		}

		setLoadingDel(false);
	}

	const sendFileData = async () => {

		const apiUrl = process.env.REACT_APP_API_URL;
		let myHeaders = new Headers();
		myHeaders.append("Authorization", TOKEN);

		let formData = new FormData();
		formData.append('', file, file.name);

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formData,
			redirect: 'follow'
		};

		const resp = await fetch(`${apiUrl}/admin/upload-image`, requestOptions)
		setFileSel(resp)
	}

	useEffect(() => {
		if (file) {
			sendFileData();
		}
	}, [file]);

	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Lesson Creation Page</h2>
			{isOpenDelete && <DeleteLesson isDeleteOpen={isDeleteOpen} deleteLesson={deleteLesson} loadingDel={loadingDel} />}
			<ContentWrapperCreateLessons>
				{courseItem.name?.en ? <OneLesson lang="En" setName={setNameEn} setDescription={setDescriptionEn} name={nameEn}
																				 description={descriptionEn} setFile={setFile} file={file}/> : null}
				{courseItem.name?.uk ? <OneLesson lang="Uk" setName={setNameUa} setDescription={setDescriptionUa} name={nameUa}
																				 description={descriptionUa} setFile={setFile} file={file}/> : null}
				{courseItem.name?.ru ? <OneLesson lang="Ru" setName={setNameRu} setDescription={setDescriptionRu} name={nameRu}
																				 description={descriptionRu} setFile={setFile} file={file}/> : null}
			</ContentWrapperCreateLessons>
			<CreateCourseButtonWrapper>
				{lessonItem.lesson_id && <DeleteButton onClick={isDeleteOpen}>Delete</DeleteButton>}
				<Link to={`/course-info/${courseItem.course_id}`}>
					<CancelButton onClick={() => dispatch(courseItemClear())}>Cancel</CancelButton>
				</Link>
				{lessonItem.lesson_id ? (
						<>
							{loading ? (
								<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
							) : (
								<Link to={`/course-info/${courseItem.course_id}`}>
									<CreateButton onClick={updateLesson} disabled={disabled}>Save</CreateButton>
								</Link>
							)}
						</>
				) : (
					<>
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<Link to={`/course-info/${courseItem.course_id}`}>
								<CreateButton onClick={createLesson} disabled={disabled}>Save</CreateButton>
							</Link>
						)}
					</>
				)}
			</CreateCourseButtonWrapper>
		</Row>
	);
};

export default CreateLesson;