import React from 'react';
import {Col} from "react-bootstrap";

import clear from "../pages/usersList/img/close.png";

import './usersCollection/userCollection.sass';
import './usersCollection/searchBar.sass';

const SearchBar = ({searchString, setSearchString, handleSearchName}) => {


   const handlerClear = () => {
     setSearchString('');
   };

    return(
        <>
            <Col className="col-user-header-search"  xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <label className="input-search">
                    <input type="text"
                           className="clear"
                           value={searchString}
                           onChange={handleSearchName}
                           placeholder={searchString ? searchString : "SearchUser"}/>
                    <button className="button-img-search"
                            onClick={handlerClear}>
                      <img src={clear} alt="close"/>
                    </button>
                </label>
            </Col>
        </>
    );
}
export default SearchBar;