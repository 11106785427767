import React, {useEffect, useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import {Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import {CLEAR_LINE, MIN_FINAL_PRISE_UAH, MIN_FINAL_PRISE_USD} from "../../../../helpers/consts";
import Checkbox from "../../../searchBar/usersCollection/checkbox/Checkbox";
import {changeState} from "../../../../helpers";
import {
	cancelEditingTrue,
	monetizationItemEditFalse,
	monetizationItemEditTrue,
	monetizationItemRemove, openDeleteTrue
} from "../../../../store/slicers/monitization-slice";
import NotEdit from "./NotEdit";
import CancelEditing from "../pop-ups/CancelEditing";
import ObjectDelete from "../pop-ups/ObjectDelete";
import {fetchApiPost, fetchApiPut} from "../../../../utils/axios";
import {ADMIN_PACKAGES, ADMIN_PACKAGES_BY_ID, route} from "../../../../utils/constants_urls";
import useToken from "../../../hooks/useToken";
import CancelCreate from "../pop-ups/CancelCreate";

import {
	ButtonBack, CancelButton,
	ContentWrapperMonetization, CreateButton, DeleteButton,
	DivLine,
	FormContent,
	HeaderButtonsWrapper,
	ImgSpanWrapper, IsActiveCheckbox,
	IsDisplayedWrapper,
	WrapperButtonCreate,
	WrapperUlCreate,
} from "../styled-monetization/StyledMonetization";

import btnSquare from "../img/BtnSquare.png";
import checkUser from "../img/check.png";
function ObjectOfPurchaseCreation(props) {

	const {monetizationItem} = useSelector(state => state.monetization);
	const {edit: packageEdit} = useSelector(state => state.monetization);
	const {cancelEditing} = useSelector(state => state.monetization);
	const {openDelete} = useSelector(state => state.monetization);
	const {token} = useToken();
	const [isActive, setIsActive] = useState(true);
	const [nameEn, setNameEn] = useState(CLEAR_LINE);
	const [priceUah, setPriceUah] = useState(CLEAR_LINE);
	const [priceUahFinal, setPriceUahFinal] = useState(0);
	const [priceUsd, setPriceUsd] = useState(CLEAR_LINE);
	const [priceUsdFinal, setPriceUsdFinal] = useState(0);
	const [isNewUser, setIsNewUser] = useState(false);
	const [isOldUser, setIsOldUser] = useState(false);
	const [discount, setDiscount] = useState(CLEAR_LINE);
	const [balance, setBalance] = useState(CLEAR_LINE);
	const [packageId, setPackageId] = useState(CLEAR_LINE);
	const [edit, setEdit] = useState(false);
	const [checkForm, setCheckForm] = useState(false);
	const [cancelCreate, setCancelCreate] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (monetizationItem) {
			setPackageId(monetizationItem.package_id)
		}
	}, [monetizationItem]);

	useEffect(() => {
		if (packageEdit) {
			setEdit(packageEdit)
		}
	}, [packageEdit]);

	useEffect(() => {
		if (monetizationItem) {
			setNameEn(monetizationItem.name.en);
			setPriceUah(monetizationItem.price.uah);
			setPriceUsd(monetizationItem.price.usd);
			setIsNewUser(monetizationItem.is_displayed_new_users);
			setIsOldUser(monetizationItem.is_displayed_old_users);
			if (monetizationItem.discount) {
				setDiscount(monetizationItem.discount);
			}
			setBalance(monetizationItem.balance);
			setIsActive(monetizationItem.is_active);
		}
	}, [edit]);

	const checkInput = (value) => {
		if (checkForm){
			return value.length && value.trim()!== "" ? null : "monetization-input" ;
		}
	}
	const checkInputNumber = (value, minPrise) => {
		if (checkForm && !minPrise) {
			return Number(value) > 0 ? null : "monetization-input";
		} else if(checkForm && minPrise){
			return Number(value) > minPrise? null : "monetization-input";
		}
	}

	const checkInputDiscount = () => {
		if(checkForm){
			return priceUsdFinal < MIN_FINAL_PRISE_USD || priceUahFinal < MIN_FINAL_PRISE_UAH ? "monetization-input"  : null;
		}
	}


	useEffect(() => {
		if (discount > 100) {
			setDiscount("100");
		} else if (discount < 0) {
			setDiscount("0");
		}
	}, [discount]);

	useEffect(() => {
		if (priceUah) {
			setPriceUahFinal(priceUah - ((priceUah / 100) * discount));
		}else {
			setPriceUahFinal(0)
		}
	}, [priceUah, discount]);

	useEffect(() => {
		if (priceUsd) {
			setPriceUsdFinal(priceUsd - ((priceUsd / 100) * discount));
		} else {
			setPriceUsdFinal(0);
		}
	}, [priceUsd, discount]);
	const data = {
		"name": {
			"en": nameEn,
			"uk": nameEn,
			"ru": nameEn
		},

		"price": {
			"usd": priceUsd,
			"uah": priceUah
		},
		"is_active": isActive,
		"is_displayed_new_users": isNewUser,
		"is_displayed_old_users": isOldUser,
		"discount": discount ? parseInt(discount) : 0,
		"balance": parseInt(balance)
	};

	// console.log("data", data)
	const createPackage = async () => {
		setCheckForm(true);
		if (!nameEn || !balance || priceUah < MIN_FINAL_PRISE_UAH || priceUsd < MIN_FINAL_PRISE_USD || priceUsdFinal < MIN_FINAL_PRISE_USD || priceUahFinal < MIN_FINAL_PRISE_UAH)return;

		// changeState(isActive, setIsActive)
		setLoading(true);
		const response = await fetchApiPost({url: ADMIN_PACKAGES, data, token});
		if (response.package_id) {
			navigate("/monetization");
			setLoading(false);
		}
		setLoading(false);
	}


	const updatePackage = async () => {
		setCheckForm(true);
		if (!nameEn || !balance || priceUah < MIN_FINAL_PRISE_UAH || priceUsd < MIN_FINAL_PRISE_USD || priceUsdFinal < MIN_FINAL_PRISE_USD || priceUahFinal < MIN_FINAL_PRISE_UAH)return;
		setLoading(true);
		const url = route(ADMIN_PACKAGES_BY_ID, [':packageId', monetizationItem.package_id]);
		const response = await fetchApiPut({url, data ,token});
		navigate("/monetization");
		setLoading(false);
	}


	const handlerIsEdit = () => {
		dispatch(monetizationItemEditTrue())
	}
	const handleBack = () => {
		dispatch(monetizationItemEditFalse())
		dispatch(monetizationItemRemove())
	}

	const classLiNewUser = isNewUser === false ? "img-wrapper" : "img-wrapper active-img";
	const classLiOldUser = isOldUser === false ? "img-wrapper" : "img-wrapper active-img";

	const handlerCancel = () => {
		setCancelCreate(!cancelCreate)
	}

	const handleChange = (el, setPrice) => {
		const inputValue = el.target.value;

		const re = /^[0-9]{0,3}\.?[0-9]{0,2}$/
		if (inputValue === '' || re.test(inputValue)) {
			setPrice(inputValue);
		}
	};
	const handleChangeBalance = (el,setPrice) => {
		const inputValue = el.target.value;

		const re = /^[0-9]{0,3}$/
		if (inputValue === '' || re.test(inputValue)) {
			setPrice(inputValue);
		}
	};


	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Monetization Management Section</h2>
			{cancelEditing && <CancelEditing/>}
			{openDelete && <ObjectDelete/>}
			{cancelCreate && <CancelCreate handlerCancel={handlerCancel}/>}
			<ContentWrapperMonetization>
				<HeaderButtonsWrapper>
					<Link to="/monetization">
						<ButtonBack onClick={handleBack}>
							<img src={btnSquare}/>Back
						</ButtonBack>
					</Link>
					<IsActiveCheckbox>
						{isActive ? <span>Active</span> : <span style={{color: "#A5ADBA"}}>Inactive</span>}

						{packageId ? (
							<>
								{!edit && <CancelButton onClick={handlerIsEdit}>Edit</CancelButton>}
								{edit && <Checkbox handleChangeState={() => changeState(isActive, setIsActive)}
																	 myState={isActive}/>}
							</>
						) : (
							<Checkbox handleChangeState={() => changeState(isActive, setIsActive)}
												myState={isActive}/>
						)}

					</IsActiveCheckbox>
				</HeaderButtonsWrapper>
				<FormContent>
					<WrapperUlCreate>
						<li>
							<label>Object name
								{edit && <input type="text"
																placeholder="Object name"
																className={checkInput(nameEn)}
																value={nameEn}
																onChange={(el) => setNameEn(el.target.value)} required/>}
								{!packageId && !edit && <input type="text"
																							 className={checkInput(nameEn)}
																							 placeholder="Object name"
																							 value={nameEn}
																							 onChange={(el) => setNameEn(el.target.value)} required/>}
								{packageId && !edit && <NotEdit text={nameEn}/>}
							</label>
						</li>
						<li>
							<label>Amount of calls
								{edit && <input type="text"
																className={checkInputNumber(balance)}
																placeholder="0"
																value={balance}
																onChange={(el) => handleChangeBalance(el,setBalance)} required/>}
								{!packageId && !edit && <input type="text"
																							 className={checkInputNumber(balance)}
																							 placeholder="0"
																							 value={balance}
																							 onChange={(el) => handleChangeBalance(el,setBalance)} required/>}
								{packageId && !edit && <NotEdit text={balance}/>}
							</label>
						</li>
						<li style={{display: "flex", paddingTop: "10px", alignItems: "center"}}>Is displayed to
							<IsDisplayedWrapper>
								<ImgSpanWrapper>
									{edit &&
										<div className={classLiNewUser}
												 onClick={() => changeState(isNewUser, setIsNewUser)}>
											<img src={checkUser}/>
										</div>}
									{!packageId && !edit &&
										<div className={classLiNewUser}
												 onClick={() => changeState(isNewUser, setIsNewUser)}>
											<img src={checkUser}/>
										</div>}
									{packageId && !edit && <div className={classLiNewUser}>
										<img src={checkUser}/>
									</div>}
									<span>New users</span>
								</ImgSpanWrapper>
								<ImgSpanWrapper>
									{edit &&
										<div className={classLiOldUser}
												 onClick={() => changeState(isOldUser, setIsOldUser)}>
											<img src={checkUser}/>
										</div>}
									{!packageId && !edit &&
										<div className={classLiOldUser}
												 onClick={() => changeState(isOldUser, setIsOldUser)}>
											<img src={checkUser}/>
										</div>}
									{packageId && !edit &&
										<div className={classLiOldUser}
												 onClick={() => changeState(isOldUser, setIsOldUser)}>
											<img src={checkUser}/>
										</div>}
									<span>Old users</span>
								</ImgSpanWrapper>
							</IsDisplayedWrapper>
						</li>
						<DivLine/>
						<li style={{marginBottom: "10px"}}>Prices</li>
						<li>
							<label>Price (UAH)
								{edit && <input type="text"
																className={`remove-arr ${checkInputNumber(priceUah, 25)}`}
																placeholder="00,00"
																value={priceUah}
																onChange={(el) => handleChange(el,setPriceUah)} required/>}
								{!packageId && !edit && <input type="text"
																							 className={`remove-arr ${checkInputNumber(priceUah,25)}`}
																							 placeholder="00,00"
																							 value={priceUah}
																							 onChange={(el) => handleChange(el,setPriceUah)} required/>}
								{packageId && !edit && <NotEdit text={priceUah}/>}
							</label>
						</li>
						<li>
							<label>Price (USD)
								{edit && <input type="text"
																className={`remove-arr ${checkInputNumber(priceUsd, 0.5)}`}
																placeholder="00,00"
																value={priceUsd}
																onChange={(el) => handleChange(el,setPriceUsd)} required/>}
								{!packageId && !edit && <input type="text"
																							 className={`remove-arr ${checkInputNumber(priceUsd, 0.5)}`}
																							 placeholder="00,00"
																							 value={priceUsd}
																							 onChange={(el) => handleChange(el,setPriceUsd)} required/>}
								{packageId && !edit && <NotEdit text={priceUsd}/>}
							</label>
						</li>
						<li>
							<label>Discount (%)
								{edit && <input type="number"
																className={`remove-arr ${checkInputDiscount()}`}
																placeholder="00,00"
																value={discount}
																onChange={(el) => setDiscount(el.target.value)} required/>}
								{!packageId && !edit && <input type="number"
																							 className={`remove-arr ${checkInputDiscount()}`}
																							 placeholder="00,00"
																							 value={discount}
																							 onChange={(el) => setDiscount(el.target.value)} required/>}
								{packageId && !edit && <NotEdit text={discount}/>}
							</label>
						</li>
						<DivLine/>
						<li className="price-li">Final price (UAH)
							<span className="span-price" >{priceUahFinal.toFixed(2)} UAH</span>
						</li>
						<li>Final price (USD)
							<span className="span-price">{priceUsdFinal.toFixed(2)} USD</span>
						</li>
					</WrapperUlCreate>
					{edit && packageId &&
						<WrapperButtonCreate>
							{loading ? (
								<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
							) : (
								<>
									<DeleteButton onClick={() => dispatch(openDeleteTrue())}>Delete</DeleteButton>
									<CancelButton onClick={() => dispatch(cancelEditingTrue())}>Cancel</CancelButton>
									<CreateButton onClick={updatePackage}>Save</CreateButton>
								</>
							)}
						</WrapperButtonCreate>
					}
					{!packageId && !edit &&
						<WrapperButtonCreate>
							{loading ? (
								<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
							) : (
								<>
									<CancelButton onClick={handlerCancel}>Cancel</CancelButton>
									<CreateButton onClick={createPackage}>Save</CreateButton>
								</>
							)}
						</WrapperButtonCreate>
					}
				</FormContent>
			</ContentWrapperMonetization>
		</Row>
	);
}

export default ObjectOfPurchaseCreation;