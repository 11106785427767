import React from 'react';
import {Row} from "react-bootstrap";

import Calendar from "../../calendar/Calendar";

import './dashboards.sass';

function Dashboards() {

	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Dashboards</h2>
			<Calendar/>
		</Row>
	);
}

export default Dashboards;