import React from 'react';
import {useSelector} from "react-redux";
import moment from "moment";

import useOpen from "../../../../hooks/useOpen";
import {entertainerSlots} from "../../../../../helpers";

import {
	EventBox,
	EventBoxKid,
	FreeSlotBox
} from "../../styled-components/CalendarColumnStyled";

const SlotDayFilter = ({stamp, allMeetingsEntertainer, eventsNew, handleAddMeeting}) => {

	const {eventSearchListKid} = useSelector(state => state.eventSearchListKid);
	const {parentName} = useSelector(state => state.name);
	const {entertainerName} = useSelector(state => state.name);
	const {handleClickOpenUpdateFilter} = useOpen();


	const bunchEntertainerFilter = eventsNew.filter(event => {
		const result = +moment(event.slot_time.start_time).format('x')<= stamp.format('x') &&
			stamp.format('x') < +moment(event.slot_time.end_time).format('x') && event.id === entertainerName?.id;

		return result;
	});


	return (
		<>
			{allMeetingsEntertainer &&
				allMeetingsEntertainer
					.filter(  event => +moment(event.slot_time.start_time).format('x') === +stamp.format('x') && event.meeting_id )
					.map( event => (
						<EventBox key={event.meeting_id} id="EventBoxSearchListEntertainer"
											onClick={() => handleClickOpenUpdateFilter(stamp.format('x'), event)}>
							+
						</EventBox>
					))
			}
			{eventSearchListKid && parentName &&
				eventSearchListKid
					.filter( event => +moment(event.slot_time.start_time).format('x') === +stamp.format('x') && event.meeting_id)
					.map( event => (
						<EventBoxKid key={event.meeting_id} id="EventBoxSearchListKid"
												 onClick={() => handleClickOpenUpdateFilter(stamp.format('x'), event)}>
							+
						</EventBoxKid>
					))
			}

			{bunchEntertainerFilter.length && entertainerName ?
				(<FreeSlotBox onClick={() => handleAddMeeting(stamp.format('x'))}>+</FreeSlotBox>)
				: null
			}
			{entertainerSlots(eventsNew,stamp).length && !entertainerName && parentName
				? (
					<FreeSlotBox onClick={() => handleAddMeeting(stamp.format('x'))}>+</FreeSlotBox>
				)
				: null
			}
		</>
	);
}

export default SlotDayFilter;
