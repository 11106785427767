import React from 'react';
import moment from "moment";
import useOpen from "../../../../hooks/useOpen";

import {TIME_ZONE} from "../../../../../helpers/consts";

import {EventBoxTogether} from "../../styled-components/CalendarColumnStyled";

const SlotDayTogether = ({togetherMeeting, stamp}) => {

	const {handleClickOpenUpdateFilter} = useOpen();

	const x = togetherMeeting
		.filter( event => +moment(event.slot_time.start_time).format('x') +TIME_ZONE === +stamp.format('x') && event.meeting_id);

	return (

			<>
				{
						x.map( event => (
							<EventBoxTogether key={event.meeting_id}
																onClick={() => handleClickOpenUpdateFilter(stamp.format('x'), event)}>+</EventBoxTogether>
						))
				}
			</>
	);
}

export default SlotDayTogether;