import React from 'react';
import {FieldContainer, Label} from "../StyledConfig";

const WayForPayDomain = ({wayForPatDomain}) => {
	return (
		<FieldContainer>
			<Label>Wayforpay domain:</Label>
			<div style={{width: "200px"}}>
				{wayForPatDomain}
			</div>
			<div></div>
		</FieldContainer>
	);
};

export default WayForPayDomain;