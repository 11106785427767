import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	allCourses: [],
	groupItem: {},
	courseItem: {},
	courseItemCreateCall: {},
	groupsArr: [],
	lessonItem: {},
	imageUrl: "",
	courseProgress: [],
}
const courseSlice = createSlice({
	name: "course",
	initialState,
	reducers: {
		coursesSave(state, action) {
			state.allCourses = action.payload;
		},
		groupItemSave(state, action) {
			state.groupItem = action.payload;
		},
		courseItemSave(state, action) {
			state.courseItem = action.payload;
		},
		courseItemClear:(state, action)=> {
			state.courseItem = {};
		},
		courseItemSaveCreateCall(state, action) {
			state.courseItemCreateCall = action.payload;
		},
		courseItemClearCreateCall:(state, action)=> {
			state.courseItemCreateCall = {};
		},
		groupsArrSave(state, action) {
			state.groupsArr = action.payload;
		},
		lessonItemSave(state, action) {
			state.lessonItem = action.payload;
		},
		lessonItemClear:(state, action)=> {
			state.lessonItem = {};
		},
		imageUrlSave(state, action) {
			state.imageUrl = action.payload;
		},
		courseProgressSave(state, action) {
			state.courseProgress = action.payload;
		},
	},

})

export const {
	coursesSave,
	groupItemSave,
	courseItemSave,
	groupsArrSave,
	courseItemClear,
	lessonItemSave,
	imageUrlSave,
	courseProgressSave,
	lessonItemClear,
	courseItemClearCreateCall,
	courseItemSaveCreateCall
} = courseSlice.actions;

export default courseSlice.reducer;
