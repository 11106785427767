import React from 'react';
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

import {
	ModalContentCancelEditing,
} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {
	BackButton,
	DiscardButton, H3PopUp, PPopUp,
	WrapperButtonPopUp
} from "../styled-monetization/StyledMonetization";

import close from "../../../create-meeting/img/close.png";

import {cancelEditingFalse, monetizationItemEditFalse} from "../../../../store/slicers/monitization-slice";

function CancelEditing() {

	const dispatch = useDispatch();
	const handlerCancel = () => {
		dispatch(monetizationItemEditFalse());
		dispatch(cancelEditingFalse());
	}
	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn  onClick={() => dispatch(cancelEditingFalse())}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Cancel editing</H3PopUp>
					<PPopUp>Do you want to discard all changes?</PPopUp>
					<WrapperButtonPopUp>
						<BackButton onClick={() => dispatch(cancelEditingFalse())}>Back</BackButton>
						<Link to="/monetization"><DiscardButton onClick={handlerCancel}>Discard changes</DiscardButton></Link>
					</WrapperButtonPopUp>
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>
	);
}

export default CancelEditing;