import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import NavPanel from "../nav-panel/navBar/NavPanel";
import Container from "react-bootstrap/Container";
import useToken from "../hooks/useToken";
import styled from "styled-components";


const ErrorNotification = styled('div')`
  position: absolute;
  height: 100px;
  width: 300px;
  background-color: #f00;
  color: #fff;
  right: 10px;
  bottom: 20px;
  padding: 8px;
  border-radius: 4px;
`;

const Layout = () => {

  const {isValidToken, token} = useToken();
  const errorStatus = false

  useEffect(() => {
    isValidToken();
  },[]);

    return(
        <>
            <Container fluid>
              <NavPanel/>
              <main>
                  <Outlet/>
              </main>
              {
                errorStatus ? (
                  <ErrorNotification>
                    <div><b>Error notification</b></div>
                    <p>Text of error notification</p>
                  </ErrorNotification>
                ) : null
              }
            </Container>
        </>
    )
}
export default Layout;