import React, {useState, useEffect, useRef} from 'react';
import {Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

import {
	dayArray,
	isCurrentDay,
	isSelectedMonth,
	startOfTime,
	timeStampListCount
} from "../../../helpers";
import useMoment from "../../hooks/useMoment";


import {Monitor} from "../../calendar/monitor/Monitor";
import GridHeader from "../../calendar/header/GridHeader";
import TimeSlots from "../../calendar/calendar-grid/calendar-column/TimeSlots";
import RedTimeLine from "../../calendar/calendar-grid/calendar-column/RedTimeLine";
import SearchEntertainerSlots from "./SearchEntertainerSlots";
import SelectedSlots from "./SelectedSlots";
import CreateSlotsModal from "./create-modal/CreateSlotsModal";
import {openCreateSlots, openCreateTimeOffSlot} from "../../../store/slicers/create-meeting-slice";
import StartWindow from "./StartFile";
import DeleteSlotModal from "./delete-modal/DeleteSlotModal";
import ViewingSlotInfo from "./ViewingSlotInfo";
import useToken from "../../hooks/useToken";
import AllNotificationsSchedule from "./AllNotificationsSchedule";
import {getDataSchedule} from "../../../store/actions/schedule-action";
import SlotEditingConfirmation from "./create-modal/SlotEditingConfirmation";

import {ShadowWrapper} from "../../calendar/styled-calendar/CalendarTStyled";
import {ButtonPlanSlots, ButtonPlanTimeOff} from "./create-modal/StyledcreateSlotsModal";
import {
	GridWrappers,
	MainWrapperGrid,
} from "../../calendar/calendar-grid/styled-components/StyledComponents";
import {
	CellWrapper,
	HoursMinutes,
	RowInCell,
} from "../../calendar/calendar-grid/styled-components/CalendarColumnStyled";
import {
	CalendarHeaderStyle,
	WrapperForButtons,
	WrapperForSearches
} from "../../calendar/header/header-styled/HeaderStyled";

function Schedule(props) {

	const {isDeleteRepeatable} = useSelector(state => state.modal);
	const {isOpenCreateSlots} = useSelector(state => state.create);
	const {isOpenDeleteSlots} = useSelector(state => state.create);
	const {isOpenInfoSlot} = useSelector(state => state.create);
	const {entertainerNameSlotsCreate} = useSelector(state => state.name);
	const {setToday, today, startDay, timeStampEnd, timeStampStart, prevSetDayHandler, nextSetDayHandler} = useMoment();
	const {token} = useToken();
	const daysArray = dayArray(startDay);
	const [timeStampList, setTimeStampList] = useState([]);
	const [timeStamp, setTimeStamp] = useState([]);
	const startTime =  startOfTime(today);
	const hour = startTime.clone();
	const dispatch = useDispatch();

	const [showOk, setShowOk] = useState(false);
	const [showErr, setShowErr] = useState(false);
	const [showOkCreate, setShowOkCreate] = useState(false);
	const [showErrCreate, setShowErrCreate] = useState(false);
	const [showOkUpdate, setShowOkUpdate] = useState(false);
	const [showErrUpdate, setShowErrUpdate] = useState(false);

	const ref = useRef(null);



	let timerId= undefined;
	useEffect(()=>{
		dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
	},[today]);

	useEffect(() => {
		if(timerId !== undefined){
			clearInterval(timerId);
		}
		timerId = setInterval(async () => {
			dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
		}, 30000);
		return()=>{
			if(timerId !== undefined){
				clearInterval(timerId);
				timerId = undefined
			}
		}
	},[today]);


	useEffect(() => {
		timeStampListCount(hour, setTimeStamp);
	}, []);

	useEffect(() => {
		timeStampListCount(hour, setTimeStampList);
	}, []);

	useEffect(()=>{
		dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
	},[today]);


	const inputEl = useRef(null);
	const onButtonClick = () => {
		inputEl.current.focus();
	};
	const isOpenOk = () => {
		setShowOk(!showOk);
	}
	const isOpenErr = () => {
		setShowErr(!showErr);
	}
	const isOpenOkCreate = () => {
		setShowOkCreate(!showOkCreate);
	}
	const isOpenErrCreate = () => {
		setShowErrCreate(!showErrCreate);
	}
	const isOpenOkUpdate = () => {
		setShowOkUpdate(!showOkUpdate);
	}
	const isOpenErrUpdate = () => {
		setShowErrUpdate(!showErrUpdate);
	}

	const openTimeOff = () => {
		dispatch(openCreateSlots());
		dispatch(openCreateTimeOffSlot());
	}


	console.log("isDeleteRepeatable",isDeleteRepeatable);
	console.log("isOpenCreateSlots",isOpenCreateSlots);
	console.log("isOpenDeleteSlots",isOpenDeleteSlots);
	console.log("isOpenInfoSlot",isOpenInfoSlot);

	return(
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Schedule</h2>
		<ShadowWrapper>
			<MainWrapperGrid>
				<div/>
				<div>
					<CalendarHeaderStyle>
						<WrapperForSearches>
							<SearchEntertainerSlots today={today} inputEl={inputEl}/>
						</WrapperForSearches>
						<AllNotificationsSchedule showErr={showErr}
																			isOpenErr={isOpenErr}
																			showOk={showOk}
																			isOpenOk={isOpenOk}
																			showErrCreate={showErrCreate}
																			isOpenErrCreate={isOpenErrCreate}
																			showOkCreate={showOkCreate}
																			isOpenOkCreate={isOpenOkCreate}
																			isOpenErrUpdate={isOpenErrUpdate}
																			showOkUpdate={showOkUpdate}
																			isOpenOkUpdate={isOpenOkUpdate}
																			showErrUpdate={showErrUpdate} />
						<WrapperForButtons>
							<ButtonPlanSlots onClick={()=> dispatch(openCreateSlots())}>Plan Slots</ButtonPlanSlots>
							<ButtonPlanTimeOff onClick={openTimeOff}>Plan TimeOff</ButtonPlanTimeOff>
							{entertainerNameSlotsCreate && 	<Monitor today={today}
																											 setToday={setToday}
																											 nextSetDayHandler={nextSetDayHandler}
																											 prevSetDayHandler={prevSetDayHandler}/>}
							{isOpenCreateSlots && <CreateSlotsModal timeStampStart={timeStampStart}
																											isOpenOkCreate={isOpenOkCreate}
																											isOpenErrCreate={isOpenErrCreate}
																											isOpenOkUpdate={isOpenOkUpdate}
																											isOpenErrUpdate={isOpenErrUpdate}
																											timeStampEnd={timeStampEnd}/>}
							{isOpenDeleteSlots && <DeleteSlotModal timeStampStart={timeStampStart}
																										 isOpenOk={isOpenOk}
																										 isOpenErr={isOpenErr}
																										 timeStampEnd={timeStampEnd}/>}
							{isDeleteRepeatable && <SlotEditingConfirmation timeStampStart={timeStampStart}
																															isOpenOk={isOpenOk}
																															isOpenErr={isOpenErr}
																															timeStampEnd={timeStampEnd}/>}
							{isOpenInfoSlot && <ViewingSlotInfo/>}
						</WrapperForButtons>
					</CalendarHeaderStyle>
					{!entertainerNameSlotsCreate && <StartWindow onButtonClick={onButtonClick}/>}

					<GridHeader today={today} daysArray={daysArray}/>

				</div>
				<TimeSlots timeStamp={timeStamp}/>
				<GridWrappers className="calendar-grid">
					{
						daysArray.map((dayItem, indexDay) => (
							<div key={indexDay}>
								<CellWrapper key={dayItem.unix()}
														 isSelectedMonth={isSelectedMonth(dayItem, today)}
														 isWeekend={dayItem.day() === 6 || dayItem.day() === 0}>
									<RowInCell justifyContent={'flex-end'}
														 isSelectedMonth={isSelectedMonth(dayItem, today)}>
										{isCurrentDay(dayItem) && <RedTimeLine today={dayItem}/>}
										<SelectedSlots dayItem={dayItem}/>
											<ul ref={ref}>
												{
													timeStampList.map((stamp, index) => (
														<HoursMinutes data-time="time-set"
																					key={+stamp.format('x')}
																					isDayWeekend={indexDay === 6 || indexDay === 5}>
															<div></div>
														</HoursMinutes>

													))
												}
											</ul>
									</RowInCell>
								</CellWrapper>
							</div>
						))
					}
				</GridWrappers>
			</MainWrapperGrid>
		</ShadowWrapper>
		</Row>
	);
}

export default Schedule;