import React from 'react';
import {Button, FieldContainer, Label, Select} from "../StyledConfig";

const DefaultLanguage = ({isEditing, defaultLanguage, setDefaultLanguage, handlePublish, handleDiscard, setIsEditing}) => {


	const editHandler = () => {
		handleDiscard('defaultLanguage')
		setIsEditing((prevState) => ({...prevState, defaultLanguage: true}))
	}

	return (
		<FieldContainer>
			<Label>Default Language:</Label>
			{isEditing.defaultLanguage ? (
				<Select
					value={defaultLanguage}
					onChange={(e) => setDefaultLanguage(e.target.value)}>
					<option value="no">no (default)</option>
					<option value="uk">uk</option>
					<option value="en">en</option>
				</Select>
			) : (
				<div style={{width: "150px"}}>
					<span>{defaultLanguage}</span>
				</div>

			)}
			{isEditing.defaultLanguage ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('defaultLanguage')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('defaultLanguage')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>
	);
};

export default DefaultLanguage;