import {createSlice} from '@reduxjs/toolkit';
import moment from "moment";

const initialState = {
	timeStampStart: null,
	timeStampEnd: null,
	today: moment().format("x"),

}
const timeSlice = createSlice({
	name: "time",
	initialState,
	reducers: {
		setTodaySave(state, action){
			state.today = action.payload;
		},

	},

})

export const {
	setTodaySave
} = timeSlice.actions;

export default timeSlice.reducer;
