import React from 'react';
import CourseCollection from "./CourseCollection";
import {useDispatch} from "react-redux";
import {groupItemSave} from "../../../store/slicers/course-slice";

import {CourseHeader} from "./StyledCourse";

import editImg from "./img/edit.png";

function GroupItem({group, courses, groupEditIsOpen}) {
	const dispatch = useDispatch();

	const saveGroup = () => {
	  dispatch(groupItemSave(group));
		groupEditIsOpen();
	}
	return (
		<>
			<CourseHeader>
				<li>{group.group_name}</li>
				<li>Age</li>
				<li>Language</li>
				<li style={{
					position: "absolute",
					right: "10px",
					display: "flex",
					justifyContent: "end"}}
					onClick={saveGroup}><img src={editImg}/></li>
			</CourseHeader>
			<CourseCollection courses={courses} group={group}/>
		</>
	);
}

export default GroupItem;