import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import {getDataSchedule} from "../../../../store/actions/schedule-action";
import {
	closeCreateTimeOffSlot,
	closeDeleteSlots,
	closeInfoSlots,
	slotInfoClear
} from "../../../../store/slicers/create-meeting-slice";
import {closeIsDeleteRepeatable} from "../../../../store/slicers/slice-modal";
import useToken from "../../../hooks/useToken";
import {
	ADMIN_ENTERTAINERS_SLOTS_DELETE,
	ADMIN_USER_BY_ID,
	route
} from "../../../../utils/constants_urls";
import {fetchApiDelete, fetchApiGet} from "../../../../utils/axios";

import {
	LabelForRadio,
	ModalContentCancelEditing,
	WrapperForSlotEditingConfirmation
} from "./StyledcreateSlotsModal";
import {
	CloseBtn,
	CloseBtnSlotEditionConf
} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {
	BackButton, DeleteButtonPop,
	H3PopUp, SaveButton,
	WrapperButtonPopUp
} from "../../monetization/styled-monetization/StyledMonetization";

import close from "../../../create-meeting/img/close.png";

const SlotEditingConfirmation = (props) => {

	const {
		updateSlots,
		handlerConfirmClose,
		isOpenErr,
		isOpenOk,
		timeStampEnd,
		timeStampStart
	} = props;

	const {slotInfo} = useSelector(state => state.create);
	const {isDeleteRepeatable} = useSelector(state => state.modal);
	const {token} = useToken();
	const [loading, setLoading] = useState(true);
	const [loadingDel, setLoadingDel] = useState(false);
	const [checked, setChecked] = useState(true);
	const [checkedAll, setCheckedAll] = useState(false);
	const [user, setUser] = useState({});

	const dispatch = useDispatch();


	useEffect(() => {
		const getProfileByUserId = async () => {
			const url =  route(ADMIN_USER_BY_ID, [':userId', slotInfo.id]);
			const result = await fetchApiGet({url, token});
			setUser(result);
			setLoading(false);
		}
		getProfileByUserId();
	},[]);

	const checkedHandler = () => {
		setChecked(true);
		setCheckedAll(false);
	}
	const checkedHandlerAll = () => {
		setChecked(false);
		setCheckedAll(true);
	}
	const handleCancel = () => {
		if(slotInfo.is_repeatable && isDeleteRepeatable){
			dispatch(closeIsDeleteRepeatable());
		} else {
			handlerConfirmClose();
		}

	}

	const deleteSlot = async () => {
		setLoadingDel(true);
		const url = route(ADMIN_ENTERTAINERS_SLOTS_DELETE, [':entertainer_id', slotInfo.id], [':slot_id', slotInfo.slot_id]);
		const data = {
			"remove_all": checkedAll
		}
		const response =  await fetchApiDelete({url, token, data});
		if(response.status === 'ok'){
			dispatch(closeDeleteSlots());
			dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
			dispatch(closeInfoSlots());
			dispatch(slotInfoClear());
			dispatch(closeInfoSlots());
			dispatch(closeCreateTimeOffSlot());
			isOpenOk();
			setLoadingDel(false);
			dispatch(closeIsDeleteRepeatable());
		} else {
			isOpenErr();
			setLoadingDel(false);
		}

	}

	console.log("checkedAll",checkedAll)
	return (
		<WrapperForSlotEditingConfirmation>
					<ModalContentCancelEditing>
						<CloseBtnSlotEditionConf onClick={handleCancel}>
							<img src={close} alt={close}/>
						</CloseBtnSlotEditionConf>
						<H3PopUp>{isDeleteRepeatable ? "Delete Reservation" : "Save changes for reservation"}</H3PopUp>
						{isDeleteRepeatable ? (
							<>
								{ loading ? <ClipLoader color={"#C014E3"} loading={loading} size={30}/> :
									<p style={{fontSize:"14px"}}>Delete reservation for {user.first_name} {user.last_name}?</p>
								}
							</>
						) : null}
						<div style={{marginTop: "30px"}}>
							<LabelForRadio>
								<input type="radio" checked={checked} onChange={checkedHandler}/>
								<span>This slot</span>
							</LabelForRadio>
						</div>
						<div>
							<LabelForRadio>
								<input type="radio" checked={checkedAll} onChange={checkedHandlerAll}/>
								<span>This and following slots</span>
							</LabelForRadio>
						</div>
						{loadingDel ? (
							<ClipLoader color={"#C014E3"} loading={loadingDel} size={30}/>
						) : (
							<WrapperButtonPopUp>
								<BackButton onClick={handleCancel}>Cancel</BackButton>
								{isDeleteRepeatable ? (
									<DeleteButtonPop onClick={deleteSlot}>Delete</DeleteButtonPop>

								):(
									<SaveButton onClick={()=>updateSlots(checkedAll)}>Save changes</SaveButton>

								)}
							</WrapperButtonPopUp>
						)}

					</ModalContentCancelEditing>
		</WrapperForSlotEditingConfirmation>
	);
};

export default SlotEditingConfirmation;