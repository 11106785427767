import {createAsyncThunk} from '@reduxjs/toolkit';

export const searchKidMeetings = createAsyncThunk(
	"slotsKid/searchKidMeetings",
	async function ({searchPhone, timeStampStart, timeStampEnd}, {rejectWithValue}) {
		try {
			const res = await fetch(`/api/admin/get-slots?phone=${searchPhone}&start=${timeStampStart}&end=${timeStampEnd}`)
			const data = await res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}

	}
);

