import React from 'react';
import {Button, FieldContainer, Input, Label} from "../StyledConfig";

const StartUserBalance = ({isEditing,startUserBalance, setStartUserBalance,handlePublish, handleDiscard, setIsEditing}) => {

	const editHandler = () => {
		handleDiscard('startUserBalance')
		setIsEditing((prevState) => ({...prevState, startUserBalance: true}))
	}


	return (
		<FieldContainer>
			<Label>Start user balance (calls):</Label>
			{isEditing.startUserBalance ? (
				<Input
					type="number"
					value={startUserBalance}
					onChange={(e) => setStartUserBalance(e.target.value)}
				/>
			) : (
				<div style={{width: "150px"}}>
					<span>{startUserBalance} {+startUserBalance > 0 ? null: "(default)" }</span>
				</div>
			)}
			{isEditing.startUserBalance ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('startUserBalance')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('startUserBalance')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>

	);
};

export default StartUserBalance;