import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

import Checkbox from "../usersCollection/checkbox/Checkbox";
import LiRole from "./LiRole";
import {getPadTime} from "../../../helpers/getPadTime";

import {openOpenUserModal} from "../../../store/slicers/slice-modal";
import {editRoleItemSave} from "../../../store/slicers/edit-user-slice";

import {
	CLEAR_LINE, ONE,
	ROLE_ADMIN, ROLE_ADMIN_STRING,
	ROLE_ENTERTAINER, ROLE_ENTERTAINER_STRING, ROLE_OBSERVER, ROLE_OBSERVER_STRING,
	ROLE_USER,
	ROLE_USER_STRING,
} from "../../../helpers/consts";

import './userItem.sass';
import '../usersCollection/userCollection.sass';

import {
	ADMIN_USER_BY_ID,
	ADMIN_USERS_ACTIVITY_STATUS, ADMIN_USERS_BALANCE,
	ADMIN_USERS_DEPENDENTS,
	route
} from "../../../utils/constants_urls";
import {fetchApiGet, fetchApiPost} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import editUser from "../../pages/usersList/img/web icon.png";
import {handleStopPropagation} from "../../../helpers";

const UserItem = ({itemUser, setShowModal, checked, setUser}) => {
	const {admin} = useSelector(state => state.admin);
	const {token} = useToken();
	const [item, setItem] = useState(itemUser);
	const [addState, setAddState] = useState(CLEAR_LINE);
	const [loading, setLoading] = useState(false);
	const [loadingBalance, setLoadingBalance] = useState(false);
	const [loadingChildren, setLoadingChildren] = useState(false);
	const [role, setRole] = useState(CLEAR_LINE);
	const [children, setChildren] = useState([]);
	const [userBalance, setUserBalance] = useState('');

	const date = new Date(item.created_at);
	const dd = getPadTime(date.getDate());
	const mm = getPadTime(date.getMonth() + 1); //January is 0!
	const yyyy = date.getFullYear();
	const final = `${dd}.${mm}.${yyyy}`

	const dispatch = useDispatch();

	useEffect(() => {
		setAddState(item.is_active)
	}, [item.is_active])

		const getBalance = async () => {
			setLoadingBalance(true)
			const url = route(ADMIN_USERS_BALANCE, [':userId', item.id])

			const result =  await fetchApiGet({url, token})
			setUserBalance(result.balance);
			setLoadingBalance(false);

		}
	const getUsersChildren = async () =>{
		setLoadingChildren(true)
		const url = route(ADMIN_USERS_DEPENDENTS, [':kidId', item.id]);
		const response = await fetchApiGet({url, token});
		setChildren(response.entities)
		setLoadingChildren(false)
	}

	useEffect(()=>{
		if (checked === ONE){
			getUsersChildren();
			getBalance();
		}
	},[item,checked]);

	useEffect(() => {
		if(item?.role === ROLE_USER){
			setRole(ROLE_USER_STRING);
		} else if(item?.role === ROLE_ENTERTAINER){
			setRole(ROLE_ENTERTAINER_STRING);
		} else if (item?.role === ROLE_ADMIN){
			setRole(ROLE_ADMIN_STRING);
		} else if(item?.role === ROLE_OBSERVER){
			setRole(ROLE_OBSERVER_STRING);
		}

	}, [item?.role]);

	const classLi = addState === true || !addState ? "first-ul" : "first-ul red-background";
	const classSelect = checked === ONE  ? "ul-user-item-normal-role" : "ul-user-item-normal";

	const handleClickOpenModal = (e) => {
		dispatch(openOpenUserModal());
		handleStopPropagation(e)
		dispatch(editRoleItemSave(item));
	}

	const handleClickUserBalance = () => {
		setShowModal(true);
		setUser(item);
	}

 const getProfileByUserId = async () => {
	 const url =  route(ADMIN_USER_BY_ID, [':userId', item.id]);
	 const result = await fetchApiGet({url, token});
	 setItem(result);
	 setLoading(false);
 }

 const handleChangeState = async () => {
	 let changeStatus = CLEAR_LINE;

	 if (addState === true) {
		 changeStatus = false;
	 } else {
		 changeStatus = true;
	 }

	 const url =  route(ADMIN_USERS_ACTIVITY_STATUS, [':userId', item.id]);
	 const data = {
		 "is_active": changeStatus
	 }
	 const result = await fetchApiPost({url, token, data});
	 setLoading(true);
	 getProfileByUserId();

 }


	return (
		<>
			<ul className={classSelect}>
				<li className={classLi}>{item.first_name} {item.last_name}</li>
				<li className={classLi}>{item.phone}</li>
				<li className={classLi}>{item.comments}</li>
				<LiRole classLi={classLi}
								item={item}
								myRole={role}
								getProfileByUserId={getProfileByUserId}/>
				{admin.role === ROLE_ADMIN && (
					<li className={classLi}>{loading ? (
						<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
					) : (
						<Checkbox handleChangeState={handleChangeState}
											myState={addState}/>
					)}
					</li>)
				}
				<li className={classLi}>{final}</li>
				{checked === ONE && children && children.length ? (
					<li><span>{children.length}</span></li>
				) : (
					<li><ClipLoader color={"#C014E3"} loading={loadingChildren} size={30}/></li>
				)}
				{checked === ONE && userBalance ?(
					<li>{userBalance} calls</li>
				) : (
					<li><ClipLoader color={"#C014E3"} loading={loadingBalance} size={30}/></li>
				)}
				<li style={{paddingLeft: "10px", display: "flex", gap: "20px"}}>
					{checked === ONE &&
						<div style={{paddingLeft: "10px"}}>
							<Button onClick={handleClickUserBalance} variant="success"
											className="modal-add-button">Edit call  balance</Button>
						</div>
					}
					<div onClick={(e)=>handleClickOpenModal(e)}><img src={editUser}/></div>
				</li>

			</ul>
		</>
	);
}
export default UserItem;