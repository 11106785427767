import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import {countOfCallsUpdateSave} from "../../../store/slicers/slice-modal";
import {ADMIN_USERS_BALANCE, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";

import {CloseBtn} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {ContentWrapperUserBalance} from "./UserModalStyled";
import {WrapperForModalMeeting} from "../../create-meeting/styled-create-meeting/CreateMeetingStyled";

import close from "./img/close_balance.png";


function UserBalanceModal({setShowModal, user, setShowModalConfirm}) {

	const {token} = useToken();
	const [userBalance, setUserBalance] = useState(null);
	const [changeValue, setChangeValue] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(()=>{

		const getBalance = async () => {
			const url = route(ADMIN_USERS_BALANCE, [':userId', user.id])

			const result =  await fetchApiGet({url, token})
			setLoading(false);
			setUserBalance(result.balance);
		}
		getBalance();

	},[user]);

	const handlerShowConfirm = () => {
		setShowModalConfirm(true);
		setShowModal(false);
		dispatch(countOfCallsUpdateSave({
					changeValue,
					userBalance
		}));
	}

	useEffect(()=>{
		if(changeValue.trim().length > 0 && changeValue !== "0"){
			setDisabled(false)
		}
	},[changeValue]);

	const handleChange = (el) => {
		const inputValue = el.target.value.replace(/\D/g, '');

		if (inputValue === "") {
			setChangeValue("");
			return;
		}
		if (!Number(inputValue)) {
			setChangeValue("");
			return;
		}
		if (Number(inputValue) < 0) {
			setChangeValue("0");
			return;
		}
		if (Number(inputValue) > 9999) {
			setChangeValue("999");
			return;
		}
		setChangeValue(inputValue);
	};

	return (

			<WrapperForModalMeeting>
				<div className="modal-meeting active">
					<ContentWrapperUserBalance>
						<CloseBtn onClick={()=> setShowModal(false)}>
							<img src={close} alt={close}/>
						</CloseBtn>
						<h4>User balance</h4>
						{user && <p className="user-balance-p first">Current amount of <span>{user.first_name} {user.last_name}</span>
							calls:
							{loading ? (
								<ClipLoader color={"#C014E3"} loading={loading} size={15}/>
							) : (
								<span>{userBalance}</span>
							)} </p>}
						<p className="user-balance-p">Enter number of calls</p>
						<form>
							<div className="user-balance-div-input">
								<input type="text"
											 placeholder="only positive numerical"
											 value={changeValue}
											 onChange={(el)=> handleChange(el)}
											 maxLength={3}
											 required/>
							</div>
							<div className="buttons">
								<button onClick={handlerShowConfirm} disabled={disabled}>Add Calls</button>
								<button onClick={()=> setShowModal(false)}>Decline</button>
							</div>
						</form>
					</ContentWrapperUserBalance>
				</div>
			</WrapperForModalMeeting>

	);
}

export default UserBalanceModal;