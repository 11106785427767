import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import useToken from "../../../hooks/useToken";
import {getDataSchedule} from "../../../../store/actions/schedule-action";
import {
	closeCreateTimeOffSlot,
	closeDeleteSlots,
	closeInfoSlots,
	slotInfoClear
} from "../../../../store/slicers/create-meeting-slice";
import {
	ADMIN_ENTERTAINERS_SLOTS_DELETE,
	ADMIN_ENTERTAINERS_SLOTS_DELETE_TIME_OFF,
	ADMIN_USER_BY_ID,
	route
} from "../../../../utils/constants_urls";
import {fetchApiDelete, fetchApiGet} from "../../../../utils/axios";

import {
	ModalContentCancelEditing,
} from "../create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {
	WrapperForModalSlotDelete
} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {
	BackButton, DeleteButtonPop,
	H3PopUp,
	PPopUp,
	WrapperButtonPopUp
} from "../../monetization/styled-monetization/StyledMonetization";
import {WrapperContentPopUp} from "../StyledSlots";

import close from "../../../create-meeting/img/close.png";

function DeleteSlotModal({timeStampStart, timeStampEnd, isOpenOk, isOpenErr}) {

	const {slotInfo} = useSelector(state => state.create);
	const {token} = useToken();
	const {isOpenCreateTimeOffSlot} = useSelector(state => state.create);

	const [user, setUser] = useState('');
	const [loading, setLoading] = useState(true);
	const [loadingDel, setLoadingDel] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		const getProfileByUserId = async () => {
			const url =  route(ADMIN_USER_BY_ID, [':userId', slotInfo.id]);
			const result = await fetchApiGet({url, token});
			setUser(result);
			setLoading(false);
		}
		getProfileByUserId();
	},[]);
	const deleteSlot = async () => {
		setLoadingDel(true);
		const url = isOpenCreateTimeOffSlot ? (
				route(ADMIN_ENTERTAINERS_SLOTS_DELETE_TIME_OFF, [':entertainer_id', slotInfo.id], [':slot_id', slotInfo.slot_id])
		) : (
			route(ADMIN_ENTERTAINERS_SLOTS_DELETE, [':entertainer_id', slotInfo.id], [':slot_id', slotInfo.slot_id])
		)
		const result =  await fetchApiDelete({url, token});
		if(result.status === 'ok'){
			dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
			dispatch(closeDeleteSlots());
			dispatch(closeInfoSlots());
			dispatch(slotInfoClear());
			dispatch(closeInfoSlots());
			dispatch(closeCreateTimeOffSlot());
			isOpenOk();
			setLoadingDel(false);
		} else {
			isOpenErr();
			setLoadingDel(false);
		}

	}

	const closeBtn = () => {
		dispatch(closeDeleteSlots());
		dispatch(closeCreateTimeOffSlot());
	}

	return (
		<WrapperForModalSlotDelete>
			<WrapperContentPopUp  className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn onClick={closeBtn} >
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Delete Reservation</H3PopUp>
					{ loading ? <ClipLoader color={"#C014E3"} loading={loading} size={30}/> :
						<PPopUp>Delete reservation for {user.first_name} {user.last_name}?</PPopUp>
					}
					<WrapperButtonPopUp>
						{loadingDel ? (
							<ClipLoader color={"#C014E3"} loading={loadingDel} size={30}/>
						) : (
							<>
								<BackButton onClick={() => dispatch(closeDeleteSlots())}>Cancel</BackButton>
								<DeleteButtonPop onClick={deleteSlot}>Delete</DeleteButtonPop>
							</>
						)}
					</WrapperButtonPopUp>
				</ModalContentCancelEditing>
			</WrapperContentPopUp>
		</WrapperForModalSlotDelete>
	);
}

export default DeleteSlotModal;