import React from "react";
import styled from "styled-components";
export const SearchFilterWrapper = styled.ul`
  overflow-y: auto;
	max-height: 250px;
	position: absolute;
  z-index: 250;
	background-color: #ffffff;
	width: 230px;
	border: 1px solid #DBE2EA;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 30px;
`;