import {createAsyncThunk} from '@reduxjs/toolkit';

import {fetchApiGet} from "../../utils/axios";
import {DASHBOARD, route} from "../../utils/constants_urls";

// todo token
export const getData = createAsyncThunk(
	"dashboard/getData",
	async function ({timeStampStart,timeStampEnd, token}, {rejectWithValue}) {
		const url =  route(DASHBOARD, [':timeStampStart', timeStampStart], [':timeStampEnd', timeStampEnd]);
		console.log("go-go dashboard")

		const response = await fetchApiGet({url, token});
		//console.log("response dashboard", response)
		console.log("response dashboard")
		return response?.error ? [] : response;
	}
);


