import React from 'react';
import {FieldContainer, Label} from "../StyledConfig";

const WayForPayMerchant = ({wayForPatMerchant}) => {
	return (
		<FieldContainer>
			<Label>Wayforpay merchant:</Label>
			<div style={{width: "200px"}}>
				{wayForPatMerchant}
			</div>
			<div></div>
		</FieldContainer>
	);
};

export default WayForPayMerchant;