import React from 'react';

import ModalMeeting from "./modal-meeting/ModalMeeting";

import {WrapperForModalMeeting} from './styled-create-meeting/CreateMeetingStyled';

function CreateMeeting({setShowAlert, timeStampEnd, timeStampStart}) {

	return (
		<WrapperForModalMeeting>
			<ModalMeeting
				setShowAlert={setShowAlert}
				timeStampEnd={timeStampEnd}
				timeStampStart={timeStampStart}/>
		</WrapperForModalMeeting>
	);
}


export default CreateMeeting;