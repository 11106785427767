import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import {Col, Row, Navbar} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

import useToken from "../../hooks/useToken";
import SearchBar from "../../searchBar/SearchBar";
import UsersCollection from "../../searchBar/usersCollection/UsersCollection";
import { ALL, CLEAR_LINE, ONE, THREE, TWO } from "../../../helpers/consts";
import UserBalanceModal from "./UserBalanceModal";
import ConfirmAddingCalls from "./ConfirmAddingCalls";
import {
	ADMIN_USERS_ROLE_BY_PAGE,
	ADMIN_USERS_SEARCH_ROLE,
	route
} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import UserModal from "./UserModal";

import '../../searchBar/usersCollection/userCollection.sass';
import './userHeader.sass';
import {PageWrapper} from "./UserModalStyled";



const UsersList = () => {

	const {isOpenUserModal} = useSelector(state => state.modal);
	const {token} = useToken();
	const [items, setItems] = useState([]);
	const [searchString, setSearchString] = useState(CLEAR_LINE);
	const [checked, setChecked] = useState(ALL);
	const [showModal, setShowModal] = useState(false);
	const [showModalConfirm, setShowModalConfirm] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);

	const { isValidToken } = useToken();


	const sortItems = (arr) => {
		arr.forEach(item => {
			if(item.first_name === undefined || !item.first_name){
				item.first_name = ""
			}
		})

		setItems( arr.sort((x,y) => {
			if(x.first_name && y.first_name){
				return x.first_name.localeCompare(y.first_name)
			} else {
				return -1
			}
		}));

	}
	const getUserListPage = async () => {

		const url = "/admin/users?page=" + currentPage;
		const result = await fetchApiGet({url, token});
		setTotalCount(result.pages);
	}
	const getUserList = async () => {
		if (searchString === CLEAR_LINE){
		const url = "/admin/users?page=" + currentPage;
		const result = await fetchApiGet({url, token});
		const arr = [...result.entities]
		setLoading(false);
		setTotalCount(result.pages);
		sortItems(arr);
		}
	}



	const searchUsersByRole = async (searchGo) => {
			const url = checked !== ALL ? route(ADMIN_USERS_SEARCH_ROLE, [':searchBody', searchGo])+`${checked} &page=${currentPage}`:
				route(ADMIN_USERS_SEARCH_ROLE, [':searchBody', searchGo])+`&page=${currentPage}`;
			const result = await fetchApiGet({url, token});
			const arr = [...result.entities]
			setLoading(false);
			setTotalCount(result.pages);
			sortItems(arr);
	}



	useEffect(()=>{
		getUserListPage();
		getUserList();
	},[]);

	function handleNextPage() {
		if (currentPage < totalCount) {
			setCurrentPage(prevState => prevState + 1);

		}

	}


	function handlePrevPage() {
		if (currentPage > 1) {
			setCurrentPage(prevState => prevState - 1);
		}
	}

	useEffect(() => {
		setCurrentPage(1);
		if(searchString === CLEAR_LINE){
			getUserList();
		}
	},[searchString]);


	useEffect(() => {
		isValidToken();
	}, []);

	const handleSearchName = (el) => {
		let searchGo = el.target.value;
		setSearchString(searchGo);
		searchUsersByRole(searchGo);
	}

	const handleSort = (e) => {
		setChecked(e.target.id);
		setCurrentPage(1);
	};

	const getRole = async () => {
		if (searchString === CLEAR_LINE){
		const url =  route(ADMIN_USERS_ROLE_BY_PAGE, [':number',checked]) + currentPage;
		const result = await fetchApiGet({url, token});
		setTotalCount(result.pages);
		const arr = [...result.entities]
		sortItems(arr);
		}
	}
	useEffect(() => {
		if(checked === ALL && !searchString){
			getUserList();
		}else if (checked !== ALL && !searchString){
			getRole();
		} else if (checked === ALL && searchString){
			searchUsersByRole(searchString)
		} else {
			searchUsersByRole(searchString)
		}

	}, [checked, searchString, currentPage]);

	const classSelect = checked === ONE  ? "users-collection-row-role" : "users-collection-row";

	return (
		<>

			{isOpenUserModal && <UserModal checked={checked}/>}

			<Row className="row-user-header">
				<Col className="users-header-col-h2" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}><h2>Users</h2></Col>
				<Col className="col-user-header-nav" xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
					<Navbar>
						<nav className="me-auto">
							<input className='user-header-radio'
										 type='radio'
										 name='list'
										 id={ONE}
										 checked={checked === ONE ? true : false}
										 onChange={handleSort}/>
							<label className="user-header-nav" htmlFor={ONE}>Users</label>
							<input className='user-header-radio'
										 type='radio'
										 name='list'
										 id={TWO}
										 checked={checked === TWO ? true : false}
										 onChange={handleSort}/>
							<label className="user-header-nav" htmlFor={TWO}>Entertainer</label>
							<input className='user-header-radio'
										 type='radio'
										 name='list'
										 id={THREE}
										 checked={checked === THREE ? true : false}
										 onChange={handleSort}/>
							<label className="user-header-nav" htmlFor={THREE}>Admins</label>
							<input className='user-header-radio'
										 type='radio'
										 name='list'
										 id={ALL}
										 checked={checked === ALL ? true : false}
										 onChange={handleSort}/>
							<label className="user-header-nav" htmlFor={ALL}>All</label>
						</nav>
					</Navbar>
				</Col>
				{showModal && <UserBalanceModal setShowModal={setShowModal}
																				user={user}
																				setShowModalConfirm={setShowModalConfirm}/>}
				{showModalConfirm && <ConfirmAddingCalls setShowModalConfirm={setShowModalConfirm}
																								 user={user}/>}
				<SearchBar searchString={searchString}
									 setSearchString={setSearchString}
									 handleSearchName={handleSearchName}/>
			</Row>
			<Row className={classSelect}>
				{loading ? (
					<ClipLoader color={"#C014E3"} loading={loading} size={300}/>
				) : (
					<UsersCollection items={items}
													 setShowModal={setShowModal}
													 setUser={setUser}
													 checked={checked}/>
				)}
				<PageWrapper>
					<button onClick={handlePrevPage}>{'<'}</button>
					<span>{currentPage}/{totalCount}</span>
					<button onClick={handleNextPage}>{'>'}</button>
				</PageWrapper>
			</Row>
		</>
	);
}

export default UsersList;
