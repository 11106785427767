import React, { useState} from 'react';
import {
	FilteredLanguage,
	FilterHeader,
	FilterSelectUl,
	FilterWrapper
} from "./StyledCourse";

const CourseFilters = (props) => {

	const {
		selectedLanguage,
		selectedAge,
		selectedActive,
		selectedSort,
		languages,
		setLanguages,
		sort,
		setSort,
		actives,
		setActives,
		ages,
		setAges
	} = props;

	const [language, setLanguage] = useState(false);
	const [age, setAge] = useState(false);
	const [active, setActive] = useState(false);
	const [sortOpen, setSortOpen] = useState(false);

	return (
		<FilterWrapper>
			<FilterHeader>
				<li>Course Language</li>
				<li>Age</li>
				<li>Status</li>
				<li>Sort on</li>
			</FilterHeader>
			<FilterHeader>
				<FilteredLanguage>
					<div onClick={()=> setLanguage(!language)}>{selectedLanguage.name}</div>
					{ language &&
						<FilterSelectUl>
							{
								languages.map(({name}) => (
									<li key={name} onClick={() => {
										setLanguages(name);
										setLanguage(false);
									}}>{name}</li>
								))
							}
						</FilterSelectUl>
					}
				</FilteredLanguage>
				<FilteredLanguage>
					<div onClick={()=> setAge(!age)}>{selectedAge.name}</div>
					{ age &&
						<FilterSelectUl>
							{
								ages.map(({name}) => (
								<li key={name} onClick={() => {
									setAges(name);
									setAge(false);
							}}>{name}</li>))
							}
						</FilterSelectUl>
					}
				</FilteredLanguage>
				<FilteredLanguage>
					<div onClick={()=> setActive(!active)}>{selectedActive.name}</div>
					{ active &&
						<FilterSelectUl>
							{
								actives.map(({name}) => (
								<li key={name} onClick={() => {
									setActives(name);
									setActive(false);
							}}>{name}</li>
								))
							}
						</FilterSelectUl>
					}
				</FilteredLanguage>
				<FilteredLanguage>
					<div onClick={()=> setSortOpen(prevState => !prevState)}>{selectedSort.name}</div>
					{ sortOpen &&
						<FilterSelectUl>
							{
								sort.map((el) => (
									<li key={el.name} onClick={() => {
										setSort(el);
										setSortOpen(false)
									}}>{el.name}</li>
								))
							}
						</FilterSelectUl>
					}
				</FilteredLanguage>
			</FilterHeader>
		</FilterWrapper>
	);
};

export default CourseFilters;