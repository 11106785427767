import React from 'react';
import GroupItem from "./GroupItem";

const GroupCollection = ({groups, courses, groupEditIsOpen}) => {

	return (
		<div>
			{
				groups.map(group => (
					<GroupItem key={group.group_id}
										 group={group}
										 courses={courses}
										 groupEditIsOpen={groupEditIsOpen}/>
				))
			}
		</div>
	);
}

export default GroupCollection;