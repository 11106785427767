import {createAsyncThunk} from '@reduxjs/toolkit';

export const getMeetingById = createAsyncThunk(
	"update/getMeetingById",
	async function (modalPropsMeetingId, {rejectWithValue}) {
		try {
			const res = await 	fetch(`/api/admin/get-meeting-info?meetingId=${modalPropsMeetingId}`)
			const data = await  res.json();

			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}

	}
);
