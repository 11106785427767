import React from "react";
import styled from "styled-components";

export const CloseBtn = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
  z-index: 1000;
  
`;
export const CloseBtnSlotEditionConf = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
  z-index: 2000;
  
`;
export const CloseBtnVideo = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background-color: black;

`;

export const DesWrapper = styled.div`
  display: flex;
`;
export const ModalWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30%;
  z-index: 2999;
  width: 100%;
  padding: 10px;

`
;
export const ModalVideo = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 545px;
  z-index: 3000;
 
 
 `
;