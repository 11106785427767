
export const route = (template, ...args) => {
	args.forEach(_ => template = template.replace(_[0],_[1]));
	return template
}

export const ROOT_URL = "/";

export const AUTH_VERIFY = "/auth/verify";
export const ADMIN_AUTHENTICATE = "/admin/authenticate";

export const DASHBOARD = `/admin/dashboard?time_from=:timeStampStart&time_to=:timeStampEnd`;
export const ADMIN_ENTERTAINERS_SLOTS = `/admin/entertainers/:entertainerId/slot`;
export const ADMIN_ENTERTAINERS_SLOTS_TIME_OFF = `/admin/entertainers/:entertainer_id/time-off`;
export const ADMIN_ENTERTAINERS_SLOTS_DELETE = `/admin/entertainers/:entertainer_id/slot/:slot_id`;
export const ADMIN_ENTERTAINERS_SLOTS_DELETE_TIME_OFF = `/admin/entertainers/:entertainer_id/time-off/:slot_id`;
export const ADMIN_USERS_DEPENDENTS = `/admin/users/:kidId/dependents`;
export const ADMIN_USER_BY_ID = `/admin/users/:userId`;
export const ADMIN_USER_BY_ID_DEPENDENTS = `/admin/users/:userId/dependents`;
export const ADMIN_USERS = `/admin/users`;
export const ADMIN_USERS_ROLE = `/admin/users?role=:number`;
export const ADMIN_USERS_ROLE_BY_PAGE = `/admin/users?role=:number&page=`;
export const ADMIN_USERS_SEARCH = `/admin/users?search=:searchBody`;
export const ADMIN_USERS_ACTIVITY_STATUS = `/admin/users/:userId/activity-status`;

export const ADMIN_PACKAGES = "/admin/packages/";
export const ADMIN_PACKAGES_BY_ID = "/admin/packages/:packageId";
export const ADMIN_PACKAGES_ACTIVITY_STATUS = `/admin/packages/:Id/activity-status`;

export const ADMIN_MEETING = "/admin/meeting";
export const ADMIN_MEETING_BY_ID =  `/admin/meeting/:meetingId`;
export const ADMIN_MEETING_DELETE_BY_ID =   `/admin/meeting/:meetingId`;

export const ROLE_ITEM =`/admin/users/:roleId/role`;

export const ADMIN_CHARACTERS = "/admin/characters";
export const ADMIN_CHARACTERS_BY_ID = `/admin/characters/:characterId`;
export const ADMIN_CHARACTERS_ACTIVITY_STATUS =`/admin/characters/:characterId/activity-status`;
export const ADMIN_USERS_BALANCE =`/admin/users/:userId/call-balance`;
export const ADMIN_COURSES = "/admin/courses";
export const ADMIN_COURSES_SLASH = "/admin/courses/";
export const ADMIN_COURSES_LINKING = "/admin/course/linking";
export const ADMIN_COURSES_LINKING_CREATE = `/admin/course/linking/:course_id`;
export const ADMIN_COURSES_BY_ID = `/admin/courses/:courseId`;
export const ADMIN_GROUPS = "/admin/course-groups/";
export const ADMIN_GROUPS_ID = `/admin/course-groups/:group_id`;
export const ADMIN_COURSE_ID_LESSONS = `/admin/courses/:course_id/lessons/`;
export const ADMIN_COURSES_ID_LESSONS_ID=`/admin/courses/:course_id/lessons/:lesson_id`;
export const ADMIN_COURSES_PROGRESS =`/admin/users/:userId/courses-progress`;
export const ADMIN_CONFIGS =`/admin/configs`;
export const ADMIN_CONFIGS_ID =`/admin/configs/:config_id`;
export const ADMIN_USERS_SEARCH_ROLE =`/admin/users?search=:searchBody&role=`;
