import React, {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import {fetchApiPost} from "../../../../utils/axios";
import useToken from "../../../hooks/useToken";
import {ADMIN_GROUPS} from "../../../../utils/constants_urls";

import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {H3PopUp} from "../../monetization/styled-monetization/StyledMonetization";
import {CanselGroup, SaveButtonGroup, WrapperButtonGroupEdit} from "../StyledCourse";
import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";

import close from "../../../create-meeting/img/close.png";


const GroupCreate = ({createGroupIsOpen, getGroup}) => {
	const {token} = useToken();
	const [value, setValue] = useState('');
	const [loading, setLoading] = useState(false);

	const createGroup = async () => {
		setLoading(true);
		const url = ADMIN_GROUPS;
		const data = {
			"group_name": value
		};
		const result = await fetchApiPost({url, token, data});
		createGroupIsOpen();
		getGroup();
		setLoading(false);
	}

	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn onClick={createGroupIsOpen}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Group edit</H3PopUp>
					<input className="editGroupInput"
								 type="text"
								 placeholder="Введите название"
								 onChange={(el) => setValue(el.target.value)}/>
					<WrapperButtonGroupEdit>
						{ loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<>
								<CanselGroup onClick={createGroupIsOpen}>Cancel</CanselGroup>
								<SaveButtonGroup onClick={createGroup} >Save</SaveButtonGroup>
							</>
						)}
					</WrapperButtonGroupEdit>

				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>
	);
};

export default GroupCreate;