import React from 'react';

import SearchFilterItem from "./search-filter-item/SearchFilterItem";

import {SearchFilterWrapper} from '../search-meeting-styled/search-filter-styled/SearchFilterCollectionStyled';

const SearchFilterCollection = (props) => {

	const items = props.items;
	const us = props.onPersonSelect;
	const setKid = props.setKid;
	const setEntertainer = props.setEntertainer;

	return (
		<>
			<SearchFilterWrapper>
				{items &&
					items.map( item =>
						<SearchFilterItem key={item.id}
															item={item}
															us={us}
															setKid={setKid}
															setEntertainer={setEntertainer} />
					)
				}
			</SearchFilterWrapper>
		</>
	);

}

export default SearchFilterCollection;