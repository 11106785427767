import React from 'react';
import moment from "moment";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {openCreateTimeOffSlot, openInfoSlots, slotInfoSave} from "../../../store/slicers/create-meeting-slice";
import {TIME_ZONE} from "../../../helpers/consts";
import {isDayContainCurrentEvent} from "../../../helpers";

import {Slot, SlotTimeOff} from "./StyledSlots";

function SelectedSlots({dayItem}) {

	const {entertainerNameSlotsCreate} = useSelector(state => state.name);
	const {schedule} = useSelector(state => state.schedule);

	const [freeSlot, setFreeSlot] = useState([]);
	const [freeSlotTimeOff, setFreeSlotTimeOff] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (schedule) {
			const timeOff = schedule?.reduce((acc, slot) => {
				if (entertainerNameSlotsCreate?.id && slot.entertainer_id !== entertainerNameSlotsCreate?.id) return acc;
				const x = slot.time_offs?.map(item => ({...item, id: slot.entertainer_id}));
				return [...acc, ...x];
			}, [])

			setFreeSlotTimeOff(timeOff)

			const temp = schedule?.reduce((acc, slot) => {
				if (entertainerNameSlotsCreate?.id && slot.entertainer_id !== entertainerNameSlotsCreate?.id) return acc;
				const x = slot.entertainer_slots?.map(item => ({...item, id: slot.entertainer_id}));
				return [...acc, ...x];
			}, [])

			setFreeSlot(temp)
		}

	}, [schedule, entertainerNameSlotsCreate]);

	const eventsNew = freeSlot.filter(event => {
		const slot = isDayContainCurrentEvent(event, dayItem.clone());
		return slot;
	});

	const eventsTimeOff = freeSlotTimeOff?.filter(event => {
		const slot = isDayContainCurrentEvent(event, dayItem.clone());
		return slot;
	});

	const height = (event) => {

		let start = parseInt(moment(event.slot_time.start_time).format('x')) + TIME_ZONE;
		let end = parseInt(moment(event.slot_time.end_time).format('x')) + TIME_ZONE;
		let twentyMinutes = 20 * 60 * 1000;
		return (end - start) / twentyMinutes;

	}

	const top = (event) => {

		let start = parseInt(moment(event.slot_time.start_time).format('x')) + TIME_ZONE;
		let twentyMinutes = 20 * 60 * 1000;
		const t = start - +dayItem.format('x')
		return t / twentyMinutes;

	}

	const slotInfo = (event) => {
		dispatch(slotInfoSave(event));
		dispatch(openInfoSlots());
	}
	const slotInfoTimeOff = (event) => {
		dispatch(slotInfoSave(event));
		dispatch(openInfoSlots());
		dispatch(openCreateTimeOffSlot());
	}

	return (
		<div>
			<>
				{
					eventsNew &&
					eventsNew
						.map((event, index) => (
							<div style={{position: 'relative', width: "100%"}} key={index}>
								<Slot h={42 * height(event)}
											top={42 * top(event)} onClick={() => slotInfo(event)}>
									<div style={{textAlign: "left"}}>
										Reserved<br/>
										{moment(event.slot_time.start_time).format("HH:mm")}-{moment(event.slot_time.end_time).format("HH:mm")}
									</div>
								</Slot>
							</div>
						))
				}
				{
					eventsTimeOff &&
					eventsTimeOff
						.map((event, index) => (
							<div style={{position: 'relative', width: "100%"}} key={index}>
								<SlotTimeOff h={42 * height(event)}
											top={42 * top(event)} onClick={() => slotInfoTimeOff(event)}>
									<div style={{textAlign: "left"}}>
										Time Off<br/>
										{moment(event.slot_time.start_time).format("HH:mm")}-{moment(event.slot_time.end_time).format("HH:mm")}
									</div>
								</SlotTimeOff>
							</div>
						))
				}
			</>
		</div>
	);
}

export default SelectedSlots;