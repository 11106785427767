import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";

import {options} from "../../../helpers/objects";
import useToken from "../../hooks/useToken";
import {ADMIN_USER_BY_ID, ROLE_ITEM, route} from "../../../utils/constants_urls";
import {fetchApiGet, fetchApiPost, fetchApiPut} from "../../../utils/axios";
import {editRoleItemClear, editRoleItemSave} from "../../../store/slicers/edit-user-slice";
import UserModalInfoChildren from "./UserModalInfoChildren";

import {
	CLEAR_LINE,
	ROLE_ADMIN,
	ROLE_ADMIN_STRING,
	ROLE_ENTERTAINER,
	ROLE_ENTERTAINER_STRING, ROLE_OBSERVER, ROLE_OBSERVER_STRING, ROLE_USER, ROLE_USER_STRING
} from "../../../helpers/consts";

import {
	ButtonCancelEditUserGo,
	ButtonEditUserGo,
	ButtonEditUserWrapper,
	ImgHWrapper,
	UserInfoGridItem,
	UserInfoGridWrapper,
	UserRoleInfo,
	UserRoleInfoWrapper
} from "./UserModalStyled";

import Vector from "./img/Vector.png";
import VectorAcc from "./img/VectorAcc.png";
import phoneImg from "./img/phone.png";
import emailImg from "./img/email.png";
import desImg from "./img/dess.png";

const UserModalEditContent = ({handlerEditUserGo, roleInfo, setRoleInfo, checked,handlerDetailsOpen}) => {
	const {editRoleItem} = useSelector(state => state.editRole);

	const [selectedOptions, setSelectedOptions] = useState(null);
	const [firstName, setFirstName] = useState(CLEAR_LINE);
	const [secondName, setSecondName] = useState(CLEAR_LINE);
	const [description, setDescription] = useState(CLEAR_LINE);
	const [email, setEmail] = useState(CLEAR_LINE);

	const dispatch = useDispatch();
	const {token} = useToken();

	const getProfileByUserIdIEdit = async () => {
		const url =  route(ADMIN_USER_BY_ID, [':userId', editRoleItem.id]);
		const result = await fetchApiGet({url, token});
		dispatch(editRoleItemSave(result));
	}
	const onSaveMeta = async () => {
		const url = route(ADMIN_USER_BY_ID, [':userId', editRoleItem.id]);
		const data = {
			"first_name": firstName,
			"last_name": secondName,
			"email": email,
			"comments": description
		};
		const response = await fetchApiPut({url, data, token});
		if(Object.keys(response).length !== 0){
			dispatch(editRoleItemClear());
			getProfileByUserIdIEdit();
			handlerEditUserGo();
		}
	}

	const onSave = async () => {
		const url = route(ROLE_ITEM, [':roleId', editRoleItem.id]);
		const data = {
			"role": selectedOptions
		};
		const response = await fetchApiPost({url, data, token});
		if(Object.keys(response).length !== 0){
			onSaveMeta();
		}
	}

	useEffect(()=>{
		if(selectedOptions){
			if(selectedOptions === ROLE_ADMIN){
				setRoleInfo(ROLE_ADMIN_STRING);
			} else if(selectedOptions === ROLE_ENTERTAINER){
				setRoleInfo(ROLE_ENTERTAINER_STRING);
			} else if(selectedOptions === ROLE_USER){
				setRoleInfo(ROLE_USER_STRING);
			} else if(selectedOptions === ROLE_OBSERVER){
				setRoleInfo(ROLE_OBSERVER_STRING);
			}
		}

	},[selectedOptions])

	useEffect(() => {
		if(editRoleItem){
			setSelectedOptions(editRoleItem.role);
			setFirstName(editRoleItem.first_name);
			setSecondName(editRoleItem.last_name);
			setDescription(editRoleItem.comments);
			setEmail(editRoleItem.email);
		}
	},[editRoleItem]);


	const handlerChangeRole = (event) => {
		const value = event.value;
		setSelectedOptions(value);
	}

	return (
		< >
			<UserRoleInfoWrapper>
				<div>
					<img src={Vector}/>
				</div>
				<UserRoleInfo>
					<Select className="edit-user-select"
									options={options}
									value={selectedOptions}
									placeholder={roleInfo}
									onChange={handlerChangeRole}/>
				</UserRoleInfo>
			</UserRoleInfoWrapper>
			<div style={{overflow: "auto", maxHeight: "600px"}}>
			<UserInfoGridWrapper>
				<UserInfoGridItem>
					<ImgHWrapper>
						<div>
							<img src={VectorAcc}/>
						</div>
						<span>First Name</span>
					</ImgHWrapper>
					<input type="text" className="edit-user-input"
								 value={firstName}
								 onChange={(el) => setFirstName(el.target.value)}
					/>
				</UserInfoGridItem>
				<UserInfoGridItem>
					<ImgHWrapper>
						<div>
							<img src={VectorAcc}/>
						</div>
						<span>Second Name</span>
					</ImgHWrapper>
					<input type="text" className="edit-user-input"
								 value={secondName}
								 onChange={(el) => setSecondName(el.target.value)}
					/>
				</UserInfoGridItem>
				<UserInfoGridItem>
					<ImgHWrapper>
						<div>
							<img src={phoneImg}/>
						</div>
						<span>Phone</span>
					</ImgHWrapper>
					<span className='span-info-user'>{editRoleItem.phone}</span>
				</UserInfoGridItem>
				<UserInfoGridItem>
					<ImgHWrapper>
						<div>
							<img src={emailImg}/>
						</div>
						<span>Email</span>
					</ImgHWrapper>
					<input type="email" className="edit-user-input"
								 value={email}
								 onChange={(el) => setEmail(el.target.value)}
					/>
				</UserInfoGridItem>
				<UserInfoGridItem>
					<ImgHWrapper>
						<div>
							<img src={desImg}/>
						</div>
						<span>Description</span>
					</ImgHWrapper>
					<textarea className="edit-user-textarea"
																				value={description}
																				onChange={(el) => setDescription(el.target.value)}>
						{editRoleItem.comments}
					</textarea>
				</UserInfoGridItem>
			</UserInfoGridWrapper>
			<UserModalInfoChildren checked={checked} handlerDetailsOpen={handlerDetailsOpen}/>
			</div>
			<ButtonEditUserWrapper>
				<ButtonCancelEditUserGo onClick={handlerEditUserGo}>Cancel</ButtonCancelEditUserGo>
				<ButtonEditUserGo onClick={onSave}>Save</ButtonEditUserGo>
			</ButtonEditUserWrapper>
		</>
	);
};

export default UserModalEditContent;