import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	valueChange: null
}
const valueChangeSlice = createSlice({
	name: "values",
	initialState,
	reducers: {

		valueChangeSave(state, action){
			state.valueChange = action.payload;
		},
	},

})

export const {valueChangeSave} = valueChangeSlice.actions;

export default valueChangeSlice.reducer;
