import React, {useEffect, useRef, useState} from 'react';
import moment from "moment";
import {RedLine} from "../styled-components/StyledComponents";


const RedTimeLine = ({today}) => {

		const timeRef = useRef(null);
		const [, setCounter] = useState(0);

		useEffect(() => {
			let element = document.getElementById("lineTimeGoToScroll");
				setTimeout(() => {
				 element.scrollIntoView({behavior: "auto", block: "center", inline: "center"});
			}, 200);

		},[]);

	const getRedLinePosition = () => {
		return ((moment().format('X') - today.format('X')) / 86400) * 100;
	}

	useEffect(() => {
		const timerId = setInterval(()=>{
			setCounter(prevState => prevState + 1);
		},1000);
		return () => clearInterval(timerId);
	},[]);

		return (

				<RedLine
						 ref={timeRef}
						 position={getRedLinePosition()}
						 id="lineTimeGoToScroll">
					<div className="circle-wrapper">
						<div className="circle-left"></div>
						<div className="circle-right"></div>
					</div>
				</RedLine>

		);
	}


export default RedTimeLine;