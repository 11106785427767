import * as React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';

import Auth from './components/pages/auth/Auth';
import UsersList from './components/pages/usersList/UsersList';
import Layout from './components/hoc/Layout';
// import Home from './components/pages/home/Home';
// import CheckLogin from './components/hoc/CheckLogin';
import Dashboards from './components/pages/dashboards/Dashboards';
import Report from './components/pages/report/Report';
import NotFound from './components/pages/notFound/NotFound';
import Characters from "./components/pages/characters/Characters";

import './components/fonts/fonts.css';
import '../src/components/index.sass';
import Schedule from "./components/pages/create-slot/Schedule";
import Monetization from "./components/pages/monetization/Monetization";
import ObjectOfPurchaseCreation from "./components/pages/monetization/object-purchase/ObjectOfPurchaseCreation";
import Course from "./components/pages/course/Course";
import CourseInfo from "./components/pages/course/CourseInfo";
import LessonInfo from "./components/pages/course/LessonInfo";
import CreateCourse from "./components/pages/course/create-course/CreateCourse";
import CreateLesson from "./components/pages/course/create-lessons/CreateLesson";
import Configuration from "./components/pages/gonfiguration/Configuration";


const App = () => {
    console.log("Render App")
    return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout/>}>
                  {/*<Route index element={*/}
                  {/*    <CheckLogin redirectTo="/login"><Home/></CheckLogin>*/}
                  {/*}/>*/}
                  <Route path="/users" element={<UsersList/>}/>
                  <Route path="/dashboard" element={<Dashboards/>}/>
                  <Route path="/report" element={<Report />} />
                  <Route path="/characters" element={<Characters/>} />
                  <Route path="/create-slot" element={<Schedule/>} />
                  <Route path="/monetization" element={<Monetization/>} />
                  <Route path="/monetization-create-obg" element={<ObjectOfPurchaseCreation/>} />
                  <Route path="/course" element={<Course/>} />
                  <Route path="/course-info/:id" element={<CourseInfo/>} />
                  <Route path="/lesson-info/:id" element={<LessonInfo/>} />
                  <Route path="/create-course" element={<CreateCourse/>} />
                  <Route path="/create-lesson" element={<CreateLesson/>} />
                  <Route path="/config" element={<Configuration/>} />
              </Route>
              <Route path="/login" element={<Auth/>}/>
              <Route path="*" element={<NotFound/>}/>
          </Routes>
      </BrowserRouter>
    );
}

export default App;
