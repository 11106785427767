import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
	parentNameForSave,
} from "../../../../store/slicers/name-slice";

import {UlSearchItem} from "../../styled-create-meeting/search-styled/SearchStyled";


function SearchItemParent({item, us}) {

	const {entertainerNameFor} = useSelector(state => state.name);

	const [user, setUser] = useState(item);

	const dispatch = useDispatch();

	const onclick = (event) => {

		us(user);
		dispatch(parentNameForSave(user));

		if(user && entertainerNameFor){

			if(user.phone === entertainerNameFor.phone){
				us(null);
				event.currentTarget.classList.add('show-message');
			}
		}

	}

	return (
		<>
			<UlSearchItem onClick={onclick} className="ul-search-item">
				<li onClick={onclick}>{item.first_name} {item.last_name}</li>
				<li>{item.phone}</li>
			</UlSearchItem>
		</>
	);
}


export default SearchItemParent;