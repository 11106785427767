import React from 'react';
import NotificationContentErr from "../../notification/NotificationContentErr";
import NotificationContentOk from "../../notification/NotificationContentOk";

const AllNotificationsSchedule = (props) => {

	const {
		showErr,
		isOpenErr,
		showOk,
		isOpenOk,
		showErrCreate,
		isOpenErrCreate,
		showOkCreate,
		isOpenOkCreate,
		isOpenErrUpdate,
		showErrUpdate,
		showOkUpdate,
		isOpenOkUpdate
	} = props;

	return (
		<>
			{showErr && <NotificationContentErr h3="The slot has not been deleted"
																					setClose={isOpenErr}
																					p="Something went wrong. Try again"/>}
			{showOk && <NotificationContentOk h3="Delete slot"
																				setClose={isOpenOk}
																				p="Slot was successfully deleted!"/>}
			{showErrCreate && <NotificationContentErr h3="The slot has not been created"
																								setClose={isOpenErrCreate}
																								p="Selected time is already booked"/>}
			{showOkCreate && <NotificationContentOk h3="Create slot"
																							setClose={isOpenOkCreate}
																							p="Slot was successfully created!"/>}
			{showErrUpdate && <NotificationContentErr h3="The slot has not been updated"
																								setClose={isOpenErrUpdate}
																								p="Selected time is already booked"/>}
			{showOkUpdate && <NotificationContentOk h3="Update slot"
																							setClose={isOpenOkUpdate}
																							p="Slot was successfully updated!"/>}
		</>
	);
};

export default AllNotificationsSchedule;