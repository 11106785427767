import React from "react";
import styled from "styled-components";

export const HeaderButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
  & a{
    text-decoration: none;
  }
`;

export const ContentWrapperMonetization = styled.div`
	background-color: #F8F9FA;
	padding: 10px;
	border-radius: 8px;
`;

export const ButtonAdd = styled.button`
	padding: 6px 0;
  width: 120px;
	box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid #1485ED;
	background-color: #2F80ED;
	color: #ffffff;
	text-align: center;
	font-size: 16px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	cursor: pointer;
	&:disabled{
		background-color: lightgray;
		border: 2px solid lightgray;
	}
`;
export const ButtonAddWIthArr = styled(ButtonAdd)`
	width: 144px;
`;
export const WrapperUl = styled.ul`
	list-style-type: none;
  overflow-y: auto;
  max-height: calc(100vh - 155px);
`;

export const WrapperLi = styled.li`
	:nth-child(even) {
    background-color: #F2F4F5;
  }
`;

export const WrapperUlAll = styled.ul`
  display: flex;
	justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  color: #554D66;
  font-size: 14px;
  font-weight: 700;
  & li {
    width: 15%;
    display: flex;
    justify-content: center;
		padding: 10px;
  }
`;
export const WrapperUlHeader = styled(WrapperUlAll)`
`;
export const WrapperUlBody = styled(WrapperUlAll)`
  font-weight: 500;
	color:  ${props => props.isActive ? '#554D66' : '#BDBDBD'};
	& .is-active{
    color:  ${props => props.isActive ? '#44C124' : '#BDBDBD'};
	}
	& li a {
		color: ${props => props.isActive ? '#554D66' : '#BDBDBD'} ;
	}
  & li a:hover{
		color: #1485ED;
	}
`;
export const ButtonBack = styled.button`
	border: none;
	background-color: #F8F9FA;
	display: flex;
	gap: 10px;
	align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #515E6B;
	margin: 10px;
	& img{
		width: 38px;
	}
`;

export const FormContent = styled.div`
	
`;

export const WrapperUlCreate = styled.ul`
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #515E6B;
	background-color: #ffffff;
	padding: 28px;
	& .price-li{
		margin-bottom: 20px;
	}
	& .span-price{
    font-size: 16px;
    font-weight: 700;
    color: #554D66;
		padding-left: 50px;
  }
	& li label{
		display: flex;
		justify-content: space-between;
		width: 415px;
		align-items: center;
	}
  & input {
    outline: none;
    width: 287px;
    border-radius: 6px;
    padding: 7px;
    border: 1px solid #DBE2EA;
  }
  & input.monetization-input{
    border: 1px solid red;
  	}
	}
	& .remove-arr::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
	
`;

export const DivLine = styled.div`
  background-color: #F8F9FA;
 	height: 2px;
	margin: 20px -28px;
`;
export const IsDisplayedWrapper = styled.div`
  width: 415px;
	display: flex;
`;
export const ImgSpanWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
  margin-left: 37px;
	& .img-wrapper{
		width: 34px;
		height: 34px;
		border-radius: 6px;
		background-color: #E0E0E0;
		border: 1px solid #BDBDBD;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	& .active-img{
    background-color:  #42CB6F;
    
	}
`;

export const WrapperButtonCreate = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
	gap: 10px;
	& a {
		text-decoration: none;
	}
`;
export const WrapperButtonPopUp = styled(WrapperButtonCreate)`
	justify-content: center;
	margin-top: 30px;
`;

export const CreateButton = styled(ButtonAdd)`
	width: 144px;
`;
export const SaveButton = styled(ButtonAdd)`
	width: 164px;
`;

export const CancelButton = styled(ButtonAdd)`
	width: 144px;
  background-color:#FFFFFF ;
  color:  #1485ED;
`;

export const DeleteButton = styled(CancelButton)`
  color: #EB5757;
  border: 2px solid #EB5757;
`;

export const BackButton = styled(CancelButton)`
	width: 164px;
`;

export const DiscardButton = styled(ButtonAdd)`
	width: 164px;
`;
export const DeleteButtonPop = styled(DiscardButton)`
  background-color:#EB5757 ;
  color:  #ffffff;
  border: 2px solid #EB5757;
`;


export const IsActiveCheckbox = styled.div`
	display: flex;
	gap: 34px;
	align-items: center;
	& span{
		color: #44C124;
	}
`;

export const NotEditWrapper = styled.div`
	min-height: 38px;
  width: 287px;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
	background-color: #F2F4F5;
`;

export const H3PopUp = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #554D66;
`;
export const PPopUp = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #554D66;
	padding-top: 20px;
`;
export const PPopUpColor = styled(PPopUp)`
 	color:  #8E8E8E;
	padding-top: 0;
`;

export const PopUpP = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #172B4D;
`;