import {configureStore} from '@reduxjs/toolkit';

import adminSlice from "./slicers/admin-slice";
import meetingSlice from "./slicers/meeting-slice";
import meetingSearchSliceEntertainer from "./slicers/search-meetings-entertainer-slice";
import meetingSearchSliceKid from "./slicers/search-meetings-kid-slice";
import stampModal from "./slicers/slice-modal";
import getMeetingByIdSlice from "./slicers/get-meeting-by-meting-id-slice";
import getChildProfileUpdate from "./slicers/get-profile-by-phone-for-update-child-slice";
import getEntertainerProfileUpdate from "./slicers/get-profile-by-phone-for-update-entertainer-slice";
import goStartTime from "./slicers/go-time-slice";
import getForUserListProfile from "./slicers/get-prifile-for-user-list-slice";
import nameSlice from "./slicers/name-slice";
import editRoleItemSlice from "./slicers/edit-user-slice";
import valueChangeSlice from "./slicers/value-slice";
import loadingSlice from "./slicers/loading-slice";
import meetingSearchSliceUser from "./slicers/search-meetings-users-slice";
import callsAllSlice from "./slicers/calls-slice";
import userListSlice from "./slicers/user-list-slice";
import getProfileByIdSlice from "./slicers/get-profile-by-id-slice";
import dashboardSlice from './slicers/dashboard-slice';
import saveCallForDeleteSlice from './slicers/save-call-for-delete-slice';
import slotIdSlice from './slicers/create-meeting-slice';
import MonetizationSlice from "./slicers/monitization-slice";
import tokenSlice from "./slicers/token-slice";
import timeSlice from "./slicers/time-slice";
import courseSlice from "./slicers/course-slice";
import scheduleSlice from "./slicers/schedule-slice";
export default configureStore({

	reducer: {
		admin: adminSlice,
		eventList: meetingSlice,
		eventSearchListEntertainer: meetingSearchSliceEntertainer,
		eventSearchListKid: meetingSearchSliceKid,
		modal: stampModal,
		MeetingById: getMeetingByIdSlice,
		childProfile: getChildProfileUpdate,
		entertainerProfile: getEntertainerProfileUpdate,
		go: goStartTime,
		userListProfile: getForUserListProfile,
		name: nameSlice,
		editRole: editRoleItemSlice,
		values: valueChangeSlice,
		loading: loadingSlice,
		eventSearchListUsers: meetingSearchSliceUser,
		calls: callsAllSlice,
		accounts: userListSlice,
		userProfile: getProfileByIdSlice,
		dashboard: dashboardSlice,
		deleteCall: saveCallForDeleteSlice,
		create: slotIdSlice,
		monetization: MonetizationSlice,
		token: tokenSlice,
		time: timeSlice,
		course: courseSlice,
		schedule: scheduleSlice,
	},
});