import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import {
	ConfigurationWrapper,
} from "./StyledConfig";

import useToken from "../../hooks/useToken";
import {ADMIN_CONFIGS, ADMIN_CONFIGS_ID, route} from "../../../utils/constants_urls";
import {fetchApiGet, fetchApiPost} from "../../../utils/axios";
import ConfigPopUp from "./ConfigPopUp";
import {
	ALLOW_JOIN_PASSED_MEETINGS,
	DEFAULT_LANGUAGE,
	EXTENDED_ONBOARDING, INTRODUCTION_LESSON_REQUIRED, RT_SERVERS,
	STARTING_BALANCE, SUPPORT_LINK, SURNAME_FIELD_ON_CREATION, WAYFORPAY_DOMAIN, WAYFORPAY_MERCHANT,
} from "../../../helpers/consts";
import DefaultLanguage from "./configs/DefaultLanguage";
import ExtendedOnboarding from "./configs/ExtendedOnboarding";
import IntroductoryLessonRequired from "./configs/IntroductoryLessonRequired";
import StartUserBalance from "./configs/StartUserBalance";
import {clientUse} from "../../../helpers";
import SurnameCreation from "./configs/SurnameCreation";
import SupportLink from "./configs/SupportLink";
import RtServers from "./configs/RTServers";
import WayForPayDomain from "./configs/WayForPayDomain";
import WayForPayMerchant from "./configs/WayForPayMerchant";
import AllowJoinPassedMeetings from "./configs/AllowJoinPassedMeetings";


const Configuration = () => {

	const [defaultLanguage, setDefaultLanguage] = useState('no');
	const [extendedOnboarding, setExtendedOnboarding] = useState('yes');
	const [introductoryLessonRequired, setIntroductoryLessonRequired] = useState('yes');
	const [allowJoinPassedMeetings, setAllowJoinPassedMeetings] = useState('no');
	const [startUserBalance, setStartUserBalance] = useState(0);
	const [onboarding, setOnboarding] = useState(null);
	const [lessonRequired, setLessonRequired] = useState(null);
	const [passedMeetings, setPassedMeetings] = useState(null);
	const [surnameCreation, setSurnameCreation] = useState('yes');
	const [surname, setSurname] = useState(null);
	const [supportLink, setSupportLink] = useState(null);
	const [rTServers, setRTServers] = useState(null);
	const [wayForPatMerchant, setWayForPatMerchant] = useState(null);
	const [wayForPatDomain, setWayForPatDomain] = useState(null);
	const [configs, setConfigs] = useState([]);

	useEffect(() => {
		if (extendedOnboarding === 'yes') {
			setOnboarding(true);
		} else {
			setOnboarding(false);
		}
	}, [extendedOnboarding]);

	useEffect(() => {
		if (introductoryLessonRequired === 'yes') {
			setLessonRequired(true);
		} else {
			setLessonRequired(false);
		}
	}, [introductoryLessonRequired]);

	useEffect(() => {
		if (allowJoinPassedMeetings === 'yes') {
			setPassedMeetings(true);
		} else {
			setPassedMeetings(false);
		}
	}, [allowJoinPassedMeetings]);

	useEffect(() => {
		if (surnameCreation === 'yes') {
			setSurname(true);
		} else {
			setSurname(false);
		}
	}, [surnameCreation]);


	const {token} = useToken();

	const [isEditing, setIsEditing] = useState({
		defaultLanguage: false,
		extendedOnboarding: false,
		introductoryLessonRequired: false,
		startUserBalance: false,
		surnameCreation: false,
		supportLink: false,
	});

	const [showPopup, setShowPopup] = useState(false);

	const handlePublish = (field) => {
		setShowPopup(true);
	};

	const handleDiscard = (field) => {
		getConfigs();
		setIsEditing({
			defaultLanguage: false,
			extendedOnboarding: false,
			introductoryLessonRequired: false,
			startUserBalance: false,
			surnameCreation: false,
			supportLink: false,
			allowJoinPassedMeetings: false,
		});
		setShowPopup(false);
	};

	const handleConfirmation = () => {
		if (defaultLanguage !== configs.find(x => x.config_id === DEFAULT_LANGUAGE).value) {
			const data = {
				"value": defaultLanguage,
				"client_use": clientUse(configs, DEFAULT_LANGUAGE)
			}
			updateConfigById(DEFAULT_LANGUAGE, data);

		} else if (onboarding !== configs.find(x => x.config_id === EXTENDED_ONBOARDING).value) {
			const data = {
				"value": onboarding,
				"client_use": clientUse(configs, EXTENDED_ONBOARDING)
			}
			updateConfigById(EXTENDED_ONBOARDING, data);
		} else if (lessonRequired !== configs.find(x => x.config_id === INTRODUCTION_LESSON_REQUIRED).value) {
			const data = {
				"value": lessonRequired,
				"client_use": clientUse(configs, INTRODUCTION_LESSON_REQUIRED)
			}
			updateConfigById(INTRODUCTION_LESSON_REQUIRED, data);
		} else if (passedMeetings !== configs.find(x => x.config_id === ALLOW_JOIN_PASSED_MEETINGS).value) {
			const data = {
				"value": passedMeetings,
				"client_use": clientUse(configs, ALLOW_JOIN_PASSED_MEETINGS)
			}
			updateConfigById(ALLOW_JOIN_PASSED_MEETINGS, data);
		} else if (startUserBalance !== configs.find(x => x.config_id === STARTING_BALANCE).value) {
			const data = {
				"value": +startUserBalance,
				"client_use": clientUse(configs, STARTING_BALANCE)
			}
			updateConfigById(STARTING_BALANCE, data);
		} else if (surname !== configs.find(x => x.config_id === SURNAME_FIELD_ON_CREATION).value) {
			const data = {
				"value": surname,
				"client_use": clientUse(configs, SURNAME_FIELD_ON_CREATION)
			}
			updateConfigById(SURNAME_FIELD_ON_CREATION, data);
		} else if (surname !== configs.find(x => x.config_id === SUPPORT_LINK).value) {
			const data = {
				"value": supportLink,
				"client_use": clientUse(configs, SUPPORT_LINK)
			}
			updateConfigById(SUPPORT_LINK, data);
		}

		setShowPopup(false);
	};


	const getConfigs = async () => {
		const url = ADMIN_CONFIGS;
		const response = await fetchApiGet({url, token});
		setConfigs([...response]);
		response.filter(item => {
			if (item.config_id === DEFAULT_LANGUAGE) {
				setDefaultLanguage(item.value);
			}
			if (item.config_id === STARTING_BALANCE) {
				setStartUserBalance(item.value);
			}
			if (item.config_id === EXTENDED_ONBOARDING) {
				setExtendedOnboarding(item.value ? 'yes' : 'no');
			}
			if (item.config_id === INTRODUCTION_LESSON_REQUIRED) {
				setIntroductoryLessonRequired(item.value ? 'yes' : 'no');
			}
			if (item.config_id === ALLOW_JOIN_PASSED_MEETINGS) {
				setAllowJoinPassedMeetings(item.value ? 'yes' : 'no');
			}
			if (item.config_id === SURNAME_FIELD_ON_CREATION) {
				setSurnameCreation(item.value ? 'yes' : 'no');
			}
			if (item.config_id === RT_SERVERS) {
				setRTServers(item.value);
			}
			if (item.config_id === SUPPORT_LINK) {
				setSupportLink(item.value);
			}
			if (item.config_id === WAYFORPAY_MERCHANT) {
				setWayForPatMerchant(item.value);
			}
			if (item.config_id === WAYFORPAY_DOMAIN) {
				setWayForPatDomain(item.value);
			}

		})
	};

	const getConfigById = async (id) => {
		const url = route(ADMIN_CONFIGS_ID, [':config_id', id]);
		const result = await fetchApiGet({url, token});
	};
	const updateConfigById = async (id, data) => {
		const url = route(ADMIN_CONFIGS_ID, [':config_id', id]);
		const result = await fetchApiPost({url, token, data});
		getConfigById(id);
		setIsEditing({
			defaultLanguage: false,
			extendedOnboarding: false,
			introductoryLessonRequired: false,
			startUserBalance: false,
			surnameCreation: false,
			supportLink: false,
			allowJoinPassedMeetings: false,
		});
	};


	useEffect(() => {
		getConfigs();
	}, []);


	return (

		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Configuration</h2>
			<ConfigurationWrapper>
				<DefaultLanguage setIsEditing={setIsEditing}
												 defaultLanguage={defaultLanguage}
												 isEditing={isEditing}
												 setDefaultLanguage={setDefaultLanguage}
												 handlePublish={handlePublish}
												 handleDiscard={handleDiscard}/>
				<ExtendedOnboarding extendedOnboarding={extendedOnboarding}
														handleDiscard={handleDiscard}
														isEditing={isEditing}
														setExtendedOnboarding={setExtendedOnboarding}
														handlePublish={handlePublish}
														setIsEditing={setIsEditing}/>

				<IntroductoryLessonRequired introductoryLessonRequired={introductoryLessonRequired}
																		setIsEditing={setIsEditing}
																		handlePublish={handlePublish}
																		isEditing={isEditing}
																		handleDiscard={handleDiscard}
																		setIntroductoryLessonRequired={setIntroductoryLessonRequired}/>
				<AllowJoinPassedMeetings handleDiscard={handleDiscard}
																 handlePublish={handlePublish}
																 allowJoinPassedMeetings={allowJoinPassedMeetings}
																 setAllowJoinPassedMeeting={setAllowJoinPassedMeetings}
																 setIsEditing={setIsEditing}
																 isEditing={isEditing}/>
				<StartUserBalance isEditing={isEditing}
													handlePublish={handlePublish}
													handleDiscard={handleDiscard}
													setIsEditing={setIsEditing}
													setStartUserBalance={setStartUserBalance}
													startUserBalance={startUserBalance}/>
				<SurnameCreation isEditing={isEditing}
												 handlePublish={handlePublish}
												 handleDiscard={handleDiscard}
												 setIsEditing={setIsEditing}
												 setSurnameCreation={setSurnameCreation}
												 surnameCreation={surnameCreation}/>
				<SupportLink isEditing={isEditing}
										 handlePublish={handlePublish}
										 handleDiscard={handleDiscard}
										 setIsEditing={setIsEditing}
										 setSupportLink={setSupportLink}
										 supportLink={supportLink}/>
				<RtServers rTServers={rTServers}/>
				<WayForPayDomain wayForPatDomain={wayForPatDomain}/>
				<WayForPayMerchant wayForPatMerchant={wayForPatMerchant}/>
				{showPopup && (
					<ConfigPopUp handleConfirmation={handleConfirmation} handleDiscard={handleDiscard}/>
				)}
			</ConfigurationWrapper>
		</Row>
	);
};

export default Configuration;
