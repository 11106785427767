import React from 'react';
import {SearchWrapperCourse} from "./StyledCourse";

import SearchItemCourse from "./SearchItemCourse";

function SearchCollectionCourse({items, onCourseSelected}) {

	return (
		<SearchWrapperCourse>
			{items &&
				items.map( item =>
					<SearchItemCourse
						onCourseSelected={onCourseSelected}
						key={item.course_id}
						item={item}
					/>
				)
			}
		</SearchWrapperCourse>
	);
}

export default SearchCollectionCourse;