import React from "react";
import styled from "styled-components";

export const Slot = styled.div`
  width: 100%;
  position: absolute;
  background-color: #2F80ED;
  height: ${props => props.h}px;
  top: ${props => `${props.top}`}px;
	border-radius: 6px;
  z-index: 240;
	color: #ffffff;
	padding: 6px 10px;
	& div {
    font-size: 14px;
    font-weight: 500;
  }
`;
export const SlotTimeOff = styled(Slot)`
  background-color: #F2994A;
`;
export const SlotInfoWrapper = styled.ul`
	& li{
		display: flex;
		justify-content: left;
		align-items: center;
		gap: 10px;
		margin-bottom: 20px;
		color: #554D66;
    font-size: 14px;
    font-weight: 500;
    & .avatar{
			border-radius: 50%;
		}
	}
`;
export const PEntertainer = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #554D66;
`;

export const WrapperContentPopUp = styled.div`
	
`;