import React, {useEffect} from "react";

import useMoment from "./components/hooks/useMoment";
import {useDispatch, useSelector} from "react-redux";
// import {getData} from "./store/actions/dashboard-action";
// import {getDataSchedule} from "./store/actions/schedule-action";

export const UploadTimer = () => {
	const {token} = useSelector(state => state.token);
	const {setToday, today, startDay, timeStampEnd, timeStampStart} = useMoment();
	const dispatch = useDispatch();
	useEffect(() => {
		// const timerId = setInterval(async () => {

			// todo
			// check isAuth user then fetch data, else doesn't

			// dispatch(getData({timeStampStart, timeStampEnd, token}));
			// dispatch(getDataSchedule({timeStampStart, timeStampEnd, token}));
		// }, 30000)
	}, []);

	return (
		<>

		</>
	)
}