import {createSlice} from '@reduxjs/toolkit';
import {searchKidMeetings} from "../actions/search-meetings-kid-action";
// import {searchEntertainerMeetings} from "../actions/search-meetings-entertainer-action";


const meetingSearchSliceKid = createSlice({
	name: "slotsKid",
	initialState: {
		eventSearchListKid: [],
		// statusCode: null,
		// status: null,
		// error: null,
		// updateEvent: null,
	},
	reducers: {
		parentCallsFilter(state, action){
			state.eventSearchListKid = action.payload;
		},
			clearEventSearchListKid: (state, action) => {
				state.eventSearchListKid = [];
			},
	},
	// extraReducers: {
	// 	//? get meetings
	// 	[searchKidMeetings.pending]: (state) => {
	// 		state.status = 'loading';
	// 		state.error = null;
	// 		state.statusCode = null;
	// 	},
	// 	[searchKidMeetings.fulfilled]: (state, action) => {
	// 		state.status = 'resolved';
	// 		state.eventSearchListKid = action.payload.slots;
	// 		state.error = null;
	// 	},
	// 	[searchKidMeetings.rejected]: (state, action) => {
	// 		state.status = 'rejected';
	// 		state.error = action.payload;
	// 		state.statusCode = null;
	// 	},
	//
	// }

})
export const {clearEventSearchListKid, parentCallsFilter} = meetingSearchSliceKid.actions;
export default meetingSearchSliceKid.reducer;