import React from 'react';
import moment from "moment";

import {isCurrentDay, isSelectedMonth} from "../../../helpers";

import {
	END_OF_WEEKEND,
	ONE_NUMBER,
	START_OF_WEEKEND
} from "../../../helpers/consts";

import {
	CellWrappers,
	CurrentDay,
	DayWrapper,
	GridWrappersHeader,
	RowInCells,
	WrapperWeek
} from "../calendar-grid/styled-components/CalendarSecondHeaderStyled";

function GridHeader({daysArray, today}) {

	return (
		<GridWrappersHeader isHeader>
			{
				daysArray.map((dayItem, i) => (
					<CellWrappers key={dayItem.unix()}
												isSelectedMonth={isSelectedMonth(dayItem, today)}
												isWeekend={dayItem.day() === START_OF_WEEKEND || dayItem.day() === END_OF_WEEKEND}>
						<RowInCells
							isSelectedMonth={isSelectedMonth(dayItem, today)}>
							<WrapperWeek>{moment().day(i + ONE_NUMBER).format('ddd')}</WrapperWeek>
							<div>
								<DayWrapper>
									{
										isCurrentDay(dayItem) ? (
											<CurrentDay>{dayItem.format('D')}</CurrentDay>
										) : (
											dayItem.format('D')
										)
									}
								</DayWrapper>
							</div>
						</RowInCells>
					</CellWrappers>
				))
			}
		</GridWrappersHeader>
	);

}

export default GridHeader;