import React from 'react';
import {Button, Popup, PopupContainer, PopupText, PopupTitle} from "./StyledConfig";

const ConfigPopUp = ({handleConfirmation, handleDiscard}) => {
	return (
		<PopupContainer>
			<Popup>
				<PopupTitle>Are you sure?</PopupTitle>
				<PopupText>All changes will be applied immediately in the application.</PopupText>
				<Button className="primary" onClick={() => handleConfirmation()}>
					Yes, publish
				</Button>
				<Button className="discard" onClick={() => handleDiscard()}>
					No, discard
				</Button>
			</Popup>
		</PopupContainer>
	);
};

export default ConfigPopUp;