import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import MonetizationItem from "./MonetizationItem";
import {fetchApiGet} from "../../../utils/axios";
import {ADMIN_PACKAGES} from "../../../utils/constants_urls";
import useToken from "../../hooks/useToken";

import {
	ButtonAdd,
	ContentWrapperMonetization,
	HeaderButtonsWrapper, WrapperLi,
	WrapperUl, WrapperUlHeader
} from "./styled-monetization/StyledMonetization";

import plus from "./img/Add.png";



function Monetization(props) {

	const {token} = useToken();
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const getAllPackage = async () => {
		const url = ADMIN_PACKAGES;
		const response = await fetchApiGet({url, token});
		setItems(response);
		setLoading(false);
	}

	useEffect(() => {
		getAllPackage();
	},[]);

	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Monetization Management Section</h2>
			<ContentWrapperMonetization>
				<HeaderButtonsWrapper>
					<div></div>
					<Link to="/monetization-create-obg">
						<ButtonAdd>Add <img src={plus}/></ButtonAdd>
					</Link>
				</HeaderButtonsWrapper>
				<WrapperUl>
					<WrapperLi>
						<WrapperUlHeader>
							<li>Object Name</li>
							<li>State</li>
							<li>Amount of calls</li>
							<li>Price (UAH)</li>
							<li>Price (USD)</li>
							<li style={{width: '50px'}}></li>
						</WrapperUlHeader>
					</WrapperLi>
					{loading ? (
						<ClipLoader color={"#C014E3"} loading={loading} size={300}/>
					) : (
						<>
							{
								items &&
								items.map(item =>(
									<MonetizationItem key={item.package_id} item={item}/>
								))
							}
						</>
					)}
				</WrapperUl>
			</ContentWrapperMonetization>
		</Row>
	);
}

export default Monetization;