import React from 'react';
import {useDispatch} from "react-redux";
import {Button} from "react-bootstrap";

import {getLogout} from "../../../store/actions/admin-actions";
import {logOut} from "../../../store/slicers/admin-slice";

import useCode from "../../hooks/useCode";

import {InputStyle} from "./StyledPin";

export default function PinCodeInput({apiSendCodeVerify, minutes, seconds, setToSendCode,setIsCountingTimer, apiSendCodeResendCall, apiBeginPhoneAuth, setLoading}) {

	const dispatch = useDispatch();
	const code = useCode();
	const codes = code.codes;
	const startFocus = code.startFocus;
	const handlePrevious = code.handlePrevious;
	const handleChangeInput = code.handleChangeInput;
	const nextDisabled = code.nextDisabled;

	const handleCodeVerify = () => {
		apiSendCodeVerify(codes.join(''));
	}

	const handlerLogOutBack = () =>{
		dispatch(getLogout());
		dispatch(logOut());
		setToSendCode(null);
		setLoading(false);
	}

	return (
		<>
		<div>
				<h3 className="h3-auth h3-code">Please enter code</h3>
			</div>
			<div>
				<InputStyle onKeyDown={handlePrevious}
										type='tel'
										id='1'
										ref={startFocus}
										name="codeFirst"
										value={codes[0]}
										onChange={handleChangeInput}
										maxLength={1}
										placeholder="_"/>
				<InputStyle onKeyDown={handlePrevious}
										type='tel'
										id='2'
										name="codeSecond"
										value={codes[1]}
										onChange={handleChangeInput}
										maxLength={1}
										placeholder="_"/>
				<InputStyle onKeyDown={handlePrevious}
										type='tel'
										id='3'
										name="codeThird"
										value={codes[2]}
										onChange={handleChangeInput}
										maxLength={1}
										placeholder="_"/>
				<InputStyle onKeyDown={handlePrevious}
										type='tel'
										id='4'
										name="codeFourth"
										value={codes[3]}
										onChange={handleChangeInput}
										maxLength={1}
										placeholder="_"/>
				<InputStyle onKeyDown={handlePrevious}
										type='tel'
										id='5'
										name="codeFive"
										value={codes[4]}
										onChange={handleChangeInput}
										maxLength={1}
										placeholder="_"/>
				<InputStyle onKeyDown={handlePrevious}
										type='tel'
										id='6'
										name="codeSix"
										value={codes[5]}
										onChange={handleChangeInput}
										maxLength={1}
										placeholder="_"/>
			</div>
			<p className="p-sms-info">The SMS sent to the given Phone number</p>
			{seconds == 0 && minutes == 0 ? (
				<div className="resend">
					<p onClick={() => {
						apiBeginPhoneAuth()
						setIsCountingTimer(false)
					}} className="resending-sms">Resending SMS</p>
					<p onClick={() => {
						apiSendCodeResendCall()
						setIsCountingTimer(false)
					}} className="resending-sms">SMS not coming call me</p>
				</div>
			) : (
				<p className="resending-sms-timer">Resending SMS is available after {minutes} min {seconds} sec</p>)}
			<div></div>
			<div className="code-input-div">
				<Button onClick={handlerLogOutBack} className="button code-input-button">Back</Button>
				<Button onClick={() => handleCodeVerify()}
								className="button code-input-button"
								id="codeDisableBtn"
								disabled={nextDisabled}>Log in</Button>
			</div>
		</>

	);
}
