import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import useToken from "../../hooks/useToken";
import UsersChildrenItem from "./UsersChildrenItem";
import {ONE} from "../../../helpers/consts";
import {ADMIN_USERS_DEPENDENTS, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";

import {ImgHWrapper, UsersChildrenCollection, UsersChildrenWrapper} from "./UserModalStyled";

import childrenIm from "./img/Children.png";

const UserModalInfoChildren = ({checked, handlerDetailsOpen}) => {
	const {editRoleItem} = useSelector(state => state.editRole);
	const [children, setChildren] = useState([]);


	const {token} = useToken();
	const getUsersChildren = async () =>{
		const url = route(ADMIN_USERS_DEPENDENTS, [':kidId', editRoleItem.id]);
		const response = await fetchApiGet({url, token});
		setChildren(response.entities)
	}

	useEffect(()=>{
		if (editRoleItem){
			getUsersChildren();
		}
	},[editRoleItem]);

	return (
		<>

			{checked === ONE && children && children.length ? (
				<UsersChildrenWrapper>
					<div style={{}}></div>
					<ImgHWrapper>
						<div>
							<img src={childrenIm}/>
						</div>
						<span>Children - {children.length}</span>
					</ImgHWrapper>
					<UsersChildrenCollection>
						{
							children.map( item => (
								<UsersChildrenItem item={item}
																	 handlerDetailsOpen={handlerDetailsOpen}
																	 key={item.dependent_id}/>
							))
						}
					</UsersChildrenCollection>
				</UsersChildrenWrapper>
			):null}
		</>
	);
}

export default UserModalInfoChildren;