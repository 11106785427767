import React from 'react';
import {useDispatch} from "react-redux";

import {entertainerNameForClear, parentNameForClear} from "../../store/slicers/name-slice";
import {closeIsOpenMeetingFilter} from "../../store/slicers/slice-modal";


export default function useFunc(){

	const dispatch = useDispatch();

	const onHide = () => {
		dispatch(parentNameForClear());
		dispatch(entertainerNameForClear());
		dispatch(closeIsOpenMeetingFilter());
	}

	return {

		onHide

	}

}
