import {createSlice} from '@reduxjs/toolkit';
import {getProfile, getLogout} from "../actions/admin-actions"

const setLoading = (state, action) => {
	state.status = 'loading';
	state.error = null;
	state.statusCode = null;
}
const setError = (state, action) => {
	state.status = 'rejected';
	state.error = action.payload;
	state.statusCode = null;
}


const adminSlice = createSlice({
	name: "admin",
	initialState: {
		admin: {},
		statusCode: null,
		statusCodeLogout: null,
		status: null,
		error: null,
	},
	reducers: {
		logOut(state) {
			localStorage.removeItem("token");
			// todo clear token from Redux
			state.admin = {};
			state.status = null;
			state.error = null;
		},

	},
	extraReducers: {

		//? get profile
		[getProfile.pending]: setLoading,
		[getProfile.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.admin = action.payload;
			state.statusCode = action.payload.statusCode
		},
		[getProfile.rejected]: setError,

		//? logout
		[getLogout.pending]: (state) => {
			state.status = 'loading';
			state.error = null;
			state.statusCodeLogout = null;
		},
		[getLogout.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.statusCodeLogout = action.payload.statusCode;
			state.error = null;
			if(action.payload.statusCode === 200){
				localStorage.removeItem('token')

			}
		},
		[getLogout.rejected]: (state, action) => {
			state.status = 'rejected';
			state.error = action.payload;
			state.statusCodeLogout = null;
		},
	}

})

export const {logOut} = adminSlice.actions;

export default adminSlice.reducer;