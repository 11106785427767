import React from 'react';
import {useSelector} from "react-redux";

import {
	CourseCreateWrapper,
	CreateLessonsWrapper,
	DescriptionWrapperCreateCourse, FileWrapper,
	GroupName,
	LanguageSpan
} from "../StyledCourse";

const OneLesson = ({lang, setName, setDescription, name, description, setFile, file}) => {

	const {courseItem} = useSelector(state => state.course);
	const onChange = (el) => {
		setFile(el.target.files[0]);
	}

	return (
		<CreateLessonsWrapper>
			<LanguageSpan>{lang}</LanguageSpan>
			<CourseCreateWrapper>
				<label>
					<div>* <span>Lesson name</span> required</div>
					<input type="text"
								 placeholder="Enter text"
								 value={name}
								 onChange={(el) => setName(el.target.value)}/>
				</label>
				<label>
					<div><span>Course name</span></div>
					<GroupName>{courseItem.name.uk ? courseItem.name.uk : courseItem.name.en || courseItem.name.ru}</GroupName>
				</label>
				<FileWrapper>
						<label>
							<input name="file" type="file" id="input__file" className="input input__file" onChange={ (el) => onChange(el)}/>
							{file ? (
								<span className="input__file-button-text">{file.name}</span>
							) : <span className="input__file-button-text">Script.json</span>}
						</label>
				</FileWrapper>
			</CourseCreateWrapper>
			<DescriptionWrapperCreateCourse>
				<label>
					<div>* <span>Lesson description</span>required</div>
					<textarea placeholder="Enter text"
										value={description}
										onChange={(el) => setDescription(el.target.value)}></textarea>
				</label>
			</DescriptionWrapperCreateCourse>
		</CreateLessonsWrapper>
	);
};

export default OneLesson;