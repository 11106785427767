import React, {useEffect, useState} from 'react';
import moment from "moment";
import { useDispatch } from "react-redux";
import useToken from "../hooks/useToken";
import useMoment from "../hooks/useMoment";

import { CalendarGrid } from "./calendar-grid/CalendarGrid";

import { ShadowWrapper } from './styled-calendar/CalendarTStyled';
import { getData } from "../../store/actions/dashboard-action";


const Calendar = () => {

	window.moment = moment;

	const dispatch = useDispatch();

	const {setToday, today, startDay, timeStampEnd, timeStampStart, prevSetDayHandler, nextSetDayHandler} = useMoment();
	const {token} = useToken();

	let timerId= undefined;

	// useEffect(()=>{
	// 	dispatch(getData({timeStampStart, timeStampEnd, token}));
	// },[today]);

	// useEffect(() => {
	// 	if(timerId !== undefined){
	// 		clearInterval(timerId);
	// 	}
	// 	timerId = setInterval(async () => {
	// 		dispatch(getData({timeStampStart, timeStampEnd, token}));
	// 	}, 30000);
	// 	return()=>{
	// 		if(timerId !== undefined){
	// 			clearInterval(timerId);
	// 			timerId = undefined
	// 		}
	// 	}
	// },[today]);

	const getPrevSetDayHandler = () => {
		prevSetDayHandler();
		dispatch(getData({timeStampStart, timeStampEnd, token}));
	}
	const getNextSetDayHandler = () => {
		nextSetDayHandler();
		dispatch(getData({timeStampStart, timeStampEnd, token}));
	}

	return (
		<ShadowWrapper>
			<CalendarGrid
				setToday={setToday}
				startDay={startDay}
				today={today}
				prevSetDayHandler={getPrevSetDayHandler}
				nextSetDayHandler={getNextSetDayHandler}
				timeStampStart={timeStampStart}
				timeStampEnd={timeStampEnd}/>
		</ShadowWrapper>
	);
}

export default Calendar;