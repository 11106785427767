import React from "react";
import styled from "styled-components";

export const MainWrapperGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
	max-height: calc(100vh - 210px);
	&::-webkit-scrollbar{
    width: 0px;
	}
`;

export const GridWrappers = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(60px, 1fr));
  grid-column-gap: 10px;
  width: 100%;
`;

export const TimeFoSlots = styled.li`
	color: #888888;
	font-size: 10px;
	font-weight: 300;
	display: flex;
	align-items: center;
	padding: 0 10px;
  height: 42px;
`;
export const RedLine = styled.div`
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #FF3838;
    z-index: 9;
    top: ${props => props.position}%;
  .circle-wrapper{
    position: relative;
    .circle-left{
      width: 10px;
      height: 10px;
      background-color: #FF3838;
      border-radius: 50%;
      position: absolute;
      top: -4px;
    }
    .circle-right{
      width: 10px;
      height: 10px;
      background-color: #FF3838;
      border-radius: 50%;
      position: absolute;
      top: -4px;
      right: 0;
    }
  }

`;