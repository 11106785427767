import React from 'react';
import CourseItem from "./CourseItem";

function CourseCollection({courses, group}) {

	return (
		<>
			{
				courses.length ? (
					<>
						{
							courses
								.filter(course => course.group_id === group?.group_id)
								.map(course => (
									<CourseItem key={course.course_id} course={course}/>
								))}
					</>
				) : null
			}

		</>
	);
}

export default CourseCollection;