import {createAsyncThunk} from '@reduxjs/toolkit';


export const getAllAccounts  = createAsyncThunk(
	"accounts/getAllAccounts",
	async function (currentPage, {rejectWithValue}) {
		try {

			let myHeaders = new Headers();
			myHeaders.append("Authorization", localStorage.getItem("token"));
			let requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			const res = await fetch(process.env.REACT_APP_API_URL + "/admin/users?page=" + currentPage, requestOptions)
			const data = await res.json();
			const arr = [...data.entities]
			arr.forEach(item => {
				if(item.first_name === undefined || !item.first_name){
					item.first_name = ""
				}
			})
			return arr.sort((x,y) => {
				if(x.first_name && y.first_name){
					return x.first_name.localeCompare(y.first_name)
				} else {
					return -1
				}
			});
		} catch (error) {
			console.log(error)
			return rejectWithValue(error.message);
		}

	}

);
// export const getAllAccounts  = createAsyncThunk(
// 	"accounts/getAllAccounts",
// 	async function (_, {rejectWithValue}) {
// 		try {
//
// 			let myHeaders = new Headers();
// 			myHeaders.append("Authorization", localStorage.getItem("token"));
// 			let requestOptions = {
// 				method: 'GET',
// 				headers: myHeaders,
// 				redirect: 'follow'
// 			};
// 			const res = await fetch(process.env.REACT_APP_API_URL + "/admin/users/", requestOptions)
// 			const data = await res.json();
// 			const arr = [...data.entities]
// 			arr.forEach(item => {
// 				if(item.first_name === undefined || !item.first_name){
// 					item.first_name = ""
// 				}
// 			})
// 			return arr.sort((x,y) => {
// 				if(x.first_name && y.first_name){
// 					return x.first_name.localeCompare(y.first_name)
// 				} else {
// 					return -1
// 				}
// 			});
// 		} catch (error) {
// 			console.log(error)
// 			return rejectWithValue(error.message);
// 		}
//
// 	}
//
// );
