import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Col, Row} from "react-bootstrap";

import {getProfile} from "../../../store/actions/admin-actions";
import NewDate from "../newDate/NewDate";
import {logOut} from "../../../store/slicers/admin-slice";
import {tokenClear} from "../../../store/slicers/token-slice";

import './navbar.sass';

import rightArr from "./img/arrowsymbol.png";
import heart from "../navBar/img/heart.png";
const NavPanel = () => {

	const dispatch = useDispatch();
	const {admin} = useSelector(state => state.admin);

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getProfile());
	}, []);

	const handlerLogOut = () => {
		dispatch(logOut());
		dispatch(tokenClear())
		navigate("/login");
	}

	useEffect(() => {
		if (admin?.Message === 'User is not authorized to access this resource with an explicit deny') {
			handlerLogOut()
		}
	}, []);

	return (
		<>
			<Row className="row-main-panel">
				<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="navbar-col">
					<NewDate/>
					<hr className="navbar-hr"/>
					<div className="navbar-div-user-name">
						<a href="#">{admin?.first_name && admin.first_name} {admin?.last_name && admin.last_name}
							<img className="navbar-div-user-img-arr"
									 src={rightArr}
									 alt="arrow-to-right"/>
						</a>
					</div>
					<hr className="navbar-hr"/>
					<nav>
						<p className="navbar-p-main">main</p>
						<ul className="navbar-ul">
							<li className="navbar-ul-li">
								<Link to="/dashboard">Dashboard</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/users">Accounts</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/report">Report</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/characters">Characters</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/create-slot">Schedule</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/monetization">Monetization</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/course">Course</Link>
							</li>
							<li className="navbar-ul-li">
								<Link to="/config">Configuration</Link>
							</li>
							<li className="navbar-ul-li" onClick={handlerLogOut}>
								<Link to="/login">log out</Link>
							</li>
						</ul>
					</nav>
					<p className="navbar-p-copy">© 2023, made with <img className="heart" src={heart}/> by MaPanda</p>
				</Col>
			</Row>

		</>
	);
}

export default NavPanel;