import React from "react";
import styled from "styled-components";

export const ShadowWrapper = styled.div`
	border-radius: 8px;
	background-color: #F8F9FA;
	padding-top: 130px;
	padding-right: 10px;
	position: relative;
`;


