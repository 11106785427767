import React from 'react';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import SearchFilterCollection from "../../search-meetings/search-filter-collection/SearchFilterCollection";
import {
	entertainerNameSlotsCreateClear,
	entertainerNameSlotsCreateSave
} from "../../../store/slicers/name-slice";
import {ADMIN_USERS} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";

import {
	InputEntertainer,
	LabelSearchFilter, SearchDiv, UlSelectedEntertainer
} from "../../search-meetings/search-meeting-styled/GetEntertainerMeetingStyled";

import clear from "../../search-meetings/close.png";

function SearchEntertainerSlots({today, inputEl}) {

	const {entertainerNameSlotsCreate} = useSelector(state => state.name);
	const {token} = useToken();
	const [entertainerItems, setEntertainerItems] = useState([]);
	const [entertainerNameSlots, setEntertainerNameSlots] = useState(entertainerNameSlotsCreate);

	const [elChange, setElChange] = useState('');

	const dispatch = useDispatch();
		const handleChangeEntertainerSearch = async (el) => {

		let searchPhoneK = el.target.value;
		setElChange(searchPhoneK);

		const url = `${ADMIN_USERS}?search=${searchPhoneK}`
		const response = await fetchApiGet({url, token});
		setEntertainerItems(response.entities.filter(item => item.role === 2));
	}

	useEffect(() =>{
		if(entertainerNameSlots !== null) {
			let searchPhone = entertainerNameSlots.phone;
			dispatch(entertainerNameSlotsCreateSave(entertainerNameSlots));
		}
	}, [entertainerNameSlots, today])

	const onEntertainerSelect = (entertainer) => {
		setEntertainerNameSlots(entertainer);
	}

	const handlerClear = () => {
		setEntertainerNameSlots(null);
		setEntertainerItems([]);
		dispatch(entertainerNameSlotsCreateClear())
	}

	return (
		<div>
			{!entertainerNameSlotsCreate ? (
				<>
					<LabelSearchFilter>
						<InputEntertainer type="text"
															ref={inputEl}
															onChange={handleChangeEntertainerSearch}
															placeholder="Entertainer"
															maxLength='16'/>
					</LabelSearchFilter>
					<>
						{elChange.length === 0 ? (
							<></>
						) : (
							<SearchDiv>
								<SearchFilterCollection onPersonSelect={onEntertainerSelect}
																				items={entertainerItems}
																				setEntertainer={setEntertainerNameSlots}/>
							</SearchDiv>
						)}
					</>
				</>
			) : (
				<UlSelectedEntertainer onClick={handlerClear}>
					<li>
						<div>{entertainerNameSlotsCreate.first_name} {entertainerNameSlotsCreate.last_name}</div>
						<div className="img-clear">
							<img src={clear} alt="close"/>
						</div>
					</li>
				</UlSelectedEntertainer>
			)}

		</div>
	);
}

export default SearchEntertainerSlots;