import styled from "styled-components";


export const UserModalWrapper = styled.div`
	position: fixed;
	z-index: 250;
	height: 100vh;
	width: 100%;
	display: flex;
  padding-left: calc(45% - 333px);
	align-items: center;
`;

export const UserWrapperContent = styled.div`
  background-color: #F8F9FA;
  min-height: 333px;
  width: 585px;
  border: 1px solid #C6C2CF;
  border-radius: 8px;
	position: relative;
	padding: 17px;
`;
export const UserRoleInfoWrapper = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
  width: 100%;
	margin-bottom: 15px;
`;
export const UserRoleInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 28px 9px 16px;
  width: 167px;
  height: 40px;
  background: #F2F4F5;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 6px;
`;
export const UserInfoGridWrapper = styled.div`
	display: grid;
  grid-template-columns: 45% 55%;
	row-gap: 15px;
`;

export const ImgHWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	& span {
    font-size: 14px;
    font-weight: 600;
    color: #554D66;
		margin-top: 2px;
  }
`;

export const UserInfoGridItem = styled.div`
	& .span-info-user{
    font-size: 12px;
    font-weight: 400;
		padding-left: 35px;
  }
	& .edit-user-input{
    box-sizing: border-box;
    padding: 5px;
    margin-left: 35px;
    margin-right: 10px;
    border: 1px solid hsl(0deg 0% 80%);
    color: gray;
    height: 40px;
    min-width: 166px;
    border-radius: 5px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}

  & textarea.edit-user-textarea{
    border: 1px solid hsl(0deg 0% 80%);
    color: gray;
    resize: none;
    width: 493px;
    border-radius: 4px;
    padding: 12px 0 0 10px;
    height: 50px;
    box-sizing: border-box;
    margin: 8px 10px 12px 35px;
    &:focus{
      outline: none;
    }
  }
`;

export const DivInfoDescription = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 28px 9px 16px;
  width: 493px;
  height: 40px;
  background: #F2F4F5;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 6px;
  margin-left: 32px;
	margin-top: 8px;
	margin-bottom: 21px;
`;

export const ButtonEditUserWrapper = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
	justify-content: right;
	padding-right: 20px;
`;
export const ButtonEditUserGo = styled.button`
  height: 40px;
  width: 150px;
  background-color: #1485ED;
  color: #ffffff;
  border-radius: 6px;
	border: #1485ED;
	
`;

export const ButtonCancelEditUserGo = styled.button`
  height: 40px;
  width: 146px;
  border-radius: 6px;
  border: 2px solid #1485ED;
	color: #1485ED;
	background-color: #ffffff;
`;

export const UsersChildrenWrapper = styled.div`
	
`;

export const UsersChildrenCollection = styled.div`
`;

export const UsersChildrenWrapperItem = styled.div`
  height: 59px;
  width: 92%;
  border-radius: 8px;
  border: 1px solid #C6C2CF;
  box-sizing: border-box;
  background-color: #F8F9FA;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	margin-left: 26px;
	margin-top: 8px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	padding-left: 12px;
	&:hover > button{
		opacity: 1;
	}
`;

export const DetailBtn = styled.button`
  height: 30px;
  width: 123px;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #2F80ED;
  border: 2px solid #2F80ED;
	background-color: #ffffff;
	margin-left: 20px;
	opacity: 0;
`;

export const ChildInfoWrapper = styled.div`
	position: fixed;
  z-index: 350;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(000, 000, 000, 0.3);
	padding-left: 27.5%;
`;

export const ChildInfoContentWrapper = styled.div`
  max-height: 671px;
  width: 596px;
  border-radius: 8px;
  border: 1px solid #C6C2CF;
  box-sizing: border-box;
  background: #F8F9FA;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	padding: 17px;
`;

export const QuestionsAnswers = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-height: 532px;
  background: #F8F9FA;
  border: 1px solid #C6C2CF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
	overflow: auto;
	padding: 16px;
	margin-top: 14px;
	margin-bottom: 14px;
`;

export const BackChildInfoBtn = styled.div`
  box-sizing: border-box;
  width: 146px;
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #1485ED;
  border-radius: 6px;
	float: right;
	color: #1485ED;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const QuestionWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #554D66;
	padding-left: 38px;
	padding-right: 38px;
	margin-top: 15px;
`;
export const AnswersWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #554D66;
`;

export const SpanNoAnswer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #EB5757;
	margin-left: 35px;
	margin-top: 10px;
`;

export const ContentWrapperUserBalance = styled.div`
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #F8F9FA;
  border: 1px solid #C6C2CF;
  border-radius: 8px;
  width: 368px;
  height: 258px;
  padding: 15px;
	& h4{
    font-weight: 700;
    font-size: 16px;
    color: #554D66;
		padding: 10px;
		margin-bottom: 18px;
  }
  & .user-balance-p{
    padding: 0;
    margin: 0;
		text-align: center;
    font-size: 14px;
    color: #554D66;
		& span {
			color: #27AE60;
      font-weight: 400;
		}
  }
  & .first{
    font-weight: 700;
		margin-bottom: 22px;
  }
	& .user-balance-div-input{
		display: flex;
		justify-content: center;
		padding-top: 10px;
	}

	& input{
		width: 147px;
		border-radius: 6px;
		font-size: 12px;
		height: 38px;
		border: 1px solid #DBE2EA;
		text-align: center;
    color: #BDBDBD;
	}
	& input:focus{
		outline: none;
	}
  & .buttons {
    display: flex;
		width: calc(100% - 30px);
		justify-content: space-between;
		position: absolute;
		bottom: 15px;
    & button {
      border: none;
      border-radius: 6px;
      padding: 5px 10px;
      width: 164px;
    }
    & button:first-child {
      color: #27AE60;
      background-color: #ffffff;
      border: 2px solid #27AE60;
     
    }
		& button:last-child {
       color: #ffffff;
       background-color: #EB5757;
     }
	
  }
`;

export const ContentWrapperUserBalanceConfirm = styled.div`
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #F8F9FA;
  border: 1px solid #C6C2CF;
  border-radius: 8px;
  width: 368px;
  height: 211px;
  padding: 15px;
  & h4{
    font-weight: 700;
    font-size: 16px;
    color: #554D66;
    padding: 10px;
    margin-bottom: 18px;
  }
  & .user-balance-p{
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #554D66;
    & span {
      color: #27AE60;
      font-weight: 400;
    }
    & .bold{
      font-weight: 700;
      color: #554D66;
    }
  }
  & .first{
    font-weight: 700;
    margin-bottom: 22px;
  }
  & .buttons {
    display: flex;
    width: calc(100% - 30px);
    justify-content: space-between;
    position: absolute;
    bottom: 15px;
    & button {
      border: none;
      border-radius: 6px;
      padding: 5px 10px;
      width: 164px;
    }
    & button:first-child {
      color: #1485ED;
      background-color: #ffffff;
      border: 2px solid #1485ED;

    }
    & button:last-child {
      color: #ffffff;
      background-color: #1485ED;
    }

  }
	
`;

export const PageWrapper = styled.div`
	display: flex;
	position: absolute;
	width: calc(100% - 200px);
	bottom: 20px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: #2C2738;
	& button{
		border: #0b5ed7;
		padding: 5px 10px;
		border-radius: 5px;
		color: white;
		background-color: #9c8ede;
	}
`;