import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {lessonItemSave} from "../../../../store/slicers/course-slice";

import {
	CourseNameLi, LessonOrderWrapper,
	UlSearchCourseItem
} from "../../styled-create-meeting/search-styled/SearchStyled";

const SearchItemLessons = ({item, closeLessons, index}) => {

	const {courseItemCreateCall} = useSelector(state => state.course);
	const dispatch = useDispatch()

	const selectLessonHandler = (item) => {
	  dispatch(lessonItemSave(item));
		closeLessons();
	}

	return (
		<UlSearchCourseItem onClick={()=> selectLessonHandler(item)}
			className="ul-search-item">
			<CourseNameLi>	{item.name.uk ? item.name.uk : item.name.en || item.name.ru}
			</CourseNameLi>
			<LessonOrderWrapper>{index + 1}/{courseItemCreateCall.lessons.length}</LessonOrderWrapper>
		</UlSearchCourseItem>
	);
};

export default SearchItemLessons;