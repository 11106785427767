import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  closeOpenMeetingOptions,
  setCurrentPage,
} from "../../store/slicers/slice-modal";
import { showMeetingByIdFalse } from "../../store/slicers/calls-slice";

import ShowMeeting from "./ShowMeeting";
import useOpen from "../hooks/useOpen";
import SearchOptions from "./search-options/SearchOptions";
import {
  ADMIN_USER_BY_ID,
  ADMIN_USERS_DEPENDENTS,
  route,
} from "../../utils/constants_urls";
import { fetchApiGet } from "../../utils/axios";
import useToken from "../hooks/useToken";
import { userNameClear } from "../../store/slicers/name-slice";

import { CloseBtn } from "../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {
	ChoiceCall,
	ChoiceUsers,
	ModalContentOptionsMeetings,
	ModalContentOptionsWrapper, PaginationWrapper,
} from "./styled-meeting-options/StyledOptions";
import { WrapperForModalMeeting } from "../create-meeting/styled-create-meeting/CreateMeetingStyled";

import close from "../create-meeting/img/close.png";

const MeetingOptions = ({ setShowAlert, timeStampEnd, timeStampStart }) => {
  const { modalPropsMeetingsByPages, modalCurrentPage } = useSelector(
    (state) => state.modal
  );
  const { modalProps } = useSelector((state) => state.modal);
  const { admin } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { handleClickOpenModal } = useOpen();
  const { slotId } = useSelector((state) => state.create);

  const [withoutEntertainer, setWithoutEntertainer] = useState(false);
  const [shownContent, setShownContent] = useState({});

  const { token } = useToken();
  const handleClickWithout = () => {
    setWithoutEntertainer(true);
    document.getElementById("userAll").classList.remove("active");
    document.getElementById("without").classList.add("active");
    dispatch(showMeetingByIdFalse());
    setShownContent({});
  };

  const handleClickAll = () => {
    setWithoutEntertainer(false);
    document.getElementById("userAll").classList.add("active");
    document.getElementById("without").classList.remove("active");
    dispatch(showMeetingByIdFalse());
    setShownContent({});
    getAllUsers();
  };

  useEffect(() => {
    document.getElementById("userAll").classList.add("active");
  }, []);

  const handlerClose = () => {
    dispatch(showMeetingByIdFalse());
    setShownContent({});
    dispatch(closeOpenMeetingOptions());
    dispatch(userNameClear());
  };

  // console.log("modalPropsMeetings",modalPropsMeetings)
  const getAllUsers = () => {
    console.log("modalCurrentPage", modalCurrentPage);
    modalPropsMeetingsByPages[modalCurrentPage].forEach(async (x) => {
      // console.log('Call data')
      {
        const url = route(ADMIN_USER_BY_ID, [":userId", x.parent_id]);
        const response = await fetchApiGet({ url, token });
        localStorage.setItem(`${x.parent_id}`, JSON.stringify(response));
        window.dispatchEvent(new Event("local-storage"));
      }
      {
        const url = route(ADMIN_USER_BY_ID, [":userId", x.id]);
        const response = await fetchApiGet({ url, token });
        localStorage.setItem(`${x.id}`, JSON.stringify(response));
        window.dispatchEvent(new Event("local-storage"));
      }
      {
        const url = route(ADMIN_USERS_DEPENDENTS, [":kidId", x.parent_id]);
        const response = await fetchApiGet({ url, token });
        localStorage.setItem(
          `${x.parent_id}dependents`,
          JSON.stringify(response.entities)
        );
        window.dispatchEvent(new Event("local-storage"));
        const dependent = response.entities.filter(
          (i) => i.dependent_id === x.dependent_id
        );
        localStorage.setItem(`${x.dependent_id}`, JSON.stringify(dependent));
        window.dispatchEvent(new Event("local-storage"));
      }
    });
  };

  function handleNextPage() {
    if (modalCurrentPage < modalPropsMeetingsByPages.length - 1) {
      dispatch(setCurrentPage(modalCurrentPage + 1));
      console.log("modalCurrentPage", modalCurrentPage);
      getAllUsers();
    }
  }

  function handlePrevPage() {
    if (modalCurrentPage > 0) {
      dispatch(setCurrentPage(modalCurrentPage - 1));
      console.log("modalCurrentPage", modalCurrentPage);
      getAllUsers();
    }
  }

  return (
    <WrapperForModalMeeting>
      <ModalContentOptionsWrapper>
        <ModalContentOptionsMeetings>
          <Row>
            <header>
              <ChoiceCall>
                {/*<SearchOptions*/}
                {/*  setShownContent={setShownContent}*/}
                {/*  getAllUsers={getAllUsers}/>*/}
                <div style={{ minHeight: "40px" }}>
                  {admin && admin.role === 3 && (
                    <button
                      className="button new-call active"
                      onClick={() => handleClickOpenModal(modalProps, slotId)}
                    >
                      New call
                    </button>
                  )}
                </div>
                <CloseBtn onClick={handlerClose}>
                  <img src={close} alt={close} />
                </CloseBtn>
              </ChoiceCall>
              <ChoiceUsers>
                <button
                  className="button new-user"
                  id="userAll"
                  onClick={handleClickAll}
                >
                  All
                </button>
                <button
                  className="button entertainer"
                  id="without"
                  onClick={handleClickWithout}
                >
                  Without Entertainer
                </button>
              </ChoiceUsers>
							<PaginationWrapper>
              <button onClick={handlePrevPage}>{"<"}</button>
              	{modalCurrentPage + 1} / {modalPropsMeetingsByPages.length}
              <button onClick={handleNextPage}>{">"}</button>
							</PaginationWrapper>
            </header>
            <ShowMeeting
              withoutEntertainer={withoutEntertainer}
              timeStampStart={timeStampStart}
              getAllUsers={getAllUsers}
              timeStampEnd={timeStampEnd}
              setShowAlert={setShowAlert}
              shownContent={shownContent}
              setShownContent={setShownContent}
            />
          </Row>
        </ModalContentOptionsMeetings>
      </ModalContentOptionsWrapper>
    </WrapperForModalMeeting>
  );
};
export default MeetingOptions;
