import React from 'react';
import {
	UlSearchItemFilter
} from "../../../../search-meetings/search-meeting-styled/search-filter-styled/SearchFilterItemStyled";

function EntertainerItem({item, setEntertainer}) {

	const handleClick = () => {
		setEntertainer(item);
	}

	return (
		<UlSearchItemFilter onClick={handleClick} className="ul-search-item">
			<li> {item.first_name} {item.last_name}</li>
			<li>{item.phone}</li>
		</UlSearchItemFilter>
	);
}

export default EntertainerItem;