import React from "react";
import styled from "styled-components";

export const CellWrapper = styled.div`
`;
export const RowInCell = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
	position: relative;
`;
export const HoursMinutes = styled.li`
  color: #A9A9A9;
  height: 38px;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  background-color: ${props => props.isDayWeekend ? '#F1F2F3' : '#ffffff'};
  text-align: center;
  margin-bottom: 4px;
	padding-top: 5px;
`;
export const EventBox = styled.div`
  background-color: ${props => props.isEventIdSame ? 'red' : '#1485ED'};
  width: 100%;
  height: 38px;
	position: absolute;
	top: -5px;
  left: 0;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;
export const FreeSlotBox = styled.div`
  background-color: ${props => props.isEventIdSame ? 'red' : '#c9caea'};
  width: 100%;
  height: 38px;
	position: absolute;
	top: -5px;
  left: 0;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EventBoxKid = styled.div`
  background-color: #FFBF7D;
  width: 100%;
  height: 38px;
  position: absolute;
  top: -5px;
  left: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
	z-index: 2;
`;
export const EventBoxTogether = styled.div`
  background-color: rgba(31, 243, 42, 0.95);
  width: 100%;
  height: 38px;
  position: absolute;
  top: -5px;
  left: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
	z-index: 3;
	
`;
export const SlotsDay = styled.div`
	position: relative;
`;
export const WithoutEntertainerLength = styled.div`
	font-size: 12px;
	background-color: #FF3838;
	border-radius: 50%;
	padding: 0px 5px;
	position: absolute;
	top: -7px;
	right: -7px;
	z-index: 10;
	display: flex;
	justify-content: center;
	text-align: center;
`;

