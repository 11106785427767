import React, {useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";

import {entertainerSlots} from "../../../../../helpers";

import {
	EventBox, FreeSlotBox,
	SlotsDay,
	WithoutEntertainerLength
} from "../../styled-components/CalendarColumnStyled";





const SlotDayAll = ({stamp, eventsNew, handleAddMeeting, handleClickOpenUpdateModal}) => {

	const {dashboardMeetings} = useSelector(state => state.dashboard);
	const {entertainerName} = useSelector(state => state.name);
	const {parentName} = useSelector(state => state.name);
	const [loading, setLoading] = useState(false);

	const cals = dashboardMeetings.reduce((filteredEvents, event) => {
		if (+moment(event.slot_time.start_time).format('x') === +stamp.format('x')) {
			filteredEvents.push(event);
		}
		return filteredEvents;
	}, []);

	return (
		<SlotsDay>

			{
				(cals.length && !entertainerName && !parentName) ?
				(
					<EventBox onClick={() => handleClickOpenUpdateModal(stamp.format('x'), cals)}>
						{cals.length}
						<WithoutEntertainerLength>
							{cals.filter(event => !event.id ).length === 0 ? null : cals.filter(event => !event.entertainer ).length }
						</WithoutEntertainerLength>
					</EventBox>
				) : (
					entertainerSlots(eventsNew,stamp).length && !entertainerName && !parentName
						? (
							<FreeSlotBox onClick={() => handleAddMeeting(stamp.format('x'))}>+</FreeSlotBox>
						)
						: null
					)
			}
		</SlotsDay>
	);
}

export default SlotDayAll;