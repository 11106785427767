import React, {useEffect, useState} from 'react';
import {LanguageSelect, LanguageSelectWrapper} from "./StyledCourse";
import arrToDownEng from "./img/chevron-down.png";

const CourseSelectLanguage = ({selectedLanguage,arrLanguage, setLangHandler}) => {

	const [isOpenSelectLanguage, setIsOpenSelectLanguage] = useState(false);

	useEffect(()=>{
		if(!selectedLanguage && arrLanguage.length){
			setLangHandler(arrLanguage[0].name)
		}
	},[selectedLanguage, arrLanguage]);
	return (
		<div style={{position: "relative"}}>
			<LanguageSelect onClick={() => setIsOpenSelectLanguage(!isOpenSelectLanguage)}>
				{ selectedLanguage && selectedLanguage.name ? selectedLanguage.name : ''}
				<img src={arrToDownEng}/>
			</LanguageSelect>
			{isOpenSelectLanguage && arrLanguage &&
				<LanguageSelectWrapper>
					{
						arrLanguage.map(({name}) => (
							<li key={name} onClick={() => {
								setLangHandler(name);
								setIsOpenSelectLanguage(false);
							}}>{name}</li>
						))
					}
				</LanguageSelectWrapper>
			}
		</div>
	);
};

export default CourseSelectLanguage;