import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ShowMeetingContent from "./ShowMeetingContent";
import {showMeetingByIdFalse, showMeetingByIdTrue} from "../../store/slicers/calls-slice";
import WithoutEntertainerTrue from "./WithoutEntertainerTrue";
import WithOutEntertainerFalse from "./WithOutEntertainerFalse";

import {WrapperForScrollMeeting} from "./styled-meeting-options/StyledOptions";

const ShowMeeting = (props) => {
	const {
		setShowAlert,
		timeStampEnd,
		timeStampStart,
		withoutEntertainer,
		setShownContent,
		shownContent,
		getAllUsers
	} = props;

	const {showMeetingById} = useSelector(state => state.calls);

	const [meetingShow, setMeetingShow] = useState(null);

	const dispatch = useDispatch();

	const handleClick = (item, id) => {
		setMeetingShow(item);
		dispatch(showMeetingByIdTrue())
		setShownContent(prevShownContent => ({
			...prevShownContent,
			[id]: !prevShownContent[id]
		}));

	};


	useEffect(() => {
		getAllUsers();
	},[])

	useEffect(() => {
		if(Object.keys(shownContent).length === 0){
			dispatch(showMeetingByIdFalse())
		}
	},[shownContent]);

	return (
		<WrapperForScrollMeeting>
			{showMeetingById &&
				<ShowMeetingContent meetingShow={meetingShow}
														timeStampEnd={timeStampEnd}
														timeStampStart={timeStampStart}
														setShowAlert={setShowAlert}
														withoutEntertainer={withoutEntertainer}
														setShownContent={setShownContent}/>
			}

			{withoutEntertainer ? (
				<WithoutEntertainerTrue withoutEntertainer={withoutEntertainer}
																handleClick={handleClick}
																shownContent={shownContent}/>

			) : (
				<WithOutEntertainerFalse handleClick={handleClick}
																 shownContent={shownContent}/>

			)}

		</WrapperForScrollMeeting>
	);
};

export default ShowMeeting;
