import styled from "styled-components";

export const InputStyle = styled.input`
  border: 1px solid #DBE2EA;
  border-radius: 6px;
  color: #7C9CBF;
  width: 40px;
  height: 45px;
  font-size: 14px;
  text-align: center;
  margin: 6px;
`;