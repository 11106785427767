import React, {useEffect, useState} from 'react';

import SearchKids from "../../search/SearchKids";
import ParentNameBlock from "./ParentNameBlock";
import SelectChildren from "./SelectChildren";

import {ParentWrapper, WrapperForImgAnaRequired} from "../../styled-create-meeting/CreateMeetingStyled";

import account from "../../img/account.png";
import useToken from "../../../hooks/useToken";
import {ADMIN_USERS_DEPENDENTS, route} from "../../../../utils/constants_urls";
import {fetchApiGet} from "../../../../utils/axios";

const Parent = ({kid, setKid, setShowChildren, showChildren, child, meetingShow, setChild}) => {


	const {token} = useToken();
	const [newParent, setNewParent] = useState(false)
	const getUserDependents = async () =>{
		const url = route(ADMIN_USERS_DEPENDENTS, [':kidId', kid.id]);
		const response = await fetchApiGet({url, token});
		setChild(response.entities);
		localStorage.setItem(`${kid.id}dependents`, JSON.stringify(response.entities))
		window.dispatchEvent(new Event('local-storage'))
		const dependent = response.entities.filter(i => i.dependent_id === kid.dependent_id)
		localStorage.setItem (`${kid.dependent_id}`, JSON.stringify(dependent));
		window.dispatchEvent(new Event('local-storage'))
	}

	useEffect(()=>{
		if(kid){
			getUserDependents();
		}
	},[kid]);
	return(
		<ParentWrapper>
			<div className="div-img-account-search-kids">
				<WrapperForImgAnaRequired>
					<div className="div-img">
						<img src={account} alt={account}/>
					</div>
					<div className="required-div">
						<span>Parent</span>
						* required
					</div>
				</WrapperForImgAnaRequired>
					{!kid ? (
						<SearchKids setKid={setKid}/>
					) : (
							<ParentNameBlock setKid={setKid}
															 kid={kid}
															 setNewParent={setNewParent}
															 setChild={setChild}
															 setShowChildren={setShowChildren}/>
					)}
			</div>
			{kid ?(
				<SelectChildren child={child}
												newParent={newParent}
												setNewParent={setNewParent}
												meetingShow={meetingShow}
												showChildren={showChildren}
												setShowChildren={setShowChildren}/>
			) : null}
		</ParentWrapper>
	)
}
export default Parent;