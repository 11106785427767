import React from 'react';
import SearchUser from "../../../create-meeting/search/SearchUser";
import EntertainerNameBlock from "../../../create-meeting/modal-meeting/create-modal-pieces/EntertainerNameBlock";

import {DivImgAccountsSearchUserEdit} from "../../styled-meeting-options/StyledShowMeeting";
import {WrapperForImgAnaRequired} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";

import accounts from "../../img/accounts.png";

const SearchUserEdit = ({setUser, user}) => {

	return (
		<DivImgAccountsSearchUserEdit>
			<WrapperForImgAnaRequired>
				<div className="div-img">
					<img src={accounts} alt={accounts}/>
				</div>
				<div className="required-div" style={{paddingTop: "8px"}}>
					<span>Entertainer</span>
					* required
				</div>
			</WrapperForImgAnaRequired>
			{!user ? (
				<SearchUser setUser={setUser}/>
			) : (
				<EntertainerNameBlock user={user} setUser={setUser}/>
			)}
		</DivImgAccountsSearchUserEdit>
	);
};

export default SearchUserEdit;