import React from 'react';
import {useSelector} from "react-redux";

import {UlSearchItemFilter} from '../../search-meeting-styled/search-filter-styled/SearchFilterItemStyled';

const SearchFilterItem = ({item, us, setKid, setEntertainer}) => {

	const {parentName} = useSelector(state => state.name);
	const {entertainerName} = useSelector(state => state.name);

	const onclick = (event) => {
		us(item);

		if(item && entertainerName){

			if(item.phone === entertainerName.phone){
				setKid(null);
				event.currentTarget.classList.add('show-message');
			}
		} else if(item && parentName){

			if(item.phone === parentName.phone){
				setEntertainer(null);
				event.currentTarget.classList.add('show-message');
			}
		}

	}

	// const ava = !item.avatar? <img src={account}/> : <img src={item.avatar}/>
	return (
		<>
			<UlSearchItemFilter onClick={onclick} className="ul-search-item">
				<li onClick={onclick}> {item.first_name} {item.last_name}</li>
				<li>{item.phone}</li>
			</UlSearchItemFilter>
		</>
	);
}

export default SearchFilterItem;