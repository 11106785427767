import styled from "styled-components";

export const CharactersDiv = styled.div`
	height: calc(100vh - 87px);
	width: 100%;
	border-radius: 8px;
  background: linear-gradient(
					to right, 
					#F8F9FA 16px, #F2F4F5 16px, 
					#F2F4F5 250px, #F8F9FA 250px, 
					#F8F9FA 260px, #F2F4F5 260px, 
					#F2F4F5 450px, #F8F9FA 450px,
					#F8F9FA 0%, #F2F4F5 0%, 
					#F2F4F5 0%, #F8F9FA 0%);
`;


export const CharactersLi = styled.li`
  font-family: "IBM regular";
  font-size: 17px;
  color: #554D66;
	width: 290px;
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
	margin: 10px;

`;
export const CharactersUlHeader = styled.ul`
  font-family: "IBM bold";
	font-size: 25px;
  color: #554D66;
	padding: 20px 20px 0px 30px;
	display: flex;
	justify-content: space-between;
  width: 405px;
`;
export const CharactersUl = styled.ul`
	margin: 20px;
`;
