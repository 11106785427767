import React from 'react';
import {useNavigate} from "react-router-dom";
import {logOut} from "../../store/slicers/admin-slice";
import {useDispatch, useSelector} from "react-redux";
import {tokenSave} from "../../store/slicers/token-slice";

export default function useToken(){

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {token: tokenInRedux} = useSelector(state => state.token);
	const getToken = () => {
		if(!tokenInRedux) {
			const tokenInLocalStorage = localStorage.getItem("token");
			if(tokenInLocalStorage) {
				dispatch(tokenSave(tokenInLocalStorage));
				return tokenInLocalStorage;
			}
		}
		return tokenInRedux;
	}

	const token = getToken();

	const isValidToken = () => {
		if (!token){
			dispatch(logOut());
			navigate("/login");
		}
	}

	return { isValidToken, token }
}
