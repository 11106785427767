import React from "react";
import styled from "styled-components";

export const WrapperForModalMeeting = styled.div`
	position: absolute;
	height: calc(100vh - 500px);
  width: 100%;
	z-index: 300;
	right: 0px;
	top: 0;
	display: flex;
	justify-content: center;
`;

export const WrapperForModalSlotDelete = styled(WrapperForModalMeeting)`
  z-index: 350;
`;
export const WrapperForModalSlotViewing = styled(WrapperForModalMeeting)`
  z-index: 250;
`;
export const WrapperForImgAnaRequired = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	position: relative;
	& .required-div{
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
		color: #EB5757;
		& span{
      font-size: 14px;
      font-weight: 600;
			color: #554D66;
			font-style: normal;
    }
  }
`;

export const CallBalanceWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #27AE60;
  position: absolute;
	right: 0px;
	top: 5px;
`;
export const ThemeWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-height: 80px;
`;

export const WithesWrapper = styled.div`
	width: 100%;
`;

export const ParentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
  max-height: 80px;
`;