import React from 'react';
import {CourseSearchItem} from "./StyledCourse";

function SearchItemCourse({item, onCourseSelected}) {

	return (
		<CourseSearchItem onClick={ () => onCourseSelected(item)}>
			{item.name.uk ? item.name.uk : item.name.en || item.name.ru}
		</CourseSearchItem>
	);
}

export default SearchItemCourse;