import {createAsyncThunk} from '@reduxjs/toolkit';


export const getProfileById = createAsyncThunk(
	"userProfile/getProfileById",
	async function (id, {rejectWithValue}) {
		try {

			let myHeaders = new Headers();

			myHeaders.append("Authorization", localStorage.getItem('token'));

			let requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};

			const res = await fetch(process.env.REACT_APP_API_URL + `/admin/users/${id}`, requestOptions)
			const data = await  res.json();
			console.log("dats", data)
			return data;
		} catch (error) {
			console.log(error)
			return rejectWithValue(error.message);
		}

	}
);
