import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import useTimeShow from "../../hooks/useTimeShow";
import {openCallDeleteIsOpen} from "../../../store/slicers/calls-slice";
import SearchUserEdit from "./content-pieces/SearchUserEdit";
import {parentNameForSave, selectChildAddSave} from "../../../store/slicers/name-slice";
import ContentEntertainer from "./content-pieces/ContentEntertainer";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import {
	courseItemSaveCreateCall,
	courseProgressSave,
	lessonItemSave
} from "../../../store/slicers/course-slice";
import {
	ADMIN_COURSES_BY_ID,
	ADMIN_COURSES_ID_LESSONS_ID,
	ADMIN_COURSES_PROGRESS,
	ADMIN_USERS_BALANCE,
	route
} from "../../../utils/constants_urls";

import {
	ChildrenWrapper,
	ContentInfo,
	FamilyWrapper,
	NameWrapperShort,
	NameWrapperW, WishesNameWrapper
} from "../styled-meeting-options/StyledShowMeeting";
import {
	CallBalanceWrapper,
	WrapperForImgAnaRequired
} from "../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {CourseItemImgWrapper} from "../../create-meeting/styled-create-meeting/search-styled/SearchStyled";
import {
	CourseNameDiv, CourseNameWrapper
} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled";
import {CloseBtn} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";

import account from "../img/icon-prev.png";
import clock from "../img/time.png";
import search from "../img/withes.png";
import arrow from "../img/arrow-symbol.png";
import character from "../img/character.png";
import kinder from "../../create-meeting/img/kinder.png";
import theme from "../../create-meeting/img/Theme.png";
import close from "../../create-meeting/img/close.png";

const ContentBoth = (props) => {

	const {
		setUser,
		user,
		handlerModalUpdateEntertainer,
		entertainerName,
		parentName,
		handleClickArrow,
		handlerEdit,
		videoUrl,
		meetingShow,
		wishes,
		selectCharacter,
		handleOnHide

	} = props;

	const dispatch = useDispatch();
	const {timeToShow} = useTimeShow();
	const [disabled, setDisabled] = useState(true);
	const {admin} = useSelector(state => state.admin);
	const {isOpenMeetingFilter} = useSelector(state => state.modal);
	const dependents = localStorage.getItem(`${meetingShow.parent_id}dependents`) === null ?
		[] : JSON.parse(localStorage.getItem(`${meetingShow.parent_id}dependents`));
	const child = dependents.filter(item => item.dependent_id === meetingShow.dependent_id);
	const [balance, setBalance] = useState('');
	const [loading, setLoading] = useState(true);
	const {courseItemCreateCall} = useSelector(state => state.course);
	const {courseProgress} = useSelector(state => state.course);
	const {lessonItem} = useSelector(state => state.course);

	const {token} = useToken();

	const getUserProgress = async () => {
		const url = route(ADMIN_COURSES_PROGRESS, [':userId', parentName.id]);
		const response = await fetchApiGet({url, token});
		const res = response.filter(item => item.dependent_id === child[0].dependent_id).filter(course => course.course_id === meetingShow.course_id);
		dispatch(courseProgressSave([...res]));
	};

	useEffect(() => {

		getUserProgress();

	}, []);
	const getUserBalance = async () => {
		const url = route(ADMIN_USERS_BALANCE, [':userId', meetingShow.parent_id]);
		const response = await fetchApiGet({url, token});
		setBalance(response.balance)
	};


	const getUserCourse = async () => {
		const url = route(ADMIN_COURSES_BY_ID, [':courseId', meetingShow.course_id]);
		const response = await fetchApiGet({url, token});
		dispatch(courseItemSaveCreateCall(response));
	};


	const getUserLesson = async () => {
		const url = route(ADMIN_COURSES_ID_LESSONS_ID, [':course_id', meetingShow.course_id], [':lesson_id', meetingShow.lesson_id]);
		const response = await fetchApiGet({url, token});
		dispatch(lessonItemSave(response))

	};

	useEffect(() => {
		if (meetingShow) {
			getUserBalance();
			getUserCourse();
			getUserLesson();
		}
	}, [meetingShow]);

	useEffect(() => {
		if (user) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [user]);

	useEffect(() => {
		dispatch(parentNameForSave(parentName));
	}, []);

	useEffect(() => {
		if (parentName && parentName.children) {
			dispatch(selectChildAddSave(meetingShow.dependent_id))
		}
	}, [parentName]);

	useEffect(() => {
		if (courseItemCreateCall.course_id) {
			setLoading(false);
		}
	}, [courseItemCreateCall]);

	return (
		<ContentInfo>
			{isOpenMeetingFilter ? (
				<CloseBtn onClick={handleOnHide}>
					<img src={close} alt={close}/>
				</CloseBtn>) : null
			}
			<div className="line">
				<div className="icon-entertainer">
					<img src={clock} alt={clock}/>
					<h6 className="time">{timeToShow}</h6>
				</div>
			</div>
			<FamilyWrapper>
				<div>
					<div className="line">
						<div className="icon-kid">
							<img src={account} alt={account}/>
							<div className="kid-name">Previous calls (4)</div>
						</div>
					</div>
					{parentName && parentName.first_name ? (
						<NameWrapperW>{parentName.first_name} {parentName.last_name}</NameWrapperW>) : null}
				</div>
				{child.length ?
					(<ChildrenWrapper>
						<WrapperForImgAnaRequired>
							<div className="div-img">
								<img src={kinder} alt={kinder}/>
							</div>
							<div className="required-div">
								<span>Kid</span>
							</div>
						</WrapperForImgAnaRequired>
						<NameWrapperShort>
							<>{child[0].first_name}</>
						</NameWrapperShort>
					</ChildrenWrapper>) : null
				}
			</FamilyWrapper>
			{courseItemCreateCall && courseItemCreateCall.course_id ? (
				<FamilyWrapper>
					<div>
						<WrapperForImgAnaRequired>
							<div className="div-img">
								<img src={theme} alt={theme}/>
							</div>
							<div className="required-div" style={{paddingTop: "5px"}}>
								<span>Theme</span>
							</div>
							{balance ? <CallBalanceWrapper>Call balance - {balance}</CallBalanceWrapper> : null}
						</WrapperForImgAnaRequired>
						<CourseNameWrapper>
							<CourseItemImgWrapper>
								<img src={courseItemCreateCall.cover_image_url}/>
							</CourseItemImgWrapper>
							<CourseNameDiv>
								{courseItemCreateCall.name.uk ? courseItemCreateCall.name.uk : courseItemCreateCall.name.en || courseItemCreateCall.name.ru}
								{courseProgress.length && courseProgress[0].finished_lessons ?
									(
										<span>
							{courseProgress[0].finished_lessons.length}
											/{courseItemCreateCall.lessons.length}
						</span>
									) : (
										<span>0/{courseItemCreateCall.lessons.length}</span>
									)
								}
							</CourseNameDiv>
						</CourseNameWrapper>
					</div>

					<div>
						<WrapperForImgAnaRequired>
							<div className="required-div" style={{paddingTop: "5px"}}>
								<span>Lesson</span>
							</div>
						</WrapperForImgAnaRequired>
						{lessonItem && lessonItem.lesson_id ? (
							<NameWrapperShort>{lessonItem.name.uk ? lessonItem.name.uk : lessonItem.name.en || lessonItem.name.ru}</NameWrapperShort>) : null}
					</div>
				</FamilyWrapper>
			) : (
				<>
					{loading && <ClipLoader color={"#C014E3"} loading={loading} size={30}/>}
				</>
			)}

			<div className="line">
				<div style={{width: "100%", paddingRight: "53px"}}>
					<div className="icon-search-wishes">
						{wishes &&
							<>
								<img src={search} alt={search}/>
								<h6>Wishes</h6>
							</>
						}
					</div>
					<WishesNameWrapper>{wishes}</WishesNameWrapper>

				</div>
			</div>
			{!isOpenMeetingFilter ? (
				<div className="line-div-arrow" onClick={handleClickArrow}>
					<img src={arrow}/>
				</div>
			) : null}
			<FamilyWrapper>
				{parentName && entertainerName && entertainerName.phone ? (
					<ContentEntertainer handlerEdit={handlerEdit}
															entertainerName={entertainerName}
															videoUrl={videoUrl}/>
				) : (
					<>
						<SearchUserEdit user={user} setUser={setUser}/>

						<div className="line position">
							{admin && admin.role === 3 && (
								<>
									<button className="button cancel"
													onClick={() => dispatch(openCallDeleteIsOpen())}>
										Cancel call
									</button>
									<button className="button edit"
													onClick={handlerModalUpdateEntertainer}
													disabled={disabled}>Save
									</button>
								</>)}
						</div>

					</>
				)}
				<ChildrenWrapper style={{marginTop: "10px"}}>
					<WrapperForImgAnaRequired>
						<div className="div-img">
							<img src={character} alt={character}/>
						</div>
						<div className="required-div">
							<span>character</span>
						</div>
					</WrapperForImgAnaRequired>
					<NameWrapperShort>
						{selectCharacter &&
							<>{selectCharacter}</>
						}
					</NameWrapperShort>
				</ChildrenWrapper>
			</FamilyWrapper>
		</ContentInfo>
	);
};

export default ContentBoth;
