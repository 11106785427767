import React from "react";
import styled from "styled-components";

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
	justify-content: space-between;
`;

export const Label = styled.label`
  font-weight: bold;
  width:250px;
	margin-top: 5px;
`;

export const Select = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 120px;
`;

export const Input = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 120px;
`;

export const Button = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
	border: none;
  &.primary {
    background-color: blue;
    color: #fff;
  }

  &.discard {
    border: 1px solid red;
    color: red;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Popup = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
`;

export const PopupTitle = styled.h2`
  margin-bottom: 10px;
`;

export const PopupText = styled.p`
  margin-bottom: 20px;
`;

export const ConfigurationWrapper = styled.div`
  background-color: #F8F9FA;
  border-radius: 8px;

  & div:nth-child(odd) {
    background-color: #eaeaee;
    padding: 5px 10px;
  }
  & div:nth-child(even) {
    padding: 5px 10px;
  }
`;