import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import CourseName from "./CourseName";
import SearchCollectionCourse from "../search/search-collection/SearchCollectionCourse";
import {ADMIN_USERS_BALANCE, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";

import {CallBalanceWrapper, WrapperForImgAnaRequired} from "../styled-create-meeting/CreateMeetingStyled";
import {
	LabelSearchCourse,
	SearchDiv
} from "../styled-create-meeting/search-styled/SearchStyled";

import theme from "../img/Theme.png";


const SelectedCourse = ({value, kid, items, clearValue, onChange}) => {

	const {courseItemCreateCall} = useSelector(state => state.course);
	const {token} = useToken();
	const [balance, setBalance] = useState('');
	const [loading, setLoading] = useState(false);

	const getUserBalance = async () => {
		setLoading(true);
		const url = route(ADMIN_USERS_BALANCE, [':userId', kid.id]);
		const response = await fetchApiGet({url, token});
		if(response.balance){
			setBalance(response.balance);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};


	useEffect(() => {
		if (kid && kid.id){
			getUserBalance();
		}else {
			setBalance('')
		}
	},[kid]);


	return (
		<div>
			<WrapperForImgAnaRequired>
				<div className="div-img">
					<img src={theme} alt={theme}/>
				</div>
				<div className="required-div"  style={{paddingTop: "5px"}}>
					<span>Course</span>
					* required
				</div>
				{loading ? (
					<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
				) : (
					<>
						{balance ? <CallBalanceWrapper>Call balance - {balance}</CallBalanceWrapper> : null}
					</>
				)}
			</WrapperForImgAnaRequired>
			<div>
				{courseItemCreateCall.course_id ? (
					<CourseName/>
				) : (
					<>
						<LabelSearchCourse>
							<input type="text"
										 className="search-user theme-input"
										 placeholder="Select theme"
										 value={value}
										 onChange={(el)=> onChange(el)}/>
						</LabelSearchCourse>
						<>
							{value.length === 0 ? (
								<></>
							) : (
								<SearchDiv>
									<SearchCollectionCourse kid={kid} items={items} clearValue={clearValue}/>
								</SearchDiv>
							)}
						</>
					</>
				)}
			</div>
		</div>
	);
};

export default SelectedCourse;