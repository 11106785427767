import moment from "moment";
import {useSelector} from "react-redux";

export default function useTimeShow(){

	const {modalProps} = useSelector(state => state.modal);

	const startTime = moment(parseInt(modalProps));
	const time = startTime.format("HH:mm");
	const dayShow = startTime.format("DD");
	const month = startTime.format("MMM");
	const timeToShow = `${dayShow} ${month} ${time}`;

	return {
		timeToShow
	}

}