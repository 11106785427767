import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Row} from "react-bootstrap";

import OneLessonInfo from "./OneLessonInfo";

import {
	ButtonBackCourse, ButtonInfoCourseEdit,
	ContentWrapperCreateLessons,
	GroupInfoHeaderBtnWrapper,
	HeaderButtonsWrapperCourseInfo
} from "./StyledCourse";
import btnSquare from "../monetization/img/BtnSquare.png";

const LessonInfo = () => {

	const {lessonItem} = useSelector(state => state.course);
	const {groupItem} = useSelector(state => state.course);
	const {courseItem} = useSelector(state => state.course);

	return (
		<Row className="dashboard-calendar">
			<h2 className="dashboard-h2">Lesson Info</h2>
			<ContentWrapperCreateLessons>
				<HeaderButtonsWrapperCourseInfo>
					<Link to={`/course-info/${courseItem.course_id}`}>
						<ButtonBackCourse>
							<img src={btnSquare}/>Back
						</ButtonBackCourse>
					</Link>
					<GroupInfoHeaderBtnWrapper>
						<Link to="/create-lesson">
							<ButtonInfoCourseEdit>Edit</ButtonInfoCourseEdit>
						</Link>
					</GroupInfoHeaderBtnWrapper>
				</HeaderButtonsWrapperCourseInfo>
				{lessonItem?.name?.uk ? <OneLessonInfo lang="Uk" name={lessonItem?.name?.uk} description={lessonItem?.description?.uk} group={groupItem[0]?.group_name}/> : null}
				{lessonItem?.name?.en ? <OneLessonInfo lang="En" name={lessonItem?.name?.en} description={lessonItem?.description?.en} group={groupItem[0]?.group_name}/> : null}
				{lessonItem?.name?.ru ? <OneLessonInfo lang="Ru" name={lessonItem?.name?.ru} description={lessonItem?.description?.ru} group={groupItem[0]?.group_name}/> : null}
			</ContentWrapperCreateLessons>
		</Row>
	);
};

export default LessonInfo;