import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
	addKidName,
	addKidNameClear,
	setSelectChildClear
} from "../../../store/slicers/slice-modal";
import {
	clearEventSearchListKid,
	parentCallsFilter
} from "../../../store/slicers/search-meetings-kid-slice";
import {
	childClear,
	parentNameClear,
	parentNameForClear,
	parentNameSave
} from "../../../store/slicers/name-slice";
import {ADMIN_USERS} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import LabelSearchFilterWr from "./LabelSearchFilterWr";
import GetChildren from "./GetChildren";

import clear from "../close.png";

import {
	KidWrapper,
	UlSelectedKid,
} from '../search-meeting-styled/GetKidMeetingStyled';



function GetKidsMeetings({today}) {

	const {parentName} = useSelector(state => state.name);
	const {eventList} = useSelector(state => state.eventList);
	const {dashboard} = useSelector(state => state.dashboard);
	const [items, setItems] = useState([]);
	const [kid, setKid] = useState(parentName);
	const [elChange, setElChange] = useState('');
	const [showChildren, setShowChildren] = useState(false);
	const dispatch = useDispatch();
	const {token} = useToken();


	const allMeetingsParent = dashboard.reduce((acc, item) => {
		return [...acc, ...item.meetings.map(e => ({...e, id: item.entertainer_id}))]
	}, []);

	const parentCalls = allMeetingsParent.filter(item =>  item.parent_id === kid?.id );


	const handleChangeKidMeeting = async (el) => {
		let searchPhoneK = el.target.value;
		setElChange(searchPhoneK);

		const url = `${ADMIN_USERS}?search=${searchPhoneK}`
		const response = await fetchApiGet({url, token});
		const parent = response.entities.filter(item => item.role === 1);
		setItems(parent)

	}

	useEffect(() => {
		if (kid !== null) {
			dispatch(parentCallsFilter(parentCalls));
			dispatch(addKidName(kid));
			dispatch(parentNameSave(kid));
		}
	}, [kid, today, eventList, dashboard]);

	const handlerClear = () => {
		setKid(null);
		setItems([]);
		dispatch(clearEventSearchListKid());
		dispatch(setSelectChildClear());
		dispatch(parentNameClear());
		dispatch(addKidNameClear());
		dispatch(childClear());
		setShowChildren(false);
		dispatch(parentNameForClear());

	}

	return (
		<>
			<KidWrapper>
				{!parentName ? (
					<LabelSearchFilterWr handleChangeKidMeeting={handleChangeKidMeeting}
															 setKid={setKid}
															 items={items}
															 elChange={elChange}/>
				) : (
					<>
						<UlSelectedKid onClick={handlerClear}>
							<li>
								<div>{parentName.first_name} {parentName.last_name}</div>
								<div className="img-clear">
									<img src={clear} alt="close"/>
								</div>
							</li>
						</UlSelectedKid>
						<>
							{kid && !kid.children ? (
								<></>
							) : (
								<GetChildren kid={kid}
														 child={kid.children}
														 showChildren={showChildren}
														 setShowChildren={setShowChildren}/>
							)}

						</>
					</>
				)}
			</KidWrapper>
		</>
	);
}

export default GetKidsMeetings;