import React,{useState}from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {closeCallDeleteIsOpen, showMeetingByIdFalse} from "../../store/slicers/calls-slice";
import CreateTextDelete from "../showModal/CreateModal/CreateTextDelete";
import {closeIsOpenMeetingFilter, closeOpenMeetingOptions} from "../../store/slicers/slice-modal";
import {ADMIN_MEETING_DELETE_BY_ID, route} from "../../utils/constants_urls";
import {fetchApiDelete} from "../../utils/axios";
import {getData} from "../../store/actions/dashboard-action";
import useToken from "../hooks/useToken";
import {courseItemClearCreateCall} from "../../store/slicers/course-slice";

import {ModalDeleteMeetingQuestion} from "./styled-meeting-options/StyledDeleteQuestion";
import {CloseBtn} from "../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";

import close from "../create-meeting/img/close.png";

const DeleteMeetingQuestion = ({ setShowAlert, timeStampStart, timeStampEnd}) => {

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {callForDelete} = useSelector(state => state.deleteCall);
	const {token} = useToken();
	const handleClickDeleteMeeting = async () => {

		const url = route(ADMIN_MEETING_DELETE_BY_ID, [':meetingId', callForDelete.meeting_id]);
		const data = {
			"slot_id": callForDelete.slot_id,
			"user_id": callForDelete.parent_id,
			"dependent_id":callForDelete.dependent_id,
			"time_from": callForDelete.slot_time.start_time,
			"time_to": callForDelete.slot_time.end_time,
			"meeting_theme": callForDelete.meeting_theme,
			"meeting_description":callForDelete.meeting_description,
			"character": callForDelete.character
		};

		const result = await fetchApiDelete({url, token, data});
		if(result.status === "ok"){
			console.log("here")
			dispatch(closeOpenMeetingOptions());
			dispatch(getData({timeStampStart, timeStampEnd, token}));
			dispatch(showMeetingByIdFalse());
			dispatch(courseItemClearCreateCall());
			setLoading(false);
			setShowAlert(<CreateTextDelete/>);
		}
		dispatch(showMeetingByIdFalse());
		dispatch(closeCallDeleteIsOpen());
		dispatch(closeIsOpenMeetingFilter());
		setLoading(false);

	}
	const closeDelete = () => {
		dispatch(courseItemClearCreateCall());
		dispatch(closeCallDeleteIsOpen());
	}
	const deleteCall = () => {
		handleClickDeleteMeeting();
		setLoading(true)
	}

	return (
		<ModalDeleteMeetingQuestion>
			<div className="modal-delete active">
				<div className="modal-content-delete">
					<CloseBtn onClick={closeDelete} >
						<img src={close} alt={close}/>
					</CloseBtn>
					<h4>Are you sure you want to delete this call?</h4>
					{loading ? <ClipLoader color={"#C014E3"} loading={loading} size={30}/> : (
						<div className="buttons">
							<button onClick={() => dispatch(closeCallDeleteIsOpen())}>Cancel</button>
							<button onClick={deleteCall} >Delete call</button>
						</div>
					)}
				</div>
			</div>
		</ModalDeleteMeetingQuestion>
	);
};

export default DeleteMeetingQuestion;