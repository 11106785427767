import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ContentBoth from "./ContentBoth";

const ContentWithOut = (props) => {
	const {
		description,
		handleClick,
		parentName,
		entertainerName,
		videoUrl,
		meetingShow,
		wishes
	} = props;


	const [user, setUser] = useState(null);

	useEffect(() =>{
		if(entertainerName){
			if(entertainerName.phone){
				setUser(entertainerName);
			}
		}
	},[entertainerName]);

	const handlerModalUpdateEntertainer = () => {

	}

	return (
		<>
			{parentName &&
				<ContentBoth handlerModalUpdateEntertainer={handlerModalUpdateEntertainer}
										 user={user}
										 setUser={setUser}
										 parentName={parentName}
										 entertainerName={entertainerName}
										 description={description}
										 handleClick={handleClick}
										 videoUrl={videoUrl}
										 meetingShow={meetingShow}
										 wishes={wishes}/>
			}
			</>
	);
};

export default ContentWithOut;