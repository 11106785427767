import React from 'react';
import {Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import home from './button.png';

import './notFound.sass';

const NotFound = () => (
	<Row className="row-not-found">
		<h1 className="h2-not-found">Carrot not found</h1>
		<div>
			<Link to="/">
				<img src={home} alt={home}/>
			</Link>
		</div>
	</Row>
);

export default NotFound;
