import React, {useState} from 'react';

import SearchCollection from "./SearchCollection";
import {fetchApiGet} from "../../../../../utils/axios";
import {ADMIN_USERS} from "../../../../../utils/constants_urls";
import useToken from "../../../../hooks/useToken";

import {
	InputEntertainer,
	LabelSearchFilter, SearchDiv, UlSelectedEntertainer
} from "../../../../search-meetings/search-meeting-styled/GetEntertainerMeetingStyled";

import clear from "../../../../search-meetings/close.png";

function SearchEntertainer({entertainer, setEntertainer}) {

	const [items, setItems] = useState([]);
	const [elChange, setElChange] = useState('');
	const {token} = useToken();

	const handleChangeEntertainer =  async (el) => {

		let searchPhoneK = el.target.value;
		setElChange(searchPhoneK);
		const url = `${ADMIN_USERS}?search=${searchPhoneK}`
		const response = await fetchApiGet({url, token});
		setItems(response.entities);
	}

	const handlerClear = () => {
		setItems([]);
		setEntertainer(null);
	}

	return (
		<div>
			{!entertainer ? (
				<>
					<div style={{height: "50px", marginTop: "15px"}}>
						<LabelSearchFilter>
							<InputEntertainer type="text"
																onChange={(el)=>handleChangeEntertainer(el)}
																placeholder="Entertainer"
																maxLength='16'/>
						</LabelSearchFilter>
					</div>
					<>
						{elChange.length === 0 ? (
							<></>
						) : (
							<div style={{position: "absolute", top: "60px"}}>
								<SearchDiv>
									<SearchCollection items={items} setEntertainer={setEntertainer}/>
								</SearchDiv>
							</div>
						)}
					</>
				</>
			) : (
				<UlSelectedEntertainer
					onClick={handlerClear}>
					<li>
						<div>{entertainer.first_name} {entertainer.last_name}</div>
						<div className="img-clear">
							<img src={clear} alt="close"/>
						</div>
					</li>
				</UlSelectedEntertainer>
			)}
		</div>
	);
}

export default SearchEntertainer;