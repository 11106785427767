import React, {useRef} from 'react';
import {Col} from "react-bootstrap";
import {useSelector} from "react-redux";
import { ONE, ROLE_ADMIN} from "../../../helpers/consts";
import UserItem from "../userItem/UserItem";

import {UlUserCollectionNamesOfUserList} from "../serrch-styled/StyledSearchBar";
import "./userCollection.sass";

const UsersCollection = ({items, setShowModal, checked, setUser}) => {

  const {admin} = useSelector(state => state.admin);

  const ref = useRef(undefined);

  return(
        <>
            <Col className="users-collection-col-names-of-user-list" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <UlUserCollectionNamesOfUserList>
                    <li>Name</li>
                    <li>Phone</li>
                    <li>Description</li>
                    <li>Role</li>
                    {admin && admin.role === ROLE_ADMIN &&
                        (<li>Active</li>)
                    }
                    <li>Date of registration</li>
                  {checked === ONE && <li>Children</li>}
                  {checked === ONE &&  <li>Call balance</li>}

                </UlUserCollectionNamesOfUserList>
            </Col>
            <Col className="users-collection-col-users-of-user-list" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} ref={ref} id={'collection-list'}>
                    {
                      items?.map( (item) =>
                            <UserItem
                              setUser={setUser}
                              checked={checked}
                              setShowModal={setShowModal}
                              key={item.id}
                              itemUser={item}/>
                        )
                    }
            </Col>
        </>
    );
}

export default UsersCollection;