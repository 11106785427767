import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import PinCodeInput from "./PinCodeInput";
import ShowModal from "../../showModal/showModal";
import LoginByPhone from "./LoginByPhone";
import useTimer from "../../hooks/useTimer";

import vapanda from "./img/newLogo.png";

import './indentStyle.sass';
import {fetchApiPost} from "../../../utils/axios";
import {ADMIN_AUTHENTICATE, AUTH_VERIFY, ROOT_URL} from "../../../utils/constants_urls";
import {useDispatch} from "react-redux";
import {tokenSave} from "../../../store/slicers/token-slice";

const Auth = () => {
	const [byNumber, setByNumber] = useState("");
	const [sendCode, setToSendCode] = useState(false);
	const [errorTel, setErrorTel] = useState("")
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const timer = useTimer();
	const minutes = timer.minutes;
	const seconds = timer.seconds;
	const timerStart = timer.timerStart;
	const setIsCounting = timer.setIsCounting;

	const apiSendCodeVerify = async (codeVerify) => {
		const data = {
			"phone": "+" + byNumber,
			"code": codeVerify
		};
		const response = await fetchApiPost({url: AUTH_VERIFY, data});
		if (response.token) {
			localStorage.setItem('token', response.token);
			// todo here! set it to redux!
			dispatch(tokenSave(response.token));
			navigate(ROOT_URL);
			setLoading(false)
		} else {
			setShow(true)
		}
	}

	const apiBeginPhoneAuth = async () => {
		if (byNumber.length < 7) return;
		setLoading(true);
		const data = {
			"phone": `+${byNumber}`
		};
		const response = await fetchApiPost({url: ADMIN_AUTHENTICATE, data});
		// console.log("response",response)
		if (response?.error) {
			setShow(true)
			setLoading(false);
		} else {
			setToSendCode(true)
			timerStart();
		}
	}

	return (
		<Row className="row-auth">
			<Col className="col-auth" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
				<div className="div-img">
					<img src={vapanda} alt={vapanda}/>
				</div>
			</Col>

			<Form className="form col" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
				{sendCode ?
					<>
						<ShowModal show={show} setShow={setShow} text={"The code didn't send"}/>
						<PinCodeInput apiSendCodeVerify={apiSendCodeVerify}
													apiBeginPhoneAuth={apiBeginPhoneAuth}
													minutes={minutes}
													seconds={seconds}
													setLoading={setLoading}
													setToSendCode={setToSendCode}
													setIsCountingTimer={setIsCounting}/>
					</>
					:
					<>
						<ShowModal show={show}
											 setShow={setShow}
											 text={'The user doesn\'t found!'}/>
						<LoginByPhone apiBeginPhoneAuth={apiBeginPhoneAuth}
													byNumber={byNumber}
													loading={loading}
													setByNumber={setByNumber}
													errorTel={errorTel}
													setErrorTel={setErrorTel}/>
					</>
				}
			</Form>
		</Row>
	)


}

export default Auth;

