import React from "react";
import styled from "styled-components";

export const UlSearchItemFilter = styled.ul`
	font-size: 13px;
	color:#554D66;
	padding: 2px 0px 2px 16px;
	margin-top: 10px;
	border-radius: 5px;
	&:hover{
		background-color: #E0E6EC;
	}
	& li:last-child {
		font-size: 10px;
		color: #B0ABBC;
	}
	& li.show-message{
		color: red;
	}
	
`;
