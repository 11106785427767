import {createAsyncThunk} from '@reduxjs/toolkit';


export const getProfile = createAsyncThunk(
	"admin/getProfile",
	async function (_, {rejectWithValue}) {
		try {

			let myHeaders = new Headers();

			myHeaders.append("Authorization", localStorage.getItem('token'));

			let requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};

			const res = await fetch(process.env.REACT_APP_API_URL + "/users/me", requestOptions)
			const data = await  res.json();
			return data;

		} catch (error) {
			console.log(error)
			return rejectWithValue(error.message);
		}

	}
);


export const getLogout = createAsyncThunk(
	"logout/getLogout",
	async function (_, {rejectWithValue}) {
		try {
			const res = await fetch(process.env.REACT_APP_API_URL +`/api/login/logout`)
			const data = await res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}

	}
);
