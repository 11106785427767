import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";

import {openCallDeleteIsOpen} from "../../../../store/slicers/calls-slice";
import MeetingVideo from "../MeetingVideo";

import {NameWrapperW} from "../../styled-meeting-options/StyledShowMeeting";
import {WrapperForImgAnaRequired} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import accounts from "../../img/accounts.png";

const ContentEntertainer = ({entertainerName, handlerEdit, videoUrl}) => {

	const {admin} = useSelector(state => state.admin);
	const [isShowVideo, setIsShowVideo] = useState(false);

	const dispatch = useDispatch();

	const handleShowVideo = () => {
		setIsShowVideo(true);
	}
	const hideVideo = () => {
		setIsShowVideo(false);
	}

	return (
		<>
			<div style={{marginTop: "10px"}}>
				<WrapperForImgAnaRequired>
					<div className="div-img">
						<img src={accounts} alt={accounts}/>
					</div>
					<div className="required-div">
						<span>Entertainer</span>
					</div>
				</WrapperForImgAnaRequired>
				<NameWrapperW className="user-name">{entertainerName.first_name} {entertainerName.last_name}</NameWrapperW>
			</div>

				<div className="line position">
				{admin && admin.role === 3 && (
				<>
				<button className="button cancel"
						onClick={() => dispatch(openCallDeleteIsOpen())}
				>Delete
				</button>
				<button className="button edit" onClick={handlerEdit}>Edit</button>
						</>)}
					{videoUrl &&
						<Button onClick={handleShowVideo}
								style={{
									backgroundColor: "green",
									border: "none",
									color: "#ffffff",
									borderWidth: "2px",
									width: "150px"
								}}
						>Watch video
						</Button>}

			</div>
			{isShowVideo &&
				(<MeetingVideo
					videoUrl={videoUrl}
					hideVideo={hideVideo}
				/>)
			}
		</>
	);
};

export default ContentEntertainer;