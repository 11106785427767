import React from 'react';
import {
	CourseCreateWrapper,
	CreateLessonsWrapper,
	DescriptionWrapperCreateCourse,
	LanguageSpan, LessonInfoDescription, LessonInfoDiv,
	LessonInfoP
} from "./StyledCourse";

const OneLessonInfo = ({lang, name, description, group}) => {
	return (
		<CreateLessonsWrapper>
			<LanguageSpan>{lang}</LanguageSpan>
			<CourseCreateWrapper>
						<LessonInfoP>Lesson name</LessonInfoP>
						<LessonInfoDiv>{name}</LessonInfoDiv>
						<LessonInfoP>Course group</LessonInfoP>
						<LessonInfoDiv>{group}</LessonInfoDiv>
			</CourseCreateWrapper>
			<DescriptionWrapperCreateCourse>
						<LessonInfoP>Lesson description</LessonInfoP>
						<LessonInfoDescription>{description}</LessonInfoDescription>
			</DescriptionWrapperCreateCourse>
		</CreateLessonsWrapper>
	);
};

export default OneLessonInfo;