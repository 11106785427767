import React, { useEffect, useState } from 'react';

import { CalendarColumn } from './calendar-column/CalendarColumn';
import CalendarSecondHeader from '../header/CalendarSecondHeader';
import TimeSlots from "./calendar-column/TimeSlots";

import {
	dayArray,
	startOfTime,
	timeStampListCount
} from "../../../helpers";

import {
	MainWrapperGrid,
	GridWrappers,
} from './styled-components/StyledComponents';
import ClipLoader from "react-spinners/ClipLoader";
import {useSelector} from "react-redux";

const CalendarGrid = (props) => {
	const {
		startDay,
		today,
		timeStampStart,
		timeStampEnd,
		setToday,
		nextSetDayHandler,
		prevSetDayHandler
	} = props;

	const [timeStamp, setTimeStamp] = useState([]);

	const startTime = startOfTime(today);

	const daysArray = dayArray(startDay);
	const [loading, setLoading] = useState(false);
	const {dashboard} = useSelector(state => state.dashboard);

	const hour = startTime.clone();

	useEffect(() => {
		timeStampListCount(hour, setTimeStamp);
	}, []);

	useEffect(() => {
		if (dashboard.length){
			setLoading(false);
		}
	}, [dashboard]);

	return (
		<MainWrapperGrid>
			<div/>
			<CalendarSecondHeader setToday={setToday}
														startDay={startDay}
														timeStampStart={timeStampStart}
														timeStampEnd={timeStampEnd}
														daysArray={daysArray}
														nextSetDayHandler={nextSetDayHandler}
														prevSetDayHandler={prevSetDayHandler}
														setLoading={setLoading}
														today={today}/>
			<TimeSlots timeStamp={timeStamp}/>
			<GridWrappers className="calendar-grid">
				{loading ? (
					<ClipLoader color={"#C014E3"} loading={loading} size={600}/>
				):(
					<>
						{
							daysArray.map((dayItem, indexDay) => (
								<CalendarColumn key={dayItem}
																dayItem={dayItem}
																indexDay={indexDay}
																timeStampStart={timeStampStart}
																timeStampEnd={timeStampEnd}
																today={today}/>
							))
						}
					</>
				)}

			</GridWrappers>
		</MainWrapperGrid>
	);
}

export {CalendarGrid};