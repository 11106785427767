import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import CharacterItem from "./CharacterItem";
import ClipLoader from "react-spinners/ClipLoader";
import {fetchApiGet, } from "../../../utils/axios";
import { ADMIN_CHARACTERS} from "../../../utils/constants_urls";
import useToken from "../../hooks/useToken";

import {
	CharactersDiv,
	CharactersUl,
	CharactersUlHeader,
} from "./StyledCharacter";



function Characters(props) {

	const [characters, setCharacters] = useState([]);
	const [loading, setLoading] = useState(true);
	const {token} = useToken();

	useEffect(() => {
		const handlerGetCharacters = async () => {
			const response = await fetchApiGet({url: ADMIN_CHARACTERS, token});
			setCharacters(response);
			setLoading(false);
		}
		handlerGetCharacters();
	},[]);
	
	return (

	<Row className="dashboard-calendar">
		<h2 className="dashboard-h2">Characters</h2>
			<CharactersDiv>
				<CharactersUlHeader>
					<li>Name</li>
					<li>Available</li>
				</CharactersUlHeader>
				<CharactersUl>
					{
						loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={200}/>
						) : (
							<>
								{
									characters?.map((item, i) => (
										<CharacterItem key={i} itemCharacter={item}/>
									))
								}
							</>
						)
					}
				</CharactersUl>
			</CharactersDiv>
	</Row>
	);
}

export default Characters;