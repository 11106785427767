import {createSlice} from '@reduxjs/toolkit';
import {getMeetingById} from "../actions/get-meeting-by-meeting-id-action";

const getMeetingByIdSlice = createSlice({
		name: "update",
		initialState: {
			MeetingById: {},
			statusCode: null,
			status: null,
			error: null,
		},
	reducers: {
		// MeetingByIdSaveUpdate(state, action){
		// 	state.MeetingById = action.payload;
		// },
		MeetingByIdClearUpdate:(state, action)=>{
			state.MeetingById = {};
		},
		},
		extraReducers:
	{
		//? get meeting by id
		[getMeetingById.pending]: (state) => {
			state.status = 'loading';
			state.error = null;
			state.statusCode = null;
		},
			[getMeetingById.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.MeetingById = action.payload.meeting;
			state.error = null;
		},
			[getMeetingById.rejected]: (state, action) => {
			state.status = 'rejected';
			state.error = action.payload;
			state.statusCode = null;
		},
	}
})
export const {
	// MeetingByIdSaveUpdate,
	MeetingByIdClearUpdate
} = getMeetingByIdSlice.actions;
export default getMeetingByIdSlice.reducer;