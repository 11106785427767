import React from "react";
import styled from "styled-components";

export const ModalDeleteMeetingQuestion = styled.div`
  position: absolute;
  height: calc(100vh - 500px);
  width: 100%;
  z-index: 2500;
  right: 0px;
  top: 0;
  display: flex;
  justify-content: center;
  & .modal-delete {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.0001);
    position: fixed;
    top: -75%;
    left: 0;
    display: flex;
    z-index: 2000;
    opacity: 0;
    pointer-events: none;
    transition: .5s;
    padding-top: 25%;
    & .modal-content-delete {
      position: relative;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      background-color: #F8F9FA;
      border: 1px solid #C6C2CF;
      border-radius: 8px;
      width: 500px;
      height: 250px;
      padding: 16px;
      margin-right: 15px;
      & h4 {
        margin-top: 80px;
        margin-left: 10px;
      }
      & .buttons {
        display: flex;
        gap: 10px;
        margin-left: 10px;
        margin-top: 70px;
        & button {
          border: none;
          border-radius: 6px;
          padding: 5px 10px;
        }
        & button:first-child {
          color: #ffffff;
          background-color: #14A38B;
        }& button:last-child {
           color: #ffffff;
           background-color: #FF3838;
         }
      }
    }
  }
  & .modal-delete.active {
    opacity: 1;
    pointer-events: all;
    position: relative;
  }
`;
