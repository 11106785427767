import React,{ useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {fetchApiGet} from "../../utils/axios";
import {
	ADMIN_USER_BY_ID,
	ADMIN_USER_BY_ID_DEPENDENTS,
	route
} from "../../utils/constants_urls";
import useToken from "../hooks/useToken";
import ContentBoth from "../meetings-options/meeting-content/ContentBoth";

const ParentUpdateGetSee = ({handlerUpdate, handleOnHide}) => {

	const {modalPropsMeetingId} = useSelector(state => state.modal);
	const {token} = useToken();
	const	entertainerName = JSON.parse(localStorage.getItem(`${modalPropsMeetingId?.id}`));
	const parentName = JSON.parse(localStorage.getItem(`${modalPropsMeetingId?.parent_id}`));

	const [wishes, setWishes] = useState(modalPropsMeetingId.meeting_theme);
	const [selectCharacter, setSelectCharacter] = useState(modalPropsMeetingId.character);

	useEffect(() => {
		const getUser = async () => {
			const url = route(ADMIN_USER_BY_ID, [':userId', modalPropsMeetingId.parent_id])
			if (localStorage.getItem(`${modalPropsMeetingId.parent_id}`))return;
			const result = await fetchApiGet({url, token});
			localStorage.setItem (`${modalPropsMeetingId.parent_id}`,JSON.stringify(result));
		}
		getUser();
	},[]);

	useEffect(() => {

		const getUser = async () => {
			const url = route(ADMIN_USER_BY_ID, [':userId', modalPropsMeetingId.id])
			if (localStorage.getItem(`${modalPropsMeetingId.id}`))return;
			const result = await fetchApiGet({url, token});
			localStorage.setItem (`${modalPropsMeetingId.id}`, JSON.stringify(result));
		}
		getUser();

	},[]);

	useEffect(() => {

		const getUser = async () => {
			const url = route(ADMIN_USER_BY_ID_DEPENDENTS, [':userId', modalPropsMeetingId.parent_id])
			if (localStorage.getItem(`${modalPropsMeetingId.parent_id}`))return;

			const result = await fetchApiGet({url, token});

			localStorage.setItem(`${modalPropsMeetingId.parent_id}dependents`, JSON.stringify(result.entities))
			const dependent = result.entities.filter(i => i.dependent_id === modalPropsMeetingId.dependent_id)
			localStorage.setItem (`${modalPropsMeetingId.dependent_id}`, JSON.stringify(dependent));
		}
		getUser();

	},[]);

	return (
			<ContentBoth entertainerName={entertainerName}
									 parentName={parentName}
									 handleOnHide={handleOnHide}
									 handlerEdit={handlerUpdate}
									 meetingShow={modalPropsMeetingId}
									 wishes={wishes} selectCharacter={selectCharacter}/>
	);
};

export default ParentUpdateGetSee;