import React from 'react';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ADMIN_USER_BY_ID, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import SearchCollectionEntertainer from "./search-collection/SearchCollectionEntertainer";

import {
	SearchDiv,
	SearchUserWrapper,
	LabelSearchUser
} from '../styled-create-meeting/search-styled/SearchStyled';

const SearchUser = ({setUser}) => {

	const {slotId} = useSelector(state => state.create);
	const {token} = useToken();

	const [items, setItems] = useState([]);
	const [entertainerSearch, setEntertainerSearch] = useState([])
	const [value, setValue] = useState('');

	useEffect(() => {

		slotId?.forEach(async (entertainerId) => {

			const url = route(ADMIN_USER_BY_ID, [':userId', entertainerId.id]);
			const result = await fetchApiGet({url, token});

			localStorage.setItem (`${entertainerId.id}`, JSON.stringify(result));

			setEntertainerSearch(prev => [...prev, result]);
		});

	},[slotId])

	const onChange =  (el) => {

		const searchKids = el.target.value.toLowerCase();
		setValue(searchKids);

		const x = entertainerSearch.filter(i => {

			if(`${i.first_name}${i.last_name}${i.phone}`.toLowerCase().includes(searchKids)){

				return i;
			}
		});
		setItems(x)

	}

	const onUserSelect = (userIn) => {
		setUser(userIn);
	}

	return(
		<>
			<SearchUserWrapper>
				<LabelSearchUser>
					<input onChange={onChange}
								 type="text"
								 className="search-user entertainer-input"
								 placeholder="Select entertainer"
								 maxLength='16'/>
				</LabelSearchUser>
				<>
					{value.length === 0 ? (
						<></>
					) : (
						<SearchDiv>
							<SearchCollectionEntertainer
								onPersonSelect={onUserSelect}
								items={items}/>
						</SearchDiv>
					)}
				</>

			</SearchUserWrapper>
		</>
	)
}

export default SearchUser;
