import React, {useState} from 'react';

export default function useShowAlert(){

	const [showAlert, setShowAlert] = useState(null);

	const hideAlert = () => setShowAlert(null);

	return {

	setShowAlert, showAlert, hideAlert

	}

}
