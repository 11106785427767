import React, {useEffect, useState} from 'react';
// import {childAge} from "../../../helpers";
import {LANGUAGES} from "../../../helpers/objects";

import {
	AnswersWrapper,
	BackChildInfoBtn,
	ChildInfoContentWrapper,
	ChildInfoWrapper,
	ImgHWrapper,
	QuestionsAnswers,
	QuestionWrapper,
	SpanNoAnswer,
	UserInfoGridItem
} from "./UserModalStyled";

import avatarImg from "./img/fox_1.png";
import childImg from "./img/Children.png";
import ageImg from "./img/Vectorage.png";
import lang from "./img/lang.png";
import ques from "./img/ques.png";
import moment from "moment/moment";


const ChildInfoModal = ({childInfo, handlerDetailsOpen}) => {

	const [gender, setGender] = useState('');
	// const [age, setAge] = useState(null);
	const [languages, setLanguages] = useState(null);

	useEffect(()=>{

		LANGUAGES.forEach( item => {
			if(childInfo.preferred_language === item.key){
				setLanguages(item.name);
			}
		})

	},[childInfo]);

	useEffect(()=>{
		if (childInfo.gender === "F"){
			setGender("Girl");
		} else if (childInfo.gender === "M"){
			setGender("Boy");
		} else if (childInfo.gender === "U"){
			setGender("Unknown");
		}
	},[childInfo]);

	// useEffect(()=>{
	// 	childAge(childInfo.dob, setAge);
	// },[childInfo]);

	return (
		<ChildInfoWrapper>
			<ChildInfoContentWrapper>
				<div style={{ display: "flex", alignItems: "center"}}>
					<div style={{width: "150px"}}>
						<ImgHWrapper>
							<div>
								<img style={{borderRadius: "50%"}}
										 src={avatarImg}/>
							</div>
							<span>{childInfo.first_name}</span>
						</ImgHWrapper>
					</div>
					<div style={{width: "100px"}}>
						<ImgHWrapper>
							<div>
								<img src={childImg}/>
							</div>
							<span>{gender}</span>
						</ImgHWrapper>
					</div>
					<ImgHWrapper>
						<div>
							<img src={ageImg}/>
						</div>
						<span>{moment(childInfo.dob).format('DD.MM.yyyy')}</span>
					</ImgHWrapper>
				</div>
				<QuestionsAnswers>
					<UserInfoGridItem>
						<ImgHWrapper>
							<div>
								<img src={lang}/>
							</div>
							<span>Languages</span>
						</ImgHWrapper>
						<span className='span-info-user'>{languages}</span>
					</UserInfoGridItem>
					<UserInfoGridItem>
						<ImgHWrapper>
							<div>
								<img src={ques}/>
							</div>
							<span>Questionnaire</span>
						</ImgHWrapper>
					</UserInfoGridItem>
					{childInfo.survey ? (
						<>
							{
								childInfo.survey.map((item, i) => (
									<QuestionWrapper key={item.id}>{i+1}. {item.question}
										{item.answers.map(ans => (
											<AnswersWrapper key={ans.id}>{ans.answer}</AnswersWrapper>
										))}
									</QuestionWrapper>
								))
							}
						</>
					):(
						<SpanNoAnswer>No answer was given for this child</SpanNoAnswer>
					)}
				</QuestionsAnswers>
				<BackChildInfoBtn onClick={handlerDetailsOpen}>Back</BackChildInfoBtn>
			</ChildInfoContentWrapper>
		</ChildInfoWrapper>
	);
};

export default ChildInfoModal;