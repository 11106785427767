import {useEffect, useRef, useState} from "react";

export default function useCode() {

	const [codes, setCodes] = useState(['', '', '', '', '', '']);
	const nextDisabled = codes.some((v) => !v);
	const startFocus = useRef();

	const handleChangeInput = (e) => {
		const id = Number(e.target.getAttribute('id')) - 1;
		let value = e.target.value;

		if (!isNaN(value) && value.charAt(0) !== " ") {
			setCodes(prev => {
				const newArr = [...prev];
				newArr[id] = value;
				return newArr;
			});

			if (e.target.nextSibling && e.target.value.length === 1) {
				e.target.nextSibling.focus()
			}
		} else {
			value = e.target.value.replace(/\D/g, '')
		}

	}
	const handlePrevious = (e) => {
		if (e.code === 'Backspace' && e.target.previousSibling && e.target.value.length === 0) {
			e.target.previousSibling.focus()
		}
	}


	useEffect(() => {
		startFocus.current.focus();

	}, [])

	return {
		handleChangeInput, handlePrevious, codes, startFocus, nextDisabled
	}


}