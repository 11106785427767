import React from "react";
import styled from "styled-components";

export const WrapperForSlotEditingConfirmation = styled.div`
 width: 100%;
 height: 100vh;
 position: fixed;
 background-color: rgba(000, 000, 000, 0.5);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 5500;
 top: 0;
 left: 0;
 padding-left: 210px;
`;

export const ModalContentSlots = styled.div`
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #F8F9FA;
  border: 1px solid #C6C2CF;
  border-radius: 8px;
  width: 377px;
  min-height: 350px;
  padding: 16px;
 
`;
export const ModalContentSlotsViewing = styled(ModalContentSlots)`
 height: 242px;
 width: 339px;
`;
export const ModalContentCancelEditing = styled.div`
 position: relative;
 box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
 background-color: #F8F9FA;
 border: 1px solid #C6C2CF;
 border-radius: 8px;
 padding: 16px;
 height: content-box;
 width: 368px;
 box-sizing: border-box;
 & .editGroupInput{
  margin: 17px 0 17px 0;
  font-size: 14px;
  font-weight: 400;
  color: #554D66;
  padding: 7px 16px;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  background-color: #FFFFFF;
  width: 100%;
  &:focus {
   outline: none;
  }
 }
`;
export const H4CreateSlots = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: #554D66;
`;
export const PTimeEnd = styled.p`
`;
export const SelectedTimeWrapper = styled.div`
	display: flex;
	gap: 10px;
  flex-wrap: wrap;
`;

export const TimeWrapper = styled.div`
 display: flex;
 gap: 6px;
 width: 160px;
 & .no-time-div{
  font-size: 14px;
  font-weight: 500;
  color: #554D66;
  height: 40px;
  width: 69px;
  border-radius: 6px;
  background-color: #ffffff;
  text-align: center;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  padding-top: 8px;
 }
 
`;
 export const TimeLi = styled.li`
	padding: 5px;
  color: #172B4D;
	margin: 0;
	text-align: center;
	&:hover{
		background-color: #F0F1F5;
	}
`;

export const SelectedTime = styled.div`
   font-size: 14px;
   font-weight: 500;
   color: #554D66;
   height: 40px;
   width: 69px;
   border-radius: 6px;
   background-color: #ffffff;
   text-align: center;
   border: 1px solid #E0E0E0;
   box-sizing: border-box;
   padding-top: 8px;
`
;

export const PWrapper = styled.div`
	display: flex;
  padding-top: 10px;
	& p{
		width: 187px;
    font-size: 14px;
    font-weight: 700;
   	color: #554D66;
    margin: 0;
    padding: 0;
  }
`;

export const DateWrapper = styled.div`
	border: 1px solid #E0E0E0;
  width: 171px;
  border-radius: 6px;
	display: flex;
	gap: 10px;
	padding: 5px 10px;
  height: 41px;
	align-items: center;
	background-color: #ffffff;
  color: #554D66;
 `;
export const  RepeatableFieldView = styled(DateWrapper)`
 font-size: 14px;
 font-weight: 500;
 position: relative;
 width: 191px;
 & img {
  position: absolute;
  right: 15px;
  top: 15px;
 }
`;
export const ButtonPlanSlots = styled.button`
	background-color: #1485ED;
	border-radius: 6px;
	padding: 0px 10px 0 10px;
	box-sizing: border-box;
	color: #ffffff;
	height: 40px;
	margin-right: 10px;
 border: 1px solid #1485ED;
`;
export const ButtonPlanTimeOff = styled.button`
	border: 2px solid #F84E43;
	border-radius: 6px;
	padding: 0px 10px 0 10px;
	box-sizing: border-box;
	color: #F84E43;
	margin-right: 10px;
  height: 40px;
`;

export const ButtonCreateSlot = styled.button`
  border: none;
  border-radius: 6px;
  background-color: #0b5ed7;
  color: #ffffff;
  width: 165px;
  text-align: center;
  padding: 5px 0;
	position: absolute;
	bottom: 15px;
	right: 15px;
  height: 40px;
  &:hover {
    background-color: #094eb2;
		cursor: pointer;
  }
 &:disabled{
  background-color: lightgray;
 }
`;

export const ButtonCancelSlot = styled(ButtonCreateSlot)`
 left: 15px;
 background-color: #ffffff;
 color: #1485ED;
 border: 2px solid #1485ED;
 &:hover {
  background-color: #ffffff;
  cursor: pointer;
 }
`;


export const DropDownLi = styled(TimeLi)`
 font-size: 14px;
 font-weight: 400;
 line-height: 20px;
 text-align: left;
`;
 export const RepeatableUlWrapper = styled.ul`
	width: 188px;
	position: absolute;
  z-index: 260;
	background-color: #ffffff;
	top: 100px;
	left: 2px;
	border-radius: 6px;
	border: 1px solid #E0E0E0;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
`;

 export const LabelForRadio = styled.label`
  display: flex;
  gap: 10px;
 `;