import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './components/pages/usersList/userHeader.sass';

import App from "./App";
import {UploadTimer} from "./UploadTimer";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
            // <React.StrictMode>
							<Provider store={store}>
								<App/>
								<UploadTimer />
							</Provider>
            // </React.StrictMode>

);

