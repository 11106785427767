import React, {useEffect} from 'react';
import {ShowAlertWrapper} from './create-text-styled/CreateTextStyled';

export default function CreateModal(props) {
	useEffect(() => {
		const timeoutId = setTimeout(props.hideAlert, 2000);

		return () => clearTimeout(timeoutId);
	}, [props.text]);

	return (
		<ShowAlertWrapper>
			{props.text}
		</ShowAlertWrapper>
	);
}