import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {H3PopUp} from "../../monetization/styled-monetization/StyledMonetization";
import {CanselGroup, DeleteGroup, DeleteGroupName, WrapperButtonGroupDelete} from "../StyledCourse";
import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";

import close from "../../../create-meeting/img/close.png";
import ClipLoader from "react-spinners/ClipLoader";

const DeleteLesson = ({isDeleteOpen, deleteLesson, loadingDel}) => {

	const {lessonItem} = useSelector(state => state.course);
	const {courseItem} = useSelector(state => state.course);

	return (

		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn onClick={isDeleteOpen}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Lesson delete</H3PopUp>
					<DeleteGroupName>Delete Lesson {lessonItem.name.uk}</DeleteGroupName>
					<WrapperButtonGroupDelete>
						{loadingDel ? (
							<ClipLoader color={"#C014E3"} loading={loadingDel} size={30}/>
						) : (
							<>
								<CanselGroup onClick={isDeleteOpen}>Cancel</CanselGroup>
								<Link to={`/course-info/${courseItem.course_id}`}>
									<DeleteGroup onClick={deleteLesson}>Delete</DeleteGroup>
								</Link>
							</>
						)}
					</WrapperButtonGroupDelete>
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>

	);

};

export default DeleteLesson;