import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import ContentWith from "./ContentWith";
import ContentWithOut from "./ContentWithOut";
import {showMeetingByIdFalse} from "../../../store/slicers/calls-slice";

const ContentShow = (props) => {
	const {
		handlerEdit,
		withoutEntertainer,
		meetingId,
		timeToSend,
		setShowAlert,
		timeStampEnd,
		timeStampStart,
		setShownContent,
		videoUrl,
		meetingShow,
		wishes, selectCharacter
	} = props;

	const dispatch = useDispatch();
	const [entertainerName, setEntertainerName] = useState('');
	const [parentName, setParentName] = useState('');
	const handleClickArrow = () => {
		dispatch(showMeetingByIdFalse());
		setShownContent({});
	}
	useEffect(()=>{
		if(meetingShow){
			setEntertainerName(JSON.parse(localStorage.getItem(`${meetingShow.id}`)));
			setParentName(JSON.parse(localStorage.getItem(`${meetingShow.parent_id}`)));
		}

	},[meetingShow]);

	return (
		<>
			{withoutEntertainer || !entertainerName || !entertainerName.phone ? (
				<ContentWithOut handleClick={handleClickArrow}
												entertainerName={entertainerName}
												parentName={parentName}
												meetingId={meetingId}
												timeToSend={timeToSend}
												setShowAlert={setShowAlert}
												timeStampEnd={timeStampEnd}
												timeStampStart={timeStampStart}
												selectCharacter={selectCharacter}
												meetingShow={meetingShow}
												wishes={wishes}
												videoUrl={videoUrl}/>
			) : (
				<ContentWith handleClickArrow={handleClickArrow}
										 handlerEdit={handlerEdit}
										 entertainerName={entertainerName}
										 parentName={parentName}
										 meetingShow={meetingShow}
										 wishes={wishes}
										 selectCharacter={selectCharacter}
										 videoUrl={videoUrl}/>

			)}

		</>

	);
};

export default ContentShow;