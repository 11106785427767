import React,{useState}from 'react';
import {useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import {fetchApiPut} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import {ADMIN_USERS_BALANCE, route} from "../../../utils/constants_urls";

import {WrapperForModalMeeting} from "../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {CloseBtn} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {ContentWrapperUserBalanceConfirm} from "./UserModalStyled";

import close from "./img/close_balance.png";

function ConfirmAddingCalls({user, setShowModalConfirm}) {

	const {countOfCallsUpdate} = useSelector(state => state.modal);
	const {token} = useToken();
	const total = countOfCallsUpdate.userBalance + +countOfCallsUpdate.changeValue;
	const [loading, setLoading] = useState(false);
	const addUserBalance = async () => {

		const data = {
			"balance": parseInt(countOfCallsUpdate.changeValue)
		};
		const url = route(ADMIN_USERS_BALANCE, [':userId', user.id])

		const result =  await fetchApiPut({url, token, data})

		setShowModalConfirm(false);
		setLoading(false);
	}

	const saveBalance = () => {
		setLoading(true);
		addUserBalance();
	}
	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ContentWrapperUserBalanceConfirm>
					<CloseBtn onClick={()=> setShowModalConfirm(false)}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<h4>User balance</h4>
					<p className="user-balance-p first">Confirm adding calls</p>
					<p className="user-balance-p">
						<span>{user.first_name} {user.last_name}</span>&zwnj;
						calls: {countOfCallsUpdate.userBalance} +
						&zwnj;<span>{countOfCallsUpdate.changeValue}</span> =
						&zwnj;<span className="bold">Total</span> <span>{total}</span>
					</p>
					<div className="buttons">
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<>
								<button onClick={()=> setShowModalConfirm(false)}>Cancel</button>
								<button onClick={saveBalance}>Save</button>
							</>
						)}
					</div>
				</ContentWrapperUserBalanceConfirm>
			</div>
		</WrapperForModalMeeting>
	);
}

export default ConfirmAddingCalls;