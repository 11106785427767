import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";

import {ADMIN_GROUPS} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";
import {groupItemSave} from "../../../store/slicers/course-slice";

import {
	CourseDescriptionWrapper, CourseEntertainersWr, CourseInfoBody,
	CourseInfoUlWrapper,
	CourseInfoWrapper, DescriptionCourse, EntertainersWr,
	PCourse, PhotoWrapper
} from "./StyledCourse";


const InfoBodyCourse = ({course, id}) => {

	const {token} = useToken();
	const [description, setDescription] = useState('');
	const [name, setName] = useState('');
	const [groups, setGroups] = useState([]);
	const [group, setGroup] = useState([]);
	const [entertainers, setEntertainers] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if(course){
			if (id === 'English') {
				setName(course.name.en);
				setDescription(course.description.en)
			}
			if (id === 'Ukrainian') {
				setName(course.name.uk);
				setDescription(course.description.uk)
			}
		if (id === 'Russian') {
					setName(course.name.ru);
					setDescription(course.description.ru)
		}
		}
		if (course && course.entertainers){
			setEntertainers(course.entertainers);
		}
	},[course]);

	useEffect(() => {
		const getGroup = async () => {
			const url = ADMIN_GROUPS;
			const response = await fetchApiGet({url, token});
			setGroups([...response]);
		}
		getGroup();
	}, []);

	useEffect(() => {
		if(groups.length && course){
			setGroup(groups.filter( item => item.group_id === course.group_id));

		}
	},[groups, course]);
	useEffect(() => {
		dispatch(groupItemSave(group));
	},[group]);


	return (
		<CourseInfoBody>
			{course &&
				<PhotoWrapper>
					<img src={course.cover_image_url} className="im-sours"/>
				</PhotoWrapper>
			}
			<CourseInfoWrapper>
				{ course &&
					<CourseInfoUlWrapper>
						<li>Course name</li>
						<li>{name}</li>
						<li>Course group</li>
						{group.length ? <li>{group[0].group_name}</li> : null}
						<li>Age group</li>
						<li>{course.age_threshold_from} - {course.age_threshold_to} рокiв</li>
					</CourseInfoUlWrapper>
				}
			</CourseInfoWrapper>
			<CourseDescriptionWrapper>
				<PCourse>Course description</PCourse>
				<DescriptionCourse>
					{description}
				</DescriptionCourse>
			</CourseDescriptionWrapper>
				<CourseEntertainersWr>
					<PCourse>Entertainers</PCourse>
					<EntertainersWr>
						{entertainers && entertainers.length ?
						(<>{
							entertainers.map(item=> (
								<li key={item.id}>{item.first_name} {item.last_name}</li>
							))
							}</>) : <li>All entertainers</li>
						}
					</EntertainersWr>
				</CourseEntertainersWr>
		</CourseInfoBody>
	);
};

export default InfoBodyCourse;