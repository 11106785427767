import React from 'react';
import {useDispatch} from "react-redux";

import {
	entertainerNameForClear,
} from "../../../../store/slicers/name-slice";

import edit from "../../img/plus.png";

const EntertainerNameBlock = ({user, setUser}) => {

	const dispatch = useDispatch();

	const handleClickGoToSearch = () => {
		setUser(null)
		dispatch(entertainerNameForClear());
	}

	return (
		<ul onClick={handleClickGoToSearch} className="div-user-send">
				<li>{user.first_name}</li>
					<li>{user.last_name}</li>
					<li>
						<div>
							<img src={edit} alt={edit}/>
						</div>
					</li>
		</ul>
	);
}

export default EntertainerNameBlock;