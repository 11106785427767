import React from 'react';
import styled from "styled-components";
import plus from "./img/plus.png";
import {
	ButtonAdd,
	ButtonBack,
	CancelButton,
	ContentWrapperMonetization, CreateButton, HeaderButtonsWrapper, WrapperButtonCreate
} from "../monetization/styled-monetization/StyledMonetization";
import {
	SearchWrapperEntertainer
} from "../../create-meeting/styled-create-meeting/search-styled/SearchCollectionStyled";
import {UlSearchItem} from "../../create-meeting/styled-create-meeting/search-styled/SearchStyled";
export const SearchWrapper = styled.div`
  & input {
    padding: 6px 75px 6px 10px;
    font-size: 16px;
    border: 1px solid #DBE2EA;
		width: 272px;
    border-radius: 6px;
    background-color: #F8F9FA;
    position: relative;
    color: #7C9CBF;
    &::placeholder {
      color: #7C9CBF;
    }
  }

  & input:focus {
    outline: none;
  }
`;

export const DropDownWrapper = styled.div`
	position: relative;
`;

export const DropDown = styled.ul`
  height: 72px;
  width: 144px;
  border-radius: 3px;
  padding: 8px;
	list-style-type: none;
	color: #172B4D;
	position: absolute;
	background-color: #ffffff;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
	right: 0px;
	top: 52px;
	z-index: 10;
	& li{
		margin-bottom: 5px;
		cursor: pointer;
	}
	& li a{
    color: #172B4D;
	}
`;
export const FilterWrapper = styled.div`
	background-color: #F0F2F5;
	margin-top: 10px;
	padding-bottom: 20px;
`;
export const FilterHeader = styled.ul`
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0 33px;
	& li{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
		margin: 10px 0;
    width: 24.5%;
	}
`;
export const CourseWrapper = styled.div`
	overflow: auto;
	max-height: calc(100vh - 260px);
  position: relative;
	& ul:nth-child(2){
		//margin-top: 39px;
	}
`;
export const CourseHeader = styled.ul`
	display: flex;
	padding: 8px 33px;
	justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
	border: 1px solid #F2F2F2;
	background-color: #ffffff;
	margin: 0;
	& li {
		width: 33%;
	}
`;
export const CourseBody = styled.ul`
  font-size: 14px;
  font-weight: 500;
	padding: 8px 33px;
	margin: 0;
  &:nth-child(even) {
    background-color: #F2F4F5;
  }
 	&:nth-child(odd) {
    background-color: #F8F9FA;
  }
	& li ul {
		display: flex;
    justify-content: space-between;
    & li {
      width: 33%;
			& .course-age-lang {
				color: #ffffff;
				background-color: #8E88BE;
        border-radius: 20px;
				padding: 0 10px;
			}
    }
		& li:first-child{
			padding-left: 20px;
			& .course-name {
        border-bottom: 1px solid #554D66;
			}
		}
		& li a {
			text-decoration: none;
      color: #554D66;
		}
	}
`;

export const FilteredLanguage = styled.div`
  width: 24.5%;
  border: 1px solid #DBE2EA;
  border-radius: 6px;
  padding: 5px 10px;
  color: #554D66;
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
	background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5em) 0.7em;
  background-size: 0.85em auto;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Cpath d='M70.3 13.8L40 66.3 9.7 13.8z' fill='%23000'%3E%3C/path%3E%3C/svg%3E");
	position: relative;
`;

export const FilterSelectUl = styled.ul`
  width: 100%;
  border: 1px solid #DBE2EA;
  border-radius: 6px;
  color: #554D66;
  background-color: #ffffff;
	padding: 5px 0;
	position: absolute;
	top: 35px;
	left: 0;
	z-index: 10;
	& li {
		padding: 5px 8px;
		width: 100%;
	}
	& li:hover {
		background-color: #E9F0FD;
	}
 `;

export const AgeWrapperLi = styled.li`
	display: flex;
	gap: 10px;
`;

export const GroupInfoHeaderBtnWrapper = styled.div`
	display: flex;
	gap: 10px;
`;
export const ButtonInfoCourseAdd = styled(ButtonAdd)`
  height: 40px;
	width: 157px;
`;
export const ButtonInfoCourseEdit = styled(CancelButton)`
  height: 40px;
`;
export const ButtonBackCourse = styled(ButtonBack)`
  margin: 0;
`;
export const LanguageSelect = styled.button`
  height: 40px;
  width: 100px;
  border-radius: 3px;
  padding: 4px 12px;
  color: #0052CC;
	border: none;
  background:#DEEBFF;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const CourseInfoBody = styled.div`
	padding: 20px;
	background-color: #ffffff;
	display: flex;
	margin: 0 0 5px 0;
	position: relative;
	flex-wrap: wrap;
`;
export const ContentWrapperCourseInfo = styled(ContentWrapperMonetization)`
	padding: 10px 0;
`;
export const HeaderButtonsWrapperCourseInfo = styled(HeaderButtonsWrapper)`
	padding: 0 10px 10px 10px;
`;

export const DivImgCourseInfo = styled.div`
  height: 169px;
  width: 172px;
  border-radius: 36px;
	& img{
		width: 100%;
	}
	
`;

export const CourseInfoWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
	width: 347px;
  margin-right: 36px;
`;
export const CourseDescriptionWrapper = styled.div`
  max-width: 360px;
	margin-top: 5px;
`;
export const DescriptionCourse = styled.div`
  font-size: 14px;
  font-weight: 400;
	width: 347px;
  min-height: 198px;
  border-radius: 6px;
  padding: 8px 28px 9px 16px;
  background-color: #F2F4F5;
	line-height: 18px;
`;
export const CourseInfoUlWrapper = styled.ul`
  & li:nth-child(odd){
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
		padding: 10px 0px 5px 0px;
	}
 & li:nth-child(even){
   font-size: 14px;
   font-weight: 400;
   line-height: 18px;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 8px 28px 9px 16px;
   width: 347px;
   height: 38px;
   background: #F2F4F5;
   border-radius: 6px;
 }
	& li{
		margin-bottom: 10px;
	}
`;

export const PCourse = styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin: 5px 0;
`;

export const CourseLessonsWrapper = styled.div`
	background-color: #ffffff;
	padding: 10px;
	& ul{
    & li{
      padding: 8px 20px 8px 65px;
      display: flex;
			justify-content: space-between;
			& a{
				color: inherit;
				text-decoration: none;
			}
    }
    & li:nth-child(odd){
      background-color: #F2F4F5;
    
    }
    & li:nth-child(even) {
      background-color: #F8F9FA;
     
    }
		
  }
`;
export const PCourseLessons = styled(PCourse)`
	padding-left: 58px;
`;

export const SearchWrapperCourse = styled(SearchWrapperEntertainer)`
  top: -8px;
	padding-bottom: 10px;
	max-height: 200px;
	overflow: auto;
`;

export const CourseSearchItem = styled(UlSearchItem)`

`;

export const CourseSearch = styled.div`
  padding: 6px 75px 6px 10px;
  font-size: 16px;
  border: 1px solid #DBE2EA;
  width: 272px;
  border-radius: 6px;
  background-color: #F8F9FA;
  position: relative;
  color: #7C9CBF;
`;
export const WrapperButtonGroupEdit = styled(WrapperButtonCreate)`
	justify-content: center;
`;
export const WrapperButtonManageTranslation = styled(WrapperButtonCreate)`
	justify-content: center;
  position: absolute;
	bottom: 15px;
	left: 0;
`;
export const WrapperButtonGroupDelete = styled(WrapperButtonCreate)`
	justify-content: center;
	margin-top: 30px;
`;

export const DeleteButtonGroup = styled.button`
  color: #EB5757;
  border: 2px solid #EB5757;
  padding: 6px 0;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #ffffff;
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  width: 164px;
	&:disabled{
    color: #DBE2EA;
    border: 2px solid #DBE2EA;
	}
`;
export const DeleteGroup = styled(DeleteButtonGroup)`
  background-color: #EB5757;
	color: #ffffff;
`;
export const SaveButtonGroup = styled(CreateButton)`
  width: 164px;
`;

export const DeleteGroupName = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #554D66;
	margin-top: 30px;
`;
export const HaveCoursesMessage = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #EB5757;
	margin: 20px 0 0 0;
	padding: 0;
`;

export const CanselGroup = styled(CancelButton)`
width: 164px
`;
export const ManageTranslations = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const HeaderButtonsWrapperCreateCourse = styled.div`
  display: flex;
  justify-content: right;
	gap: 30px;
	margin-bottom: 10px;
  & a{
    text-decoration: none;
  }
`;

export const LoadPhotoWrapper = styled.div`
  height: 169px;
  width: 172px;
  border-radius: 36px;
	border: 1px dashed #D8D8D8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	& img{
		width: 48px;
		height: 44px;
	}
	& p{
		margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
		color: #515E6B;

  }
`;

export const IconPhoto = styled.div`
	position: absolute;
	bottom: -18px;
	right: 10px;
	z-index: 20;
	& img{
		width: 50px;
		height: 50px;
	}
`;

export const DownloadFileWrapper = styled.div`
  position: absolute;
  height: calc(100vh - 500px);
  width: 100%;
  z-index: 300;
  right: 0px;
  top: 0;
  display: flex;
  justify-content: center;
`;

export const DownloadFileBody = styled.div`
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #F8F9FA;
  border: 1px solid #C6C2CF;
  box-sizing: border-box;
  height: 489px;
  width: 368px;
  border-radius: 8px;
  padding: 15px;

`;
export const DownloadFileP = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
	color: #554D66;
`;
export const DownloadFileContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ButtonSave = styled.button`
  padding: 6px 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid #1485ED;
  background-color: #2F80ED;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
	width: 338px;
	position: absolute;
	bottom: 10px;
	&:disabled{
		background-color: lightgray;
		border: none;
	}
`;

export const FileNameWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 40px;
	& p{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
		color: #554D66;
  }
	& span{
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    color: #BDBDBD;
  }
`;

export const PopUpHeader = styled.div`
	display: flex;
	gap: 10px;
	& p.h-header{
    font-size: 16px;
    font-weight: 700;
		color: #554D66;
  }

  & p.h-header-png {
		padding-top: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #BDBDBD;
  }
`;
export const LoadPhotoWrapperCloud = styled.div`
  height: 258px;
  width: 264px;
  border-radius: 69px;
  padding: 9px 9px 9px 7px;
  border: 1px dashed #D8D8D8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
	overflow: hidden;
  & img.im-sours{
    width: 100%;
		
  }
`;

export const PhotoWrapper = styled.div`
  height: 169px;
  width: 172px;
  border-radius: 36px;
  padding: 9px 9px 9px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
	overflow: hidden;
  margin: 35px 20px 20px 0px;
  & img.im-sours{
    width: 100%;
		
  }
`;
export const LoadPhotoWrapperSmall = styled(LoadPhotoWrapperCloud)`
  height: 169px;
  width: 172px;
  border-radius: 36px;
  border: 1px dashed #D8D8D8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const WithoutPhoto = styled(LoadPhotoWrapperCloud)`
  flex-direction: column;
  & img{
    width: 50px;

  }
`;
export const PCloud = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

export const CourseCreateWrapper = styled.div`
	width: 350px;
	margin-right: 36px;
  margin-left: 36px;
	& label{
		width: 100%;
		margin-top: 12px;
		& div{
			margin-bottom: 5px;
			color: #EB5757;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 14px;
      & span{
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
				color: #554D66;
				font-style: normal;
				padding: 0 5px;
			}
		}
		& input{
			width: 100%;
      height: 38px;
      border-radius: 6px;
      padding: 8px 28px 9px 16px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 1px solid #DBE2EA;
      box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
    }
	}
`;
export const DescriptionWrapperCreateCourse = styled.div`
  max-width: 360px;
  & label{
    width: 100%;
    margin-top: 12px;
    & div{
      margin-bottom: 5px;
      color: #EB5757;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 14px;
      & span{
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: #554D66;
        font-style: normal;
        padding: 0 5px;
      }
    }
		& textarea{
      height: 198px;
      width: 359px;
      border-radius: 6px;
      padding: 8px 28px 9px 16px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 1px solid #DBE2EA;
      box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
			resize: none;
    }
  }
`;

export const CreateCourseButtonWrapper = styled.div`
	position: absolute;
	bottom: 32px;
	right: 40px;
	display: flex;
	justify-content: right;
	gap: 10px;
	& a{
		text-decoration: none;
	}
`;

export const ContentWrapperCreateLessons = styled(ContentWrapperMonetization)`
	overflow: auto;
	max-height: calc(100vh - 80px);
`;
export const CreateLessonsWrapper = styled.div`
	background-color: #ffffff;
	display: flex;
	gap: 36px;
	padding: 10px 10px 80px 10px;
	margin-bottom: 10px;
`;

export const LanguageSpan = styled.span`
  font-size: 14px;
  font-weight: 700;
	padding: 10px 0 0 8px;
`;
export const LessonInfoP = styled.p`
  font-size: 14px;
  font-weight: 700;
	margin: 0 0 5px 0;
`;

export const LessonInfoDiv = styled.div`
  padding: 8px 28px 9px 16px;

  width: 347px;
  margin-bottom: 10px;
  background-color: #F2F4F5;
  border-radius: 6px;
	

`;
export const LessonInfoDescription = styled(LessonInfoDiv)`
	width: 360px;
`;

export const GroupWrapper = styled.ul`
  width: 350px;
  left: 0px;
  top: 75px;
  border-radius: 6px;
	position: absolute;
	border: 1px solid #DBE2EA;
	background-color: #ffffff;
  color: #554D66;
	max-height: 300px;
	overflow: auto;
	z-index: 300;
	& li{
		padding: 10px;
		border-radius: 6px;
	}
	& li:hover{
		background-color: #F0F3F6;
	}
`;

export const LanguageSelectWrapper = styled.ul`
	position: absolute;
  width: 360px;
  right: -50px;
  top: 50px;
  border-radius: 3px;
  padding: 4px 0px 4px 0px;
	background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
	z-index: 250;
  & li{
	padding: 8px;
	}
	& li:hover {
		background-color: #E9F0FD;
  ;
	}
;
`;

export const ModalContentManageTranslation = styled.div`
  position: relative;
  height: 299px;
  width: 368px;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
  background-color: #F8F9FA;
  border: 1px solid #C6C2CF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

`;

export const ManageTranslationHeaderWrapper = styled.div`
	display: flex; 
	justify-content: space-between; 
	align-items: center;
	padding: 30px 20px 10px 20px;
`;

export const InputCheckbox = styled.input`
  margin-right:10px;
	margin-bottom: 12px;
`;
export const LabelToCheckbox = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: #172B4D;
 `;

export const GroupName = styled.p`
  width: 347px;
  border-radius: 6px;
  padding: 8px 28px 9px 16px;
  box-sizing: border-box;
  background-color: #F2F4F5;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
	color: #554D66;
 `;

export const FileWrapper = styled.div`
  width:150px;
  position: relative;
  margin: 15px 0;
	& label{
    border: 1px solid lightgray;
		border-radius: 6px;
		padding: 20px;
	}
  & input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
`;
export const RequiredImg = styled.div`
	position: absolute;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
	color: #EB5757;
	top: 200px;
`;

export const EntertainersWr = styled.ul`
	display: flex;
	flex-wrap: wrap;
	gap: 9px;
	margin-top: 10px;
	& li{
    border-radius: 6px;
    background: #F2F4F5;
    box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04);
    color: #554D66;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    //gap: 10px;
	}
`;

export const CourseEntertainersWr = styled.div`
	width: 100%;
	margin-left: 195px;
`;

export const EntertainerBodyWr = styled(CourseCreateWrapper)`
  width: 100%;
  margin-right: 36px;
  margin-left: 210px;
	display: flex;
	flex-wrap: wrap;
	align-items: self-end;
	gap: 11px;
  & label{
    width: 350px;
    margin-top: 0;
		margin-bottom: 0;
  }
	& input {
		background-image: url(${plus}) !important;
		background-repeat: no-repeat!important;
		background-position: right 10px top 10px!important;
		background-size: 16px!important;
	}
`;


export const SearchEntWrapper = styled.ul`
  overflow-y: auto;
	max-height: 250px;
	position: absolute;
  z-index: 250;
  top: 87px;
  width: 347px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #DBE2EA;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04);
`;

export const UlSearchItemEnter = styled.ul`
	font-size: 13px;
	color:#554D66;
	padding: 2px 0px 2px 16px;
	margin-top: 10px;
	border-radius: 5px;
	position: relative;
	&:hover{
		background-color: #E0E6EC;
	}
	& li:last-child {
		font-size: 10px;
		color: #B0ABBC;
	}
	& li.show-message{
		color: red;
	}
	
`;

export const RemoveWr = styled.li`
	position: absolute;
	right: 20px;
	top: 10px;
`;
export const GetAllBtn = styled(ButtonAdd)`
  padding: 3px 0;
`;

export const CourseEntertainerInfoWr = styled.div`
  display: flex;
  padding: 7px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #DBE2EA;
  background: var(--background-colors-bg-white, #FFF);
  box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04);

`;

export const InfoWrAddEntertainer = styled.p`
  color: #44C124;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
	margin: 10px 0 0 5px;
	padding: 0;
`;