import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {setSelectChild} from "../../../store/slicers/slice-modal";
import {childClear, childSave} from "../../../store/slicers/name-slice";

import {
	ChildrenUlHeader,
	DivAb,
	DivAbOne} from "../search-meeting-styled/GetKidMeetingStyled";
import {
	LabelChild
} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled";

const GetChildren = ({child, kid, showChildren, setShowChildren}) => {

	const {childN} = useSelector(state => state.name)

	const [childName, setChildName] = useState(childN);

	const dispatch = useDispatch();

	const handleClickChildIdOne = () => {
		setChildName(null);
		dispatch(childClear());
		setShowChildren(false);
	};

	const handleClickChildId = (id, name) => {
		setChildName(name);
		dispatch(setSelectChild(id));
		dispatch(childSave(name));
	};

	return (
		<>
			{kid.children && kid.children.length !== 0 ? (
				<>
					{kid.children.length === 1 ? (
						<DivAbOne>
							<ul>
								{
									kid.children.map(item =>
										<li key={item.id} item={item}>{item.name}</li>
									)
								}
							</ul>
						</DivAbOne>
					) : (
						<div>
							{!childName ? (
								<>
									{child !== null ? (
										<DivAb>
											<LabelChild onClick={() => setShowChildren(true)}>
												<input type="text"
															 className="search-user search-user-children header-kid"
															 placeholder="Kid selects"
															 readOnly/>
											</LabelChild>
										</DivAb>
									) : null}
									{showChildren &&
										<ChildrenUlHeader>
											{
												kid.children.map(item =>
													<li key={item.id} item={item}
															onClick={() => handleClickChildId(item.id, item.name)}>{item.name}</li>
												)
											}
										</ChildrenUlHeader>
									}
								</>
							) : (
								<DivAbOne>
									<ul>
										<li onClick={handleClickChildIdOne}>{childName}</li>
									</ul>
								</DivAbOne>
							)}

						</div>
					)}
				</>
			) : null}
		</>
	);
}

export default GetChildren;