import React from 'react';
import EntertainerItem from "./EntertainerItem";

import {
	SearchFilterWrapper
} from "../../../../search-meetings/search-meeting-styled/search-filter-styled/SearchFilterCollectionStyled";

function SearchCollection({items, setEntertainer}) {

	return (
		<>
			<SearchFilterWrapper>
				{items &&
					items.map( item =>
						<EntertainerItem key={item.id}
														 item={item}
														 setEntertainer={setEntertainer}/>
					)
				}
			</SearchFilterWrapper>
		</>
	);

}

export default SearchCollection;