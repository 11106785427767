import React from 'react';

import ContentBoth from "./ContentBoth";


const ContentWith = ({parentName, entertainerName, handleClickArrow, handlerEdit, videoUrl, meetingShow, wishes, selectCharacter}) => {
	return (
		<>
			{parentName && entertainerName && entertainerName.phone &&
				<ContentBoth entertainerName={entertainerName}
										 parentName={parentName}
										 handleClickArrow={handleClickArrow}
										 handlerEdit={handlerEdit}
										 meetingShow={meetingShow}
										 selectCharacter={selectCharacter}
										 wishes={wishes}
										 videoUrl={videoUrl}/>
			}
		</>
	);
};

export default ContentWith;