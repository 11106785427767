import React from 'react';
import {handleStopPropagation} from "../../../../helpers";

import {
	LabelChild, SelectChildWrapper
} from '../../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled';

const LabelChildMore = ({setShowChildren}) => {

	return (
		<SelectChildWrapper onClick={(e)=>handleStopPropagation(e)}>
			<LabelChild onClick={() => setShowChildren(true)}>
				<input type="text"
							 className="search-user search-user-children"
							 placeholder="Kid select"
							 readOnly/>
			</LabelChild>
		</SelectChildWrapper>
	);
}

export default LabelChildMore;