import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	callsAll: null,
	callDeleteIsOpen: false,
	showMeetingById: false
}
const callsAllSlice = createSlice({
	name: "calls",
	initialState,
	reducers: {
		callsAllSave(state, action){
			state.callsAll = action.payload;
		},
		openCallDeleteIsOpen: (state, action) => {
			state.callDeleteIsOpen = true;
		},
		closeCallDeleteIsOpen: (state, action) => {
			state.callDeleteIsOpen = false;
		},
		showMeetingByIdTrue: (state, action) => {
			state.showMeetingById = true;
		},
		showMeetingByIdFalse: (state, action) => {
			state.showMeetingById = false;
		},
	},

})

export const {
	showMeetingByIdFalse,
	showMeetingByIdTrue,
	closeCallDeleteIsOpen,
	openCallDeleteIsOpen
} = callsAllSlice.actions;

export default callsAllSlice.reducer;
