import React, {useEffect, useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import {ADMIN_CHARACTERS,} from "../../../../utils/constants_urls";
import {fetchApiGet} from "../../../../utils/axios";
import useToken from "../../../hooks/useToken";

import {
	CharacterLi,
	CharacterUl,
	LabelCharacter, WrapperRelative
} from "../../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled";
import {WrapperForImgAnaRequired} from "../../styled-create-meeting/CreateMeetingStyled";
import '../modal.sass';

import char from "../../img/character.png";
import edit from "../../img/Vector (Stroke).png";

const CharacterSelect = ({selectCharacter, setSelectCharacter}) => {

	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showCharacters, setShowCharacters] = useState(false);
	const {token} = useToken();

	useEffect(() => {

		const getCharacter = async () => {
			const url = ADMIN_CHARACTERS;
			const result = await fetchApiGet({url, token});
			if(result[0]?.character_id){
				setItems( result);
				setLoading(true);
			}
		}

		getCharacter();

	},[]);

	useEffect(() => {
		if(items){
			setLoading(false);
		}
	},[items]);

	const handlerClickSelect = () => {
		setShowCharacters(false);
		setSelectCharacter(null);
	}

	return (
		<WrapperRelative style={{paddingTop: "4px"}}>
			<WrapperForImgAnaRequired>
				<div className="div-img">
					<img src={char} alt={char}/>
				</div>
				<div className="required-div" style={{paddingTop: "5px"}}>
					<span>Character</span>
					* required
				</div>
			</WrapperForImgAnaRequired>
			{!selectCharacter ? (
				<>
				<LabelCharacter onClick={() => setShowCharacters(true)}>
					<input type="text"
								 className="search-user character"
								 placeholder="Select character"
								 readOnly/>
				</LabelCharacter>
					{loading ? <ClipLoader color={"#C014E3"} loading={loading} size={200}/> : (
						<>
							{showCharacters &&
								<CharacterUl>
									{
										items
											.filter(char => char.is_active)
											.map(item => (
											<li key={item.character_id}
													onClick={() => setSelectCharacter(item.character_id)}>
												{item.name.en}
											</li>
										))
									}
								</CharacterUl>
							}
						</>
					)}

				</>
			) : (
				<ul onClick={handlerClickSelect} >
					<CharacterLi>
						{selectCharacter}
						<div>
							<img src={edit} alt={edit}/>
						</div>
					</CharacterLi>
				</ul>
			)
			}
		</WrapperRelative>
	);
};

export default CharacterSelect;