import React from 'react';
import {useSelector} from "react-redux";

import HideContent from "./HideContent";


const ContentWithOutHIde = ({ handleClick, shownContent}) => {

	const {userName} = useSelector(state => state.name);
	const {modalPropsMeetings} = useSelector(state => state.modal);

	return (
		<>
			{
				modalPropsMeetings &&
				modalPropsMeetings
					.filter( item =>
					(item.parent_id === userName.id &&
						!item.id))
					.map(item => (
						<HideContent key={item.meeting_id}
												 item={item}
											   handleClick={handleClick}
											   shownContent={shownContent}/>
					))
			}
			</>
	);
};

export default ContentWithOutHIde;