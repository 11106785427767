import React from 'react';
import {useSelector} from "react-redux";

import ContentWithOutHideFalse from "./meeting-content/ContentWithOutHideFalse";
import ContentWithHideFalse from "./meeting-content/ContentWithHideFalse";

const WithOutEntertainerFalse = ({ handleClick, shownContent}) => {

	const {userName} = useSelector(state => state.name);

	return (
		<>
			{userName ? (
				<ContentWithOutHideFalse handleClick={handleClick}
																 shownContent={shownContent}/>

			) : (
				<ContentWithHideFalse handleClick={handleClick}
															shownContent={shownContent}/>
			)}
		</>
	);
};

export default WithOutEntertainerFalse;