import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import useClose from "../../hooks/useClose";

import {entertainerCallsFilter} from "../../../store/slicers/search-meetings-entertainer-slice";
import {entertainerNameSave} from "../../../store/slicers/name-slice";

import SearchFilterCollection from "../search-filter-collection/SearchFilterCollection";

import {CLEAR_LINE} from "../../../helpers/consts";

import clear from "../close.png";

import {
	InputEntertainer,
	UlSelectedEntertainer,
	LabelSearchFilter,
	SearchDiv
} from '../search-meeting-styled/GetEntertainerMeetingStyled';
import {callsEntertainer} from "../../../helpers";
import {ADMIN_USERS_SEARCH, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";



const GetEntertainerMeetings = ({today}) => {

	const {entertainerName} = useSelector(state => state.name);
	const {eventList} = useSelector(state => state.eventList);
	const {dashboard} = useSelector(state => state.dashboard);
	const {token} = useToken();
	const [entertainerItems, setEntertainerItems] = useState([]);
	const [entertainer, setEntertainer] = useState(entertainerName);
	const [elChange, setElChange] = useState(CLEAR_LINE);

	const dispatch = useDispatch();
	const {handleClearGetEntertainer} = useClose();

	const entertainerCalls = callsEntertainer(dashboard, entertainer);


	useEffect(() =>{
		if(entertainer !== null) {
			dispatch(entertainerCallsFilter(entertainerCalls));
			dispatch(entertainerNameSave(entertainer));
		}
	}, [entertainer, today, eventList, dashboard])

	const onEntertainerSelect = (kidIn) => {
		setEntertainer(kidIn);
	}

	const handlerClear = () => {
		setEntertainer(null);
		setEntertainerItems([]);
		handleClearGetEntertainer();
	}

	const handleChangeEntertainerMeeting = async (el) => {
		let searchPhoneK = el.target.value;
    setElChange(searchPhoneK);
		const url = route(ADMIN_USERS_SEARCH, [':searchBody', searchPhoneK])
		const result = await fetchApiGet({url, token});

		const entertainer = result.entities.filter(item => item.role === 2);
		setEntertainerItems(entertainer);
	}

	return (
		<>
			<div>
				{!entertainerName ? (
					<>
						<LabelSearchFilter>
							<InputEntertainer type="text"
																onChange={(el)=>handleChangeEntertainerMeeting(el)}
																placeholder="Entertainer"
																maxLength='16'/>
						</LabelSearchFilter>
						<>
							{elChange.length === 0 ? (
								<></>
							) : (
								<SearchDiv>
									<SearchFilterCollection onPersonSelect={onEntertainerSelect}
																					items={entertainerItems}
																					setEntertainer={setEntertainer}/>
								</SearchDiv>
							)}
						</>
					</>
				) : (
					<UlSelectedEntertainer onClick={handlerClear}>
						<li>
							<div>{entertainerName.first_name} {entertainerName.last_name}</div>
							<div className="img-clear">
								<img src={clear} alt="close"/>
							</div>
						</li>
					</UlSelectedEntertainer>
				)}

			</div>
		</>
	);
}

export default GetEntertainerMeetings;