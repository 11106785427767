import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import LessonName from "./LessonName";
import SearchCollectionLessons from "../search/search-collection/SearchCollectionLessons";

import {WrapperForImgAnaRequired} from "../styled-create-meeting/CreateMeetingStyled";
import {
	LabelSearchLessons, SearchDiv
} from "../styled-create-meeting/search-styled/SearchStyled";
const SelectedLesson = () => {

	const {courseItemCreateCall} = useSelector(state => state.course);
	const {lessonItem} = useSelector(state => state.course);

	const [items, setItems] = useState([]);
	const [showLessons, setShowLessons] = useState(false);

	useEffect(()=> {
		if(courseItemCreateCall.lessons.length){
			setItems(courseItemCreateCall.lessons)
		}
	},[courseItemCreateCall]);

	const closeLessons = () => {
		setShowLessons(!showLessons);
	}
	return (
		<div>
			<WrapperForImgAnaRequired>
				<div className="required-div"  style={{paddingTop: "5px"}}>
					<span>Lesson</span>
					* required
				</div>
			</WrapperForImgAnaRequired>
			<div>
				{lessonItem.lesson_id ? (
					<LessonName/>
				) : (
					<>
				<LabelSearchLessons>
					<input type="text"
								 className="search-user search-user-children"
								 placeholder="Select lesson"
								 readOnly
								 onClick={closeLessons}
					/>
				</LabelSearchLessons>
								{showLessons &&
								<SearchDiv>
									<SearchCollectionLessons items={items} closeLessons={closeLessons}/>
								</SearchDiv>
								}
					</>
				)}
			</div>
		</div>
	);
};

export default SelectedLesson;