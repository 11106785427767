import React from 'react';
import {CharactersLi} from "./StyledCharacter";

import {useEffect, useState} from "react";


import ClipLoader from "react-spinners/ClipLoader";
import Checkbox from "../../searchBar/usersCollection/checkbox/Checkbox";

import {fetchApiGet, fetchApiPost} from "../../../utils/axios";
import {
	ADMIN_CHARACTERS_ACTIVITY_STATUS,
	ADMIN_CHARACTERS_BY_ID,
	route
} from "../../../utils/constants_urls";
import useToken from "../../hooks/useToken";


function CharacterItem({itemCharacter}) {

	const [item, setItem] = useState(itemCharacter);
	const [newState, setNewState] = useState(null);
	const [loading, setLoading] = useState(false);
	const {token} = useToken();

	useEffect(() => {
		setNewState(item.is_active)
	}, [item.is_active]);

	const getCharacter = async () => {
		const url = route(ADMIN_CHARACTERS_BY_ID, [':characterId', item.character_id])
		const response = await fetchApiGet({url, token});
		setItem(response);
		setLoading(false);
	}


	const changeState = async () => {
		const data = {
			"is_active": !item.is_active
		};

		const url = route(ADMIN_CHARACTERS_ACTIVITY_STATUS, [':characterId', item.character_id]);
		const response = await fetchApiPost({url, data, token});
		setLoading(true);
		getCharacter();

	}


	return (
		<CharactersLi>
			<div>{item.name.uk ? item.name.uk : item.name.en}</div>
			{
				loading ? (
					<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
				) : (

					<Checkbox  handleChangeState={() => changeState()}
										 myState={newState}/>
				)
			}
		</CharactersLi>
	);
}

export default CharacterItem;