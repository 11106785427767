import React, {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import {ADMIN_GROUPS_ID, route} from "../../../../utils/constants_urls";
import {fetchApiPath} from "../../../../utils/axios";
import useToken from "../../../hooks/useToken";
import {useSelector} from "react-redux";

import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {
	H3PopUp,
} from "../../monetization/styled-monetization/StyledMonetization";

import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {
	DeleteButtonGroup, HaveCoursesMessage,
	SaveButtonGroup,
	WrapperButtonGroupEdit
} from "../StyledCourse";

import close from "../../../create-meeting/img/close.png";


const GroupEdit = ({groupEditIsOpen, deleteGroupIsOpen, courses, getGroup}) => {

	const {groupItem} = useSelector(state => state.course);
	const {token} = useToken();
	const [value, setValue] = useState(groupItem.group_name);
	const [haveCourses, setHaveCourses] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetchEdit = async () => {
		setLoading(true);
		const url = route(ADMIN_GROUPS_ID, [':group_id', groupItem.group_id]);
		const data = {
			"group_name": value
		};

		const result = await fetchApiPath({url, token, data});
		getGroup();
		groupEditIsOpen();
		setLoading(false);
	}

	const isHaveCourses = courses.filter(course => course.group_id === groupItem?.group_id);

	const checkCourse = () => {
	  if(isHaveCourses.length){
			setHaveCourses(true);
			setDisabled(true);
		} else {
			deleteGroupIsOpen();
		}
	}
	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn onClick={groupEditIsOpen}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Group edit</H3PopUp>
						<input className="editGroupInput"
									 value={value}
									 type="text"
									 onChange={(el) => setValue(el.target.value)}/>
					<WrapperButtonGroupEdit>
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<>
								<DeleteButtonGroup onClick={checkCourse} disabled={disabled} >Delete group</DeleteButtonGroup>
								<SaveButtonGroup onClick={fetchEdit} >Save</SaveButtonGroup>
							</>
						)}
					</WrapperButtonGroupEdit>
					{haveCourses && <HaveCoursesMessage>You cannot delete a group while there are related courses!</HaveCoursesMessage>}
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>
	);
}

export default GroupEdit;