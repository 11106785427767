import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
	entertainerNameForSave,
} from "../../../../store/slicers/name-slice";

import {UlSearchItem} from "../../styled-create-meeting/search-styled/SearchStyled";

function SearchItemEntertainer({item, us}) {

	const {parentNameFor} = useSelector(state => state.name);

	const [user, setUser] = useState(item);

	const dispatch = useDispatch();


	const onclick = (event) => {

		us(user);
		dispatch(entertainerNameForSave(user));

		if(user && parentNameFor){

			if(user.phone === parentNameFor.phone){
				us(null);
				event.currentTarget.classList.add('show-message');
			}
		}

	}

	return (
		<>
			<UlSearchItem onClick={onclick} className="ul-search-item">
				<li onClick={onclick}>{item.first_name} {item.last_name}</li>
				<li>{item.phone}</li>
			</UlSearchItem>
		</>
	);
}


export default SearchItemEntertainer;