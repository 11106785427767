import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import useFunc from "../hooks/useFunc";
import UpdateToSend from "./UpdateToSend";
import ParentUpdateGetSee from "./ParentUpdateGetSee";

import {
	FilterModalContent,
	FilterModalWrapper
} from "./update-meeting-styled/UpdateMeetingStyled";
import {callForDeleteSave} from "../../store/slicers/save-call-for-delete-slice";

const UpdateMeeting = ({ timeStampEnd, timeStampStart, setShowAlert}) => {

	const {modalPropsMeetingId} = useSelector(state => state.modal);
	const {isOpenMeetingFilter} = useSelector(state => state.modal)

	const [loading, setLoading] = useState(true);
	const [editMeeting, setEditMeeting] = useState(false);
	const {onHide} = useFunc();
	const dispatch = useDispatch();

	useEffect(() => {
		if (modalPropsMeetingId?.id) {
			setLoading(false);
			dispatch(callForDeleteSave(modalPropsMeetingId));
		}
	}, [modalPropsMeetingId]);

	const handlerUpdate = () => {
		setEditMeeting(true);
	}
	const handleOnHide = () => {

		onHide();
		setEditMeeting(false);
	}

	return (
		<>
			{isOpenMeetingFilter &&
				<FilterModalWrapper>
					<FilterModalContent>
						{!editMeeting ? (
							<>
								{
									loading ? (
										<ClipLoader color={"#C014E3"} loading={loading} size={150}/>
									) : (
										<ParentUpdateGetSee handlerUpdate={handlerUpdate}
																				handleOnHide={handleOnHide}/>
									)
								}
							</>
						) : (
							<UpdateToSend timeStampEnd={timeStampEnd}
																timeStampStart={timeStampStart}
																setShowAlert={setShowAlert}
																setEditMeeting={setEditMeeting}/>
						)}

					</FilterModalContent>
				</FilterModalWrapper>
			}
		</>
	);
}

export default UpdateMeeting;