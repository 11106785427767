import React from 'react';
import {Button, FieldContainer, Label, Select} from "../StyledConfig";

const ExtendedOnboarding = ({isEditing, extendedOnboarding, setExtendedOnboarding, handlePublish, handleDiscard, setIsEditing}) => {

	const editHandler = () => {
		handleDiscard('extendedOnboarding')
		setIsEditing((prevState) => ({...prevState, extendedOnboarding: true}))
	}


	return (
		<FieldContainer>
			<Label>Extended Onboarding:</Label>
			{isEditing.extendedOnboarding ? (
				<Select
					value={extendedOnboarding}
					onChange={(e) => setExtendedOnboarding(e.target.value)}
				>
					<option value="yes">yes (default)</option>
					<option value="no">no</option>
				</Select>
			) : (
				<div style={{width: "150px"}}>
					<span>{extendedOnboarding} </span>
				</div>
			)}
			{isEditing.extendedOnboarding ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('extendedOnboarding')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('extendedOnboarding')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>
	);
};

export default ExtendedOnboarding;