import {createSlice} from '@reduxjs/toolkit';


const initialState = {
	callForDelete: {}
}
const saveCallForDeleteSlice = createSlice({
	name: "deleteCall",
	initialState,
	reducers: {
		callForDeleteSave(state, action){
			state.callForDelete = action.payload;
		},
		callForDeleteClear: (state, action) =>{
			state.callForDelete = null;
		},
	},

})

export const {
	callForDeleteSave,
	callForDeleteClear,

} = saveCallForDeleteSlice.actions;

export default saveCallForDeleteSlice.reducer;
