import React from 'react';
import { FieldContainer, Label} from "../StyledConfig";

const RtServers = ({rTServers}) => {
	return (
		<FieldContainer>
			<Label>RT Servers:</Label>
				<div style={{width: "200px"}}>
					{rTServers && rTServers.length ? (
						<>
							{
								rTServers && rTServers.map( (item, index) => (
									<p key={index}>{item}</p>
								))
							}
						</>
					) : (null)}
				</div>
			<div></div>
		</FieldContainer>
	);
};

export default RtServers;