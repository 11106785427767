import React from 'react';

import SearchFilterCollection from "../search-filter-collection/SearchFilterCollection";

import {
	InputKid,
	LabelSearchFilter,
	LabelSearchFilterWrapper,
	SearchDiv
} from "../search-meeting-styled/GetKidMeetingStyled";


function LabelSearchFilterWr({handleChangeKidMeeting, items, elChange, setKid}) {

	const onKidSelect = (kidIn) => {
		setKid(kidIn)
	}

	return (
		<>
			<LabelSearchFilterWrapper>
				<LabelSearchFilter>
					<InputKid type="text"
										onChange={handleChangeKidMeeting}
										placeholder="parent"
										id="inputKidSearch"
										maxLength='16'/>
				</LabelSearchFilter>
				<>
					{elChange.length === 0 ? (
						<>
						</>
					) : (
						<>
							<SearchDiv>
								<SearchFilterCollection onPersonSelect={onKidSelect}
																				items={items}
																				setKid={setKid}/>
							</SearchDiv>
						</>
					)}
				</>
			</LabelSearchFilterWrapper>
		</>
	);
}

export default LabelSearchFilterWr;