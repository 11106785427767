import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {lessonItemClear} from "../../../store/slicers/course-slice";

import {
	CourseNameDiv,
	LessonNameWrapper
} from "../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled";

import imgArr from '../img/Vector (Stroke).png';

const LessonName = () => {

	const {lessonItem} = useSelector(state => state.course);

	const dispatch = useDispatch();

	return (
			<LessonNameWrapper onClick={() => dispatch(lessonItemClear())}>
				<CourseNameDiv>
					{lessonItem.name.uk ? lessonItem.name.uk : lessonItem.name.en || lessonItem.name.ru}
				</CourseNameDiv>
				<img src={imgArr}/>
			</LessonNameWrapper>
	);
};

export default LessonName;