import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import ContentEdit from "../meetings-options/meeting-content/ContentEdit";
import {setSelectChild} from "../../store/slicers/slice-modal";
import {
	entertainerNameForSave,
	parentNameForSave
} from "../../store/slicers/name-slice";

import "../create-meeting/modal-meeting/modal.sass";


const UpdateToSend = (props) => {

	const {
		setShowAlert,
		setEditMeeting,
		timeStampEnd,
		timeStampStart
	} = props;

	const dispatch = useDispatch();

	const {modalPropsMeetingId} = useSelector(state => state.modal);

	const	entertainerName = JSON.parse(localStorage.getItem(`${modalPropsMeetingId?.id}`));
	const parentName = JSON.parse(localStorage.getItem(`${modalPropsMeetingId?.parent_id}`));
	const dependents = JSON.parse(localStorage.getItem(`${modalPropsMeetingId.parent_id}dependents`));

	const [user, setUser] = useState(entertainerName);
	const [kid, setKid] = useState(parentName);
	const [selectCharacter, setSelectCharacter] = useState(modalPropsMeetingId.character);
	const [wishes, setWishes] = useState(modalPropsMeetingId.meeting_description);
	const meetingId = modalPropsMeetingId.meeting_id;


	useEffect(() => {
		if(dependents){
			if(dependents.length === 1){
				dispatch(setSelectChild(dependents[0].dependent_id));
			}
		}
	}, []);

	useEffect(() => {
		if(parentName){
			dispatch(parentNameForSave(kid));
		}
	},[parentName]);

	useEffect(() => {
		if(entertainerName){
			dispatch(entertainerNameForSave(user));
		}
	},[entertainerName]);

	return (

			<ContentEdit entertainerName={entertainerName}
									 parentName={parentName}
									 meetingShow={modalPropsMeetingId}
									 wishes={wishes} selectCharacter={selectCharacter}
									 meetingId={meetingId}
									 setEditMeeting={setEditMeeting}
									 setShowAlert={setShowAlert}
									 timeStampEnd={timeStampEnd}
									 timeStampStart={timeStampStart}/>

	);
}

export default UpdateToSend;