import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";

import ContentShow from "./meeting-content/ContentShow";
import ContentEdit from "./meeting-content/ContentEdit";
import {addPropsMeetingId} from "../../store/slicers/slice-modal";
import {callForDeleteSave} from "../../store/slicers/save-call-for-delete-slice";

const ShowMeetingContent = (props) => {

	const {
		meetingShow,
		setShowAlert,
		withoutEntertainer,
		timeStampStart,
		timeStampEnd,
		setShownContent,
	} = props

	const dispatch = useDispatch();

	const [editMeeting, setEditMeeting] = useState(false);
	const [selectCharacter, setSelectCharacter] = useState(null);
	const [meetingId, setMeetingId] = useState(null);
	const [timeToSend, setTimeToSend] = useState(null);
	const [entertainerName, setEntertainerName] = useState('');
	const [parentName, setParentName] = useState('');
	const [wishes, setWishes] = useState(null);

	useEffect(() => {
		if(meetingShow){
			setTimeToSend( meetingShow.slot_time.start_time);
			setMeetingId(meetingShow.meeting_id);
			dispatch(addPropsMeetingId(meetingShow.meeting_id));
			dispatch(callForDeleteSave(meetingShow));
			setSelectCharacter(meetingShow.character)
			setWishes(meetingShow.meeting_description);
			setEntertainerName(JSON.parse(localStorage.getItem(`${meetingShow.id}`)));
			setParentName(JSON.parse(localStorage.getItem(`${meetingShow.parent_id}`)));
		}
	},[meetingShow]);

	const handlerEdit = () => {
		setEditMeeting(true);
	}

	return (
		<>
			{!editMeeting ? (
				<>
					{meetingShow &&
						<ContentShow handlerEdit={handlerEdit}
												 parentName={parentName}
												 entertainerName={entertainerName}
												 meetingId={meetingId}
												 withoutEntertainer={withoutEntertainer}
												 timeToSend={timeToSend}
												 timeStampStart={timeStampStart}
												 timeStampEnd={timeStampEnd}
												 setShowAlert={setShowAlert}
												 setShownContent={setShownContent}
												 meetingShow={meetingShow}
												 wishes={wishes}
												 selectCharacter={selectCharacter}
						/>
					}
				</>
			) : (
				<ContentEdit selectCharacter={selectCharacter}
										 meetingId={meetingId}
										 parentName={parentName}
										 entertainerName={entertainerName}
										 setEditMeeting={setEditMeeting}
										 setShowAlert={setShowAlert}
										 timeStampStart={timeStampStart}
										 timeStampEnd={timeStampEnd}
										 meetingShow={meetingShow}
										 wishes={wishes}
				/>

			)}
		</>
	);
};

export default ShowMeetingContent;