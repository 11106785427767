import React from 'react';
import SearchItemEntertainer from "../search-item/SearchItemEntertainer";

import {SearchWrapperEntertainer} from '../../styled-create-meeting/search-styled/SearchCollectionStyled';

function SearchCollection(props) {

	const items = props.items;
	const us = props.onPersonSelect;

	return (
		<>
			<SearchWrapperEntertainer>
				{items &&
					items.map( item =>
						<SearchItemEntertainer
								key={item.phone}
								item={item}
								us={us}/>
					)
				}
			</SearchWrapperEntertainer>
		</>
	);
}

export default SearchCollection;