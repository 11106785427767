import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import {monetizationItemRemove, openDeleteFalse} from "../../../../store/slicers/monitization-slice";


import {fetchApiDelete} from "../../../../utils/axios";
import {ADMIN_PACKAGES_BY_ID, route} from "../../../../utils/constants_urls";
import useToken from "../../../hooks/useToken";

import {
	BackButton, DeleteButtonPop,
	H3PopUp,
	PPopUp,
	WrapperButtonPopUp
} from "../styled-monetization/StyledMonetization";
import {WrapperForModalMeeting} from "../../../create-meeting/styled-create-meeting/CreateMeetingStyled";
import {ModalContentCancelEditing} from "../../create-slot/create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";

import close from "../../../create-meeting/img/close.png";

function ObjectDelete(props) {

	const {monetizationItem} = useSelector(state => state.monetization);
	const dispatch = useDispatch();
	const {token} = useToken();
	const [loading, setLoading] = useState(false);

	const handlerDelete = async () => {
		setLoading(true)
		const url = route(ADMIN_PACKAGES_BY_ID, [':packageId', monetizationItem.package_id]);
		const response = await fetchApiDelete({url, token});
		dispatch(openDeleteFalse());
		dispatch(monetizationItemRemove());
		setLoading(false);
	}

	return (
		<WrapperForModalMeeting>
			<div className="modal-meeting active">
				<ModalContentCancelEditing>
					<CloseBtn  onClick={() => dispatch(openDeleteFalse())}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<H3PopUp>Object delete</H3PopUp>
					<PPopUp>Delete Object {monetizationItem?.name.en}?</PPopUp>
					<WrapperButtonPopUp>
						{loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={30}/>
						) : (
							<>
								<BackButton onClick={() => dispatch(openDeleteFalse())}>Back</BackButton>
								<Link to="/monetization"><DeleteButtonPop onClick={handlerDelete}>Delete object</DeleteButtonPop></Link>
							</>
						)}
					</WrapperButtonPopUp>
				</ModalContentCancelEditing>
			</div>
		</WrapperForModalMeeting>
	);
}

export default ObjectDelete;