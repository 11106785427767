import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import useToken from "../../hooks/useToken";
import {openIsDeleteRepeatable} from "../../../store/slicers/slice-modal";
import {ADMIN_USER_BY_ID, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import {
	closeCreateTimeOffSlot,
	closeInfoSlots,
	openCreateSlots,
	openDeleteSlots,
	slotInfoClear
} from "../../../store/slicers/create-meeting-slice";

import {H4CreateSlots, ModalContentSlotsViewing} from "./create-modal/StyledcreateSlotsModal";
import {CloseBtn} from "../../create-meeting/styled-create-meeting/modal-meeting-styled/ModalMeetingStyled";
import {PEntertainer, SlotInfoWrapper} from "./StyledSlots";
import {
	WrapperForModalSlotViewing
} from "../../create-meeting/styled-create-meeting/CreateMeetingStyled";

import editImg from "./img/edit.png";
import deleteImg from "./img/del.png";
import calendarImg from "./img/Calendar icon.png";
import avatarImg from "./img/Image.png";
import timeImg from "./img/Vector.png";
import close from "../../create-meeting/img/close.png";



function ViewingSlotInfo(props) {

	const {slotInfo} = useSelector(state => state.create);
	const {isOpenCreateTimeOffSlot} = useSelector(state => state.create);
	const {token} = useToken();

	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		const getProfileByUserId = async () => {
			const url =  route(ADMIN_USER_BY_ID, [':userId', slotInfo.id]);
			const result = await fetchApiGet({url, token});
			setUser(result);
			setLoading(false);
		}
		getProfileByUserId();
	},[]);

	const date = `${moment(slotInfo.slot_time.end_time).format("D")} ${moment(slotInfo.slot_time.end_time).format("MMM")}`;

	const closeBtn = () => {
	  dispatch(slotInfoClear());
		dispatch(closeInfoSlots());
		dispatch(closeCreateTimeOffSlot());

	}
	const handlerDelete = () => {
		if(slotInfo.is_repeatable){
			dispatch(openIsDeleteRepeatable());
		} else {
			dispatch(openDeleteSlots());
		}
	}
	return (
		<WrapperForModalSlotViewing>
			<div className="modal-meeting active">
				<ModalContentSlotsViewing>
					<CloseBtn  onClick={closeBtn}>
						<img src={close} alt={close}/>
					</CloseBtn>
					<div className="modal-content-meeting-div" >
						<div style={{display: "flex", justifyContent: "space-between", marginRight: "40px"}}>
							<H4CreateSlots>{isOpenCreateTimeOffSlot ? "Time Off Info" : "Reserved Slot Info"}</H4CreateSlots>
							<div  style={{display: "flex", gap: "16px"}}>
								<div onClick={() => dispatch(openCreateSlots())}><img src={editImg}/></div>
								<div onClick={handlerDelete}><img src={deleteImg}/></div>
							</div>
						</div>
						<PEntertainer>Entertainer</PEntertainer>
						<SlotInfoWrapper>
							{ loading ? <ClipLoader color={"#C014E3"} loading={loading} size={30}/> :
								<li>
									<img className="avatar" src={avatarImg}/>
									<span>{user.first_name} {user.last_name}</span>
								</li>
							}
							<li>
								<img src={timeImg}/>
								<span>{moment(slotInfo.slot_time.start_time).format("HH:mm")}-{moment(slotInfo.slot_time.end_time).format("HH:mm")}</span>
							</li>
							<li>
								<img src={calendarImg}/>
								<span>{date}</span>
							</li>
						</SlotInfoWrapper>
					</div>
				</ModalContentSlotsViewing>
			</div>
		</WrapperForModalSlotViewing>
	);
}

export default ViewingSlotInfo;