import React from 'react';

import './checkbox.sass';

const Checkbox = ({ myState, handleChangeState }) => {

    return(
        <>
            <div className={myState === true ? "switch" : "switch-active"}
                 onClick={handleChangeState}>
                <div className="switch-button"></div>
            </div>
        </>
    );
}
 export default Checkbox;