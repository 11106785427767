
export const QUANTITY_OF_TIMELINE_ELEMENTS = 72;
export const QUANTITY_OF_MINUTES_ELEMENTS_INTERVAL = 20;
export const TOTAL_DAYS = 7;
export const START_OF_WEEKEND = 6;
export const END_OF_WEEKEND = 0;
export const ALL = 'all';
export const TOKEN = localStorage.getItem("token");
export const ZERO = 0;
export const ZERO_STRING = '0';
export const CLEAR_LINE = '';
export const ONE = '1';
export const TWO = '2';
export const THREE = '3';
export const ROLE_USER = 1;
export const ROLE_ENTERTAINER = 2;
export const ROLE_ADMIN = 3;
export const ROLE_OBSERVER = 4;
export const ROLE_USER_STRING = 'User';
export const ROLE_ENTERTAINER_STRING = 'Entertainer';
export const ROLE_ADMIN_STRING = 'Admin';
export const ROLE_OBSERVER_STRING = 'Observer';
export const WEEK = 'week';
export const DAY = 'day';
export const HOURS = 'hours';
export const MINUTES = 'minutes';
export const TIME_ZONE = 0;
// export const TIME_ZONE = new Date().getTimezoneOffset() * 60 * 1000;
export const ONE_NUMBER = 1;
// export const ALL_MEETINGS = [];
export const TWENTY_MINUTES = 20*60*1000;
export const ISO_STRING_START = "T00:00:00.000Z";
export const ISO_STRING_END = "T23:59:59.000Z";

export const MIN_FINAL_PRISE_USD = 0.5;
export const MIN_FINAL_PRISE_UAH = 25;
export const DISABLE_LANGUAGE_STEP = 'disable_language_step';
export const EXTENDED_ONBOARDING = 'extended_onboarding';
export const STARTING_BALANCE = 'starting_balance';
export const SUPPORT_LINK = 'support_link';
export const DEFAULT_LANGUAGE = 'default_language';
export const SURNAME_FIELD_ON_CREATION = 'surname_field_on_creation';
export const INTRODUCTION_LESSON_REQUIRED = 'introduction_lesson_required';
export const RT_SERVERS = 'rt_servers';
export const WAYFORPAY_MERCHANT = 'wayforpay_merchant';
export const WAYFORPAY_DOMAIN = 'wayforpay_domain';
export const ALLOW_JOIN_PASSED_MEETINGS = 'allow_join_passed_meetings';

