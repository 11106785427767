import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {courseItemClear, courseItemSave, lessonItemClear} from "../../../store/slicers/course-slice";
import InfoBodyCourse from "./CourseInfoBody";
import CourseSelectLanguage from "./CourseSelectLanguage";
import {ADMIN_COURSES_BY_ID, ADMIN_COURSES_LINKING, route} from "../../../utils/constants_urls";
import {fetchApiGet} from "../../../utils/axios";
import useToken from "../../hooks/useToken";

import btnSquare from "../monetization/img/BtnSquare.png";
import plus from "../monetization/img/Add.png";


import {
	ButtonBackCourse,
	ButtonInfoCourseAdd,
	ButtonInfoCourseEdit,
	ContentWrapperCourseInfo,
	CourseLessonsWrapper,
	GroupInfoHeaderBtnWrapper,
	HeaderButtonsWrapperCourseInfo,
	PCourseLessons
} from "./StyledCourse";

import LessonInfoItem from "./LessonInfoItem";

function CourseInfo(props) {
	const {courseItem} = useSelector(state => state.course);
	const {loadingCourse} = useSelector(state => state.loading);
	const {token} = useToken();
	let params = useParams();
	const dispatch = useDispatch();
	const [course, setCourse] = useState(null);
	const [loading, setLoading] = useState(loadingCourse);

	const arrLang = [

		{name: 'English', key: 'English', selected: false, langId: "en", isDefault: false},
		{name: 'Ukrainian', key: 'Ukrainian', selected: false, langId: "uk", isDefault:false },
		{name: 'Russian', key: 'Russian', selected: false, langId: "ru", isDefault: false},

	]
	const [arrLanguage, setArrLanguage] = useState(arrLang.filter(el => el.langId));
	useEffect(()=>{
		if(courseItem && courseItem.name){
			setArrLanguage(arrLang.filter(el => {
				Object.keys(courseItem.name).filter(i => el.langId === i)
			}))
		}
	},[courseItem])
	const setLangHandler = (arrLanguage) => {
		setArrLanguage(prevState => prevState.map(lang => ({
			...lang,
			selected: arrLanguage === lang.name
		})));
	}
	const selectedLanguage = arrLanguage.filter(({selected}) => selected)[0];

	useEffect(()=>{
		setCourse(courseItem);
	},[courseItem]);
	useEffect(() => {
		setLoading(loadingCourse);
	}, [loadingCourse]);

	useEffect( () => {
		const getCoursesById = async () => {
			const url = route(ADMIN_COURSES_BY_ID, [':courseId', params.id]);
			const response = await fetchApiGet({url, token});
			// setCourse(response);
			dispatch(courseItemSave(response));

			setLoading(false);
		}
		 getCoursesById();
	},[]);

	const getLocale = (locale) => {
		const result = {name: '', description: ''}
		if (course.name[locale]) {
			result.name = course.name[locale]
		}
		if (course.description[locale]) {
			result.description = course.description[locale]
		}
		return result;
	}

	const addLocale = (locale, name, description) => {
		const prototype = arrLang.find(element => element.langId === locale);
		if (prototype === undefined) return;
		const persist = arrLanguage.find(element => element.langId === locale);
		if (persist !== undefined) return;
		arrLanguage.push(prototype);
		const arr = [...arrLanguage]
		setArrLanguage(arr);
	}

	useEffect(() => {
		if (course && course.course_id){
			arrLang.forEach( lang => {
				const infoLoc = getLocale(lang.langId);
				if (infoLoc.name || infoLoc.description){
					addLocale(lang.langId, infoLoc.name, infoLoc.description);
				}
			})
		}

	},[course]);



	return (
		<Row className="dashboard-calendar">
					<h2 className="dashboard-h2">Course Info</h2>
					<ContentWrapperCourseInfo>
						<HeaderButtonsWrapperCourseInfo>
							<Link to="/course">
								<ButtonBackCourse onClick={() =>dispatch(courseItemClear())}>
									<img src={btnSquare}/>Back
								</ButtonBackCourse>
							</Link>
							<GroupInfoHeaderBtnWrapper>
								<CourseSelectLanguage selectedLanguage={selectedLanguage}
																			arrLanguage={arrLanguage}
																			setLangHandler={setLangHandler}/>
								<Link to="/create-course">
								<ButtonInfoCourseEdit>Edit</ButtonInfoCourseEdit>
								</Link>
								<Link to="/create-lesson">
									<ButtonInfoCourseAdd onClick={() => dispatch(lessonItemClear())}><img src={plus}/> Add lesson</ButtonInfoCourseAdd>
								</Link>
							</GroupInfoHeaderBtnWrapper>
						</HeaderButtonsWrapperCourseInfo>
						{ loading ? (
							<ClipLoader color={"#C014E3"} loading={loading} size={300}/>
						) : (
							<>
								{
									arrLanguage.map(({name, selected, key}) => (
										<span key={key}>
											{
												selected ? 	<InfoBodyCourse course={course}  id={key}/>: null
											}
										</span>
									))
								}

								<CourseLessonsWrapper>
									<PCourseLessons>Course lessons</PCourseLessons>
									<ul>
										{ course && course.lessons &&
											course.lessons.map(lesson => (
												<div key={lesson.lesson_id}>
													{
														arrLanguage.map(({name, selected, key}) => (
															<span key={key}>
														{
															selected ? <LessonInfoItem  lesson={lesson} id={key}/> : null
														}
													</span>
														))
													}
												</div>

											))
										}
									</ul>
								</CourseLessonsWrapper></>
						)}
					</ContentWrapperCourseInfo>
		</Row>
	);
}

export default CourseInfo;