import React, {useRef} from 'react';
import styled from "styled-components";
import {Button} from "react-bootstrap";

const WindowWrapper = styled.div`
  background-color: #F8F9FA;
  width: 100%;
  height: calc(100vh - 130px);
  position: absolute;
  z-index: 249;
  border-radius: 6px;
	top: 50px;
	left: 0;
`;

const Window = styled.div`
	background-color: #DBE2EA;
	margin: 0 10px 10px 10px;
	width: calc(100% - 20px);
	height: calc(100vh - 150px);
	position: absolute;
	z-index: 250;
	border-radius: 6px;
  top: 10px;
  left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	& p {
    font-size: 16px;
    font-weight: 700;
    color: #554D66;
  }
`;

function StartWindow({onButtonClick}) {
	return (
		<WindowWrapper>
			<Window>
					<p>Select Entertainer to view calendar</p>
					<Button onClick={onButtonClick}>Search for Entertainer</Button>
			</Window>
		</WindowWrapper>
	);
}

export default StartWindow;