import React, {useState} from 'react';
import moment from 'moment';
import styled from "styled-components";
import MonitorSmall from "./MonitorSmall";


const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: .5%;
  grid-row-gap: 4px;
  width: 100%;
  background-color:  ${props => props.isHeader ? '#ffffff' : '#F8F9FA'};
	padding: 5px 5px 5px 5px;
  border-radius: 6px;
`;
const CellWrapper = styled.div`
  min-width: 24px;
  min-height: ${props => props.isHeader ? 24 : 24}px;
  background-color: ${props => props.isWeekend ? '#F1F2F3' : '#ffffff'};
  color: #A9A9A9;
  border-radius: 2px;
  border:${props => props.isHeader ? 'none' : '1px solid #E0E0E0'};

`;
const RowInCell = styled.div`
	display: flex;
	color: ${props => props.isSelectedMonth ? 'gray' : '#A9A9A9'};
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-and'};`;
const DayWrapper = styled.div`
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
  justify-content: center;
	margin: 2px;
`;
const CurrentDay = styled.div`
  height: 100%;
  width: 100%;
  background: #2545d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
	color: #ffffff;
`;
const CalendarWrapper = styled.div`
	position: absolute;
	z-index: 5;
	width: 300px;
	background-color: #F8F9FA;
	border-radius: 6px;
	//border: 1px solid gray;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
	left: -60px;
	top: 30px;
`;

const DayInterval = ({onDaySelect}) => {

	const [toDays, setToday] = useState(moment())
	const startDays = toDays.clone().startOf('month').startOf('week');

	const totalDays = 42;
	const day = startDays.clone().subtract(1, 'day');
	const daysArray = [...Array(totalDays)].map(() => day.add(1, 'day').clone());

	const isCurrentDay = (day) => moment().isSame(day, 'day');
	const isSelectedMonth = (day) => toDays.isSame(day, 'month');

	return (
		<CalendarWrapper onClick={(e)=> e.stopPropagation()}>
			<MonitorSmall toDays={toDays} setToday={setToday} />
			<GridWrapper isHeader>
				{
					[...Array(7)].map((_, i) =>(
						<CellWrapper isHeader isSelectedMonth key={i}>
							<RowInCell justifyContent={'center'} >
								{moment().day(i + 1).format('ddd')}
							</RowInCell>
						</CellWrapper>
					))
				}
			</GridWrapper>
			<GridWrapper className="calendar-grid">
				{
					daysArray.map((dayItem) => (
						<CellWrapper
							key={dayItem.unix()}
							isSelectedMonth={isSelectedMonth(dayItem)}
							isWeekend={dayItem.day() === 6 || dayItem.day() === 0}>
							<RowInCell
								justifyContent={'center'}
								isSelectedMonth={isSelectedMonth(dayItem)}
								onClick={()=>onDaySelect(dayItem.format("x"))}>
								<DayWrapper>
									{!isCurrentDay(dayItem) && dayItem.format('D')}
									{isCurrentDay(dayItem) && <CurrentDay>{dayItem.format('D')}</CurrentDay>}
								</DayWrapper>
							</RowInCell>
						</CellWrapper>
					))
				}
			</GridWrapper>
		</CalendarWrapper>
	);
}

export default DayInterval;