import React from "react";
import styled from "styled-components";
import plus from "../img/plus.png";
export const ModalContentOptionsWrapper = styled.div`
  height: calc(100vh - 200px);
  background-color: rgba(0, 0, 0, 0.0001);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  transition: .5s;
  opacity: 1;
  pointer-events: all;
  position: relative;
  
`;
export const ModalContentOptionsMeetings = styled.div`
  position: absolute;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #F8F9FA;
  border: 1px solid #C6C2CF;
  border-radius: 8px;
  width: 650px;
  min-height: 400px;
	max-height: 787px;
  padding-top: 15px;
  padding-left: 13px;
`;
export const ChoiceCall = styled.div`
	display: flex;
  & button, input{
    border: 1px solid #DBE2EA;
    border-radius: 6px;
    padding: 11px 0;
    height: 40px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7C9CBF;
    background-color: #f8f9fa;
    filter: drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04));
	}
	& .div-for-collection{
		
	}
	& label {
		position: relative;
		width: 232px;
    & input {
      outline: none;
			position: absolute;
      background-image: url(${plus});
      background-repeat: no-repeat;
      background-position: 195px 12px;
			left: 0;
      z-index: 255;
    }
	}

	& .name-time {
    width: 221px;
    padding-left: 16px;
    margin-right: 10px;
    text-align: left;
	}
	& .new-call{
    width: 206px;
    text-align: center;
	}
  & .active{
    color: #fff;
    background-color: #1386f0;
    border: 1px solid #327cc5;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    padding-top: 9px;
    padding-bottom: 10px;
	}
`;
export const ChoiceUsers = styled.div`
  margin-top: 17px;
	margin-bottom: 20px;
	& button{
    background-color: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
		border: none;
    border-radius: 8px;
    margin-right: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #554D66;
	}
	& button:first-child{
    margin-left: 8px;
    padding: 4px 23px;
	}
	& button:nth-child(2){
    padding: 4px 18px;
	}
	& button:nth-child(3){
    padding: 4px 22px
	}
	& button:last-child{
    padding: 4px 19px;
	}
	& .active{
    background: #1485ED;
    border-radius: 6px;
    border: none;
    color: #FFF;
    padding-bottom: 5px !important;
	}
`;

export const SearchWrapperUser = styled.ul`
  overflow-y: auto;
	max-height: 250px;
	position: absolute;
  z-index: 245;
	background-color: #ffffff;
	width: 221px;
	border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
	border: 1px solid #DBE2EA;
	top: 50px;
	left: 13px;
`;

export const UlSelectedUser = styled.ul`
  border: 1px solid #DBE2EA;
  border-radius: 6px;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04));
  width: 222px;
	background-color: #ffffff;
	padding: 7px 10px 7px 10px;
	margin-right: 10px;
	margin-bottom: 0;
	& li{
		display: flex;
		justify-content: space-between;
		& div.img-clear{
      width: 12px;
			& img{
				width: 100%;
				margin-bottom: 2px;
			}
    }
	}
`;

export const WrapperForScrollMeeting = styled.div`
  overflow-y: auto;
  max-height: 550px;
	padding-bottom: 20px;
`;
export const PaginationWrapper = styled.div`
	position: absolute;
	top: 60px;
	left: 410px;
	color: #2C2738;
	& button{
		border: none;
		padding: 5px 10px;
		border-radius: 5px;
		background-color: #1485ED;
		color: white;
		margin: 0px 10px;
	}
`;
