import React from "react";
import {converterMS, converterToTime} from "../../../helpers/converterMS";


export function ReportCallItem(props) {

    return (
        <ol className='table-up'>
            <li>{converterMS(props.item.start_time)}</li>
            <li>{converterToTime(props.item.start_time)}</li>
            <li>{props.item.parent.name}</li>
            <li>{props.item.child.name}</li>
            <li></li>
            <li></li>
        </ol>
    )
}