import React from 'react';
import SearchItemCourse from "../search-item/SearchItemCourse";

import {
	SearchWrapperCourse,
} from "../../styled-create-meeting/search-styled/SearchCollectionStyled";

const SearchCollectionCourse = ({clearValue, items, kid}) => {

	return (
		<>
			<SearchWrapperCourse>
				{items &&
					items
						.map( item =>
							<SearchItemCourse item={item}
																kid={kid}
																clearValue={clearValue}
																key={item.course_id}/>
					)
				}
			</SearchWrapperCourse>
		</>
	);
}

export default SearchCollectionCourse;