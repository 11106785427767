import React from "react";
import styled from "styled-components";

export const RenderDivChildName = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #DBE2EA;
  background-color: #ffffff;
  height: 42px;
  padding: 5px 10px 0px 10px;
	box-sizing: border-box;
  margin: 7px 0px;
  width: 210px;
`;
export const UlOneKidName = styled.ul`
`;

export const LabelChild = styled.label`
  margin: 7px 0px;
	width: 210px;
`;


export const ChildrenUl = styled.ul`
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: #ffffff;
  width: 210px;
	max-height: 150px;
  overflow-y: auto;
	z-index: 255;
  & li {
    padding: 10px;
    border-bottom: 1px solid #DBE2EA;
  }
  & li:first-child {
    padding: 10px;
    border-top: 1px solid #DBE2EA;
    border-right: 1px solid #DBE2EA;
    border-left: 1px solid #DBE2EA;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  & li:last-child {
    padding: 10px;
    border-bottom: 1px solid #DBE2EA;
    border-right: 1px solid #DBE2EA;
    border-left: 1px solid #DBE2EA;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const DivImgClockTime = styled.div`
  display: flex;
  margin-bottom: 20px;
  color: #554D66;
  position: relative;
	& .div-img{
    margin-right: 10px;
	}
  
`;
export const WrapperRelative = styled.div`
  position: relative;
	//display: flex;
`;
export const CharacterUl = styled.ul`
  overflow-y: auto;
  max-height: 150px;
  position: absolute;
  z-index: 230;
  background-color: #ffffff;
  width: 210px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #DBE2EA;
  top: 80px;
	left: 0px;

	& li {
    font-size: 14px;
    color:#554D66;
    padding: 2px 0px 2px 16px;
    margin-top: 5px;
    border-radius: 5px;
    &:hover{
      background-color: #E0E6EC;
			cursor: pointer;
    }
	}
`;

export const LabelCharacter = styled.label`
  
`;

export const CharacterLi = styled.li`
	display : flex;
	gap: 10px;
	left: 50px;
  align-items: baseline;
	justify-content: space-between;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #DBE2EA;
  background-color: #ffffff;
  height: 40px;
  padding: 5px 10px 0px 10px;
  box-sizing: border-box;
  margin: 7px 0px;
  width: 210px;
`;

export const SelectChildWrapper = styled.div`
`;

export const CourseNameWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
	color: #554D66;
	width: 275px;
	display: flex;
	height: 40px;
  gap: 10px;
  align-items: center;
  margin: 10px 0px 0px 30px;
  & span{
    font-size: 14px;
    font-weight: 600;
  }
`;

export const CourseNameDiv = styled.div`
	display: flex;
	justify-content: space-between;
	width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LessonNameWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #DBE2EA;
  background-color: #ffffff;
  height: 42px;
  padding: 5px 10px 0px 10px;
  box-sizing: border-box;
  margin: 5px 0px;
  width: 210px;
	justify-content: space-between;
`;