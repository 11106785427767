import {createSlice} from '@reduxjs/toolkit';
import {getDataSchedule} from "../actions/schedule-action";
export const setLoading = (state, action) => {
	state.status = 'loading';
	state.error = null;
	state.statusCode = null;
}
export const setError = (state, action) => {
	state.status = 'rejected';
	state.error = action.payload;
	state.statusCode = null;
}

const scheduleSlice = createSlice({
	name: "schedule",
	initialState: {
		schedule: [],
		statusCode: null,
		statusCodeLogout: null,
		status: null,
		error: null,
	},

	reducers: {

	},
	extraReducers: {

		[getDataSchedule.pending]: setLoading,
		[getDataSchedule.fulfilled]: (state, action) => {
			state.status = 'resolved';
			state.schedule = action.payload; // todo CHECK IT!!!
			state.statusCode = action.payload.statusCode
		},
		[getDataSchedule.rejected]: setError,

	}

});

export default scheduleSlice.reducer;