import React from 'react';
import {useSelector} from "react-redux";

import HideContent from "./HideContent";

const ContentWithHide = ({ handleClick, shownContent}) => {

	const {modalPropsMeetings} = useSelector(state => state.modal);
	return (
		<>
			{
				modalPropsMeetings &&
				modalPropsMeetings
					.filter( item => !item.id)
					.map(item => (
						<HideContent key={item.meeting_id}
												 item={item}
												 handleClick={handleClick}
												 shownContent={shownContent}/>
					))
			}
			</>

	);
};

export default ContentWithHide;