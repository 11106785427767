import React from 'react';
import {Button, FieldContainer, Label, Select} from "../StyledConfig";

const IntroductoryLessonRequired = ({isEditing, introductoryLessonRequired,setIntroductoryLessonRequired, handlePublish,handleDiscard, setIsEditing}) => {


	const editHandler = () => {
		handleDiscard('introductoryLessonRequired')
		setIsEditing((prevState) => ({...prevState, introductoryLessonRequired: true}))
	}




	return (
		<FieldContainer>
			<Label>Introductory Lesson Required:</Label>
			{isEditing.introductoryLessonRequired ? (
				<Select
					value={introductoryLessonRequired}
					onChange={(e) => setIntroductoryLessonRequired(e.target.value)}
				>
					<option value="yes">yes (default)</option>
					<option value="no">no</option>
				</Select>
			) : (
				<div style={{width: "150px"}}>
					<span>{introductoryLessonRequired} </span>
				</div>
			)}
			{isEditing.introductoryLessonRequired ? (
				<div>
					<Button className="primary" onClick={() => handlePublish('introductoryLessonRequired')}>
						Publish changes
					</Button>
					<Button className="discard" onClick={() => handleDiscard('introductoryLessonRequired')}>
						Discard
					</Button>
				</div>
			) : (
				<Button onClick={editHandler}>
					Edit
				</Button>
			)}
		</FieldContainer>

	);
};

export default IntroductoryLessonRequired;