import {createSlice} from '@reduxjs/toolkit';
import {getProfileById} from "../actions/get-frofile-by-id-action";

const getProfileByIdSlice = createSlice({
	name: "userProfile",
	initialState: {
		userProfile: {},
		statusCode: null,
		status: null,
		error: null,
	},
	reducers: {

	},
	extraReducers:
		{
			//? get meeting by id
			[getProfileById.pending]: (state) => {
				state.status = 'loading';
				state.error = null;
				state.statusCode = null;
			},
			[getProfileById.fulfilled]: (state, action) => {
				state.status = 'resolved';
				state.userProfile = action.payload;
				state.error = null;
			},
			[getProfileById.rejected]: (state, action) => {
				state.status = 'rejected';
				state.error = action.payload;
				state.statusCode = null;
			},
		}
})
export default getProfileByIdSlice.reducer;