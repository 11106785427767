import {createSlice} from '@reduxjs/toolkit';
import {searchEntertainerMeetings} from "../actions/search-meetings-entertainer-action";


const meetingSearchSliceEntertainer = createSlice({
	name: "slots",
	initialState: {
		eventSearchListEntertainer: [],
	},
	reducers: {
		entertainerCallsFilter(state, action){
			state.eventSearchListEntertainer = action.payload;
		},
		clearEventSearchListEntertainer: (state, action) => {
			state.eventSearchListEntertainer = [];
		},
	},


})
export const {clearEventSearchListEntertainer, entertainerCallsFilter} = meetingSearchSliceEntertainer.actions;
export default meetingSearchSliceEntertainer.reducer;