import {createAsyncThunk} from '@reduxjs/toolkit';

export const getProfileEntertainerUpdate = createAsyncThunk(
	"get-entertainer/getProfileEntertainerUpdate",
	async function (entertainerNumber, {rejectWithValue}) {
		try {

			const res = await 	fetch(`/api/admin/get-profile-by-user-phone?phone=${entertainerNumber}`)
			const data = await  res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}

	}
);
