import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

import useOpen from "../../../hooks/useOpen";
import {
	inBoth,
	isCurrentDay,
	isDayContainCurrentEvent,
	isSelectedMonth,
	timeStampListCount
} from "../../../../helpers";
import {slotIdSave} from "../../../../store/slicers/create-meeting-slice";

import SlotDayFilter from "./slots/SlotDayFilter";
import SlotDayTogether from "./slots/SlotDayTogether";
import SlotDayAll from "./slots/SlotDayAll";
import RedTimeLine from "./RedTimeLine";

import {
	CellWrapper,
	RowInCell,
	HoursMinutes,
	SlotsDay,
} from "../styled-components/CalendarColumnStyled";

export const CalendarColumn = ({dayItem, indexDay, today}) => {

	const {eventSearchListEntertainer} = useSelector(state => state.eventSearchListEntertainer);
	const {eventSearchListKid} = useSelector(state => state.eventSearchListKid);
	const {dashboard} = useSelector(state => state.dashboard);
	const {admin} = useSelector(state => state.admin);
	const {entertainerName} = useSelector(state => state.name);
	const {parentName} = useSelector(state => state.name);

	const [timeStampList, setTimeStampList] = useState([]);
	const [freeSlot, setFreeSlot] = useState([]);
	const [allMeetingsEntertainer, setAllMeetingsEntertainer] = useState([]);

	const dispatch = useDispatch()
	const {handleClickOpenModal, handleClickOpenUpdateModal} = useOpen();

	const hour = dayItem.clone();

	useEffect(() => {
		timeStampListCount(hour, setTimeStampList);
	}, []);

	const togetherMeeting = inBoth(allMeetingsEntertainer, eventSearchListKid);


	useEffect(() => {

		setAllMeetingsEntertainer(eventSearchListEntertainer.reduce((acc, item) => {
			return [...acc, ...item.meetings.map(e => ({...e, id: item.entertainer_id}))]
		}, []))

		const temp = ((eventSearchListEntertainer.length !== 0) ? (
			dashboard.filter(x => eventSearchListEntertainer.some(esle => x.entertainer_id === esle.entertainer_id))
		) : dashboard).reduce((acc, item) => {
			return [...acc, ...item.entertainer_slots.map(e => ({...e, id: item.entertainer_id}))]
		}, []);

		setFreeSlot(temp);
	},[eventSearchListEntertainer, dashboard])


		const eventsNew = freeSlot.filter(event => {
			return isDayContainCurrentEvent(event, dayItem.clone());
	});

	const handleAddMeeting = (time) => {

		if (admin && admin.role === 3) {

			const x = eventsNew.filter(ev => {
				return time >= +moment(ev.slot_time.start_time).format('x')
					&& time <=  +moment(ev.slot_time.end_time).format('x')
			});
			handleClickOpenModal(time,  x);

		}
	}

	const foo = (time,b) => {
		const x = eventsNew.filter(ev => {
			return time >= +moment(ev.slot_time.start_time).format('x')
				&& time <=  +moment(ev.slot_time.end_time).format('x')
		});

		dispatch(slotIdSave(x));
		handleClickOpenUpdateModal(time, b, x);

	}

	return (
		<div>
			<CellWrapper key={dayItem.unix()}
									 isSelectedMonth={isSelectedMonth(dayItem, today)}
									 isWeekend={dayItem.day() === 6 || dayItem.day() === 0}>
				<RowInCell justifyContent={'flex-end'}
									 isSelectedMonth={isSelectedMonth(dayItem, today)}
									 togetherMeetingLength={togetherMeeting.length}
									 allMeetingsEntertainerLength={allMeetingsEntertainer.length}
									 eventSearchListKidLength={eventSearchListKid.length}>
					{isCurrentDay(dayItem) && <RedTimeLine today={dayItem}/>}
					{parentName || entertainerName ? (
						<ul>
							{
								timeStampList.map((stamp, index) => (
									<HoursMinutes key={index}
																isDayWeekend={indexDay === 6 || indexDay === 5}>
										<SlotsDay>
											<SlotDayFilter handleAddMeeting={handleAddMeeting}
																		 stamp={stamp}
																		 eventsNew={eventsNew}
																		 allMeetingsEntertainer={allMeetingsEntertainer}/>
											<SlotDayTogether stamp={stamp}
																			 togetherMeeting={togetherMeeting}/>
										</SlotsDay>
									</HoursMinutes>

								))
							}
						</ul>
					 ) : (
					 	<ul>
					 		{
								timeStampList.map(stamp => (
									<HoursMinutes  key={+stamp.format('x')}
																isDayWeekend={indexDay === 6 || indexDay === 5}>
										<SlotDayAll stamp={stamp}
																eventsNew={eventsNew}
																handleAddMeeting={handleAddMeeting}
																handleClickOpenUpdateModal={foo}/>
									</HoursMinutes>
								))
							}
						</ul>
					)}
				</RowInCell>
			</CellWrapper>
		</div>
	);
};
