import React from 'react';

import account from "../../img/account.png";

import {UlOneKidName, RenderDivChildName} from '../../styled-create-meeting/modal-meeting-styled/create-modal-pieces-styled/CreateModalPiecesStyled';

const OneChildName = ({child}) => {

	return (
		<RenderDivChildName>
			<div>
				<img src={account} alt={account}/>
			</div>
			<UlOneKidName>
				<li>{child[0].first_name}</li>
			</UlOneKidName>
		</RenderDivChildName>
	);
}

export default OneChildName;