import React from "react";
import styled from "styled-components";
import plus from "../plus.png";

export const InputEntertainer = styled.input`
  border: 1px solid #DBE2EA;
  width: 230px;
  padding: 5px 5px 5px 10px;
  border-radius: 6px;
  background-image: url(${plus});
  background-repeat: no-repeat;
  background-position: 205px 10px;
	&::placeholder {
    color: #262323;
    font-size: 12px;
  }
  &:focus {
    outline: none;
  }
`;

export const UlSelectedEntertainer = styled.ul`
	width: 230px;
	border: 1px solid #DBE2EA;
	background-color: #ffffff;
	border-radius: 6px;
	padding: 5px 10px 5px 10px;
	& li{
		display: flex;
		justify-content: space-between;
		& div.img-clear{
      width: 12px;
			& img{
				width: 100%;
				margin-bottom: 2px;
			}
    }
	}
`;

export const SearchDiv = styled.div`
	position: relative;
`;

export const LabelSearchFilter = styled.label`
	position: absolute;
	z-index: 255;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
`;
