import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  isOpenUpdate: false,
  modalProps: null,
  modalPropsMeetings: [],
  modalPropsMeetingsByPages: [],
  modalPropsMeetingId: null,
  modalCurrentPage: 0,
  modalPropsChildrenId: null,
  modalKidName: null,
  modalEntertainerName: null,
  childId: null,
  selectChild: null,
  isOpenMeetingOptions: false,
  isOpenMeetingFilter: false,
  countOfCallsUpdate: null,
  isOpenUserModal: false,
  intersectionErrorIsOpen: false,
  isDeleteRepeatable: false,
};
const stampModal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.modalCurrentPage = action.payload;
    },
    openModal: (state, action) => {
      state.isOpen = true;
    },
    closeModal: (state, action) => {
      state.isOpen = false;
    },
    closeIntersectionError: (state, action) => {
      state.intersectionErrorIsOpen = false;
    },
    openIsDeleteRepeatable: (state, action) => {
      state.isDeleteRepeatable = true;
    },
    closeIsDeleteRepeatable: (state, action) => {
      state.isDeleteRepeatable = false;
    },
    openOpenUserModal: (state, action) => {
      state.isOpenUserModal = true;
    },
    closeOpenUserModal: (state, action) => {
      state.isOpenUserModal = false;
    },
    addProps(state, action) {
      state.modalProps = action.payload;
    },
    addPropsMeetings(state, action) {
      state.modalPropsMeetings = action.payload;

      const pagesCount = Math.ceil(state.modalPropsMeetings.length / 100);
      if (pagesCount === 1) {
        state.modalPropsMeetingsByPages = [state.modalPropsMeetings];
      } else {
        let arr = [];
        let count = 0;
        for (let i = 0; i < pagesCount; i++) {
          arr.push(state.modalPropsMeetings.slice(count, count + 100));
          count += 100;
        }
        state.modalPropsMeetingsByPages = arr;
      }
    },
    addPropsMeetingId(state, action) {
      state.modalPropsMeetingId = action.payload;
    },
    clearPropsMeetingId: (state, action) => {
      state.modalPropsMeetingId = null;
    },
    addKidName(state, action) {
      state.modalKidName = action.payload;
    },
    addKidNameClear: (state, action) => {
      state.modalKidName = null;
    },
    addEntertainerName(state, action) {
      state.modalEntertainerName = action.payload;
    },
    addEntertainerNameClear(state, action) {
      state.modalEntertainerName = null;
    },
    setSelectChild(state, action) {
      state.selectChild = action.payload;
    },
    setSelectChildClear: (state, action) => {
      state.selectChild = null;
    },
    openOpenMeetingOptions: (state, action) => {
      state.isOpenMeetingOptions = true;
      state.modalCurrentPage = 0;
    },
    closeOpenMeetingOptions: (state, action) => {
      state.isOpenMeetingOptions = false;
      state.modalCurrentPage = 0;
    },
    OpenIsOpenMeetingFilter: (state, action) => {
      state.isOpenMeetingFilter = true;
    },
    closeIsOpenMeetingFilter: (state, action) => {
      state.isOpenMeetingFilter = false;
    },
    countOfCallsUpdateSave(state, action) {
      state.countOfCallsUpdate = action.payload;
    },
  },
});

export const {
  setCurrentPage,
  openModal,
  closeModal,
  addProps,
  addKidName,
  addEntertainerName,
  setSelectChild,
  setSelectChildClear,
  addEntertainerNameClear,
  addKidNameClear,
  closeOpenMeetingOptions,
  openOpenMeetingOptions,
  addPropsMeetings,
  addPropsMeetingId,
  OpenIsOpenMeetingFilter,
  closeIsOpenMeetingFilter,
  clearPropsMeetingId,
  closeOpenUserModal,
  openOpenUserModal,
  countOfCallsUpdateSave,
  closeIntersectionError,
  closeIsDeleteRepeatable,
  openIsDeleteRepeatable,
} = stampModal.actions;

export default stampModal.reducer;
